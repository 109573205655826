import {
  ArrowBackIos,
  ArrowBackIosNew,
  Clear,
  RemoveCircle,
  RemoveCircleOutline,
} from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/system";
import { debounce, groupBy } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import noProdFound from "../../../assets/images/FrankIcons/New folder/noProdFound.png";
import { useNavigate } from "react-router-dom";
import { FrankIcon, NoPointsFound } from "../../../assets/images/FrankIcons";
import apiServices from "../../../services/RequestHandler";
import { RootState } from "../../../Store";
import { strings } from "../../../Store/Localization";
import { getAllAddresses, getCart } from "../../../Store/MainAppReducer";
import GoogleAutocompleteField from "../../../UiComponent/GoogleAutocompleteField";
import GoogleMaps from "../../../UiComponent/GoogleMaps";
import HeaderForMarketPlace from "../../../UiComponent/HeaderForMarketPlace";
import Loader from "../../../UiComponent/Loader";
import InputField from "../../../UiComponent/TextFeild";
import {
  apiKey,
  defaultLabelColors,
  no_image_url,
  week_day_translation,
} from "../../../utils/constants";
import {
  handleGetMachineDetails,
  handleLocalize,
  numberFormatWithoutCurrency,
} from "../../../utils/helper";
import { saveDataToLocalStorage } from "../../../utils/LocalStore.util";
import { color_theme } from "../../SenderInbox.tsx/AccepterChat";
import InjectedCheckoutForm from "./StripeCheckout";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { decrypt } from "../../../utils/Encrypt";
import Toast from "../../../UiComponent/Toast/Toast";
import CustomFooter from "../../../UiComponent/CustomFooter";
import { ProductCard } from "..";
import {
  CheckedRadioIcon,
  UnCheckedSelectedRadioIcon,
} from "../AllProductByCateogry";
const stripePromise = loadStripe(
  "pk_test_51PHLwERrtK56w4VJovEUBRsfDb0K90Mo3tt7QrypEedKpj9HuTszcu8bnI8SnXsmA7EWSwZ87LorkcfAHi7zXz4l00kogJUBL5"
  // "pk_live_51PHLwERrtK56w4VJcMQLW2zbn7LTZPNZnBLUmFdMIakX5v5HKsPRllmYUMmWJXvmPVJaptcEN8uxmQdzDNVJYFUC0050uiD5gE"
);
export default function Checkout(props: any) {
  const mediaQuery = useMediaQuery("(max-width:500px)");
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));
  const mediaQuery800 = useMediaQuery("(max-width:800px)");
  const selector = useSelector((state: RootState) => state.mainApp);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      loading: false,
      step: 1,
      cart_data: [],
      cartByService: [],
      shipment_fees: {},
      temp_billing_addr: null,
      global_search: "",
    }
  );
  const handleAddToFavorite = async (item: any) => {
    try {
      let obj = {
        product_id: Number(item?.id),
      };

      let resp = await apiServices.postFromApi(
        "c2c-product-wishlists",
        obj,
        ""
      );
    } catch (error) {}
  };
  const handleUpdateQuantity = async (cartItem: any, qty: number) => {
    try {
      if (qty === 0) {
        handleRemoveItem(cartItem);
        return;
      }

      let update_cart_body = {
        id: Number(cartItem?.cart_id),
        quantity: Number(qty),
        shop_id: Number(cartItem?.shop_id),
        product_variation_id: Number(cartItem?.id),
      };

      const c_resp = await apiServices.patchFromApi(
        `/c2c-carts/${cartItem?.cart_id}`,
        update_cart_body,
        ""
      );
      if (c_resp?.status == 201) {
        dispatch(getCart());
      }
      setTimeout(() => {
        setState({
          loading: false,
        });
      });
    } catch (error) {
      console.log(error);
      setState({
        loading: false,
      });
    }
  };

  const handleRemoveItem = async (item: any) => {
    try {
      await apiServices.deleteFromApi(`/c2c-carts/${item?.id}`, "");
      dispatch(getCart());

      setTimeout(() => {
        setState({ loading: false });
      }, 500);
    } catch (error) {
      console.log(error);
      setState({ loading: false });
    }
  };

  useEffect(() => {
    handlegetRelatedProd();
  }, []);

  const handleGetFrankCharges = async () => {
    try {
      const resp = await apiServices.postFromApi(
        "c2c-orders/platform-fee",
        {},
        ""
      );

      const total_amount = selector?.cart?.reduce((a: any, b: any) => {
        return (
          a +
          Number(Number(b?.price ?? 0) - Number(b?.discount ?? 0)) *
            Number(b?.quantity ?? 1)
        );
      }, 0);

      let charges = 0;
      if (resp?.plarform_charges?.is_per) {
        const dis_price = resp?.plarform_charges?.amount / 100;
        charges = Number(total_amount ?? 0) * dis_price;
      } else {
        charges = resp?.plarform_charges?.amount;
      }

      setState({
        paltform_fees: charges,
      });
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    handleInitCart();

    return () => {
      handleInitCart();
    };
  }, [JSON.stringify(selector.cart)]);

  const handleInitCart = async () => {
    try {
      const cart_api = await apiServices.getFromApi("/c2c-carts", "");
      let data = cart_api?.data?.map((i: any) => {
        return {
          ...i,
          selected_service_method: i?.service_methods?.[0] ?? {},
        };
      });
      handleGetFrankCharges();
      setState({
        cart_data: data,
        loading: false,
      });
    } catch (error: any) {
      setState({
        loading_screen: false,
      });
      console.error(error);
    }
  };

  const handleOpenProduct = (item: any) => {
    try {
      if (item?.is_particular === true) {
        navigate(`/p-product/${item?.id}`);
        return;
      }

      navigate(`/product/${item?.id}`);
    } catch (error) {}
  };

  const handlegetRelatedProd = async () => {
    try {
      let product_ids = [] as any;

      if (selector.cart?.length > 0) {
        selector.cart?.map((item: any) => product_ids.push(Number(item.id)));

        let resp = await apiServices.postFromApiWithoutLang(
          "c2c-carts/related-products",
          [...product_ids],
          ""
        );
        console.log("Related Prod", resp);

        setState({
          related_cart: resp?.data,
        });
      }
    } catch (error) {}
  };

  const handleCalculatePrice = async (data: any) => {
    try {
      setState({ loading: true });
      let api_payload = [];

      for (const c_det of data) {
        let item_weight = 0;

        for (const it of c_det) {
          item_weight += Number(it.net_weight ?? 0);
        }

        if (c_det[0]?.selected_service_method?.s_key === "express") {
          let shops = [];
          for (const i of c_det) {
            shops.push({
              shop_id: i?.shop_details[0]?.id,
              address: i?.shop_details[0]?.address,
              contact: {
                firstname: i?.shop_details[0]?.first_name,
                lastname: i?.shop_details[0]?.last_name,
                phone: `${i?.shop_details[0]?.phone_code ?? ""}${
                  i?.shop_details[0]?.phone
                }`,
                email: i?.shop_details[0]?.email,
                company: i?.shop_details[0]?.shop_name,
              },
            });
          }
          let objc = {
            service_method_id:
              c_det[0]?.selected_service_method?.service_method_id ??
              c_det[0].service_methods[0]?.service_method_id,
            service_method_key:
              c_det[0]?.selected_service_method?.s_key ??
              c_det[0].service_methods[0]?.s_key,
            weight: Number(item_weight ?? 1000),
            height: Number(c_det[0]?.height ?? 55),
            width: Number(c_det[0]?.width ?? 40),
            length: Number(c_det[0]?.length ?? 40),
            customer: {
              address: c_det[0].selected_address?.address,
              contact: {
                firstname: selector?.user.first_name,
                lastname: selector?.user.surname,
                phone: `${selector?.user.phone_code ?? ""}${
                  selector?.user.phone
                }`,
                email: selector?.user.email,
              },
            },
            shop_details: shops,
            id: Number(c_det[0]?.id),
          };
          api_payload.push(objc);
          continue;
        }

        let objc = {
          service_method_id:
            c_det[0]?.selected_service_method?.service_method_id ??
            c_det[0].service_methods[0]?.service_method_id,
          service_method_key:
            c_det[0]?.selected_service_method?.s_key ??
            c_det[0].service_methods[0]?.s_key,
          weight: Number(item_weight ?? 1000),
          height: Number(c_det[0]?.height ?? 55),
          width: Number(c_det[0]?.width ?? 40),
          length: Number(c_det[0]?.length ?? 40),
          customer: {
            address: c_det.selected_address?.address,
            contact: {
              firstname: selector?.user.first_name,
              lastname: selector?.user.surname,
              phone: `${selector?.user.phone_code ?? ""}${
                selector?.user.phone
              }`,
              email: selector?.user.email,
            },
          },
          shop_details: {
            address: c_det[0]?.shop_details[0]?.address ?? ``,
            contact: {
              firstname: c_det[0]?.shop_details[0]?.first_name,
              lastname: c_det[0]?.shop_details[0]?.last_name,
              phone: `${c_det[0]?.shop_details[0]?.phone_code ?? ""}${
                c_det[0]?.shop_details[0]?.phone
              }`,
              email: c_det[0]?.shop_details[0]?.email,
              company: c_det[0]?.shop_details[0]?.shop_name,
            },
          },
          id: Number(c_det[0]?.id),
        };
        api_payload.push(objc);
      }

      const resp = await apiServices.postFromApi(
        "c2c-orders/new-shipment-price",
        {
          data: api_payload,
        },
        ""
      );
      let total_shipment_amt = 0;
      for (const key in resp?.shipment_prices) {
        if (Object.prototype.hasOwnProperty.call(resp?.shipment_prices, key)) {
          const element = resp?.shipment_prices[key];
          total_shipment_amt += Number(element?.shipment_price ?? 0);
        }
      }

      setState({
        shipment_fees: resp?.shipment_prices,
        refreshing: false,
        loading: false,
        total_shipment_amt,
      });
    } catch (error) {
      console.log(error);
      setState({
        refreshing: false,
        loading: false,
      });
    }
  };

  const handleStepChange = async (step: number) => {
    try {
      if (step === 2) {
        const grouped_data = groupBy(
          state.cart_data,
          "selected_service_method.service_method_id"
        );
        let cartByService = [];
        for (const key in grouped_data) {
          let single_item = [];
          if (Object.prototype.hasOwnProperty.call(grouped_data, key)) {
            const element = grouped_data[key];
            for (const i of element) {
              single_item.push(i);
            }
            cartByService.push(single_item);
          }
        }

        handleCalculatePrice(cartByService);

        saveDataToLocalStorage("temp_cart", {
          data: state.cart_data,
          step,
          cartByService,
        });
        setState({
          cartByService,
          loading: false,
        });
      }
      if (step === 3) {
        // if (state.step === 3) {
        //   setState({ step: 2 });
        //   return;
        // }
        // setState({ step: 3 });

        // return;
        saveDataToLocalStorage("temp_cart", {
          data: state.cart_data,
          step,
          cartByService: state.cartByService,
        });
        let api_payload: any = [];

        const cart_data = [];

        for (const element of state.cartByService) {
          for (const i of element) {
            cart_data.push(i);
          }
        }

        const data = groupBy(cart_data, "shop_id");
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            const element = data[key];

            const grouped_service_methods = groupBy(
              element,
              "selected_service_method.service_method_id"
            );

            for (const s_key in grouped_service_methods) {
              if (
                Object.prototype.hasOwnProperty.call(
                  grouped_service_methods,
                  s_key
                )
              ) {
                const it = grouped_service_methods[s_key];
                let _object: any = {
                  shop_id: Number(key),
                  channel: "web",
                  dropoff_address_id: Number(it?.[0]?.selected_address?.id),
                  // dropoff_address: it?.[0]?.selected_address?.address
                  //   ? {
                  //       ...it?.[0]?.selected_address,
                  //       location: {
                  //         type: "Point",
                  //         coordinates: [
                  //           it?.[0]?.selected_address?.pickupLocation?.result[0]
                  //             ?.latitude,
                  //           it?.[0]?.selected_address?.pickupLocation?.result[0]
                  //             ?.longitude,
                  //         ],
                  //       },
                  //     }
                  //   : null,
                  order_date: moment().format("YYYY-MM-DD"),
                  order_time: moment().utc().format("HH:mm"),
                  items: [],
                };

                const element = grouped_service_methods[s_key] as any;
                _object.service_method_id = Number(s_key);
                let items = [];
                let delivery_fee = 0;
                let partners_point = [];
                for (const it of element) {
                  items.push({
                    product_id: Number(it?.product_id),
                    product_variation_id: Number(it?.id),
                    quantity: Number(it?.quantity),
                    unit_price: Number(it?.price) - Number(it?.discount ?? 0),
                    discount: Number(it?.discount ?? 0),
                  });
                }
                if (element[0].selected_mondial?.id) {
                  partners_point.push(element[0].selected_mondial?.id);
                }
                _object.delivery_fee = delivery_fee;
                _object.items = items;
                _object.partners_point = partners_point;
                api_payload.push(_object);
              }
            }
          }
        }

        let final_api_payload = [];

        for (const item of api_payload) {
          let obj = {
            ...item,
            delivery_fee:
              state?.shipment_fees?.[item.service_method_id]?.shipment_price ??
              0,
          };

          final_api_payload.push(obj);
        }
        const resp = await apiServices.postFromApi(
          "/c2c-orders/new",
          {
            orders: final_api_payload,
            promo_code: state.promo_code_applied
              ? state.promo_code_data?.promo_code
              : null,
            platform_fee: state.paltform_fees,
            channel: "Web",
          },
          ""
        );

        if (resp.status === 201) {
          saveDataToLocalStorage("temp_cart", {
            cart_data,
            step,
            cartByService: state.cartByService,
            order_placed: true,
            order_detail: resp?.data,
            transaction_id: resp?.data?.id,
          });

          const res = await fetchPaymentSheetParams({
            ...resp?.data,
            total_amount: Number(
              Number(
                total_amount -
                  Number(state.promo_code_data?.discount_amount ?? 0)
              ) +
                Number(
                  state.promo_code_data?.is_platform_charges
                    ? 0
                    : state.paltform_fees ?? 1.99
                ) +
                Number(
                  state.promo_code_data?.is_delivery_charges
                    ? 0
                    : state.total_shipment_amt ?? 0
                )
            ),
          });
          setState({
            transaction_id: resp?.data?.id,
            stripeForm: {
              show_stripe_form: true,
              paymentIntent: res?.paymentIntent,
            },
            loading: false,
          });
        }
      }
      setState({
        step,
      });
    } catch (error) {
      console.log(error);
      setState({
        loading: false,
      });
    }
  };

  const handleSelectAddress = async () => {
    try {
      let { selected_index, selected_address, cartByService } = state;
      const { first_name, last_name, address } = selected_address ?? {};
      if (!first_name) {
        Toast.Error(`Le prénom est obligatoire`);
        return;
      }
      if (!last_name) {
        Toast.Error(`le nom de famille est obligatoire`);
        return;
      }
      if (!address) {
        Toast.Error(`l'adresse est obligatoire`);
        return;
      }

      let api_payload: any = {
        location: {
          type: "Point",
          coordinates: selected_address?.id
            ? [
                Number(selected_address?.location?.coordinates?.[0] ?? 0),
                Number(selected_address?.location?.coordinates?.[1] ?? 0),
              ]
            : [
                Number(
                  selected_address?.pickupLocation.result[0]?.latitude ?? 0
                ),
                Number(
                  selected_address?.pickupLocation.result[0]?.longitude ?? 0
                ),
              ],
        },
        address: selected_address?.address ?? "",
        digital_code: selected_address?.digital_code,
        city: selected_address?.city ?? "",
        country: selected_address?.country ?? "",
        first_name: selected_address?.first_name.trim(),
        last_name: selected_address?.last_name.trim(),
        is_primary: selected_address?.is_primary,
        additional_info: `${selected_address?.additional_info ?? ""}`.trim(),
      };

      let resp;

      if (selected_address?.id) {
        api_payload["id"] = Number(selected_address?.id);
        resp = await apiServices.patchFromApi(
          "/c2c-addresses",
          api_payload,
          ""
        );
      } else {
        resp = await apiServices.postFromApi("/c2c-addresses", api_payload, "");
      }

      let i = cartByService[selected_index];

      let arr = [];
      if (resp.status === 201) {
        for (const it of i) {
          let obj = {
            ...it,
            selected_address: resp?.data,
          };
          arr.push(obj);
        }
        dispatch(
          getAllAddresses({
            end_point: "/c2c-addresses",
          })
        );
      } else {
        for (const it of i) {
          let obj = {
            ...it,
            selected_address,
          };
          arr.push(obj);
        }
      }

      cartByService[selected_index] = arr;

      setState({
        cartByService,
        show_address_comp: false,
        selected_address: null,
        selected_index: null,
        re_render: new Date(),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectBillingAddress = async () => {
    try {
      let { temp_billing_addr } = state;
      const { first_name, last_name, address } = temp_billing_addr ?? {};
      if (!first_name) {
        Toast.Error(`Le prénom est obligatoire`);
        return;
      }
      if (!last_name) {
        Toast.Error(`le nom de famille est obligatoire`);
        return;
      }
      if (!address) {
        Toast.Error(`l'adresse est obligatoire`);
        return;
      }

      const billing_address = {
        ...temp_billing_addr,
      };

      setState({
        show_billing_addr: false,
        temp_billing_addr: null,
        billing_address,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (state.re_render) {
      handleCalculatePrice(state.cartByService);
    }
  }, [state.cartByService, state.re_render]);

  const onInputChange = (event: any) => {
    try {
      const { name, value } = event.target;
      let { selected_address } = state;
      selected_address = {
        ...selected_address,
        [name]: value,
      };
      setState({
        selected_address,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onBillingAddress = (event: any) => {
    try {
      const { name, value } = event.target;
      let { temp_billing_addr } = state;
      temp_billing_addr = {
        ...temp_billing_addr,
        [name]: value,
      };
      setState({
        temp_billing_addr,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handlePlaceSelectedBilling = async (
    place: google.maps.places.PlaceResult | null,
    from_mondial?: boolean
  ) => {
    let { temp_billing_addr } = state;
    if (place && place.geometry) {
      const lat = place.geometry.location?.lat() || 0;
      const lng = place.geometry.location?.lng() || 0;

      const pickupLocation = await apiServices.getFromApi(
        `helper/get-address?lat=${lat}&lng=${lng}`,
        ""
      );
      temp_billing_addr = {
        ...temp_billing_addr,
        pickupLocation,
        city: pickupLocation?.result?.[0]?.city ?? "",
        completeAddress: pickupLocation?.result?.[0]?.formattedAddress ?? "",
        address: pickupLocation?.result?.[0]?.formattedAddress ?? "",
        digital_code: pickupLocation?.result?.[0]?.zipcode ?? "",
        country: pickupLocation?.result?.[0]?.country ?? "",
      };
      setState({
        temp_billing_addr,
      });
    }
  };

  const handlePlaceSelected = async (
    place: google.maps.places.PlaceResult | null,
    from_mondial?: boolean
  ) => {
    let { selected_address } = state;
    if (!from_mondial) {
      if (place && place.geometry) {
        const lat = place.geometry.location?.lat() || 0;
        const lng = place.geometry.location?.lng() || 0;

        const pickupLocation = await apiServices.getFromApi(
          `helper/get-address?lat=${lat}&lng=${lng}`,
          ""
        );
        selected_address = {
          ...selected_address,
          pickupLocation,
          city: pickupLocation?.result?.[0]?.city ?? "",
          completeAddress: pickupLocation?.result?.[0]?.formattedAddress ?? "",
          address: pickupLocation?.result?.[0]?.formattedAddress ?? "",
          digital_code: pickupLocation?.result?.[0]?.zipcode ?? "",
          country: pickupLocation?.result?.[0]?.country ?? "",
        };
        setState({
          selected_address,
        });
      }
    } else {
      if (place && place.geometry) {
        const lat = place.geometry.location?.lat() || 0;
        const lng = place.geometry.location?.lng() || 0;

        const pickupLocation = await apiServices.getFromApi(
          `helper/get-address?lat=${lat}&lng=${lng}`,
          ""
        );
        const mondial_address = {
          ...pickupLocation?.result[0],
          completeAddress: pickupLocation?.result?.[0]?.formattedAddress ?? "",
          address: pickupLocation?.result?.[0]?.formattedAddress ?? "",
        };
        setState({
          mondial_address,
        });
      }
    }
  };

  const handleCloseMondialDialog = () => {
    try {
      setState({
        selected_address: null,
        show_mondial: false,
        selected_index: null,
        mondial_address: null,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onMondialSearch = async (place: any) => {
    const mondial_address = {
      place,
      city: place?.result?.city ?? "",
      completeAddress: place?.result?.formattedAddress ?? "",
      address: place?.result?.formattedAddress ?? "",
      digital_code: place?.result?.zipcode ?? "",
      country: place?.result?.country ?? "",
    };
    setState({
      mondial_address,
    });
  };

  const getMondialPoints = async (data: any) => {
    try {
      const { cartByService, selected_index } = state;
      const d = cartByService[selected_index];

      let item_weight = 0;

      for (const it of d) {
        item_weight += Number(it.net_weight ?? 0);
      }

      let obj = {
        city: String(data?.city),
        countryCode: String(data?.countryCode) ?? "FR",
        latitude: String(data?.latitude),
        longitude: String(data?.longitude),
        zipcode: data?.zipcode ? String(data?.zipcode) : "75002",
        weight: item_weight ? String(item_weight) : "1000",
      };
      let resp = await apiServices.postFromApi(
        "c2c-orders/mondial-points",
        obj,
        ""
      );
      console.log(resp?.relay_points);
      setState({
        mondialPoints: resp?.relay_points,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectMondial = async (data: any) => {
    try {
      let { selected_index, cartByService } = state;

      let i = cartByService[selected_index];

      let arr = [];
      console.log(data);

      for (const it of i) {
        let obj = {
          ...it,
          selected_mondial: data,
        };
        arr.push(obj);
      }

      cartByService[selected_index] = arr;

      setState({
        cartByService,
        show_address_comp: false,
        selected_address: null,
        selected_index: null,
        show_mondial: false,
        mondial_address: null,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmPayment = async () => {
    try {
      const resp2 = await apiServices.postFromApi(
        "/c2c-orders/submit-order",
        {
          order_transaction_id: Number(state?.transaction_id),
          billing_address: state.billing_address?.address
            ? {
                ...state.billing_address,
                location: {
                  type: "Point",
                  coordinates: [
                    state.billing_address?.pickupLocation?.result[0]?.latitude,
                    state.billing_address?.pickupLocation?.result[0]?.longitude,
                  ],
                },
              }
            : null,
        },
        ""
      );

      setState({
        show_success: true,
        loading: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateQuantityByMethod = async (
    cartItem: any,
    qty: number,
    main_idx: number,
    item_index: number
  ) => {
    try {
      let { cartByService } = state;
      let service_item = cartByService[main_idx];

      if (qty === 0) {
        console.log(cartItem?.id);
        await apiServices.deleteFromApi(`/c2c-carts/${cartItem?.id}`, "");
        dispatch(getCart());
        service_item = service_item?.splice(item_index, 1);
        cartByService[main_idx] = service_item;

        setState({
          cartByService,

          loading: false,
        });
        return;
      }

      let obj = {
        ...service_item[item_index],
        quantity: Number(qty),
      };

      service_item[item_index] = obj;
      cartByService[main_idx] = service_item;
      setState({
        cartByService,
      });

      let update_cart_body = {
        id: Number(cartItem?.cart_id),
        quantity: Number(qty),
        shop_id: Number(cartItem?.shop_id),
        product_variation_id: Number(cartItem?.id),
      };

      const c_resp = await apiServices.patchFromApi(
        `/c2c-carts/${cartItem?.cart_id}`,
        update_cart_body,
        ""
      );
      if (c_resp?.status == 201) {
        dispatch(getCart());
      }
      setTimeout(() => {
        setState({
          loading: false,
        });
      });
    } catch (error) {
      console.log(error);
      setState({
        loading: false,
      });
    }
  };

  const cart_items = selector?.cart?.reduce((a: any, b: any) => {
    return a + Number(b?.quantity ?? 0);
  }, 0);

  const total_amount = selector?.cart?.reduce((a: any, b: any) => {
    return (
      a +
      Number(Number(b?.price ?? 0) - Number(b?.discount ?? 0)) *
        Number(b?.quantity ?? 1)
    );
  }, 0);

  const handleValidatePromoCode = async () => {
    try {
      let promo_codes = [];
      if (!state.promo_code_text) {
        return;
      }
      const data = groupBy(state.cart_data, "shop_id");
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          const element = data[key];

          const grouped_service_methods = groupBy(
            element,
            "selected_service_method.service_method_id"
          );
          for (const s_key in grouped_service_methods) {
            if (
              Object.prototype.hasOwnProperty.call(
                grouped_service_methods,
                s_key
              )
            ) {
              let p_code_obj: any = {
                shop_id: Number(key),
                service_method_id: Number(s_key),
              };

              promo_codes.push(p_code_obj);
            }
          }
        }
      }
      const promo_resp = await apiServices.postFromApi(
        "c2c-promo-codes/validate",
        {
          channel: "web",
          actual_order_amount: Number(total_amount ?? 0),
          total_amount:
            Number(total_amount ?? 0) +
            Number(state.total_shipment_amt ?? 0) +
            Number(state.paltform_fees ?? 0),
          platform_fee: Number(state.paltform_fees ?? 1.99),
          total_shipment_amt: Number(state.total_shipment_amt ?? 0),
          promo_code: state.promo_code_text,
          promo_code_type: "frank_shop",
          shop_orders: promo_codes,
        },
        ""
      );
      console.log("CALL HORHA KHUD ?? ");
      if (promo_resp?.data?.promo_code_id) {
        setState({
          promo_code_applied: true,
          promo_code_data: promo_resp?.data,
        });
      } else {
        alert(
          selector.lang == "en"
            ? "Invalid promo code"
            : "Code promotionnel invalide"
        );
      }

      // const resp = await apiServices.postFromApi("", {}, "");
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPaymentSheetParams = async (data: any) => {
    try {
      const response = await apiServices.postFromApi(
        "stripe/payment-sheet-with-card-frankShop",
        {
          amount: Number(data?.total_amount),
          transaction_id: Number(data?.id ?? 0),
          machine_detail: await handleGetMachineDetails(),
        },
        ""
      );

      let decryptData = JSON.parse(decrypt(response.pi_key));

      if (typeof decryptData === "string") {
        decryptData = JSON.parse(decryptData);
      }

      const { setupIntent, paymentIntent, ephemeralKey, customer } =
        decryptData ?? {};
      console.log(decryptData);
      return {
        paymentIntent,
        ephemeralKey,
        customer,
        setupIntent,
      };
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateServiceMethods = async (
    state_name: string,
    value: any,
    main_idx: number,
    item_index: number
  ) => {
    try {
      let { cartByService } = state;
      let service_item = cartByService[main_idx];
      let obj = {
        ...service_item[item_index],
        [state_name]: value,
      };
      service_item[item_index] = obj;
      cartByService[main_idx] = service_item;

      let unstructured = [];
      for (const i of cartByService) {
        for (const element of i) {
          unstructured.push(element);
        }
      }

      const grouped_data = groupBy(
        unstructured,
        "selected_service_method.service_method_id"
      );
      let updated_cart_by_service = [];
      for (const key in grouped_data) {
        let single_item = [];
        if (Object.prototype.hasOwnProperty.call(grouped_data, key)) {
          const element = grouped_data[key];
          for (const i of element) {
            single_item.push(i);
          }
          updated_cart_by_service.push(single_item);
        }
      }
      handleCalculatePrice(updated_cart_by_service);

      saveDataToLocalStorage("temp_cart", {
        data: unstructured,
        step: 2,
        cartByService,
      });

      setState({
        cartByService: updated_cart_by_service,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      setState({
        loading: false,
      });
    }
  };

  const onInputChangeForHeader = (e: any) => {
    try {
      const { value, name } = e.target;
      setState({
        [name]: value,
      });
    } catch (error) {}
  };

  const handleSearchProducts = useCallback(
    debounce(async (searchValue: any) => {
      try {
        if (!searchValue) {
          setState({ activity: true });
          setState({
            showSearchedData: false,
            allSearchProduct: null,
            activity: false,
          });
        }
        if (searchValue?.trim()) {
          setState({ activity: true });
          let resp = await apiServices.postFromApi(
            "c2c-product-masters/products-search",
            { search: searchValue },
            ""
          );
          if (resp?.data?.length < 1) {
            setState({
              showImg: true,
            });
          }
          setState({
            allSearchProduct: resp?.data,
            activity: false,
            showSearchedData: true,
          });
        }
      } catch (error) {
        console.error("Error while searching products:", error);
      }
    }, 1500),
    []
  );
  useEffect(() => {
    handleSearchProducts(state.global_search);
  }, [state.global_search, handleSearchProducts]);

  const deleteAddress = async (addr: any) => {
    try {
      const resp = await apiServices.patchFromApi(
        `/c2c-addresses/${addr?.id}`,
        {},
        ""
      );
      dispatch(
        getAllAddresses({
          end_point: "/c2c-addresses",
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        background: defaultLabelColors.main_background_two,
      }}
    >
      <Helmet>
        <title>Cart Details | Frank</title>
        <meta name="description" content="" />
      </Helmet>
      <Loader loading={state?.loading} />
      {state.step === 1 && (
        <HeaderForMarketPlace
          {...props}
          {...state}
          onInputChangeForHeader={onInputChangeForHeader}
        />
      )}

      {state?.showSearchedData ? (
        <div
          style={{
            minHeight: "100vh",
            padding: "0px 50px 0px 50px",
            background: defaultLabelColors.main_background_two,
          }}
        >
          <Grid container spacing={2}>
            <Grid
              xs={12}
              item
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              onClick={() => {
                setState({ showSearchedData: false, global_search: "" });
              }}
            >
              <p
                style={{
                  fontFamily: "Raleway-Variable",
                  fontSize: "26px",
                  gap: 10,
                  cursor: "pointer",
                }}
                className="display-flex"
              >
                <ArrowBackIos style={{ fontSize: "22px" }} /> Vendre un article
              </p>
            </Grid>
          </Grid>

          {state?.allSearchProduct?.length < 1 ||
          !state?.allSearchProduct?.length ? (
            <div
              style={{
                height: "60vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                opacity: "0.5",
              }}
            >
              {state?.showImg && (
                <img
                  src={noProdFound}
                  alt=""
                  style={{ height: "400px", width: "400px" }}
                />
              )}
            </div>
          ) : (
            <>
              <p style={{ fontFamily: "Raleway-Semi-Bold", fontSize: "16px" }}>
                {state?.allSearchProduct?.length} annonces
              </p>
              <Grid container spacing={2}>
                {state?.allSearchProduct
                  ?.slice(0, xl ? 6 : lg ? 4 : md ? 3 : 4)
                  ?.map((i: any, idx: any) => {
                    return (
                      <Grid
                        key={idx}
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        lg={3}
                        xl={2}
                        style={{ cursor: "pointer", maxHeight: "370px" }}
                        onClick={() => {
                          handleOpenProduct(i);
                        }}
                      >
                        <ProductCard
                          i={i}
                          handleAddToFavorite={handleAddToFavorite}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </>
          )}
        </div>
      ) : (
        <>
          <div
            style={{
              padding: mediaQuery ? 20 : "40px 80px",
            }}
          >
            <div
              style={{
                minHeight: state.step === 1 ? 226 : 120,
                background: state.step === 1 ? color_theme.white : "#FEF5F1",
                borderRadius: 16,
                width: "100%",
              }}
            >
              {state.step === 1 ? (
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    display: selector.cart?.length ? "none" : "flex",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src="https://cdni.iconscout.com/illustration/premium/thumb/empty-cart-7359557-6024626.png?f=webp"
                    style={{
                      height: 200,
                      width: 200,
                      objectFit: "contain",
                    }}
                  />
                  <div>
                    <p>{strings.no_items_cart}</p>
                    <Button onClick={() => navigate("/marketplace")}>
                      {strings.start_shipping}
                    </Button>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    height: 120,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingLeft: 20,
                  }}
                >
                  <FrankIcon
                    fill={color_theme.main_orange}
                    width={120}
                    height={63.65}
                  />
                  <div style={{ flex: 1 }}>
                    <p
                      style={{
                        fontSize: 30,
                        fontFamily: "Raleway-Bold",
                        lineHeight: "35.22px",
                        margin: 0,
                        textAlign: "center",
                        marginLeft: -20,
                      }}
                    >
                      {state.show_address_comp
                        ? "Mode de Livraison"
                        : state.step === 2
                        ? "Mode de Livraison et Paiement"
                        : ""}
                    </p>
                  </div>
                </div>
              )}
            </div>

            {state.show_address_comp ? (
              <>
                <div
                  style={{
                    margin: "30px 0 10px 0px",
                    rowGap: 6,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    columnGap: 10,
                  }}
                >
                  <ArrowBackIosNew
                    onClick={() => {
                      setState({
                        show_address_comp: false,
                        selected_address: null,
                        selected_index: null,
                      });
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                  <p
                    style={{
                      fontFamily: "Raleway-Bold",
                      fontSize: 32,
                      lineHeight: "40px",
                      margin: 0,
                    }}
                  >
                    Mode de Livraison
                  </p>
                </div>

                {selector.addresses?.length > 0 ? (
                  <Grid container spacing={2}>
                    <Grid xs={12} md={4} item>
                      <div className="hide_scroll">
                        <p
                          style={{
                            fontFamily: "Raleway-Bold",
                            fontSize: 20,
                            lineHeight: "28px",
                            margin: 0,
                          }}
                        >
                          My Saved Addreses
                        </p>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: 16,
                          }}
                        >
                          {selector.addresses?.map((addr: any) => (
                            <div
                              style={{
                                padding: 16,
                                background: color_theme.white,
                                borderRadius: 8,
                                display: "flex",
                                flexDirection: "row",
                                columnGap: 10,
                              }}
                              onClick={() => {
                                setState({
                                  selected_address: {
                                    ...addr,
                                    is_primary: true,
                                  },
                                });
                              }}
                            >
                              <div
                                style={{
                                  paddingTop: 3,
                                }}
                              >
                                {state.selected_address?.id == addr?.id ? (
                                  <div
                                    style={{
                                      borderRadius: "100%",
                                      border: `2px solid rgb(238, 105, 49)`,
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: 10,
                                        height: 10,
                                        borderRadius: "100%",
                                        border: "2px solid rgb(255 255 255)",
                                        background: "rgb(238, 105, 49)",
                                      }}
                                    ></div>
                                  </div>
                                ) : (
                                  <UnCheckedSelectedRadioIcon />
                                )}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  rowGap: 6,
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      margin: 0,
                                      fontSize: 16,
                                      fontFamily: "Raleway-Bold",
                                      lineHeight: "24px",
                                    }}
                                  >{`${addr?.first_name ?? ""} ${
                                    addr?.last_name ?? ""
                                  }`}</p>
                                  <p
                                    style={{
                                      textDecoration: "underline",
                                      textAlign: "right",
                                      cursor: "pointer",
                                      margin: 0,
                                      fontSize: 16,
                                      fontFamily: "Raleway-Variable",
                                      lineHeight: "24px",
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setState({ selected_address: null });
                                      deleteAddress(addr);
                                    }}
                                  >
                                    Supprimer
                                  </p>
                                </div>
                                <p
                                  style={{
                                    margin: 0,
                                    fontSize: 16,
                                    fontFamily: "Raleway-Variable",
                                    lineHeight: "24px",
                                  }}
                                >
                                  {addr?.address ?? ""}
                                </p>
                                <p
                                  style={{
                                    margin: 0,
                                    fontSize: 16,
                                    fontFamily: "Raleway-Variable",
                                    lineHeight: "24px",
                                  }}
                                >
                                  {`${addr?.city ?? ""} ${addr?.digital_code} ${
                                    addr?.country
                                  }`}
                                </p>
                                <p
                                  style={{
                                    margin: 0,
                                    fontSize: 16,
                                    fontFamily: "Raleway-Variable",
                                    lineHeight: "24px",
                                  }}
                                >
                                  Complément d’adresse:{" "}
                                  {`${addr?.additional_info ?? ""}`}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Grid>
                    <Grid xs={12} md={8} item>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "80%",
                            minHeight: "calc(100vh - 280px)",
                          }}
                        >
                          <p
                            style={{
                              fontFamily: "Raleway-Bold",
                              fontSize: 20,
                              lineHeight: "28px",
                              margin: 0,
                            }}
                          >
                            Ajouter une adresse de livraison
                          </p>
                          <div style={{ marginTop: 16 }}>
                            <Grid container spacing={2}>
                              <Grid xs={12} md={6} item>
                                <InputField
                                  placeHolder={"Nom *"}
                                  input_value={
                                    state.selected_address?.first_name
                                  }
                                  input_name={"first_name"}
                                  input_style={{
                                    background: "#fff",
                                    width: "100%",
                                    borderRadius: 8,
                                    border: "none",
                                    margin: 0,
                                  }}
                                  onchange={onInputChange}
                                />
                              </Grid>
                              <Grid xs={12} md={6} item>
                                <InputField
                                  placeHolder={"Prénom *"}
                                  input_value={
                                    state.selected_address?.last_name
                                  }
                                  input_name={"last_name"}
                                  input_style={{
                                    background: "#fff",
                                    width: "100%",
                                    borderRadius: 8,
                                    border: "none",
                                    margin: 0,
                                  }}
                                  onchange={onInputChange}
                                />
                              </Grid>
                              <Grid xs={12} item>
                                <div
                                  style={{
                                    background: "#fff",
                                    fontSize: 16,
                                    width: "100%",
                                    borderRadius: 8,
                                  }}
                                >
                                  <GoogleAutocompleteField
                                    apiKey={apiKey}
                                    placeholder="Adresse de livraison*"
                                    inputValue={
                                      state.selected_address?.address ?? ""
                                    }
                                    onInputChange={(text) => {
                                      onInputChange({
                                        target: {
                                          name: "address",
                                          value: text,
                                        },
                                      });
                                    }}
                                    onPlaceSelected={handlePlaceSelected}
                                    key={"selectedLocation"}
                                    styles={{
                                      border: "none",
                                      fontSize: 16,
                                      minHeight: 56,
                                      borderRadius: 8,
                                      fontFamily: "Raleway-Italic",
                                      width: "calc(100% - 15px)",
                                      color: "#000",
                                      paddingLeft: 15,
                                    }}
                                  />
                                </div>
                              </Grid>
                              <Grid xs={12} item>
                                <InputField
                                  placeHolder={
                                    "Complément d’adresse ( Étage, Bâtiment, Digicode...)"
                                  }
                                  input_value={
                                    state.selected_address?.additional_info
                                  }
                                  input_name={"additional_info"}
                                  input_style={{
                                    background: "#fff",
                                    width: "100%",
                                    borderRadius: 8,
                                    border: "none",
                                    margin: 0,
                                  }}
                                  isMultiLine
                                  input_rows={2}
                                  onchange={onInputChange}
                                />
                              </Grid>
                              <Grid xs={12} md={6} item>
                                <InputField
                                  placeHolder={"Ville *"}
                                  input_value={state.selected_address?.city}
                                  input_name={"city"}
                                  input_style={{
                                    background: "#fff",
                                    width: "100%",
                                    borderRadius: 8,
                                    border: "none",
                                    margin: 0,
                                  }}
                                  onchange={onInputChange}
                                />
                              </Grid>
                              <Grid xs={12} md={6} item>
                                <InputField
                                  placeHolder={"Code postal*"}
                                  input_value={
                                    state.selected_address?.digital_code
                                  }
                                  input_name={"digital_code"}
                                  input_style={{
                                    background: "#fff",
                                    width: "100%",
                                    borderRadius: 8,
                                    border: "none",
                                    margin: 0,
                                  }}
                                  onchange={onInputChange}
                                />
                              </Grid>
                              <Grid xs={12} item>
                                <div
                                  style={{
                                    background: "#fff",
                                    fontSize: 16,
                                    borderRadius: 12,
                                    paddingLeft: 15,
                                    height: 56,
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  <FormControlLabel
                                    value={
                                      state?.selected_address?.is_primary ??
                                      false
                                    }
                                    control={
                                      <Checkbox
                                        checked={
                                          state?.selected_address?.is_primary
                                        }
                                        onChange={(event) =>
                                          onInputChange({
                                            target: {
                                              name: "is_primary",
                                              value: event.target.checked,
                                            },
                                          })
                                        }
                                      />
                                    }
                                    label="Utiliser cette adresse comme adresse par défaut"
                                  />
                                </div>
                              </Grid>
                              <Grid xs={12} item>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Button
                                    sx={{
                                      ":disabled": {
                                        background: "#E0E0E0",
                                        color: "#7C7C7C",
                                        fontFamily: "Raleway-Bold",
                                      },
                                      ":hover": {
                                        background: color_theme.white,
                                        color: color_theme.main_blue,
                                        textTransform: "none",
                                        minHeight: 48,
                                      },
                                      ":root": {
                                        borderRadius: 8,
                                      },
                                      background: color_theme.white,
                                      color: color_theme.main_blue,
                                      textTransform: "none",
                                      minHeight: 48,
                                    }}
                                    style={{
                                      borderRadius: 8,
                                      paddingInline: 15,
                                      marginRight: 16,
                                    }}
                                    onClick={() => {
                                      setState({
                                        selected_address: null,
                                      });
                                      // handleSelectAddress();
                                    }}
                                  >
                                    Clear Feilds
                                  </Button>

                                  <Button
                                    sx={{
                                      ":disabled": {
                                        background: "#E0E0E0",
                                        color: "#7C7C7C",
                                        fontFamily: "Raleway-Bold",
                                      },
                                      ":hover": {
                                        background: color_theme.main_blue,
                                        color: color_theme.white,
                                        textTransform: "none",
                                        minHeight: 48,
                                      },
                                      ":root": {
                                        borderRadius: 8,
                                      },
                                      background: color_theme.main_blue,
                                      color: color_theme.white,
                                      textTransform: "none",
                                      minHeight: 48,
                                    }}
                                    style={{
                                      borderRadius: 8,
                                      paddingInline: 15,
                                    }}
                                    onClick={() => {
                                      handleSelectAddress();
                                    }}
                                  >
                                    Ajouter cette adresse
                                  </Button>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: mediaQuery800 || mediaQuery ? "80%" : "50%",
                        minHeight: "calc(100vh - 280px)",
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "Raleway-Bold",
                          fontSize: 20,
                          lineHeight: "28px",
                          margin: 0,
                        }}
                      >
                        Ajouter une adresse de livraison
                      </p>
                      <div style={{ marginTop: 16 }}>
                        <Grid container spacing={2}>
                          <Grid xs={12} md={6} item>
                            <InputField
                              placeHolder={"Nom *"}
                              input_value={state.selected_address?.first_name}
                              input_name={"first_name"}
                              input_style={{
                                background: "#fff",
                                width: "100%",
                                borderRadius: 8,
                                border: "none",
                                margin: 0,
                              }}
                              onchange={onInputChange}
                            />
                          </Grid>
                          <Grid xs={12} md={6} item>
                            <InputField
                              placeHolder={"Prénom *"}
                              input_value={state.selected_address?.last_name}
                              input_name={"last_name"}
                              input_style={{
                                background: "#fff",
                                width: "100%",
                                borderRadius: 8,
                                border: "none",
                                margin: 0,
                              }}
                              onchange={onInputChange}
                            />
                          </Grid>
                          <Grid xs={12} item>
                            <div
                              style={{
                                background: "#fff",
                                fontSize: 16,
                                width: "100%",
                                borderRadius: 8,
                              }}
                            >
                              <GoogleAutocompleteField
                                apiKey={apiKey}
                                placeholder="Adresse de livraison*"
                                inputValue={state.selected_address?.address}
                                onInputChange={(text) => {
                                  onInputChange({
                                    target: {
                                      name: "address",
                                      value: text,
                                    },
                                  });
                                }}
                                onPlaceSelected={handlePlaceSelected}
                                key={"selectedLocation"}
                                styles={{
                                  border: "none",
                                  fontSize: 16,
                                  minHeight: 56,
                                  borderRadius: 8,
                                  fontFamily: "Raleway-Italic",
                                  width: "calc(100% - 15px)",
                                  color: "#000",
                                  paddingLeft: 15,
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid xs={12} item>
                            <InputField
                              placeHolder={
                                "Complément d’adresse ( Étage, Bâtiment, Digicode...)"
                              }
                              input_value={
                                state.selected_address?.additional_info
                              }
                              input_name={"additional_info"}
                              input_style={{
                                background: "#fff",
                                width: "100%",
                                borderRadius: 8,
                                border: "none",
                                margin: 0,
                              }}
                              isMultiLine
                              input_rows={2}
                              onchange={onInputChange}
                            />
                          </Grid>
                          <Grid xs={12} md={6} item>
                            <InputField
                              placeHolder={"Ville *"}
                              input_value={state.selected_address?.city}
                              input_name={"city"}
                              input_style={{
                                background: "#fff",
                                width: "100%",
                                borderRadius: 8,
                                border: "none",
                                margin: 0,
                              }}
                              onchange={onInputChange}
                            />
                          </Grid>
                          <Grid xs={12} md={6} item>
                            <InputField
                              placeHolder={"Code postal*"}
                              input_value={state.selected_address?.digital_code}
                              input_name={"digital_code"}
                              input_style={{
                                background: "#fff",
                                width: "100%",
                                borderRadius: 8,
                                border: "none",
                                margin: 0,
                              }}
                              onchange={onInputChange}
                            />
                          </Grid>
                          <Grid xs={12} item>
                            <div
                              style={{
                                background: "#fff",
                                fontSize: 16,
                                borderRadius: 12,
                                paddingLeft: 15,
                                height: 56,
                                alignItems: "center",
                                display: "flex",
                              }}
                            >
                              <FormControlLabel
                                value={state?.selected_address?.is_primary}
                                control={
                                  <Checkbox
                                    checked={
                                      state?.selected_address?.is_primary
                                    }
                                    onChange={(event) =>
                                      onInputChange({
                                        target: {
                                          name: "is_primary",
                                          value: event.target.checked,
                                        },
                                      })
                                    }
                                  />
                                }
                                label="Utiliser cette adresse comme adresse par défaut"
                              />
                            </div>
                          </Grid>
                          <Grid xs={12} item>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                sx={{
                                  ":disabled": {
                                    background: "#E0E0E0",
                                    color: "#7C7C7C",
                                    fontFamily: "Raleway-Bold",
                                  },
                                  ":hover": {
                                    background: color_theme.main_blue,
                                    color: color_theme.white,
                                    textTransform: "none",
                                    minHeight: 48,
                                  },
                                  ":root": {
                                    borderRadius: 8,
                                  },
                                  background: color_theme.main_blue,
                                  color: color_theme.white,
                                  textTransform: "none",
                                  minHeight: 48,
                                }}
                                style={{
                                  borderRadius: 8,
                                  paddingInline: 15,
                                }}
                                onClick={() => {
                                  handleSelectAddress();
                                }}
                              >
                                Ajouter cette adresse
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : state.show_billing_addr ? (
              <>
                <div
                  style={{
                    margin: "30px 0 10px 0px",
                    rowGap: 6,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    columnGap: 10,
                  }}
                >
                  <ArrowBackIosNew
                    onClick={() => {
                      setState({
                        show_billing_addr: false,
                      });
                    }}
                  />
                  <p
                    style={{
                      fontFamily: "Raleway-Bold",
                      fontSize: 32,
                      lineHeight: "40px",
                      margin: 0,
                    }}
                  >
                    Mode de paiement
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: mediaQuery800 || mediaQuery ? "80%" : "50%",
                      minHeight: "calc(100vh - 280px)",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Raleway-Bold",
                        fontSize: 20,
                        lineHeight: "28px",
                        margin: 0,
                      }}
                    >
                      Ajouter une adresse de livraison
                    </p>
                    <div style={{ marginTop: 16 }}>
                      <Grid container spacing={2}>
                        <Grid xs={12} md={6} item>
                          <InputField
                            placeHolder={"Nom *"}
                            input_value={state.temp_billing_addr?.first_name}
                            input_name={"first_name"}
                            input_style={{
                              background: "#fff",
                              width: "100%",
                              borderRadius: 8,
                              border: "none",
                              margin: 0,
                            }}
                            onchange={onBillingAddress}
                          />
                        </Grid>
                        <Grid xs={12} md={6} item>
                          <InputField
                            placeHolder={"Prénom *"}
                            input_value={state.temp_billing_addr?.last_name}
                            input_name={"last_name"}
                            input_style={{
                              background: "#fff",
                              width: "100%",
                              borderRadius: 8,
                              border: "none",
                              margin: 0,
                            }}
                            onchange={onBillingAddress}
                          />
                        </Grid>
                        <Grid xs={12} item>
                          <div
                            style={{
                              background: "#fff",
                              fontSize: 16,
                              width: "100%",
                              borderRadius: 8,
                            }}
                          >
                            <GoogleAutocompleteField
                              apiKey={apiKey}
                              placeholder="Adresse de livraison*"
                              inputValue={state.temp_billing_addr?.address}
                              onInputChange={(text) => {
                                onBillingAddress({
                                  target: {
                                    name: "address",
                                    value: text,
                                  },
                                });
                              }}
                              onPlaceSelected={handlePlaceSelectedBilling}
                              key={"billing_sel"}
                              styles={{
                                border: "none",
                                fontSize: 16,
                                minHeight: 56,
                                borderRadius: 8,
                                fontFamily: "Raleway-Italic",
                                width: "calc(100% - 15px)",
                                color: "#000",
                                paddingLeft: 15,
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid xs={12} item>
                          <InputField
                            placeHolder={
                              "Complément d’adresse ( Étage, Bâtiment, Digicode...)"
                            }
                            input_value={
                              state.temp_billing_addr?.additional_info
                            }
                            input_name={"additional_info"}
                            input_style={{
                              background: "#fff",
                              width: "100%",
                              borderRadius: 8,
                              border: "none",
                              margin: 0,
                            }}
                            isMultiLine
                            input_rows={2}
                            onchange={onBillingAddress}
                          />
                        </Grid>
                        <Grid xs={12} md={6} item>
                          <InputField
                            placeHolder={"Ville *"}
                            input_value={state.temp_billing_addr?.city}
                            input_name={"city"}
                            input_style={{
                              background: "#fff",
                              width: "100%",
                              borderRadius: 8,
                              border: "none",
                              margin: 0,
                            }}
                            onchange={onBillingAddress}
                          />
                        </Grid>
                        <Grid xs={12} md={6} item>
                          <InputField
                            placeHolder={"Code postal*"}
                            input_value={state.temp_billing_addr?.digital_code}
                            input_name={"digital_code"}
                            input_style={{
                              background: "#fff",
                              width: "100%",
                              borderRadius: 8,
                              border: "none",
                              margin: 0,
                            }}
                            onchange={onBillingAddress}
                          />
                        </Grid>
                        <Grid xs={12} item>
                          <div
                            style={{
                              background: "#fff",
                              fontSize: 16,
                              borderRadius: 12,
                              paddingLeft: 15,
                              height: 56,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <FormControlLabel
                              value={state?.temp_billing_addr?.is_primary}
                              control={
                                <Checkbox
                                  checked={state?.temp_billing_addr?.is_primary}
                                  onChange={(event) =>
                                    onBillingAddress({
                                      target: {
                                        name: "is_primary",
                                        value: event.target.checked,
                                      },
                                    })
                                  }
                                />
                              }
                              label="Utiliser cette adresse comme adresse par défaut"
                            />
                          </div>
                        </Grid>
                        <Grid xs={12} item>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              sx={{
                                ":disabled": {
                                  background: "#E0E0E0",
                                  color: "#7C7C7C",
                                  fontFamily: "Raleway-Bold",
                                },
                                ":hover": {
                                  background: color_theme.main_blue,
                                  color: color_theme.white,
                                  textTransform: "none",
                                  minHeight: 48,
                                },
                                ":root": {
                                  borderRadius: 8,
                                },
                                background: color_theme.main_blue,
                                color: color_theme.white,
                                textTransform: "none",
                                minHeight: 48,
                              }}
                              style={{
                                borderRadius: 8,
                                paddingInline: 15,
                              }}
                              onClick={() => {
                                handleSelectBillingAddress();
                              }}
                            >
                              Ajouter cette adresse
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {state?.cart_data?.length > 0 ? (
                  <Grid container spacing={2}>
                    <Grid xs={12} md={8} sm={6} item>
                      <div
                        style={{
                          margin: "30px 0 10px 0px",
                          rowGap: 6,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <p
                          style={{
                            fontFamily: "Raleway-Bold",
                            fontSize: 32,
                            lineHeight: "40px",
                            margin: 0,
                          }}
                        >
                          Votre panier{" "}
                          <span style={{ fontSize: 28 }}>
                            ( {cart_items} produits )
                          </span>
                        </p>
                        <div
                          style={{
                            position: "relative",
                          }}
                        >
                          <span>Vendus et livrés par</span>
                          <div
                            style={{
                              position: "absolute",
                              left: 150,
                              top: -10,
                            }}
                          >
                            <FrankIcon
                              fill={color_theme.main_orange}
                              height={35}
                              width={50}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          rowGap: 20,
                          flexDirection: "column",
                        }}
                      >
                        {state.step === 1 ? (
                          state?.cart_data?.map((item: any, index: number) => {
                            return (
                              <div
                                style={{
                                  background: "#fff",
                                  padding: "20px",
                                  display: "flex",
                                  flexDirection: "row",
                                  columnGap: 20,
                                  borderRadius: 6,
                                }}
                              >
                                <img
                                  src={item?.images?.[0] ?? no_image_url}
                                  style={{
                                    objectFit: "contain",
                                    height: 240,
                                    width: 195,
                                    border: "1px solid #ddd",
                                  }}
                                  alt="prod_img"
                                />

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    flex: 1,
                                  }}
                                >
                                  <div>
                                    <p
                                      style={{
                                        fontSize: 18,
                                        fontFamily: "Raleway-Variable",
                                        lineHeight: "28px",
                                        margin: 0,
                                      }}
                                    >
                                      {handleLocalize(item?.product_title)}
                                    </p>
                                    <div>
                                      <span
                                        style={{
                                          fontSize: 18,
                                          fontFamily: "Raleway-Variable",
                                        }}
                                      >
                                        {handleLocalize(item?.category_name)}
                                      </span>
                                      <span
                                        style={{
                                          margin: "0px 5px",
                                          fontSize: 20,
                                        }}
                                      >
                                        •
                                      </span>
                                      <span
                                        style={{
                                          fontSize: 18,
                                          fontFamily: "Raleway-Variable",
                                        }}
                                      >
                                        {handleLocalize(item?.brand_name)}
                                      </span>
                                    </div>
                                    <div>
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: 18,
                                          fontFamily: "Raleway-Variable",
                                          lineHeight: "28px",
                                        }}
                                      >
                                        Vendu par{" "}
                                        {item?.shop_details?.[0]?.shop_name ??
                                          "N/A"}
                                      </p>
                                    </div>
                                    <div>
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: 12,
                                          fontFamily: "Raleway-Variable",
                                          lineHeight: "28px",
                                        }}
                                      >
                                        Disponibilité :{" "}
                                        {Number(item?.items_in_stock) > 10
                                          ? "En stock"
                                          : Number(item?.items_in_stock) < 10 &&
                                            Number(item?.items_in_stock) >= 5
                                          ? "Faible stock"
                                          : `${item?.items_in_stock} articles restants`}
                                      </p>
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        rowGap: 26,
                                        minWidth: 110,
                                      }}
                                    >
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: 18,
                                          color: color_theme.black,
                                          fontFamily: "Raleway-Bold",
                                          textAlign: "right",
                                        }}
                                      >
                                        {numberFormatWithoutCurrency(
                                          Number(
                                            Number(item?.price ?? 0) -
                                              Number(item?.discount ?? 0)
                                          ) * Number(item?.quantity ?? 0),
                                          "en-FR"
                                        )}{" "}
                                        €
                                      </p>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          gap: 5,
                                        }}
                                      >
                                        <select
                                          style={{
                                            padding: 2,
                                            borderRadius: 6,
                                            border: "1px solid #ccc",
                                            background: "#D9D9D9",
                                            fontFamily: "Raleway-Variable",
                                            fontSize: 16,
                                            lineHeight: "28px",
                                          }}
                                          value={item?.quantity}
                                          onChange={(e) => {
                                            setState({
                                              loading: true,
                                            });
                                            handleUpdateQuantity(
                                              item,
                                              Number(e.target.value)
                                            );
                                          }}
                                        >
                                          <option
                                            style={{
                                              background: "#fff",
                                              fontFamily: "Raleway-Variable",
                                            }}
                                            key={0}
                                            value={0}
                                          >
                                            Qté : {0}
                                          </option>
                                          {Array.from(
                                            {
                                              length:
                                                Number(item?.items_in_stock) >
                                                10
                                                  ? 10
                                                  : Number(
                                                      item?.items_in_stock
                                                    ),
                                            },
                                            (_, i) => i + 1
                                          )?.map((qty: any) => (
                                            <option
                                              style={{
                                                background: "#fff",
                                                fontFamily: "Raleway-Variable",
                                              }}
                                              key={qty}
                                              value={qty}
                                            >
                                              Qté : {qty}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-end",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <p
                                        style={{
                                          textDecoration: "underline",
                                          textAlign: "right",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setState({ loading: true });
                                          handleRemoveItem(item);
                                        }}
                                      >
                                        Supprimer
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : state.step === 2 ? (
                          state.cartByService?.map(
                            (item: any, index: number) => (
                              <div
                                key={`SERVICE_METHOD_CART_ITEM_${index + 1}`}
                                style={{
                                  backgroundColor: color_theme.white,
                                  borderRadius: 8,
                                  padding: 16,
                                  border:
                                    item[0]?.selected_address?.address &&
                                    state.shipment_fees?.disable_checkout &&
                                    item[0]?.selected_service_method?.s_key ===
                                      "express"
                                      ? `2px solid red`
                                      : "none",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontFamily: "Raleway-Semi-Bold",
                                      fontSize: 22,
                                      margin: 0,
                                      color: color_theme.main_blue,
                                    }}
                                  >
                                    {handleLocalize(
                                      item[0]?.selected_service_method
                                        ?.service_method_name
                                    )}
                                  </p>
                                  <p
                                    style={{
                                      fontFamily: "Raleway-Semi-Bold",
                                      fontSize: 22,
                                      margin: 0,
                                    }}
                                  >
                                    {item[0]?.selected_service_method?.s_key ===
                                      "click_collect" ||
                                    item[0]?.selected_service_method?.s_key ===
                                      "free"
                                      ? "Gratuit"
                                      : `${numberFormatWithoutCurrency(
                                          Number(
                                            state?.shipment_fees?.[
                                              item[0]?.selected_service_method
                                                ?.service_method_id
                                            ]?.shipment_price ?? 0
                                          )
                                        )} €`}
                                  </p>
                                </div>

                                <div
                                  style={{
                                    padding: "20px 0px 20px 40px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      rowGap: 8,
                                    }}
                                  >
                                    {item[0]?.selected_service_method?.s_key !==
                                      "mondial" && (
                                      <p
                                        style={{
                                          fontFamily: "Raleway-Semi-Bold",
                                          fontSize: 18,
                                          margin: 0,
                                        }}
                                      >
                                        Adresse de livraison :
                                      </p>
                                    )}
                                    {item[0]?.selected_service_method?.s_key ===
                                    "click_collect" ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          rowGap: 5,
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <p
                                            style={{
                                              fontSize: 18,
                                              fontFamily: "Raleway-Semi-Bold",
                                              lineHeight: "18px",
                                              margin: 0,
                                            }}
                                          >
                                            {
                                              item[0]?.shop_details[0]
                                                ?.shop_name
                                            }
                                          </p>
                                          <a
                                            style={{
                                              fontSize: 18,
                                              fontFamily: "Raleway",
                                              lineHeight: "18px",
                                              margin: 0,
                                              textDecoration: "underline",
                                              cursor: "pointer",
                                              color: color_theme.black,
                                            }}
                                            href={`https://www.google.com/maps/search/?api=1&query=${item[0]?.shop_details[0]?.address}`}
                                            target="_blank"
                                          >
                                            Voir les détails
                                          </a>
                                        </div>

                                        <p
                                          style={{
                                            fontSize: 18,
                                            fontFamily: "Raleway",
                                            lineHeight: "18px",
                                            margin: 0,
                                          }}
                                        >
                                          {item[0]?.shop_details[0]?.address ??
                                            "N/A"}
                                        </p>
                                        <p
                                          style={{
                                            fontSize: 18,
                                            fontFamily: "Raleway",
                                            lineHeight: "18px",
                                            margin: 0,
                                          }}
                                        >
                                          {item[0]?.shop_details[0]
                                            ?.digital_code ?? "N/A"}{" "}
                                          {item[0]?.shop_details[0]?.city ??
                                            "N/A"}{" "}
                                          {item[0]?.shop_details[0]?.country ??
                                            "N/A"}
                                        </p>
                                      </div>
                                    ) : (
                                      <>
                                        {item[0]?.selected_address?.address ||
                                        item[0]?.selected_mondial?.id ? (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              rowGap: 5,
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                flexDirection: "row",
                                              }}
                                            >
                                              <p
                                                style={{
                                                  fontSize: 18,
                                                  fontFamily:
                                                    "Raleway-Semi-Bold",
                                                  lineHeight: "18px",
                                                  margin: 0,
                                                }}
                                              >
                                                {item[0]
                                                  ?.selected_service_method
                                                  ?.s_key === "mondial"
                                                  ? `${
                                                      item[0]?.selected_mondial
                                                        ?.point_name ?? ""
                                                    }`
                                                  : `${
                                                      item[0]?.selected_address
                                                        ?.first_name ?? ""
                                                    } ${
                                                      item[0]?.selected_address
                                                        ?.last_name
                                                    }`}
                                              </p>
                                              <p
                                                style={{
                                                  fontSize: 18,
                                                  fontFamily: "Raleway",
                                                  lineHeight: "18px",
                                                  margin: 0,
                                                  textDecoration: "underline",
                                                  cursor: "pointer",
                                                  color: color_theme.black,
                                                }}
                                                onClick={() => {
                                                  if (
                                                    item[0]
                                                      ?.selected_service_method
                                                      ?.s_key === "mondial"
                                                  ) {
                                                    setState({
                                                      selected_index: index,
                                                      show_mondial: true,
                                                    });
                                                    return;
                                                  }

                                                  setState({
                                                    selected_index: index,
                                                    show_address_comp: true,
                                                    selected_address:
                                                      item[0]?.selected_address,
                                                  });
                                                }}
                                              >
                                                modifier
                                              </p>
                                            </div>

                                            {item[0]?.selected_service_method
                                              ?.s_key === "mondial" ? (
                                              <>
                                                <p
                                                  style={{
                                                    fontSize: 18,
                                                    fontFamily: "Raleway",
                                                    lineHeight: "18px",
                                                    margin: 0,
                                                  }}
                                                >
                                                  {item[0]?.selected_mondial
                                                    ?.address ?? "N/A"}
                                                </p>
                                                <p
                                                  style={{
                                                    fontSize: 18,
                                                    fontFamily: "Raleway",
                                                    lineHeight: "18px",
                                                    margin: 0,
                                                  }}
                                                >
                                                  {item[0]?.selected_mondial
                                                    ?.zipcode ?? "N/A"}{" "}
                                                  {item[0]?.selected_mondial
                                                    ?.city ?? "N/A"}{" "}
                                                  {item[0]?.selected_mondial
                                                    ?.country_code ?? "N/A"}
                                                </p>
                                              </>
                                            ) : (
                                              <>
                                                <p
                                                  style={{
                                                    fontSize: 18,
                                                    fontFamily: "Raleway",
                                                    lineHeight: "18px",
                                                    margin: 0,
                                                  }}
                                                >
                                                  {item[0]?.selected_address
                                                    ?.address ?? "N/A"}
                                                </p>
                                                <p
                                                  style={{
                                                    fontSize: 18,
                                                    fontFamily: "Raleway",
                                                    lineHeight: "18px",
                                                    margin: 0,
                                                  }}
                                                >
                                                  {item[0]?.selected_address
                                                    ?.digital_code ??
                                                    "N/A"}{" "}
                                                  {item[0]?.selected_address
                                                    ?.city ?? "N/A"}{" "}
                                                  {item[0]?.selected_address
                                                    ?.country ?? "N/A"}
                                                </p>
                                              </>
                                            )}
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              padding: 10,
                                              backgroundColor:
                                                color_theme.main_blue,
                                              background: color_theme.main_blue,
                                              borderRadius: 6,
                                              placeContent: "center",
                                              display: "flex",
                                              placeItems: "center",
                                              height: 40,
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              if (
                                                item[0]?.selected_service_method
                                                  ?.s_key !== "mondial"
                                              ) {
                                                setState({
                                                  selected_index: index,
                                                  show_address_comp: true,
                                                });
                                              }

                                              if (
                                                item[0]?.selected_service_method
                                                  ?.s_key === "mondial"
                                              ) {
                                                setState({
                                                  selected_index: index,
                                                  show_mondial: true,
                                                });
                                              }
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: 18,
                                                fontFamily: "Raleway-Semi-Bold",
                                                lineHeight: "18px",
                                                color: color_theme.white,
                                              }}
                                            >
                                              {item[0]?.selected_service_method
                                                ?.s_key === "mondial"
                                                ? "Ajouter un Point Relais"
                                                : "Ajouter une adresse de livraison"}
                                            </span>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  {item[0]?.selected_address?.address &&
                                    state.shipment_fees?.disable_checkout &&
                                    item[0]?.selected_service_method?.s_key ===
                                      "express" && (
                                      <span style={{ color: "red" }}>
                                        Delivery not available in this area 🥺
                                      </span>
                                    )}
                                </div>

                                {FrankDivider()}

                                {item?.map((c_item: any, c_idx: number) => (
                                  <>
                                    <div
                                      style={{
                                        background: "#fff",
                                        padding: "20px 0px",
                                        display: "flex",
                                        flexDirection: "row",
                                        columnGap: 20,
                                        borderRadius: 6,
                                      }}
                                      key={`S_ITEM_${c_idx}`}
                                    >
                                      <img
                                        src={
                                          c_item?.images?.[0] ?? no_image_url
                                        }
                                        style={{
                                          objectFit: "contain",
                                          height: 86,
                                          width: 104,
                                          border: "1px solid #ddd",
                                        }}
                                        alt="prod_img"
                                      />

                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                          flex: 1,
                                        }}
                                      >
                                        <div>
                                          <p
                                            style={{
                                              fontSize: 18,
                                              fontFamily: "Raleway-Semi-Bold",
                                              lineHeight: "28px",
                                              margin: 0,
                                            }}
                                          >
                                            {handleLocalize(
                                              c_item?.product_title
                                            )}
                                          </p>

                                          <div
                                            style={{
                                              display:
                                                c_item.selected_service_method
                                                  ?.s_key === "click_collect"
                                                  ? "block"
                                                  : "none",
                                            }}
                                          >
                                            <p
                                              style={{
                                                margin: 0,
                                                fontSize: 18,
                                                fontFamily: "Raleway-Variable",
                                                lineHeight: "28px",
                                              }}
                                            >
                                              Vendu par{" "}
                                              <b>
                                                {c_item?.shop_details?.[0]
                                                  ?.shop_name ?? "N/A"}
                                              </b>
                                            </p>
                                          </div>
                                          <div>
                                            <select
                                              style={{
                                                padding: 2,
                                                borderRadius: 6,
                                                border: "1px solid #ccc",
                                                background: "#D9D9D9",
                                                fontFamily: "Raleway-Variable",
                                                fontSize: 16,
                                                lineHeight: "28px",
                                              }}
                                              value={c_item?.quantity}
                                              onChange={(e) => {
                                                setState({
                                                  loading: true,
                                                });
                                                // if (
                                                //   Number(e.target.value) === 0
                                                // ) {
                                                //   handleRemoveItem(c_item);
                                                //   return;
                                                // }

                                                handleUpdateQuantityByMethod(
                                                  c_item,
                                                  Number(e.target.value),
                                                  index,
                                                  c_idx
                                                );
                                              }}
                                            >
                                              <option
                                                style={{
                                                  background: "#fff",
                                                  fontFamily:
                                                    "Raleway-Variable",
                                                }}
                                                key={0}
                                                value={0}
                                              >
                                                Qté : {0}
                                              </option>
                                              {Array.from(
                                                {
                                                  length:
                                                    Number(
                                                      c_item?.items_in_stock
                                                    ) > 10
                                                      ? 10
                                                      : Number(
                                                          c_item?.items_in_stock
                                                        ),
                                                },
                                                (_, i) => i + 1
                                              )?.map((qty: any) => (
                                                <option
                                                  style={{
                                                    background: "#fff",
                                                    fontFamily:
                                                      "Raleway-Variable",
                                                  }}
                                                  key={qty}
                                                  value={qty}
                                                >
                                                  Qté : {qty}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                          <p
                                            style={{
                                              margin: 0,
                                              fontSize: 18,
                                              color: color_theme.black,
                                              fontFamily: "Raleway",
                                            }}
                                          >
                                            {numberFormatWithoutCurrency(
                                              Number(
                                                Number(c_item?.price ?? 0) -
                                                  Number(c_item?.discount ?? 0)
                                              ) * Number(c_item?.quantity ?? 1),
                                              "en-FR"
                                            )}{" "}
                                            €
                                          </p>
                                        </div>

                                        <div>
                                          <select
                                            style={{
                                              padding: 2,
                                              borderRadius: 6,
                                              border: "1px solid #ccc",
                                              background: "#D9D9D9",
                                              fontFamily: "Raleway-Variable",
                                              fontSize: 14,
                                              lineHeight: "24px",
                                            }}
                                            value={
                                              c_item?.selected_service_method
                                                ?.service_method_id
                                            }
                                            onChange={(e) => {
                                              let s_id = Number(
                                                e.target?.value
                                              );
                                              const d_method =
                                                c_item?.service_methods?.find(
                                                  (a: any) =>
                                                    Number(
                                                      a.service_method_id
                                                    ) === s_id
                                                );
                                              handleUpdateServiceMethods(
                                                "selected_service_method",
                                                d_method,
                                                index,
                                                c_idx
                                              );
                                            }}
                                          >
                                            {c_item?.service_methods?.map(
                                              (service_method: any) => (
                                                <option
                                                  style={{
                                                    background: "#fff",
                                                    fontFamily:
                                                      "Raleway-Variable",
                                                  }}
                                                  key={service_method?.id}
                                                  value={
                                                    service_method?.service_method_id
                                                  }
                                                >
                                                  {handleLocalize(
                                                    service_method?.service_method_name
                                                  )}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    {FrankDivider("#0000001f")}
                                  </>
                                ))}
                              </div>
                            )
                          )
                        ) : (
                          <>
                            <div
                              style={{
                                background: color_theme.white,
                                borderRadius: 12,
                              }}
                            >
                              {state.cartByService?.map(
                                (item: any, index: number) => (
                                  <div
                                    key={`P_SIDE_SERVICE_METHOD_LIST_${
                                      index + 1
                                    }`}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        padding: 20,
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontFamily: "Raleway-Bold",
                                          fontSize: 21,
                                          margin: 0,
                                        }}
                                      >
                                        {handleLocalize(
                                          item[0]?.selected_service_method
                                            ?.service_method_name
                                        )}
                                        <span
                                          style={{
                                            fontFamily: "Raleway-Bold",
                                            fontSize: 18,
                                            margin: 0,
                                          }}
                                        >
                                          {" "}
                                          ({item?.length ?? 0} produit)
                                        </span>
                                      </p>
                                      <p
                                        style={{
                                          fontFamily: "Raleway-Semi-Bold",
                                          fontSize: 16,
                                          margin: 0,
                                        }}
                                      >
                                        {item[0]?.selected_service_method
                                          ?.s_key === "free" ||
                                        item[0]?.selected_service_method
                                          ?.s_key === "click_collect"
                                          ? "Gratuit"
                                          : `${numberFormatWithoutCurrency(
                                              Number(
                                                state?.shipment_fees?.[
                                                  item[0]
                                                    ?.selected_service_method
                                                    ?.service_method_id
                                                ]?.shipment_price ?? 0
                                              )
                                            )} €`}
                                      </p>
                                    </div>
                                    {state.cartByService?.length !== index + 1
                                      ? FrankDivider()
                                      : null}
                                  </div>
                                )
                              )}
                            </div>

                            <div>
                              <p
                                style={{
                                  fontSize: 32,
                                  fontFamily: "Raleway-Bold",
                                  lineHeight: `40px`,
                                  margin: 0,
                                }}
                              >
                                Mode de paiement
                              </p>
                              <div
                                style={{
                                  background: color_theme.white,
                                  padding: 20,
                                  borderRadius: 12,
                                }}
                              >
                                <div
                                  style={{
                                    flexDirection: "row",
                                    display: "flex",
                                    alignItems: "center",
                                    columnGap: 10,
                                  }}
                                >
                                  <div
                                    style={{
                                      height: 24,
                                      width: 24,
                                      background: color_theme.white,
                                      borderRadius: 100,
                                      border: `1px solid ${color_theme.main_blue}`,
                                      alignItems: "center",
                                      justifyContent: "center",
                                      display: "flex",
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: 20,
                                        width: 20,
                                        background: color_theme.main_blue,
                                        borderRadius: 100,
                                      }}
                                    ></div>
                                  </div>
                                  <p
                                    style={{
                                      fontSize: 21,
                                      fontFamily: "Raleway-Bold",
                                      lineHeight: `24px`,
                                      margin: 0,
                                    }}
                                  >
                                    Carte bancaire
                                  </p>
                                </div>
                                {FrankDivider()}
                                <div style={{ padding: 40 }}>
                                  <p
                                    style={{
                                      fontSize: 18,
                                      fontFamily: "Raleway-Bold",
                                    }}
                                  >
                                    Adresse de facturation :
                                  </p>
                                  {state.billing_address ? (
                                    <div>
                                      <p
                                        style={{
                                          fontFamily: "Raleway-Bold",
                                          fontSize: 16,
                                          margin: 0,
                                        }}
                                      >
                                        {`${
                                          state.billing_address?.first_name ??
                                          ""
                                        } ${
                                          state.billing_address?.last_name ?? ""
                                        }`}
                                      </p>

                                      <p
                                        style={{
                                          fontFamily: "Raleway",
                                          fontSize: 16,
                                          margin: 0,
                                        }}
                                      >
                                        {state.billing_address?.address ??
                                          "N/A"}
                                      </p>
                                      <p
                                        style={{
                                          fontFamily: "Raleway",
                                          fontSize: 16,
                                          margin: 0,
                                        }}
                                      >
                                        {`${
                                          state.billing_address?.zipcode ?? ""
                                        } ${
                                          state.billing_address?.city ?? ""
                                        } ${
                                          state.billing_address?.country_code ??
                                          ""
                                        }`}
                                      </p>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        width: "40%",
                                        marginLeft: "5%",
                                        padding: 10,
                                        background: color_theme.white,
                                        borderRadius: 6,
                                        placeContent: "center",
                                        display: "flex",
                                        placeItems: "center",
                                        border: `1px solid ${color_theme.main_blue}`,
                                      }}
                                      onClick={() =>
                                        setState({
                                          show_billing_addr: true,
                                        })
                                      }
                                    >
                                      <span
                                        style={{
                                          fontSize: 14,
                                          fontFamily: "Raleway-Semi-Bold",
                                          lineHeight: "18px",
                                          color: color_theme.main_blue,
                                        }}
                                      >
                                        Ajouter une adresse de facturation
                                      </span>
                                    </div>
                                  )}
                                </div>

                                {FrankDivider()}

                                <div>
                                  <p
                                    style={{
                                      fontFamily: "Raleway",
                                      fontSize: 21,
                                      textAlign: "center",
                                    }}
                                  >
                                    Renseignez votre carte bancaire
                                  </p>
                                </div>
                                {FrankDivider()}
                                <div
                                  style={{
                                    padding: 40,
                                  }}
                                >
                                  {state.stripeForm?.show_stripe_form &&
                                    state.stripeForm?.paymentIntent && (
                                      <Elements
                                        stripe={stripePromise}
                                        options={{
                                          // theme: "stripe",
                                          clientSecret:
                                            state.stripeForm?.paymentIntent,
                                          appearance: {
                                            theme: "stripe",
                                            variables: {
                                              fontFamily:
                                                '"Raleway-Variable", sans-serif',
                                            },
                                            rules: {
                                              ".Label": {
                                                fontFamily:
                                                  '"Raleway-Variable", sans-serif',
                                                fontSize: "14px", // Adjust the size as needed
                                                color: "#000000", // Change the color if needed
                                              },
                                            },
                                          },
                                        }}
                                      >
                                        <InjectedCheckoutForm
                                          handleConfirmPayment={
                                            handleConfirmPayment
                                          }
                                          onPressBuy={() =>
                                            setState({
                                              loading: true,
                                            })
                                          }
                                        />
                                      </Elements>
                                    )}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </Grid>
                    <Grid xs={12} md={4} sm={6} item>
                      <div
                        style={{
                          margin: "30px 0 10px 0px",
                        }}
                      >
                        <p
                          style={{
                            fontFamily: "Raleway-Bold",
                            fontSize: 32,
                            lineHeight: "40px",
                            margin: 0,
                          }}
                        >
                          Récapitulatif
                        </p>
                      </div>
                      <div
                        style={{
                          rowGap: 10,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {/* PROMO CODE BOX */}
                        <div
                          style={{
                            background: "#fff",
                            borderRadius: 8,
                            padding: 16,
                            opacity: state?.showStripeForm ? "0.8" : "1",
                            gap: 8,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <p
                            style={{
                              fontFamily: "Raleway-Semi-Bold",
                              fontSize: 18,
                              margin: 0,
                              padding: 2,
                              borderBottom: "1px solid #E0E0E0",
                            }}
                          >
                            Code promo
                          </p>
                          {state.promo_code_applied ? (
                            <div
                              style={{
                                padding: 10,
                                backgroundColor: color_theme.main_blue,
                                borderRadius: 6,
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: 14,
                                  fontFamily: "Raleway-Semi-Bold",
                                  lineHeight: "18px",
                                  color: color_theme.white,
                                }}
                              >
                                {selector.lang == "en"
                                  ? "Promo code applied 🥳"
                                  : `Code promo appliqué 🥳`}
                              </span>
                              <Clear
                                style={{
                                  color: "#ffffff",
                                }}
                                onClick={() => {
                                  if (state.step === 3) {
                                    return;
                                  }
                                  setState({
                                    promo_code_applied: null,
                                    promo_code_data: null,
                                    promo_code_text: "",
                                  });
                                }}
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <div style={{ width: "65%" }}>
                                <div
                                  style={{
                                    height: 40,
                                    width: "100%",
                                    border: "1px solid #E0E0E0",
                                    display: "flex",
                                    alignItems: "center",
                                    borderRadius: 6,
                                    paddingLeft: 10,
                                  }}
                                >
                                  <input
                                    style={{
                                      border: "none",
                                      outline: "none",
                                      textTransform: "uppercase",
                                    }}
                                    value={state.promo_code_text}
                                    onChange={(e) =>
                                      setState({
                                        promo_code_text: e.target.value,
                                      })
                                    }
                                    placeholder={"Saisissez le code "}
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "30%",
                                  marginLeft: "5%",
                                  padding: 10,
                                  backgroundColor: !state.promo_code_text
                                    ? color_theme.disabled
                                    : color_theme.main_blue,
                                  borderRadius: 6,
                                  placeContent: "center",
                                  display: "flex",
                                  placeItems: "center",
                                  cursor: "pointer",
                                }}
                                onClick={handleValidatePromoCode}
                              >
                                <span
                                  style={{
                                    fontSize: 14,
                                    fontFamily: "Raleway-Semi-Bold",
                                    lineHeight: "18px",
                                    color: !state.promo_code_text
                                      ? "#a4a4a4"
                                      : color_theme.white,
                                  }}
                                >
                                  Valider
                                </span>
                              </div>
                            </div>
                          )}
                        </div>

                        {/* DETAIL BOX */}

                        <div
                          style={{
                            background: "#fff",
                            borderRadius: 8,
                            padding: 16,
                            gap: 8,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <p
                              style={{
                                fontFamily: "Raleway-Semi-Bold",
                                fontSize: 18,
                                margin: 0,
                                padding: 2,
                              }}
                            >
                              Panier ({cart_items})
                            </p>
                            <p
                              style={{
                                fontFamily: "Raleway-Semi-Bold",
                                fontSize: 18,
                                margin: 0,
                                padding: 2,
                              }}
                            >
                              {state.promo_code_data?.discount_amount && (
                                <span
                                  style={{
                                    fontSize: 12,
                                    color: color_theme.red,
                                    textDecoration: "line-through",
                                    fontFamily: "Raleway",
                                    marginRight: 5,
                                  }}
                                >
                                  (
                                  {numberFormatWithoutCurrency(
                                    Number(total_amount)
                                  )}{" "}
                                  €)
                                </span>
                              )}
                              {numberFormatWithoutCurrency(
                                Number(total_amount) -
                                  Number(
                                    state.promo_code_data?.discount_amount ?? 0
                                  )
                              )}{" "}
                              €
                            </p>
                          </div>
                          {FrankDivider()}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <p
                              style={{
                                fontFamily: "Raleway-Medium",
                                fontSize: 14,
                                margin: 0,
                              }}
                            >
                              Frais de service Frank
                            </p>
                            <p
                              style={{
                                fontFamily: "Raleway-Semi-Bold",
                                fontSize: 14,
                                margin: 0,
                              }}
                            >
                              {state.promo_code_data?.is_platform_charges && (
                                <span
                                  style={{
                                    fontSize: 12,
                                    color: color_theme.red,
                                    textDecoration: "line-through",
                                    fontFamily: "Raleway",
                                    marginRight: 5,
                                  }}
                                >
                                  (
                                  {numberFormatWithoutCurrency(
                                    Number(state.paltform_fees)
                                  )}{" "}
                                  €)
                                </span>
                              )}
                              {state.promo_code_data?.is_platform_charges
                                ? "Gratuit"
                                : `${numberFormatWithoutCurrency(
                                    Number(state.paltform_fees ?? 1.99)
                                  )} €`}
                            </p>
                          </div>
                          {state.cartByService?.length > 0 ? (
                            state.cartByService?.map(
                              (item: any, index: number) => (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                  key={`SIDE_SERVICE_METHOD_LIST_${index + 1}`}
                                >
                                  <p
                                    style={{
                                      fontFamily: "Raleway-Medium",
                                      fontSize: 14,
                                      margin: 0,
                                    }}
                                  >
                                    {handleLocalize(
                                      item[0]?.selected_service_method
                                        ?.service_method_name
                                    )}
                                  </p>
                                  <p
                                    style={{
                                      fontFamily: "Raleway-Semi-Bold",
                                      fontSize: 14,
                                      margin: 0,
                                    }}
                                  >
                                    {item[0]?.selected_service_method?.s_key ===
                                      "free" ||
                                    item[0]?.selected_service_method?.s_key ===
                                      "click_collect"
                                      ? null
                                      : state.promo_code_data
                                          ?.is_delivery_charges && (
                                          <span
                                            style={{
                                              fontSize: 12,
                                              color: color_theme.red,
                                              textDecoration: "line-through",
                                              fontFamily: "Raleway",
                                              marginRight: 5,
                                            }}
                                          >
                                            (
                                            {numberFormatWithoutCurrency(
                                              Number(
                                                state?.shipment_fees?.[
                                                  item[0]
                                                    ?.selected_service_method
                                                    ?.service_method_id
                                                ]?.shipment_price
                                              )
                                            )}{" "}
                                            €)
                                          </span>
                                        )}

                                    {state.promo_code_data?.is_delivery_charges
                                      ? "Gratuit"
                                      : item[0]?.selected_service_method
                                          ?.s_key === "free" ||
                                        item[0]?.selected_service_method
                                          ?.s_key === "click_collect"
                                      ? "Gratuit"
                                      : `${numberFormatWithoutCurrency(
                                          Number(
                                            state?.shipment_fees?.[
                                              item[0]?.selected_service_method
                                                ?.service_method_id
                                            ]?.shipment_price ?? 0
                                          )
                                        )} €`}
                                  </p>
                                </div>
                              )
                            )
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                              key={`SIDE_SERVICE_METHOD_LIST_00${0}`}
                            >
                              <p
                                style={{
                                  fontFamily: "Raleway-Medium",
                                  fontSize: 14,
                                  margin: 0,
                                }}
                              >
                                Mode de livraison
                              </p>
                              <p
                                style={{
                                  fontFamily: "Raleway-Semi-Bold",
                                  fontSize: 14,
                                  margin: 0,
                                }}
                              >
                                - €
                              </p>
                            </div>
                          )}

                          {FrankDivider()}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <p
                              style={{
                                fontFamily: "Raleway-Semi-Bold",
                                fontSize: 18,
                                margin: 0,
                                padding: 2,
                              }}
                            >
                              Total (TVA incluse)
                            </p>
                            <p
                              style={{
                                fontFamily: "Raleway-Semi-Bold",
                                fontSize: 18,
                                margin: 0,
                                color: color_theme.main_blue,
                              }}
                            >
                              {numberFormatWithoutCurrency(
                                Number(
                                  Number(
                                    total_amount -
                                      Number(
                                        state.promo_code_data
                                          ?.discount_amount ?? 0
                                      )
                                  ) +
                                    Number(
                                      state.promo_code_data?.is_platform_charges
                                        ? 0
                                        : state.paltform_fees ?? 1.99
                                    ) +
                                    Number(
                                      state.promo_code_data?.is_delivery_charges
                                        ? 0
                                        : state.total_shipment_amt ?? 0
                                    )
                                )
                              )}
                              {" €"}
                            </p>
                          </div>
                          <div
                            style={{
                              padding: 10,
                              backgroundColor:
                                state.step === 3
                                  ? color_theme.disabled
                                  : state.shipment_fees?.disable_checkout
                                  ? color_theme.disabled
                                  : color_theme.main_blue,
                              borderRadius: 6,
                              placeContent: "center",
                              display: "flex",
                              placeItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (state.shipment_fees?.disable_checkout) {
                                return;
                              }
                              let error = false;
                              for (const it of state.cartByService) {
                                if (
                                  it[0]?.selected_service_method?.s_key ===
                                  "mondial"
                                ) {
                                  if (!it[0]?.selected_mondial) {
                                    error = true;
                                    Toast.Error(
                                      "Please Select Mondial Relay Point"
                                    );
                                    break;
                                  }
                                }

                                if (
                                  it[0]?.selected_service_method?.s_key ===
                                  "express"
                                ) {
                                  if (!it[0]?.selected_address?.address) {
                                    error = true;
                                    Toast.Error(
                                      "Please Enter your delivery address"
                                    );
                                    break;
                                  }
                                }

                                if (
                                  it[0]?.selected_service_method?.s_key ===
                                  "regular"
                                ) {
                                  if (!it[0]?.selected_address?.address) {
                                    error = true;
                                    Toast.Error(
                                      "Please Enter your delivery address"
                                    );
                                    break;
                                  }
                                }

                                if (
                                  it[0]?.selected_service_method?.s_key ===
                                  "free"
                                ) {
                                  if (!it[0]?.selected_address?.address) {
                                    error = true;
                                    Toast.Error(
                                      "Please Enter your delivery address"
                                    );
                                    break;
                                  }
                                }
                              }

                              if (error) return;

                              if (state.step === 3) {
                                return;
                              }
                              setState({ loading: true });
                              handleStepChange(state.step + 1);
                            }}
                          >
                            <span
                              style={{
                                fontSize: 14,
                                fontFamily: "Raleway-Semi-Bold",
                                lineHeight: "18px",

                                color:
                                  state.step === 3
                                    ? "#A4A4A4"
                                    : state.shipment_fees?.disable_checkout
                                    ? "#A4A4A4"
                                    : color_theme.white,
                              }}
                            >
                              Choisir ma livraison
                            </span>
                          </div>
                          <p
                            style={{
                              fontSize: 11.5,
                              fontFamily: "Raleway",
                            }}
                          >
                            En validant ma commande je déclare avoir pris
                            connaissance et sans réserve les conditions génerale
                            de vente de Frank
                          </p>

                          {state.cartByService?.map(
                            (item: any, index: number) => {
                              let key = item[0]?.selected_service_method?.s_key;
                              let _item = item[0];
                              const mondial = _item?.selected_mondial;
                              const shop = _item?.shop_details[0];
                              const address = _item?.selected_address;
                              const shops = groupBy(item, "shop_id");
                              let shops_ = [];
                              for (const key in shops) {
                                if (
                                  Object.prototype.hasOwnProperty.call(
                                    shops,
                                    key
                                  )
                                ) {
                                  const j = shops[key];
                                  shops_.push(j[0]);
                                }
                              }
                              return (
                                <div
                                  key={`C_SIDE_SERVICE_METHOD_LIST_${
                                    index + 1
                                  }`}
                                  style={{
                                    marginTop: index === 0 ? 0 : 10,
                                  }}
                                >
                                  <p
                                    style={{
                                      fontFamily: "Raleway-Bold",
                                      fontSize: 16,
                                      margin: 0,
                                    }}
                                  >
                                    {handleLocalize(
                                      _item?.selected_service_method
                                        ?.service_method_name
                                    )}{" "}
                                    :
                                  </p>
                                  <div
                                    style={{
                                      display:
                                        key === "mondial" && mondial
                                          ? "none"
                                          : key === "click_collect"
                                          ? "none"
                                          : address
                                          ? "none"
                                          : "block",
                                      marginTop: index === 0 ? 0 : 10,
                                    }}
                                  >
                                    <div
                                      style={{
                                        padding: 10,
                                        backgroundColor: color_theme.main_blue,
                                        background: color_theme.main_blue,
                                        borderRadius: 6,
                                        placeContent: "center",
                                        display: "flex",
                                        placeItems: "center",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        if (
                                          item[0]?.selected_service_method
                                            ?.s_key !== "mondial"
                                        ) {
                                          setState({
                                            selected_index: index,
                                            show_address_comp: true,
                                          });
                                        }

                                        if (
                                          item[0]?.selected_service_method
                                            ?.s_key === "mondial"
                                        ) {
                                          setState({
                                            selected_index: index,
                                            show_mondial: true,
                                          });
                                        }
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 14,
                                          fontFamily: "Raleway-Semi-Bold",
                                          lineHeight: "18px",
                                          color: color_theme.white,
                                        }}
                                      >
                                        {item[0]?.selected_service_method
                                          ?.s_key === "mondial"
                                          ? "Ajouter un Point Relais"
                                          : "Ajouter une adresse de livraison"}
                                      </span>
                                    </div>
                                  </div>

                                  {key === "click_collect" ? (
                                    shops_?.map((shp: any, cIdx: number) => (
                                      <div
                                        style={{
                                          marginBottom: 10,
                                          marginTop: 10,
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontFamily: "Raleway-Semi-Bold",
                                            fontSize: 16,
                                            margin: 0,
                                          }}
                                        >
                                          {shp?.shop_details[0]?.shop_name ??
                                            "N/A"}
                                        </p>

                                        <p
                                          style={{
                                            fontFamily: "Raleway",
                                            fontSize: 16,
                                            margin: 0,
                                          }}
                                        >
                                          {shp?.shop_details[0]?.address ??
                                            "N/A"}
                                        </p>
                                        <p
                                          style={{
                                            fontFamily: "Raleway",
                                            fontSize: 16,
                                            margin: 0,
                                          }}
                                        >
                                          {`${
                                            shp?.shop_details[0]
                                              ?.digital_code ?? ""
                                          } ${
                                            shp?.shop_details[0]?.city ?? ""
                                          } ${
                                            shp?.shop_details[0]
                                              ?.country_code ?? ""
                                          }`}
                                        </p>
                                        {item.length === cIdx + 1
                                          ? null
                                          : FrankDivider()}
                                      </div>
                                    ))
                                  ) : (
                                    <div
                                      style={{
                                        display:
                                          key === "mondial" && mondial
                                            ? "block"
                                            : key === "click_collect"
                                            ? "block"
                                            : address
                                            ? "block"
                                            : "none",
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontFamily: "Raleway",
                                          fontSize: 16,
                                          margin: 0,
                                        }}
                                      >
                                        {key === "mondial"
                                          ? mondial?.point_name ?? "N/A"
                                          : key === "click_collect"
                                          ? shop?.shop_name ?? "N/A"
                                          : `${address?.first_name ?? ""} ${
                                              address?.last_name ?? ""
                                            }`}
                                      </p>

                                      <p
                                        style={{
                                          fontFamily: "Raleway",
                                          fontSize: 16,
                                          margin: 0,
                                        }}
                                      >
                                        {key === "mondial"
                                          ? mondial?.address ?? "N/A"
                                          : key === "click_collect"
                                          ? shop?.address ?? "N/A"
                                          : address?.address ?? "N/A"}
                                      </p>
                                      <p
                                        style={{
                                          fontFamily: "Raleway",
                                          fontSize: 16,
                                          margin: 0,
                                        }}
                                      >
                                        {key === "mondial"
                                          ? `${mondial?.zipcode ?? ""} ${
                                              mondial?.city ?? ""
                                            } ${mondial?.country_code ?? ""}`
                                          : key === "click_collect"
                                          ? `${shop?.digital_code ?? ""} ${
                                              shop?.city ?? ""
                                            } ${shop?.country_code ?? ""}`
                                          : `${address?.digital_code ?? ""} ${
                                              address?.city ?? ""
                                            } ${address?.country_code ?? ""}`}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                ) : null}
              </>
            )}

            <Dialog
              // fullScreen={fullScreen}
              open={state.show_mondial}
              onClose={handleCloseMondialDialog}
              maxWidth="lg"
              fullWidth
            >
              <DialogContent dividers>
                <Grid container spacing={2}>
                  <Grid xs={12} md={5} item container spacing={2}>
                    <Grid xs={12} md={7} item>
                      <div
                        style={{
                          height: 45,
                          width: "100%",
                          border: "1px solid #E0E0E0",
                          display: "flex",
                          alignItems: "center",
                          borderRadius: 6,
                          paddingLeft: 10,
                        }}
                      >
                        <GoogleAutocompleteField
                          apiKey={apiKey}
                          placeholder="Rechercher"
                          inputValue={state.mondial_address?.address}
                          onInputChange={(text) => {
                            setState({
                              mondial_address: {
                                ...state.mondial_address,
                                address: text,
                              },
                            });
                          }}
                          onPlaceSelected={(places) =>
                            handlePlaceSelected(places, true)
                          }
                          key={"mondial-address"}
                          styles={{
                            border: "none",
                            fontSize: 16,
                            height: 45,
                            borderRadius: 8,
                            fontFamily: "Raleway-Italic",
                            // width: "calc(100% - 15px)",
                            color: "#000",
                            paddingLeft: 15,
                            outline: "none",
                            backgroundColor: "transparent",
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid xs={12} md={5} item>
                      <div
                        style={{
                          backgroundColor: color_theme.main_blue,
                          background: color_theme.main_blue,
                          borderRadius: 6,
                          placeContent: "center",
                          display: "flex",
                          placeItems: "center",
                          cursor: "pointer",
                          minHeight: 45,
                        }}
                        onClick={() => {
                          getMondialPoints(state.mondial_address);
                        }}
                      >
                        <span
                          style={{
                            fontSize: 14,
                            fontFamily: "Raleway-Semi-Bold",
                            lineHeight: "18px",
                            color: color_theme.white,
                          }}
                        >
                          Valider
                        </span>
                      </div>
                    </Grid>
                    <Grid xs={12} item>
                      <div
                        style={{
                          height: 590,
                          overflow: "hidden scroll",
                          display: "flex",
                          rowGap: 16,
                          flexDirection: "column",
                          paddingRight: 5,
                        }}
                        className="mondial_scroll"
                      >
                        {state?.mondialPoints?.length < 1 ? (
                          <p style={{ padding: "20px", opacity: "0.7" }}>
                            <NoPointsFound />
                          </p>
                        ) : (
                          state?.mondialPoints?.map((item: any, idx: any) => {
                            return (
                              <div key={`MONDIAL_POINTS_${idx + 1}`}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: 0,
                                  }}
                                >
                                  <div style={{ flex: 1, display: "flex" }}>
                                    <p
                                      style={{
                                        fontFamily: "Raleway-Bold",
                                        fontSize: 18,
                                        margin: 0,
                                      }}
                                    >
                                      <span>{idx + 1}.</span>{" "}
                                      {item?.point_name ?? ""}
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      fontFamily: "Raleway-Bold",
                                      fontSize: 18,
                                      margin: 0,
                                      color: color_theme.main_blue,
                                    }}
                                  >
                                    {Number(item?.distance) < 1000
                                      ? `${item?.distance}m`
                                      : `${Number(item?.distance) / 1000} km`}
                                  </div>
                                </div>
                                <div>
                                  <p
                                    style={{
                                      fontFamily: "Raleway",
                                      fontSize: 16,
                                      margin: 0,
                                      color: color_theme.black,
                                      opacity: 0.7,
                                    }}
                                  >
                                    {item?.address ?? ""}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "100%",
                                  }}
                                >
                                  {item?.formattedHours?.map(
                                    (hour: any, index: number) => (
                                      <div
                                        key={`FORMATED_HOURS_${index + 1}`}
                                        style={{
                                          borderRadius: 7,
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                          display: "flex",
                                        }}
                                      >
                                        <div style={{ flex: 1 }}>
                                          <p
                                            style={{
                                              margin: 0,
                                              marginTop: 2,
                                              marginBottom: 2,
                                              color: color_theme.black,
                                              fontFamily:
                                                week_day_translation[index + 1]
                                                  ?.en ==
                                                moment().format("dddd")
                                                  ? "Raleway-Bold"
                                                  : "Raleway-Variable",

                                              fontSize: 16,
                                              opacity:
                                                week_day_translation[index + 1]
                                                  ?.en ==
                                                moment().format("dddd")
                                                  ? 1
                                                  : 0.7,
                                            }}
                                          >
                                            {handleLocalize(
                                              week_day_translation[index + 1]
                                            )}{" "}
                                          </p>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                          {hour?.hours?.map(
                                            (hour_: any, h_index: number) => (
                                              <div
                                                key={`FORMATED_HOURS_HOUR_${
                                                  h_index + 1
                                                }`}
                                                style={{
                                                  display:
                                                    h_index > 0 &&
                                                    hour_?.is_closed
                                                      ? "none"
                                                      : "flex",
                                                }}
                                              >
                                                {hour?.hours[0]?.is_closed ? (
                                                  <p
                                                    style={{
                                                      margin: 0,
                                                      color: color_theme.red,
                                                      fontFamily:
                                                        "Raleway-Bold",
                                                    }}
                                                  >
                                                    Closed
                                                  </p>
                                                ) : (
                                                  <div
                                                    style={{
                                                      flexDirection: "row",
                                                      display: "flex",
                                                    }}
                                                  >
                                                    <p
                                                      style={{
                                                        color:
                                                          color_theme.black,
                                                        margin: 0,

                                                        fontFamily:
                                                          week_day_translation[
                                                            index + 1
                                                          ]?.en ==
                                                          moment().format(
                                                            "dddd"
                                                          )
                                                            ? "Raleway-Bold"
                                                            : "Raleway-Variable",
                                                      }}
                                                    >
                                                      {`${
                                                        hour_?.open.slice(
                                                          0,
                                                          2
                                                        ) +
                                                        ":" +
                                                        hour_?.open.slice(2)
                                                      }`}
                                                      {" - "}
                                                    </p>
                                                    <p
                                                      style={{
                                                        margin: 0,

                                                        color:
                                                          color_theme.black,
                                                        fontFamily:
                                                          week_day_translation[
                                                            index + 1
                                                          ]?.en ==
                                                          moment().format(
                                                            "dddd"
                                                          )
                                                            ? "Raleway-Bold"
                                                            : "Raleway-Variable",
                                                      }}
                                                    >
                                                      {`${
                                                        hour_?.close.slice(
                                                          0,
                                                          2
                                                        ) +
                                                        ":" +
                                                        hour_?.close.slice(2)
                                                      }`}{" "}
                                                    </p>
                                                  </div>
                                                )}
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>

                                <div
                                  style={{
                                    padding: 10,
                                    backgroundColor: color_theme.main_blue,
                                    background: color_theme.main_blue,
                                    borderRadius: 6,
                                    placeContent: "center",
                                    display: "flex",
                                    placeItems: "center",
                                    marginTop: 10,
                                    marginBottom: 10,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    handleSelectMondial(item);
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 14,
                                      fontFamily: "Raleway-Semi-Bold",
                                      lineHeight: "18px",
                                      color: color_theme.white,
                                    }}
                                  >
                                    Valider
                                  </span>
                                </div>

                                {FrankDivider("#000")}
                              </div>
                            );
                          })
                        )}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid xs={12} md={7} item>
                    <div
                      style={{
                        height: 661,
                      }}
                    >
                      <GoogleMaps
                        {...state}
                        {...props}
                        handleGetLocation={(_data) => {
                          let data = _data?.result?.[0];
                          onMondialSearch(data);
                          getMondialPoints(data);
                        }}
                        show_user_pointer={true}
                        origin={
                          state.mondial_address
                            ? {
                                lat: state.mondial_address?.latitude,
                                lng: state.mondial_address?.longitude,
                              }
                            : null
                        }
                        normal_style
                        for_mondial
                        relais_points={state?.mondialPoints ?? []}
                      />
                    </div>
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>
          </div>
          {state.step === 1 && (
            <>
              <Grid container spacing={2}>
                {state?.related_cart
                  ?.slice(0, xl ? 6 : lg ? 4 : md ? 3 : 4)
                  ?.map((i: any, idx: any) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        lg={3}
                        xl={2}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleOpenProduct(i);
                        }}
                      >
                        <ProductCard
                          i={i}
                          handleAddToFavorite={handleAddToFavorite}
                        />
                      </Grid>
                    );
                  })}
              </Grid>

              <div
                className="main-container"
                style={{
                  background: defaultLabelColors.main_background_two,
                  marginTop: "50px",
                }}
              >
                <CustomFooter />
              </div>
            </>
          )}
        </>
      )}

      <Dialog open={state.show_success}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 16,
            rowGap: 14,
          }}
        >
          <p
            style={{
              fontSize: 16,
              textAlign: "center",
              fontFamily: "Raleway",
              margin: 0,
            }}
          >
            N° de commande: {state.transaction_id ?? ""}
          </p>
          <p
            style={{
              fontSize: 32,
              textAlign: "center",
              fontFamily: "Raleway-Bold",
              color: color_theme.black,
              margin: 0,
            }}
          >
            Merci{" "}
            <span style={{ color: color_theme.main_blue }}>
              {selector.user?.first_name ?? ""}
            </span>{" "}
            votre commande à été enregistré
          </p>
          <p
            style={{
              fontSize: 16,
              textAlign: "center",
              fontFamily: "Raleway",
              margin: 0,
            }}
          >
            Le récapitulatif de votre commande vous sera envoyé par mail à
            l’adresse {selector?.user?.email ?? ""}
          </p>
          <svg
            width="95"
            height="95"
            viewBox="0 0 95 95"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M86.6654 43.9192V47.5226C86.6606 55.9686 83.9257 64.1867 78.8686 70.9514C73.8115 77.7161 66.7032 82.6648 58.6038 85.0595C50.5045 87.4543 41.848 87.1667 33.9254 84.2397C26.0028 81.3127 19.2386 75.9031 14.6416 68.8178C10.0447 61.7324 7.86121 53.3508 8.41693 44.9231C8.97265 36.4955 12.2378 28.4732 17.7253 22.0528C23.2128 15.6324 30.6288 11.1578 38.8671 9.29649C47.1054 7.43514 55.7247 8.28673 63.4396 11.7242M86.6654 16.1668L47.4987 55.3727L35.7487 43.6227"
              stroke="#2D26E6"
              stroke-width="7.83333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <p
            style={{
              fontSize: 16,
              textAlign: "center",
              fontFamily: "Raleway",
              margin: 0,
            }}
          >
            Lorsque votre commande sera disponible vous recevrez un nouveau mail
            ou SMS avec toutes les informations pratiques pour récuperer votre
            ou vos colis.
          </p>
          <div
            style={{
              width: "30%",
              marginLeft: "5%",
              padding: 10,
              backgroundColor: color_theme.main_blue,
              background: color_theme.main_blue,
              borderRadius: 6,
              placeContent: "center",
              display: "flex",
              placeItems: "center",
            }}
            onClick={() => {
              setState({
                show_success: false,
              });
              dispatch(getCart());
              navigate("/marketplace");
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: "Raleway-Semi-Bold",
                lineHeight: "18px",
                color: color_theme.white,
              }}
            >
              Valider
            </span>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export const FrankDivider = (
  dividerColor: string = "#E0E0E0",
  dividerHeight: number = 1
) => (
  <div
    style={{
      margin: "5px 0px",
      height: dividerHeight,
      background: dividerColor,
      width: "100%",
    }}
  />
);
