import { Box, Grid, Tab, Tabs } from "@mui/material";
import { styled, useMediaQuery, useTheme } from "@mui/system";
import React, { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getDataFromLocalStorage } from "../../../utils/LocalStore.util";
import apiServices from "../../../services/RequestHandler";
import Loader from "../../../UiComponent/Loader";
import HeaderForMarketPlace from "../../../UiComponent/HeaderForMarketPlace";
import { defaultLabelColors } from "../../../utils/constants";
import noProdFound from "../../../assets/images/FrankIcons/New folder/noProdFound.png";

import { numberFormatWithoutCurrencyFixedOne } from "../../../utils/helper";
import { Rating } from "react-simple-star-rating";
import InputField from "../../../UiComponent/TextFeild";
import { ArrowBackIos, Search } from "@mui/icons-material";
import AllProductsByShop from "./AllProductsByShop";
import { debounce } from "lodash";
import { ProductCard } from "..";
import CustomFooter from "../../../UiComponent/CustomFooter";
export const CustomTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#3026f1", // Indicator color
  },
});

export const CustomTab = styled(Tab)({
  fontFamily: "Raleway-Variable",
  fontWeight: 700,
  fontSize: "16px",
  textTransform: "capitalize",
  color: "#626175", // Inactive tab text color
  "&.Mui-selected": {
    color: "#3026f1", // Active tab text color
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
function FrankShopDetails(props: any) {
  const params = useParams();
  const navigate = useNavigate()
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      isFavorited: false,
      activity: false,
      shop_id: params?.id,
      user: getDataFromLocalStorage("user"),
      global_search: "",

    }
  );

  const handleOpenProduct = (item: any) => {
    try {
      if (item?.is_particular === true) {
        navigate(`/p-product/${item?.id}`);
        return;
      }

      navigate(`/product/${item?.id}`);
    } catch (error) {}
  };

  useEffect(() => {
    handleGetFrankShopDetails();
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleGetFrankShopDetails = async () => {
    try {
      let resp = await apiServices.getFromApi(
        `c2c-shop-configure/${params?.id}`,
        ""
      );

      if (resp?.status === 200 || resp?.status === 201) {
        handleGetProductbyFrankShop();
        setState({
          shopInfo: resp?.data,
        });
      }
    } catch (error) {}
  };

  const handleGetProductbyFrankShop = async () => {
    try {
      const resp = await apiServices.postFromApi(
        "c2c-product-masters/products-by-shop",
        {
          shop_id: params?.id,
        },
        ""
      );

      if (resp?.status === 200 || resp?.status === 201) {
        console.log("resp", resp);

        setState({
          shopProducts: resp?.data,
        });
      }

      console.log("resp", resp);
    } catch (error) {}
  };

  const handleSearchProducts = useCallback(
    debounce(async (searchValue: any) => {
      try {
        if (!searchValue) {
          handleGetProductbyFrankShop();
        }
        if (searchValue?.trim()) {
          let resp = await apiServices.postFromApi(
            "c2c-product-masters/search-by-store",
            { shop_id: params?.id, search: searchValue },
            ""
          );

          setState({
            shopProducts: resp?.data,
          });
        }
      } catch (error) {
        console.error("Error while searching products:", error);
      }
    }, 1500),
    [params?.id]
  );

  useEffect(() => {
    handleSearchProducts(state.searched_prod);
  }, [state.searched_prod, handleSearchProducts]);

  const { shopInfo } = state;

  const onInputChangeForHeader = (e: any) => {
    try {
      const { value, name } = e.target;
      setState({
        [name]: value,
      });
      
    } catch (error) {}
  };

  const handleAddToFavorite = async (item: any) => {
    try {
      let obj = {
        product_id: Number(item?.id),
      };

      let resp = await apiServices.postFromApi(
        "c2c-product-wishlists",
        obj,
        ""
      );
      
    } catch (error) {}
  };
  return (
    <div>
      <div style={{ background: defaultLabelColors.main_background_two, paddingBottom: 20, height: "100%" }}>
        <Loader loading={state?.activity} />
        <Grid container>
          <Grid xs={12} item>
            <HeaderForMarketPlace {...props} {...state} onInputChangeForHeader={onInputChangeForHeader} />
          </Grid>
        </Grid>
       
       
        { 
        state?.showSearchedData ?
        <div style={{ minHeight:'100vh',padding: "0px 50px 0px 50px",background:defaultLabelColors.main_background_two }}>
        <Grid container spacing={2}>
          <Grid
            xs={12}
            item
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            onClick={() => {
              setState({showSearchedData:false,global_search:""})
            }}
          >
            <p
              style={{
                fontFamily: "Raleway-Variable",
                fontSize: "26px",
                gap: 10,
                cursor: "pointer",
              }}
              className="display-flex"
            >
              <ArrowBackIos style={{ fontSize: "22px" }} /> Vendre un article
            </p>
          </Grid>
        </Grid>

        {state?.allSearchProduct?.length < 1 ||
        !state?.allSearchProduct?.length ? (
          <div
            style={{
              height: "60vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              opacity: "0.5",
            }}
          >
            {state?.showImg && (
              <img
                src={noProdFound}
                alt=""
                style={{ height: "400px", width: "400px" }}
              />
            )}
          </div>
        ) : (
          <>
            <p style={{ fontFamily: "Raleway-Semi-Bold", fontSize: "16px" }}>
              {state?.allSearchProduct?.length} annonces
            </p>
            <Grid container spacing={2}>
            {state?.allSearchProduct
                ?.slice(0, xl ? 6 : lg ? 4 : md ? 3 : 4)
                ?.map((i: any, idx: any) => {
                  return (
                    <Grid
                      key={idx}
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      xl={2}
                      style={{ cursor: "pointer", maxHeight: "370px" }}
                      onClick={() => {
                        handleOpenProduct(i);
                      }}
                    >
                      <ProductCard
                        i={i}
                        handleAddToFavorite={handleAddToFavorite}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </>
        )}
      </div> : <div style={{ padding: "20px 60px",minHeight:'60vh'}}>
          <Grid container>
            <Grid
              xs={12}
              item
              style={{
                background: defaultLabelColors.main_blue,
                padding: "20px 60px",
                borderRadius: "12px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  padding: "20px 0px",
                }}
              >
                <p
                  style={{
                    fontFamily: "Raleway-Bold",
                    fontSize: 36,
                    color: "#FCE6DD",
                    margin: 0,
                  }}
                >
                  {shopInfo?.shop_name ??
                    shopInfo?.first_name ??
                    shopInfo?.last_name ??
                    ""}{" "}
                </p>
                <p
                  style={{
                    fontFamily: "Raleway-Bold",
                    fontSize: 24,
                    color: "#FCE6DD",
                    margin: 0,
                    paddingTop: 10,
                  }}
                >
                  {shopInfo?.address?.address
                    ? shopInfo?.address?.address
                    : `${shopInfo?.address?.city},${shopInfo?.address?.country}`}
                </p>
              </div>
              <div>
                <InputField
                  placeHolder={`Chercher un produit chez ${shopInfo?.shop_name} `}
                  input_value={state?.searched_prod}
                  input_name={"searched_prod"}
                  onchange={(e) => {
                    const validValue = e.target.value.replace(/[^1-9a-zA-Z]/g, "");

                    setState({ searched_prod: validValue });
                  }}
                  input_style={{
                    background: "#F4F3F6",
                    minWidth: "200px",
                    width: "100%",
                    maxWidth: "772px",
                    borderRadius: "12px",
                  }}
                  startIcon={<Search style={{ opacity: "50%" }} />}
                />
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            className="display-flex"
            style={{ padding: "30px 30px 30px 30px" }}
          >
            <Grid xs={12} item>
              <Box sx={{ width: "100%" }}>
                <CustomTabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <CustomTab label="Profil" />
                  {/* <CustomTab label="Evaluations" /> */}
                </CustomTabs>

                <CustomTabPanel value={value} index={0}>
                  <AllProductsByShop
                    {...state}
                    {...props}
                    handleGetProductbyFrankShop={handleGetProductbyFrankShop}
                  />
                </CustomTabPanel>
              </Box>
            </Grid>
          </Grid>
        </div>}
      </div>
      <div
          className="main-container"
          style={{ background: defaultLabelColors.main_background_two }}
        >
          <CustomFooter />
        </div>
    </div>
  );
}

export default FrankShopDetails;
