import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetApiParams } from "..";
import apiServices from "../../services/RequestHandler";
import {
  getDataFromLocalStorage,
  saveDataToLocalStorage,
} from "../../utils/LocalStore.util";
import { strings } from "../Localization";

export interface MainAppState {
  value: boolean;
  cart: Array<any>;
  user: any;
  shop: any;
  categories: Array<any>;
  lang: string | any;
  messaging?: any;
  notifications: Array<any>;
  announce_items: Array<{
    height?: number;
    id?: string;
    item_level?: number;
    item_name_en?: string;
    item_name_fr?: string;
    length?: number;
    weight?: string;
    width?: number;
  }>;
  addresses: Array<any>;
  selected_address: any;
}
const initialState: MainAppState = {
  value: false,
  cart: [],
  user: getDataFromLocalStorage("user") ?? {},
  shop: getDataFromLocalStorage("shop_details") ?? {},
  categories: [],
  lang: getDataFromLocalStorage("lang") ?? "fr",
  messaging: null,
  notifications: [],

  announce_items: [],
  addresses: [],
  selected_address: null,
};

export const getCart = createAsyncThunk("user-cart", async () => {
  try {
    let response = await apiServices.getFromApi("/c2c-carts", "");
    return response;
  } catch (err) {
    console.log(err);
  }
});

export const getAnouncementList = createAsyncThunk(
  "item-lists/all",
  async (data: GetApiParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getCategoriesList = createAsyncThunk(
  "categories/all",
  async (data: GetApiParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getAllAddresses = createAsyncThunk(
  "all-addresses",
  async (data: any) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const MainAppSlice = createSlice({
  name: "mainApp",
  initialState,
  reducers: {
    updateUserData: (state, action) => {
      state.user = action?.payload ?? {};
    },
    setUpdateLanguage: (state, action) => {
      state.lang = action.payload;
      strings.setLanguage(action.payload);
      saveDataToLocalStorage("lang", action.payload);
    },
    clearUser: (state) => {
      state.user = null;
    },
    getMessageTriger: (state, action) => {
      state.messaging = action.payload;
      console.log("action", JSON.stringify(action.payload));
    },
    addAnnounceItem: (state, action) => {
      let announce_items = state.announce_items;
      const data = action.payload;
      let announce = {
        item_name_en: data?.item_name_en,
        item_name_fr: data?.item_name_fr,
      };
      announce_items.push(announce);
    },
    handleSelectAddress: (state, action: PayloadAction<any>) => {
      state.selected_address = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAnouncementList.pending, (state, { payload }) => {});
    builder
      .addCase(getAnouncementList.fulfilled, (state, { payload }) => {
        state.announce_items = payload?.data;
      })
      .addCase(getCart.pending, (state, { payload }) => {})
      .addCase(getCart.fulfilled, (state, { payload }) => {
        state.cart = payload?.data ?? [];
        saveDataToLocalStorage("cart", payload?.data);
      })
      .addCase(getAllAddresses.pending, (state, { payload }) => {})
      .addCase(getAllAddresses.fulfilled, (state, { payload }) => {
        state.selected_address = payload?.data?.filter(
          (a: any) => a.is_primary
        )[0];
        state.addresses = payload?.data ?? [];
      });
  },
});

export const {
  updateUserData,
  setUpdateLanguage,
  clearUser,
  addAnnounceItem,
  getMessageTriger,
  handleSelectAddress,
} = MainAppSlice.actions;

export default MainAppSlice.reducer;
