import React, { useEffect } from "react";
import apiServices from "../../../services/RequestHandler";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import {
  handleLocalize,
  numberFormatWithoutCurrency,
} from "../../../utils/helper";
import { defaultLabelColors } from "../../../utils/constants";

function AllProductsByShop(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {}
  );
  // useEffect(() => {}, []);
  const handleOpenProduct = (item: any) => {
    try {
      if (item?.is_particular === true) {
        navigate(`/p-product/${item?.id}`);
        return;
      }

      navigate(`/product/${item?.id}`);
    } catch (error) {}
  };
  const handleAddToFavorite = async (item: any) => {
    try {
      let obj = {
        product_id: Number(item?.id),
      };

      let resp = await apiServices.postFromApi(
        "c2c-product-wishlists",
        obj,
        ""
      );
      if (resp?.status === 201) {
        props?.handleGetProductbyFrankShop();
      }
    } catch (error) {}
  };

  console.log("props", props);

  return (
    <>
      <div>
        <p style={{ fontFamily: "Raleway-Semi-Bold", fontSize: "16px" }}>
          {props?.shopProducts?.length} annonces
        </p>
      </div>
      <Grid container spacing={2}>
        {props?.shopProducts?.map((i: any, idx: any) => {
          return (
            <Grid
              key={idx}
              item
              xs={12}
              sm={6}
              md={3}
              lg={2.4}
              style={{ cursor: "pointer", maxHeight: "370px" }}
              onClick={() => {
                handleOpenProduct(i);
              }}
            >
              <Card
                sx={{
                  borderRadius: "32px",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  overflow: "hidden",
                  minHeight: "280px",
                  maxHeight: "380px",
                }}
              >
                <Box position="relative">
                  <div style={{ height: "130px" }}>
                    <CardMedia
                      component="img"
                      image={
                        i?.images?.[0] ?? "https://via.placeholder.com/300x200"
                      }
                      alt="Product Image"
                      sx={{ objectFit: "cover", height: "160px" }}
                    />
                  </div>
                  {i?.is_particular && (
                    <div
                      style={{
                        position: "absolute",
                        bottom: "-25px",
                        right: "5px",
                        height: "20px",
                        padding: 5,
                        display: "flex",
                        fontFamily: "Raleway-Bold",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#F5D9BC",
                        borderRadius: 5,
                        color: "#281208",
                        fontSize: "14px",
                      }}
                    >
                      Seconde main
                    </div>
                  )}
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddToFavorite(i);
                    }}
                    sx={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      backgroundColor: "#fff",
                      padding: "5px",
                      borderRadius: "50%",
                      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                      "&:hover": { backgroundColor: "#fff" },
                    }}
                  >
                    {i?.wishlist ? (
                      <Favorite sx={{ color: "#ee6931" }} />
                    ) : (
                      <FavoriteBorder sx={{ color: "#626175" }} />
                    )}
                  </IconButton>
                </Box>

                <CardContent style={{ paddingTop: "30px" }}>
                  <p
                    style={{
                      fontSize: "14px",
                      fontFamily: "Raleway-Variable",
                      maxWidth: "300px",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      fontWeight: "bold",
                      color: "#3B3A3A",
                    }}
                  >
                    {handleLocalize({
                      en: i?.product_title?.en,
                      fr: i?.product_title?.fr,
                    }) ?? "N/A"}
                  </p>

                  <Typography
                    variant="body2"
                    color="#8F8F8F"
                    sx={{
                      fontSize: "10px",
                      marginBottom: "4px",
                      fontFamily: "Raleway-Variable",
                    }}
                  >
                    {handleLocalize({
                      en: i?.brand_name?.en,
                      fr: i?.brand_name?.fr,
                    }) ?? "N/A"}{" "}
                  </Typography>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      {!i?.is_particular && (
                        <Typography
                          variant="body2"
                          color="#8F8F8F"
                          sx={{
                            fontSize: "10px",
                            fontFamily: "Raleway-Semi-Bold",
                          }}
                        >
                          {i?.address?.city ?? " "},{i?.address?.country ?? " "}{" "}
                          - {i?.address?.digital_code ?? " "}
                        </Typography>
                      )}
                      <p
                        style={{
                          color: i?.is_particular
                            ? "grey"
                            : defaultLabelColors.main_blue,
                          margin: 0,
                          fontFamily: "Raleway-Semi-Bold",
                          fontSize: 10,
                        }}
                      >
                        {i?.is_particular
                          ? "Livraison possible"
                          : "Livraison standard ou express"}
                      </p>
                    </div>
                    <div
                      style={{
                        color: i?.is_particular
                          ? defaultLabelColors.main_orange
                          : defaultLabelColors.main_blue,
                        fontWeight: "700",
                        fontFamily: "Raleway-Variable",
                        fontSize: "18px",
                      }}
                    >
                      {i?.variation?.discount ? (
                        <span style={{ color: "lightgray", paddingRight: 20 }}>
                          <s> {i?.variation?.price} € </s>
                        </span>
                      ) : null}
                      {i?.variation?.discount
                        ? numberFormatWithoutCurrency(
                            Number(i?.variation?.price) -
                              Number(i?.variation?.discount)
                          )
                        : i?.variation?.price
                        ? numberFormatWithoutCurrency(i?.variation?.price)
                        : i?.base_price}
                      €
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

export default AllProductsByShop;
