import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import NavigationScroll from "./layout/NavigationScroll";
import AppRoute from "./routes";
import apiServices from "./services/RequestHandler";
import {
  getAllAddresses,
  getCart,
  getMessageTriger,
  setUpdateLanguage,
} from "./Store/MainAppReducer";
import { getProfileInfo } from "./Store/ProfileReducer";
import { firebaseConfig } from "./utils/constants";
import { getDataFromLocalStorage } from "./utils/LocalStore.util";
import { getDeviceToken, requestPermission } from "./utils/notificationListner";
const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

function App() {
  const dispatch = useDispatch<any>();
  let user = getDataFromLocalStorage("user");

  const checkForPermission = async () => {
    try {
      const isNotifAvailable = "Notification" in window;
      if (isNotifAvailable) {
        const permissionGranted = await requestPermission();
        if (permissionGranted) {
          const token = await getDeviceToken();

          if (token && user?.id) {
            await apiServices.postFromApi(
              "c2c/u-token",
              {
                device_token: token,
              },
              ""
            );
          }
        }
      }
    } catch (error) {}
  };

  const fetchProfile = async () => {
    if (user) {
      const resp: any = { end_point: "c2c/me" };
      dispatch(getProfileInfo(resp) as any);
    } else {
      return;
    }
  };
  useEffect(() => {
    const user = getDataFromLocalStorage("user");
    if (user) {
      dispatch(getCart());
      fetchProfile();
      getSavedAddresses();
    }
    checkForPermission();
    const lang = "fr";
    dispatch(setUpdateLanguage(lang));
  }, []);

  const getSavedAddresses = async () => {
    try {
      dispatch(
        getAllAddresses({
          end_point: "/c2c-addresses",
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const subscribe = () => {
      try {
        const isNotifAvailable = "Notification" in window;
        console.log("isNotifAvailable", isNotifAvailable);

        if (isNotifAvailable) {
          onMessage(messaging, (payload: any) => {
            if (payload?.data?.is_message) {
              dispatch(
                getMessageTriger(
                  JSON.stringify({
                    payload,
                    date: new Date().getTime(),
                  })
                )
              );
            }
          });
        }
      } catch (error) {
        console.log("APP ENTRY POINT ERROR: ", (error as Error)?.message);
      }
    };
    return subscribe;
  }, []);

  onMessage(messaging, (payload: any) => {
    if (payload?.data?.is_message) {
      console.log("payload", payload);

      dispatch(
        getMessageTriger(
          JSON.stringify({
            payload,
            date: new Date().getTime(),
          })
        )
      );
    }
  });
  return (
    <>
      <NavigationScroll>
        <ToastContainer limit={1} />
        <AppRoute />
      </NavigationScroll>
    </>
  );
}

export default App;
