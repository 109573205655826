import React, { useState } from "react";
import NewHeader from "../../../UiComponent/NewHeader";
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import particularLabel from "../../../assets/images/FrankIcons/particular_badge.png";

import {
  AddCircleOutline,
  ArrowBackIosNew,
  ShoppingBagOutlined,
} from "@mui/icons-material";
import {
  handleLocalize,
  numberFormatWithoutCurrency,
} from "../../../utils/helper";
import { defaultLabelColors } from "../../../utils/constants";
import { strings } from "../../../Store/Localization";
import { BoXIcon, LockIcon } from "../../../assets/images/FrankIcons";
import { color_theme } from "../../SenderInbox.tsx/AccepterChat";
import apiServices from "../../../services/RequestHandler";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import { Rating } from "react-simple-star-rating";

function ParticularOrderDetails(props: any) {
  const params = useParams();
  const [showFullDescription, setShowFullDescription] = useState(false);

  const [selectedImage, setSelectedImage] = useState("");
  const navigate = useNavigate();
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {}
  );

  React.useEffect(() => {
    handleGetOrderDetails();
  }, []);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const handleGetOrderDetails = async () => {
    try {
      let resp = await apiServices.getFromApi(`c2c-orders/${params?.id}`, "");
      console.log("resp", resp);
      setState({ orderDetails: resp?.data });
      setSelectedImage(state?.orderDetails?.[0]?.url);


    } catch (error) {}
  };

  const { orderDetails } = state;
  return (
    <div style={{ background: defaultLabelColors.main_background_two }}>
      <Grid container>
        <Grid xs={12} item>
          <NewHeader />
        </Grid>
      </Grid>

      <div style={{ padding: "30px 80px 50px 80px" }}>
        <Grid container spacing={2} style={{ paddingBottom: "40px" }}>
          <Grid
            xs={12}
            item
            style={{
              display: "flex",
              fontFamily: "Raleway-Variable",
              fontWeight: "500",
              fontSize: "26px",
              color: "#4B4A61",
              gap: 10,
              alignItems: "center",
            }}
          >
            <ArrowBackIosNew
              onClick={() => {
                navigate(-1);
              }}
              style={{ cursor: "pointer" }}
            />{" "}
            Maison .{" "}
            {orderDetails
              ? handleLocalize({
                  en: orderDetails?.category_name?.en,
                  fr: orderDetails?.category_name?.fr,
                })
              : ""}
            .{" "}
            <h1 style={{ fontWeight: "700", fontSize: "26px" }}>
              {" "}
              {orderDetails
                ? handleLocalize({
                    en: orderDetails?.product_title?.en,
                    fr: orderDetails?.product_title?.fr,
                  })
                : ""}
            </h1>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          style={{
            background: "#fff",
            borderRadius: "16px",
            padding: "20px",
            gap: 20,
          }}
        >
          <Grid
            xs={12}
            md={5.8}
            item
            style={{
              borderRadius: "16px",
              height: "100%",
              padding: 0,
            }}
          >
            <Card
              elevation={0}
              sx={{
                borderRadius: "32px",
                overflow: "hidden",
                border: "1px solid #E0E0E0",
              }}
            >
              <Box
                position="relative"
                sx={{ borderRadius: "32px 32px 0px 0px" }}
              >
                <div style={{ height: "480px" }} className="display-flex">
                  <CardMedia
                    component="img"
                    image={selectedImage ?? ""}
                    alt="Product Image"
                    sx={{
                      objectFit: "contain",
                      width: "98%",
                      height: "460px",
                      borderRadius: "32px 32px 0px 0px",
                    }}
                  />
                </div>
                {orderDetails?.is_particular && (
                  <Box
                    component="img"
                    src={particularLabel}
                    alt="Discount Label"
                    sx={{
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      width: "150px",
                      height: "auto",
                    }}
                  />
                )}
              </Box>

              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "30px",
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      color="#3B3A3A"
                      sx={{
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {handleLocalize({
                        en: orderDetails?.product_title?.en,
                        fr: orderDetails?.product_title?.fr,
                      }) ?? ""}{" "}
                    </Typography>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 5,
                    maxWidth: "100%",
                  }}
                >
                  {state?.item_images?.map((item: any) => {
                    return (
                      <div
                        className="display-flex"
                        style={{
                          height: "116px",
                          width: "116px",
                          cursor: "pointer",
                          borderRadius: "12px",
                          border: `1px solid ${
                            selectedImage === item?.url
                              ? defaultLabelColors.main_orange
                              : "grey"
                          } `,
                        }}
                        onClick={() => {
                          //
                          const filtered_variation =
                            state.product_details_?.product_variations?.filter(
                              (i: any) =>
                                Number(i.id) === Number(item?.variation_id)
                            );
                          console.log("filtered_variation", filtered_variation);

                          setState({
                            display_sel_variant: filtered_variation[0],
                          });
                          setSelectedImage(item?.url);
                        }}
                      >
                        <img
                          src={item?.url}
                          alt="prod-img"
                          style={{ objectFit: "contain", height: "110px" }}
                        />
                      </div>
                    );
                  })}
                </div>
              </CardContent>
            </Card>
            <Card
              elevation={0}
              sx={{
                borderRadius: "32px",
                overflow: "hidden",
                border: "1px solid #E0E0E0",
                marginTop: "15px",
                padding: "15px",
              }}
            >
              <p
                style={{
                  fontSize: "18px",
                  fontFamily: "Raleway-Variable",
                  fontWeight: "600",
                }}
              >
                Description
              </p>
              <div style={{ margin: 10 }}>
                <table
                  style={{
                    border: `${
                      state?.product_details_?.product_specifications
                        ? "1px"
                        : "0px"
                    } solid #ddd`,
                    padding: state?.product_details_?.product_specifications
                      ? "5px"
                      : "0px",
                    gap: 5,
                    width: "100%",
                  }}
                >
                  {state?.product_details_?.product_specifications
                    ? state?.product_details_?.product_specifications?.map(
                        (i: any, idx: any) => {
                          return (
                            <tr key={idx}>
                              <td
                                style={{
                                  background: "lightgrey",
                                  fontFamily: "Raleway-Bold",
                                  padding: 5,
                                  textAlign: "left",
                                }}
                              >
                                {i?.specification_name?.en}
                              </td>
                              {i?.product_specification_values?.map(
                                (j: any) => {
                                  return (
                                    <td
                                      style={{
                                        fontFamily: "Raleway-Variable",
                                        textAlign: "left",
                                      }}
                                    >
                                      {j?.specification_value?.en}{" "}
                                    </td>
                                  );
                                }
                              )}
                            </tr>
                          );
                        }
                      )
                    : null}
                </table>
              </div>
              {orderDetails?.product_desc && (
                <Box
                  sx={{
                    maxHeight: showFullDescription ? "none" : "80vh",
                    overflow: showFullDescription ? "visible" : "hidden",
                    position: "relative",
                  }}
                >
                  <ReactQuill
                    value={orderDetails?.product_desc}
                    readOnly
                    theme="snow"
                    modules={{ toolbar: false }}
                    style={{
                      pointerEvents: "none",
                      border: "none",
                      margin: 0,
                      fontFamily: "Raleway-Variable",
                      fontSize: "16px",
                    }}
                  />
                </Box>
              )}

              {!showFullDescription && (
                <Box
                  sx={{
                    textAlign: "center",
                    background:
                      "linear-gradient(to bottom, rgba(255,255,255,0), white)",
                    padding: "10px 0",
                  }}
                >
                  {orderDetails?.product_desc?.length > 1000 ? (
                    <Button
                      variant="contained"
                      onClick={toggleDescription}
                      sx={{
                        borderRadius: "12px",
                        textTransform: "none",
                        fontFamily: "Raleway-Variable",
                        fontSize: "14px",
                        fontWeight: "600",
                        background: defaultLabelColors.main_orange,
                        color: "#fff",
                        outline: "#ee6931",
                      }}
                    >
                      Show More
                    </Button>
                  ) : null}
                </Box>
              )}

              {showFullDescription && (
                <Box
                  sx={{
                    textAlign: "center",
                    background:
                      "linear-gradient(to bottom, rgba(255,255,255,0), white)",
                    padding: "10px 0",
                  }}
                >
                  {orderDetails?.product_desc && (
                    <Button
                      variant="contained"
                      onClick={toggleDescription}
                      sx={{
                        borderRadius: "12px",
                        textTransform: "none",
                        fontFamily: "Raleway-Variable",
                        fontSize: "14px",
                        fontWeight: "600",
                        background: defaultLabelColors.main_orange,
                        color: "#fff",
                        outline: "#ee6931",
                      }}
                    >
                      Show Less
                    </Button>
                  )}
                </Box>
              )}
            </Card>
          </Grid>

          <Grid
            xs={12}
            md={5.8}
            item
            style={{
              borderRadius: "16px",
              height: "100%",
              padding: 0,
            }}
          >
            <Card
              elevation={0}
              sx={{
                borderRadius: "32px",
                overflow: "hidden",
                border: "1px solid #E0E0E0",
                padding: "0px 15px",
              }}
            >
              <p
                style={{
                  fontFamily: "Raleway-Variable",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                {strings?.seller_details}
              </p>
              {/* <p style={{ fontFamily: "Raleway-Variable", fontWeight: "700" }}>
                {shopInfo?.address?.short_address
                  ? shopInfo?.address?.short_address
                  : shopInfo?.address?.address ?? ""}
              </p> */}
              {/* <p style={{ fontFamily: "Raleway-Variable", color: "gray" }}>
                      <Rating
                      
                        initialValue={shopInfo?.type_level}
                        readonly
                        size={30}
                      />{" "}
                      {`(${shopInfo?.type_level})`}
                    </p> */}
            </Card>

            <Card
              elevation={0}
              sx={{
                borderRadius: "32px",
                overflow: "hidden",
                border: "1px solid #E0E0E0",
                padding: "15px 15px",
                marginTop: "15px",
              }}
            >
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    flex: 1,
                    fontFamily: "Raleway-Variable",
                    fontWeight: "700",
                  }}
                >
                  {orderDetails
                    ? handleLocalize({
                        en: orderDetails?.product_title?.en,
                        fr: orderDetails?.product_title?.fr,
                      })
                    : ""}{" "}
                </div>
                {/* <div
                        style={{
                          color: defaultLabelColors.main_blue,
                          fontWeight: "700",
                          fontFamily: "Raleway-Variable",
                          fontSize: "22px",
                        }}
                      >
                        {display_sel_variant?.discount ? (
                          <span style={{ color: "lightgray", paddingRight: 20 }}>
                            <s>
                              {" "}
                              {numberFormatWithoutCurrency(
                                display_sel_variant?.price
                              )}{" "}
                              €{" "}
                            </s>
                          </span>
                        ) : null}
                        {display_sel_variant?.discount
                          ? numberFormatWithoutCurrency(
                              display_sel_variant?.price -
                                display_sel_variant?.discount
                            )
                          : numberFormatWithoutCurrency(
                              display_sel_variant?.price
                            ) ?? ""}{" "}
                        €
                      </div> */}
              </div>

              {/* <p style={{ fontFamily: "Raleway-Variable", fontSize: 14 }}>
                      Livraison disponible à partir de{" "}
                      <span
                        style={{
                          fontWeight: "700",
                          color: defaultLabelColors.main_blue,
                        }}
                      >
                        {" "}
                        3,90€{" "}
                      </span>
                    </p> */}
              {state?.product_details_?.service_methods?.map(
                (s_methods: any) => {
                  return (
                    <p style={{ fontFamily: "Raleway-Variable", fontSize: 14 }}>
                      {handleLocalize({
                        en: s_methods?.service_method_name?.en,
                        fr: s_methods?.service_method_name?.fr,
                      })}

                      <span
                        style={{
                          fontWeight: "700",
                          color: defaultLabelColors.main_blue,
                          paddingLeft: 10,
                        }}
                      >
                        Gratuit
                      </span>
                    </p>
                  );
                }
              )}
            </Card>

            <Card
              elevation={0}
              sx={{
                borderRadius: "32px",
                overflow: "hidden",
                border: "1px solid #E0E0E0",
                padding: "15px 15px",
                marginTop: "15px",
              }}
            >
              <p
                style={{
                  fontFamily: "Raleway-Variable",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontWeight: 600,
                  gap: 10,
                  fontSize: 14,
                }}
              >
                <LockIcon /> Paiement sécurisé
              </p>
              <p
                style={{
                  fontFamily: "Raleway-Variable",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontWeight: 600,
                  gap: 10,
                  fontSize: 14,
                }}
              >
                <BoXIcon /> Retours acceptés dans un délai de 14 jours
              </p>
            </Card>

            {/* <div className="display-flex" style={{ gap: 10, paddingTop: 10 }}>
                    <Button
                      fullWidth
                      disabled={
                        display_sel_variant?.items_in_stock < 1 ? true : false
                      }
                      variant="contained"
                      sx={{
                        backgroundColor: defaultLabelColors.white, // Same as the blue color in the image
                        color: "#000",
                        borderRadius: "16px",
                        padding: "10px 20px",
                        textTransform: "none",
                        fontSize: "16px",
                        fontFamily: "Raleway-Variable",
                        fontWeight: "bold",
                        "&:hover": {
                          backgroundColor: "#fff",
                        },
                      }}
                    >
                      Retrait en magasin
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleAddToCart}
                      fullWidth
                      disabled={
                        display_sel_variant?.items_in_stock < 1 ? true : false
                      }
                      sx={{
                        backgroundColor:
                          display_sel_variant?.items_in_stock < 1
                            ? "lightGrey"
                            : defaultLabelColors.main_blue, // Same as the blue color in the image
                        color: "#fff",
                        borderRadius: "16px",
                        padding: "10px 20px",
                        textTransform: "none",
                        fontSize: "16px",
                        fontFamily: "Raleway-Variable",
                        fontWeight: "bold",

                        "&:hover": {
                          backgroundColor:
                            display_sel_variant?.items_in_stock < 1
                              ? "lightGrey"
                              : "#3f00ff",
                        },
                      }}
                    >
                      <Badge
                        badgeContent={
                          <AddCircleOutline
                            sx={{
                              fontSize: "14px",
                              backgroundColor: "#FF6E40",
                              borderRadius: "50%",
                              padding: "2px",
                            }}
                          />
                        }
                        sx={{ marginRight: "10px" }}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <ShoppingBagOutlined sx={{ fontSize: "24px" }} />
                      </Badge>
                      Ajouter au panier
                    </Button>
                  </div> */}
            <Card
              elevation={0}
              sx={{
                overflow: "hidden",
                padding: "15px 15px",
                marginTop: "15px",
              }}
            >
              {/* <p
                      style={{ fontFamily: "Raleway-Variable", fontWeight: "700" }}
                    >
                      À propos de cet article :{" "}
                      <>
                        {display_sel_variant?.items_in_stock === 0 ? (
                          <span
                            style={{
                              fontSize: "18px",
                              color: color_theme.red,
                              fontFamily: "Raleway-Bold",
                            }}
                          >
                            Out of stock 😔
                          </span>
                        ) : display_sel_variant?.items_in_stock < 5 ? (
                          <span
                            style={{
                              fontSize: "18px",
                              color: color_theme.red,
                              fontFamily: "Raleway-Bold",
                            }}
                          >
                            Low in stock
                          </span>
                        ) : display_sel_variant?.items_in_stock >= 5 &&
                          display_sel_variant?.items_in_stock < 15 ? (
                          <span
                            style={{
                              fontSize: "18px",
                              color: color_theme.red,
                              fontFamily: "Raleway-Bold",
                            }}
                          >
                            only {display_sel_variant?.items_in_stock} left
                          </span>
                        ) : (
                          <span
                            style={{
                              fontSize: "18px",
                              color: defaultLabelColors.main_blue,
                              fontFamily: "Raleway-Bold",
                            }}
                          >
                            in stock
                          </span>
                        )}
                      </>
                    </p> */}

              <p
                style={{
                  fontFamily: "Raleway-Variable",
                  fontWeight: "500",
                  fontSize: 16,
                }}
              >
                Marque :
                <span
                  style={{ fontFamily: "Raleway-Bold", paddingLeft: "10px" }}
                >
                  {orderDetails
                    ? handleLocalize({
                        en: orderDetails?.brand_name?.en,
                        fr: orderDetails?.brand_name?.fr,
                      })
                    : ""}
                </span>
              </p>
              {state?.attributeNames?.length < 1 ||
              state?.attributeNames === undefined ||
              state?.attributeNames?.[0] === undefined ? null : (
                <Box>
                  {state?.attributeNames?.map((name: any, idx: any) => {
                    console.log("attributeNames", state?.attributeNames);

                    const options = [
                      ...new Set(
                        state?.product_details_?.product_variations?.flatMap(
                          (variation: any) =>
                            variation.attributes
                              ?.filter(
                                (attr: any) => attr?.attribute_name?.en === name
                              )
                              ?.map((attr: any) => attr?.attribute_value?.en)
                        )
                      ),
                    ];

                    return (
                      <Box
                        key={`VARIATION_NAME_${name}`}
                        mb={3}
                        mt={idx === 0 ? 3 : 0}
                        sx={{
                          display: "flex",
                          fontFamily: "Raleway-Variable",
                          fontWeight: "500",
                          fontSize: 16,
                          gap: "10px",
                        }}
                      >
                        <p
                          style={{
                            fontFamily: "Raleway-Variable",
                            fontWeight: "500",
                            fontSize: 16,
                          }}
                        >
                          Select {name}:
                        </p>
                        {/* <Select
                                value={localAttributes[name] || ""}
                                onChange={(e) => handleChange(name, e.target.value)}
                                displayEmpty
                                variant="outlined"
                                sx={{
                                  minWidth: "150px",
                                  margin: 0,
                                  border: "1px solid #3026f1",
                                  borderRadius: "20px",
                                  fontFamily: "Raleway-Bold",
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                  color: "#3026f1",
                                  "& .MuiSelect-icon": {
                                    color: "#3026f1",
                                  },
                                  "&.MuiOutlinedInput-root": {
                                    padding: "0px",
                                    fontFamily: "Raleway-Bold",
                                    margin: "0px",
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      border: "none",
                                    },
                                  "&:focus-visible": {
                                    outline: "none",
                                  },
                                  "&.Mui-focused": {
                                    boxShadow: "none",
                                  },
                                }}
                                renderValue={(selected) => {
                                  if (!selected) {
                                    return (
                                      <span style={{ color: "#3026f1" }}>
                                        Select Color
                                      </span>
                                    );
                                  }
                                  return selected;
                                }}
                              >
                                {options?.map((option: any, index: any) => {
                                  const isOptionAvailable =
                                    state?.product_details_?.product_variations?.some(
                                      (variation: any) =>
                                        Object.entries({
                                          ...localAttributes,
                                          [name]: option,
                                        }).every(([attrName, attrValue]) =>
                                          variation?.attributes?.some(
                                            (attr: any) =>
                                              attr.attribute_name.en === attrName &&
                                              attr.attribute_value.en === attrValue
                                          )
                                        )
                                    );

                                  return (
                                    <MenuItem
                                      key={`VARIATION_ITEM_${index}`}
                                      value={option}
                                      disabled={!isOptionAvailable}
                                      sx={{
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        color: isOptionAvailable
                                          ? "#3026f1"
                                          : "#ccc",
                                        "&.Mui-disabled": {
                                          color: "#ccc",
                                        },
                                      }}
                                    >
                                      {option}
                                    </MenuItem>
                                  );
                                })}
                              </Select> */}
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Card>
          </Grid>
        </Grid>

        {state?.product_details_?.related_product?.length < 1 ? null : (
          <Grid container spacing={2}>
            <Grid
              xs={12}
              item
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "-35px",
              }}
            >
              <p style={{ flex: 1 }} className="page-title-two">
                Produits similaires à cet article
              </p>
            </Grid>

            {state?.product_details_?.related_product
              ?.slice(0, 4)
              ?.map((i: any, idx: any) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    lg={3}
                    style={{ cursor: "pointer", maxHeight: "380px" }}
                  >
                    <Card
                      sx={{
                        borderRadius: "32px",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                        overflow: "hidden",
                        minHeight: "280px",
                      }}
                    >
                      <Box position="relative">
                        <div style={{ height: "130px" }}>
                          <CardMedia
                            component="img"
                            image={
                              i?.images[0] ??
                              "https://via.placeholder.com/300x200"
                            }
                            alt="Product Image"
                            sx={{ objectFit: "cover", height: "165px" }}
                          />
                        </div>
                        {i?.is_particular && (
                          <div
                            style={{
                              position: "absolute",
                              bottom: "-25px",
                              right: "5px",
                              height: "20px",
                              padding: 5,
                              display: "flex",
                              fontFamily: "Raleway-Bold",
                              justifyContent: "center",
                              alignItems: "center",
                              background: "#F5D9BC",
                              borderRadius: 5,
                              color: "#281208",
                              fontSize: "14px",
                            }}
                          >
                            {" "}
                            Seconde main
                          </div>
                        )}
                      </Box>

                      <CardContent
                        style={{
                          paddingTop: "30px",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "16px",
                            fontFamily: "Raleway-Variable",
                            maxWidth: "300px",
                            textOverflow: "ellipsis",
                            whiteSpace: " nowrap",
                            overflow: "hidden",
                            fontWeight: "bold",
                            color: "#3B3A3A",
                          }}
                        >
                          {handleLocalize({
                            en: i?.product_title?.en,
                            fr: i?.product_title?.fr,
                          }) ?? "N/A"}
                        </p>

                        <Typography
                          variant="body2"
                          color="#8F8F8F"
                          sx={{
                            fontSize: "12px",
                            marginBottom: "4px",
                            fontFamily: "Raleway-Variable",
                          }}
                        >
                          {handleLocalize({
                            en: i?.brand_name?.en,
                            fr: i?.brand_name?.fr,
                          }) ?? "N/A"}{" "}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            {i?.is_particular ? null : (
                              <Typography
                                variant="body2"
                                color="#8F8F8F"
                                sx={{
                                  fontSize: "12px",
                                  fontFamily: "Raleway-Semi-Bold",
                                }}
                              >
                                {i?.address?.city ?? " "},
                                {i?.address?.country ?? " "} -{" "}
                                {i?.address?.digital_code ?? " "}
                              </Typography>
                            )}
                          </div>
                          <div
                            style={{
                              color: i?.is_particular
                                ? defaultLabelColors.main_orange
                                : defaultLabelColors.main_blue,
                              fontWeight: "700",
                              fontFamily: "Raleway-Variable",
                              fontSize: "22px",
                            }}
                          >
                            {i?.variation?.[0]?.discount ? (
                              <span
                                style={{ color: "lightgray", paddingRight: 20 }}
                              >
                                <s> {i?.variation?.[0]?.price} € </s>
                              </span>
                            ) : null}
                            {i?.variation?.[0]?.discount
                              ? numberFormatWithoutCurrency(
                                  Number(i?.variation?.[0]?.price) -
                                    Number(i?.variation?.[0]?.discount)
                                )
                              : i?.variation?.[0]?.price
                              ? numberFormatWithoutCurrency(
                                  i?.variation?.[0]?.price
                                )
                              : i?.base_price}
                            €
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        )}
      </div>
    </div>
  );
}

export default ParticularOrderDetails;
