import { Box, Button, Card, CardMedia, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import sofaImage from "../../assets/images/FrankIcons/sofaImage.jpg";
import { ArrowForwardIos, East } from "@mui/icons-material";
import PropTypes from "prop-types";
import { defaultLabelColors } from "../../utils/constants";

const CustomProductCard = styled(Card)(({ theme }) => ({
  maxWidth: 300,
  cursor: "pointer",
  borderRadius: "15px",
  position: "relative",
  boxShadow: "none",
  overflow: "hidden",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
  },
}));

const Media = styled(CardMedia)({
  height: 0,
  paddingTop: "56.25%", // 16:9 aspect ratio
  backgroundSize: "cover",
});

const ProductInfo = styled(Box)({
  padding: "12px",
  backgroundColor: "#FAD9B6",
});

function ProductCard(props: any) {
  const { productTitle, productType, productDesc, prductAmount, productImage,openProduct } =
    props;
  return (
    <CustomProductCard onClick={openProduct}>
      <East
        style={{
          fontSize: "32px",
          position: "absolute",
          right: "15px",
          padding: "5px",
          top: "10px",
          background: defaultLabelColors.white,
          borderRadius: 100,
          color: defaultLabelColors.main_blue,
          cursor: "pointer",
        }}
      />
      <Media
        style={{}}
        image={productImage ?? sofaImage}
        title="Product Image"
      />

      <ProductInfo>
        <div style={{ display: "flex", gap: 20 }}>
          <p
            style={{
              fontFamily: "Raleway-Bold",
              fontSize: "16px",

              textAlign: "left",
              display: "-webkit-box",
              maxWidth: "100%",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxHeight: 20,
            }}
          >
            {productTitle ?? "Product title"}
          </p>
          <p
            style={{
              background: "#fff",
              borderRadius: "30px",
              color: !props?.productType
                ? defaultLabelColors.main_orange
                : defaultLabelColors.main_blue,
              fontFamily: "Raleway-Extra-Bold-Italic",
              fontSize: 14,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "right",
              padding: "8px",
              textTransform: "uppercase",
            }}
          >
            {productType ? "particular" : "professional"}
          </p>
        </div>

        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          style={{
            fontFamily: "Raleway-Semi-Bold",
            fontSize: "12px",
            color: "#000",
          }}
        >
          {productDesc ??
            "Lorem ipsum dolor sit amet consectetur elit dolor sit amet consectetuer adipiscing elit"}
        </Typography>

        <Typography
          variant="h5"
          component="div"
          sx={{
            color: "#3026f1",
            mt: 2,
            fontFamily: "Raleway-Extra-Bold",
            fontSize: "22px",
            display: "flex",
            paddingBottom: "5px",
          }}
        >
          {prductAmount ?? "225,90€"}{" "}
          <span
            style={{
              fontSize: "16px",
              display: "flex",
              alignItems: "flex-end",
              paddingLeft: 5,
            }}
          >
            €
          </span>
        </Typography>
      </ProductInfo>
    </CustomProductCard>
  );
}

ProductCard.defaultProps = {
  productTitle: "",
  productType: "",
  productDesc: "",
  prductAmount: "",
  productImage: "",
  openProduct: "",
};

ProductCard.propTypes = {
  productTitle: PropTypes.any,
  productType: PropTypes.any,
  productDesc: PropTypes.any,
  prductAmount: PropTypes.any,
  productImage: PropTypes.any,
  openProduct:PropTypes.any
};

export default ProductCard;
