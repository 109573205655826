// import React from 'react'

// function MostViewsProducts(props:any) {
//   return (
//     <div>

//     </div>
//   )
// }

// export default MostViewsProducts

import React, { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import Loader from "../../UiComponent/Loader";
import noProdFound from "../../assets/images/FrankIcons/New folder/noProdFound.png";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import HeaderForMarketPlace from "../../UiComponent/HeaderForMarketPlace";
import { getDataFromLocalStorage } from "../../utils/LocalStore.util";
import apiServices from "../../services/RequestHandler";
import { defaultLabelColors } from "../../utils/constants";
import {
  handleLocalize,
  numberFormatWithoutCurrency,
} from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import CustomFooter from "../../UiComponent/CustomFooter";
import { ArrowBackIos, Favorite, FavoriteBorder } from "@mui/icons-material";
import { debounce } from "lodash";
import { ProductCard } from ".";
import { useMediaQuery, useTheme } from "@mui/system";

function MostViewsProducts(props: any) {
  const navigate = useNavigate();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      isFavorited: false,
      activity: false,
      global_search: "",
    }
  );

  useEffect(() => {
    handleGetProducts();
  }, []);

  const handleGetProducts = async () => {
    try {
      setState({
        activity: true,
      });
      let user = getDataFromLocalStorage("user");
      let obj = {
        user_id: user?.id,
      };
      let resp = await apiServices.postFromApi(
        "c2c-product-masters/product-home",
        obj,
        ""
      );

      setState({
        latestProducts: resp?.data?.most_recent_view_items,
        activity: false,
      });
    } catch (error) {}
  };

  const handleOpenProduct = (item: any) => {
    try {
      if (item?.is_particular === true) {
        navigate(`/p-product/${item?.id}`);
        return;
      }

      navigate(`/product/${item?.id}`);
    } catch (error) {}
  };
  const handleAddToFavorite = async (item: any) => {
    try {
      let obj = {
        product_id: Number(item?.id),
      };

      let resp = await apiServices.postFromApi(
        "c2c-product-wishlists",
        obj,
        ""
      );
      if (resp?.status === 201) {
        handleGetProducts();
      }
    } catch (error) {}
  };

  const handleSearchProducts = useCallback(
    debounce(async (searchValue: any) => {
      try {
        if (!searchValue) {
          setState({ activity: true });
          setState({
            showSearchedData: false,
            allSearchProduct: null,
            activity: false,
          });
        }
        if (searchValue?.trim()) {
          setState({ activity: true });
          let resp = await apiServices.postFromApi(
            "c2c-product-masters/products-search",
            { search: searchValue },
            ""
          );
          console.log("resp", resp);
          if (resp?.data?.length < 1) {
            setState({
              showImg: true,
            });
          }
          setState({
            allSearchProduct: resp?.data,
            activity: false,
            showSearchedData: true,
          });
        }
      } catch (error) {
        console.error("Error while searching products:", error);
      }
    }, 1500),
    []
  );
  const onInputChangeForHeader = (e: any) => {
    try {
      const { value, name } = e.target;
      setState({
        [name]: value,
      });
    } catch (error) {}
  };

  useEffect(() => {
    handleSearchProducts(state.global_search);
  }, [state.global_search, handleSearchProducts]);
  return (
    <>
      <Helmet>
        <title>Marketplace | Trending Products Collaboratifs</title>
        <link
          rel="canonical"
          href="https://www.v2internal.frankme.com/marketplace"
        />
        <meta
          name="description"
          content="Explorez notre marketplace pour découvrir des offres de livraison entre particuliers et services collaboratifs. Trouvez ce dont vous avez besoin pour expédier vos colis facilement."
        />
      </Helmet>
      <div className="web-screen">
        <div style={{ background: defaultLabelColors.main_background_two }}>
          <Loader loading={state?.activity} />

          <Grid container>
            <Grid xs={12} item>
              <HeaderForMarketPlace
                {...state}
                {...props}
                onInputChangeForHeader={onInputChangeForHeader}
              />
            </Grid>
          </Grid>

          {state?.showSearchedData ? (
            <div
              style={{
                minHeight: "100vh",
                padding: "0px 50px 0px 50px",
                background: defaultLabelColors.main_background_two,
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  xs={12}
                  item
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setState({ showSearchedData: false, global_search: "" });
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "26px",
                      gap: 10,
                      cursor: "pointer",
                    }}
                    className="display-flex"
                  >
                    <ArrowBackIos style={{ fontSize: "22px" }} /> Vendre un
                    article
                  </p>
                </Grid>
              </Grid>

              {state?.allSearchProduct?.length < 1 ||
              !state?.allSearchProduct?.length ? (
                <div
                  style={{
                    height: "60vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    opacity: "0.5",
                  }}
                >
                  {state?.showImg && (
                    <img
                      src={noProdFound}
                      alt=""
                      style={{ height: "400px", width: "400px" }}
                    />
                  )}
                </div>
              ) : (
                <>
                  <p
                    style={{
                      fontFamily: "Raleway-Semi-Bold",
                      fontSize: "16px",
                    }}
                  >
                    {state?.allSearchProduct?.length} annonces
                  </p>
                  <Grid container spacing={2}>
                    {state?.allSearchProduct
                      ?.slice(0, xl ? 6 : lg ? 4 : md ? 3 : 4)
                      ?.map((i: any, idx: any) => {
                        return (
                          <Grid
                            key={idx}
                            item
                            xs={12}
                            sm={6}
                            md={3}
                            lg={3}
                            xl={2}
                            style={{ cursor: "pointer", maxHeight: "370px" }}
                            onClick={() => {
                              handleOpenProduct(i);
                            }}
                          >
                            <ProductCard
                              i={i}
                              handleAddToFavorite={handleAddToFavorite}
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                </>
              )}
            </div>
          ) : (
            <div style={{ padding: "50px 50px 0px 50px" }}>
              <Grid container spacing={2} style={{ paddingBottom: "10px" }}>
                <Grid
                  xs={12}
                  item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "-35px",
                  }}
                >
                  <h2 style={{ flex: 1 }} className="page-title-two">
                    Derniers produits mis en ligne
                  </h2>
                </Grid>

                {state?.latestProducts?.map((i: any, idx: any) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={3}
                      lg={3}
                      xl={2}
                      style={{ cursor: "pointer", maxHeight: "380px" }}
                      onClick={() => {
                        handleOpenProduct(i);
                      }}
                    >
                      <ProductCard
                        i={i}
                        handleAddToFavorite={handleAddToFavorite}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          )}
        </div>

        <div
          className="main-container"
          style={{  background: defaultLabelColors.main_background_two  }}
        >
          <CustomFooter />
        </div>
      </div>
    </>
  );
}

export default MostViewsProducts;
