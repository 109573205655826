import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import noProdFound from "../../assets/images/FrankIcons/New folder/noProdFound.png";

import { useNavigate } from "react-router-dom";
import { RootState } from "../../Store";
import CustomFooter from "../../UiComponent/CustomFooter";
import HeaderForMarketPlace from "../../UiComponent/HeaderForMarketPlace";
import Loader from "../../UiComponent/Loader";
import Toast from "../../UiComponent/Toast/Toast";
import { AddProductIcon, ConsoleIcon } from "../../assets/images/FrankIcons";
import marketplace_banner_img from "../../assets/images/FrankIcons/services-icon-1.png";
import marketplace_banner_img_two from "../../assets/images/banner02.png";
import apiServices from "../../services/RequestHandler";
import {
  clearAllDataFromLocalStorage,
  getDataFromLocalStorage,
} from "../../utils/LocalStore.util";
import {
  defaultLabelColors,
  no_image_url,
  spacing,
} from "../../utils/constants";
import {
  handleLocalize,
  numberFormatWithoutCurrency,
} from "../../utils/helper";
import { useTheme } from "@mui/material/styles";

import { color_theme } from "../SenderInbox.tsx/AccepterChat";
import ScrollContainer from "react-indiana-drag-scroll";
import { debounce } from "lodash";
import { ArrowBackIos } from "@mui/icons-material";
interface CartItem {
  id: number;
  name: string;
  quantity: number;
}
export const MarketPlace = (props: any) => {
  let user = getDataFromLocalStorage("user");
  const dispatch = useDispatch<any>();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));

  // const mediaQuery = getMediaScree("(max-width:500px)");

  let userDetails = getDataFromLocalStorage("user");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEN, setAnchorEN] = React.useState<null | HTMLElement>(null);
  const [isFavorited, setIsFavorited] = useState(false);

  const [anchorEM, setAnchorEM] = useState<HTMLButtonElement | null>(null);
  const openForLang = Boolean(anchorEN);
  const selector = useSelector((state: RootState) => state.mainApp);
  const navigate = useNavigate();
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      isFavorited: false,
      activity: false,
      global_search: "",
    }
  );
  const handleClickForLang = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEN(event.currentTarget);
  };
  const handleCloseForLang = () => {
    setAnchorEN(null);
  };
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleAddToFavorite = async (item: any) => {
    try {
      let obj = {
        product_id: Number(item?.id),
      };

      let resp = await apiServices.postFromApi(
        "c2c-product-wishlists",
        obj,
        ""
      );
      if (resp?.status === 201) {
        handleGetProducts();
      }
    } catch (error) {}
  };

  const [cartItems, setCartItems] = useState<CartItem[]>([
    { id: 1, name: "Product 1", quantity: 2 },
    { id: 2, name: "Product 2", quantity: 1 },
  ]);
  const handleClickforCart = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEM(event.currentTarget);
  };
  const handleCloseforCart = () => {
    setAnchorEM(null);
  };
  const openForCart = Boolean(anchorEM);
  const id = openForCart ? "cart-popover" : undefined;
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    try {
      clearAllDataFromLocalStorage();
      navigate("/");
      Toast.showSuccess("Logout Succesfully");
    } catch (error) {}
  };
  const toggleDrawer = (open: any) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };
  useEffect(() => {
    handleGetAllCategories();
    handleGetProducts();
  }, []);
  const handleOpenProduct = (item: any) => {
    try {
      if (item?.is_particular === true) {
        navigate(`/p-product/${item?.id}`);
        return;
      }

      navigate(`/product/${item?.id}`);
    } catch (error) {}
  };
  const handleGetProducts = async () => {
    try {
      let user = getDataFromLocalStorage("user");
      let obj = {
        user_id: user?.id,
      };
      let resp = await apiServices.postFromApi(
        "c2c-product-masters/product-home",
        obj,
        ""
      );

      setState({
        latestProducts: resp?.data?.latest_items,
        topTrendingProducts: resp?.data?.top_trending_items,
        mostViewedProducts: resp?.data?.most_recent_view_items,
      });
    } catch (error) {}
  };
  const getProductsByCategory = (cat_id: any) => {
    try {
      navigate(`/all-products/${Number(cat_id)}`);
    } catch (error) {}
  };
  function buildNestedStructure(data: any) {
    const lookup: any = {};
    const rootNodes: any = [];

    data.forEach((item: any) => {
      lookup[item.id] = { ...item, childern: [] };
    });
    data.forEach((item: any) => {
      if (item.parent_id) {
        lookup[item.parent_id]?.childern.push(lookup[item.id]);
      } else {
        rootNodes.push(lookup[item.id]);
      }
    });

    setState({ parentCategories: rootNodes });
    return rootNodes;
  }
  const handleGetAllCategories = async () => {
    try {
      setState({ activity: true });
      let resp = await apiServices.getFromApi("c2c-categories", "");
      if (resp?.status === 201 || resp?.status === 200) {
        buildNestedStructure(resp?.data);
        console.log(
          "buildNestedStructure(resp?.data);",
          buildNestedStructure(resp?.data)
        );
        setState({ activity: false });
      }
    } catch (error) {}
  };
  const onInputChangeForHeader = (e: any) => {
    try {
      const { value, name } = e.target;
      setState({
        [name]: value,
      });
    } catch (error) {}
  };
   
  const handleSearchProducts = useCallback(
    debounce(async (searchValue: any) => {
      try {
        if (!searchValue) {
          setState({ activity: true });
          setState({
            showSearchedData: false,
            allSearchProduct: null,
            activity: false,
          });
        }
        if (searchValue?.trim()) {
          setState({ activity: true });
          let resp = await apiServices.postFromApi(
            "c2c-product-masters/products-search",
            { search: searchValue },
            ""
          );
          console.log("resp", resp);
          if (resp?.data?.length < 1) {
            setState({
              showImg: true,
            });
          }
          setState({
            allSearchProduct: resp?.data,
            activity: false,
            showSearchedData: true,
          });
        }
      } catch (error) {
        console.error("Error while searching products:", error);
      }
    }, 1500),
    []
  );
  useEffect(() => {
    handleSearchProducts(state.global_search);
  }, [state.global_search, handleSearchProducts]);
  
  useEffect(() => {
    const footerElement = document.getElementById("footer");
    footerElement?.focus(); // This will cause scrolling to the footer
  }, []);


  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = scrollRef.current;
    if (!container) return;

    // Mouse hover scrolling logic
    let isHovered = false;

    const handleMouseMove = (e: MouseEvent) => {
      if (isHovered) {
        container.scrollLeft += e.movementX;
      }
    };

    const handleMouseEnter = () => {
      isHovered = true;
    };

    const handleMouseLeave = () => {
      isHovered = false;
    };

    container.addEventListener("mousemove", handleMouseMove);
    container.addEventListener("mouseenter", handleMouseEnter);
    container.addEventListener("mouseleave", handleMouseLeave);

    const handleWheel = (e: WheelEvent) => {
      if (e.shiftKey) {
        e.preventDefault();
        container.scrollLeft += e.deltaY;
      }
    };

    container.addEventListener("wheel", handleWheel);

    return () => {
      container.removeEventListener("mousemove", handleMouseMove);
      container.removeEventListener("mouseenter", handleMouseEnter);
      container.removeEventListener("mouseleave", handleMouseLeave);
      container.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Marketplace | Trouvez des Offres de Livraison et Services
          Collaboratifs
        </title>
        <link
          rel="canonical"
          href="https://www.v2internal.frankme.com/marketplace"
        />
        <meta
          name="description"
          content="Explorez notre marketplace pour découvrir des offres de livraison entre particuliers et services collaboratifs. Trouvez ce dont vous avez besoin pour expédier vos colis facilement."
        />
      </Helmet>

      <div className="web-screen">
        <Loader loading={state?.activity} />

        <Grid container>
          <Grid xs={12} item>
            <HeaderForMarketPlace
              onInputChangeForHeader={onInputChangeForHeader}
              {...state}
              {...props}
            />
          </Grid>
        </Grid>
        {state?.showSearchedData ? (
          <div
            style={{
              minHeight: "100vh",
              padding: "0px 50px 0px 50px",
              background: defaultLabelColors.main_background_two,
            }}
          >
            <Grid container spacing={2}>
              <Grid
                xs={12}
                item
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
                onClick={() => {
                  setState({ showSearchedData: false, global_search: "" });
                }}
              >
                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "26px",
                    gap: 10,
                    cursor: "pointer",
                  }}
                  className="display-flex"
                >
                  <ArrowBackIos style={{ fontSize: "22px" }} /> Vendre un
                  article
                </p>
              </Grid>
            </Grid>

            {state?.allSearchProduct?.length < 1 ||
            !state?.allSearchProduct?.length ? (
              <div
                style={{
                  height: "60vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  opacity: "0.5",
                }}
              >
                {state?.showImg && (
                  <img
                    src={noProdFound}
                    alt=""
                    style={{ height: "400px", width: "400px" }}
                  />
                )}
              </div>
            ) : (
              <>
                <p
                  style={{ fontFamily: "Raleway-Semi-Bold", fontSize: "16px" }}
                >
                  {state?.allSearchProduct?.length} annonces
                </p>
                <Grid container spacing={2}>
                  {state?.allSearchProduct
                    ?.slice(0, xl ? 6 : lg ? 4 : md ? 3 : 4)
                    ?.map((i: any, idx: any) => {
                      return (
                        <Grid
                          key={idx}
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          lg={3}
                          xl={2}
                          style={{ cursor: "pointer", maxHeight: "370px" }}
                          onClick={() => {
                            handleOpenProduct(i);
                          }}
                        >
                          <ProductCard
                            i={i}
                            handleAddToFavorite={handleAddToFavorite}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </>
            )}
          </div>
        ) : (
          <div style={{ background: "#FCE6DD", paddingBottom: 20 }}>
            <div style={{ padding: "0px 50px 0px 50px" }}>
              <Grid container style={{ display: "none" }}>
                <Grid xs={12} item>
                  <h1>MarketPlace</h1>
                </Grid>
              </Grid>

              <Grid container spacing={spacing.spacing2}>
                <Grid xs={12} item>
                  <h2
                    className="page-title-two"
                    style={{ color: "#281208", fontSize: "32px" }}
                  >
                    Latest articles posted online
                  </h2>
                </Grid>
              </Grid>
              <ScrollContainer
                innerRef={scrollRef}
                className="scroll-container"
                horizontal={true}
                style={{ width: "100%", height: "auto", paddingBottom: "50px" }}
              >
                {state?.parentCategories?.map((i: any, idx: any) => {
                  return (
                    <div
                      style={{ paddingLeft: "10px", width: "100%" }}
                      key={idx}
                    >
                      <RenderCategories
                        i={i}
                        getProductsByCategory={getProductsByCategory}
                      />
                    </div>
                  );
                })}
              </ScrollContainer>

              <Grid container spacing={2}>
                <Grid
                  xs={12}
                  md={6}
                  item
                  onClick={() => {
                    navigate("/add-product-individual");
                  }}
                >
                  <div
                    style={{
                      background: defaultLabelColors.main_orange,
                      borderRadius: "32px",
                      // height: 146,
                      display: "flex",
                      flexDirection: "row",
                      position: "relative",
                      cursor: "pointer",
                      alignItems: "center",
                      padding: "40px 30px",
                      columnGap: 10,
                      overflow: "hidden",
                    }}
                  >
                    <AddProductIcon />
                    <div
                      style={{
                        fontSize: "40px",
                        fontFamily: "Raleway-Bold",
                        color: "#fff",
                      }}
                    >
                      Vendre un article
                    </div>
                    <img
                      src={marketplace_banner_img}
                      alt="marketplace-banner"
                      style={{
                        position: "absolute",
                        right: 0,
                        height: 200,
                        objectFit: "contain",
                        top: "3em",
                      }}
                    />
                  </div>
                </Grid>
                <Grid xs={12} md={6} item>
                  <div
                    style={{
                      background: defaultLabelColors.main_blue,
                      borderRadius: "32px",
                      // height: 146,
                      display: "flex",
                      flexDirection: "row",
                      position: "relative",
                      cursor: "pointer",
                      alignItems: "center",
                      padding: "17px 30px",
                      columnGap: 10,
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "40px",
                        fontFamily: "Raleway-Bold",
                        color: "#fff",
                      }}
                    >
                      <div>Lorem ipsum</div>
                      <div>dolar sit amet.</div>
                    </div>
                    <img
                      src={marketplace_banner_img_two}
                      alt="marketplace-banner"
                      style={{
                        position: "absolute",
                        right: 0,
                        height: 315,
                        objectFit: "contain",
                        top: 10,
                      }}
                    />
                  </div>
                  {/* <div style={{ display: "flex", height: "120px" }}>
                  <div
                    style={{
                      flex: 1,
                      fontSize: "40px",
                      fontFamily: "Raleway-Variable",
                      fontWeight: "700",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Lorem ipsum <br />
                    dolar sit amet.
                  </div>
                  <div>
                    <img
                      src={marketplace_banner_img_two}
                      alt="marketplace-banner-two"
                      style={{ width: "200px" }}
                    />
                  </div>
                </div> */}
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ paddingBottom: "10px" }}>
                <Grid
                  xs={12}
                  item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "-35px",
                  }}
                >
                  <h2
                    style={{ flex: 1, fontSize: "28px", color: "#281208" }}
                    className="page-title-two"
                  >
                    Derniers produits mis en ligne
                  </h2>
                  {state?.latestProducts?.length < 4 ? null : (
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "18px",
                        color: defaultLabelColors.main_blue,
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate("/trending-products");
                      }}
                    >
                      Voir tous{" "}
                    </p>
                  )}
                </Grid>

                {state?.latestProducts
                  ?.slice(0, xl ? 6 : lg ? 4 : md ? 3 : 4)
                  ?.map((i: any, idx: any) => {
                    return (
                      <Grid
                        key={idx}
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        lg={3}
                        xl={2}
                        style={{ cursor: "pointer", maxHeight: "370px" }}
                        onClick={() => {
                          handleOpenProduct(i);
                        }}
                      >
                        <ProductCard
                          i={i}
                          handleAddToFavorite={handleAddToFavorite}
                        />
                      </Grid>
                    );
                  })}
              </Grid>

              <Grid container spacing={2} style={{ paddingBottom: "20px" }}>
                <Grid
                  xs={12}
                  item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "-35px",
                  }}
                >
                  <h2
                    style={{ flex: 1, color: "#281208", fontSize: "28px" }}
                    className="page-title-two"
                  >
                   Aricles les plus tendances
                  </h2>
                  {state?.topTrendingProducts?.length < 4 ? null : (
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "18px",
                        color: defaultLabelColors.main_blue,
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate("/latest-products");
                      }}
                    >
                      Voir tous{" "}
                    </p>
                  )}
                </Grid>

                {state?.topTrendingProducts
                  ?.slice(0, xl ? 6 : lg ? 4 : md ? 3 : 4)
                  ?.map((i: any, idx: any) => {
                    return (
                      <Grid
                        key={idx}
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        lg={3}
                        xl={2}
                        style={{ cursor: "pointer", maxHeight: "370px" }}
                        onClick={() => {
                          handleOpenProduct(i);
                        }}
                      >
                        <ProductCard
                          i={i}
                          handleAddToFavorite={handleAddToFavorite}
                        />
                      </Grid>
                    );
                  })}
              </Grid>

              {user && state?.mostViewedProducts?.length > 1 ? (
                <Grid container spacing={2} style={{ paddingBottom: "50px" }}>
                  <Grid
                    xs={12}
                    item
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "-35px",
                    }}
                  >
<h2
                    style={{ flex: 1, fontSize: "28px", color: "#281208" }}
                    className="page-title-two"
                  >                      Vue récemment
                    </h2>
                    {state?.topTrendingProducts?.length < 4 ? null : (
                      <p
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontSize: "18px",
                          color: defaultLabelColors.main_blue,
                          fontWeight: "600",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate("/most-viewed");
                        }}
                      >
                        Voir tous{" "}
                      </p>
                    )}
                  </Grid>
                  {state?.mostViewedProducts?.length < 1 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      {" "}
                      <span
                        style={{
                          textAlign: "center",
                          fontFamily: "Raleway-Variable",
                          color: "GrayText",
                          fontSize: "32px",
                        }}
                      >
                        No viewed any product yet!
                      </span>
                    </div>
                  ) : (
                    state?.mostViewedProducts
                      ?.slice(0, xl ? 6 : lg ? 4 : md ? 3 : 4)
                      ?.map((i: any, idx: any) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            md={3}
                            lg={3}
                            xl={2}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleOpenProduct(i);
                            }}
                          >
                            <ProductCard
                              i={i}
                              handleAddToFavorite={handleAddToFavorite}
                            />
                          </Grid>
                        );
                      })
                  )}
                </Grid>
              ) : null}
            </div>
          </div>
        )}

        <div
          className="main-container"
          style={{ background: defaultLabelColors.main_background_two }}
        >
          <CustomFooter />
        </div>
      </div>
    </>
  );
};

export default MarketPlace;

export const ProductCard = ({ i, handleAddToFavorite }: any) => (
  <div
    style={{
      background: color_theme.white,
      borderRadius: 32,
      minHeight:245
    }}
  >
    <div
      style={{
        position: "relative",
        borderTopLeftRadius: 32,
        borderTopRightRadius: 32,
      }}
    >
      <img
        src={i?.images?.[0] ?? no_image_url}
        style={{
          height: 150,
          width: "100%",
          objectFit: "cover",
          borderTopLeftRadius: 32,
          borderTopRightRadius: 32,
        }}
      />
      {i?.is_particular && (
        <div
          style={{
            position: "absolute",
            bottom: 5,
            right: 10,
            padding: 5,
            fontFamily: "Raleway-Bold",
            background: "#F5D9BC",
            borderRadius: 5,
            color: "#281208",
            fontSize: "14px",
          }}
        >
          Seconde main
        </div>
      )}
    </div>

    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 8,
        rowGap: 8,
        padding: 16,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <p
          style={{
            fontSize: 16,
            fontFamily: "Raleway-Bold",
            width: `calc(100% - 100px)`,
            flex: 1,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            color: "##281208",
            margin: 0,
          }}
        >
          {handleLocalize({
            en: i?.product_title?.en,
            fr: i?.product_title?.fr,
          }) ?? "N/A"}
        </p>

        <p
          style={{
            fontSize: 16,
            fontFamily: "Raleway-Bold",
            color: i?.is_particular
              ? color_theme.main_orange
              : color_theme.main_blue,
            margin: 0,
            padding: `0px 0px 0px 10px`,
          }}
        >
          {numberFormatWithoutCurrency(
            i?.is_particular
              ? Number(i?.base_price ?? 0)
              : i?.variation?.id
              ? Number(i?.variation?.price ?? 0) -
                Number(i?.variation?.discount ?? 0)
              : Number(i?.base_price ?? 0)
          )}{" "}
          €
        </p>
      </div>

      <p
        style={{
          fontSize: 12,
          fontFamily: "Raleway",
          maxWidth: "100%",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          color: "#281208",
          margin: 0,
        }}
      >
        {handleLocalize({
          en: i?.brand_name?.en,
          fr: i?.brand_name?.fr,
        }) ?? "N/A"}{" "}
        {" - "}
        {i?.is_particular ? (
          <span
            style={{
              fontSize: 12,
              fontFamily: "Raleway-Bold",
              color: color_theme.main_orange,
              margin: 0,
            }}
          >
            Livraison standard
          </span>
        ) : (
          <span
            style={{
              fontSize: 12,
              fontFamily: "Raleway-Bold",
              color: color_theme.main_blue,
              margin: 0,
            }}
          >
            Livraison standard ou express
          </span>
        )}
      </p>

      <p
        style={{
          fontSize: 12,
          fontFamily: "Raleway",
          maxWidth: "100%",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          color: "#281208",
          margin: 0,
        }}
      >
        {i?.address?.city ?? " "} {i?.address?.city ? "," : ""} {i?.address?.country ?? " "} {" "}
        {i?.address?.digital_code ?? " "}
      </p>
    </div>
  </div>
);

export const RenderCategories = ({ i, getProductsByCategory }: any) => (
  <div
    style={{
      height: 213,
      width: 375,
      backgroundColor: "#fff",
      borderRadius: 24,
    }}
    onClick={() => {
      getProductsByCategory(i?.id);
    }}
  >
    <div>
      <div
        style={{
          display: "flex",
          padding: 14,
          alignItems: "center",
          columnGap: 14,
        }}
      >
        <ConsoleIcon height={50} width={37} />
        <p
          style={{
            fontSize: 26,
            lineHeight: "32px",
            fontFamily: "Raleway-Semi-Bold",
            color: color_theme.black,
            margin: 0,
          }}
        >
          {handleLocalize({
            en: i?.category_name?.en,
            fr: i?.category_name?.fr,
          }) ?? "N/A"}
        </p>
      </div>
      <div
        style={{
          padding: 16,
        }}
      >
        <Grid container spacing={2}>
          {i?.childern?.slice(0, 4).map((category: any, index: any) => (
            <Grid item xs={6} key={index}>
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  backgroundColor: "#F5F5F5",
                  color: "#000",
                  textTransform: "none",
                  fontWeight: "600",
                  fontFamily: "Raleway-Variable",
                  borderRadius: "16px",
                  boxShadow: "none",
                  whiteSpace: "nowrap",
                  fontSize: "1em",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  "&:hover": {
                    backgroundColor: "#F5F5F5",
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  getProductsByCategory(category?.id);
                }}
              >
                {handleLocalize({
                  en: category?.category_name?.en,
                  fr: category?.category_name?.fr,
                }) ?? "N/A"}
              </Button>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  </div>
);
