// import {
//   Accordion,
//   AccordionDetails,
//   AccordionSummary,
//   Grid,
// } from "@mui/material";
// import React from "react";
// import Header from "../../UiComponent/Header";
// import { ExpandMore } from "@mui/icons-material";
// import {
//   FacebookBlackandWhiteIcon,
//   LinkedinBlackandWhiteIcon,
//   TwitterBlackandWhiteIcon,
// } from "../../assets/images/FrankIcons";
// import NewHeader from "../../UiComponent/NewHeader";
// import { Helmet } from "react-helmet";

// function Blogs(props: any) {
//   return (
//     <>
//       <Helmet>
//         <title>
//           Blogs | Articles et Conseils sur la Livraison Collaborative
//         </title>
//         <link rel="canonical" href="https://www.v2internal.frankme.com/blogs" />
//         <meta
//           name="description"
//           content="Découvrez nos articles et conseils sur la livraison collaborative, des témoignages de la communauté Frank, et des astuces pour optimiser vos envois."
//         />
//       </Helmet>
//       <div style={{ padding: "0px 50px 0px 50px", background: "#f9c9b3" }}>
//         <Grid
//           container
//           spacing={2}
//           className="display-flex"
//           style={{
//             background: "#F4C9B3",
//             paddingLeft: "40px",
//             paddingRight: "40px",
//             paddingTop: "30px",
//           }}
//         >
//           <Grid xs={12} item style={{ zIndex: 99 }}>
//             <NewHeader />
//           </Grid>
//         </Grid>
//         <Grid container spacing={2} style={{ padding: "0px 100px 0px 100px" }}>
//           <Grid xs={12} item>
//             <h1 className="heading-one">
//               La Livraison de Particulier à Particulier :<br /> Une Révolution
//               en Marche
//             </h1>
//             <p className="paragraph-text" style={{ marginTop: "-10px" }}>
//               Lorem ipsum dolor sit amet consectetur. Tortor nibh in massa non
//               sit nascetur et. Neque faucibus nec interdum vulputate aliquet
//               lorem quis. Mattis tincidunt mi non malesuada aliquet elit.
//               Vulputate pulvinar gravida placerat pharetra eu.
//             </p>
//           </Grid>

//           <Grid xs={12} md={8} item>
//             <img
//               src="https://i.postimg.cc/GpZhWQkG/image-720.png"
//               width={"100%"}
//               style={{ objectFit: "contain" }}
//               alt="img"
//             />
//           </Grid>
//           <Grid xs={12} md={4} item>
//             <img
//               src="https://i.postimg.cc/J087nzYh/image-720.png"
//               width={"100%"}
//               style={{ objectFit: "contain" }}
//               alt="img"
//             />
//             <div
//               style={{
//                 borderRadius: "32px",
//                 width: "97%",
//                 background: "#2D26E6",
//                 padding: "20px",
//                 color: "#fff",
//                 marginTop: "20px",
//                 marginRight: "10px",
//               }}
//             >
//               <p
//                 style={{
//                   fontFamily: "Raleway-Variable",
//                   fontWeight: "500",
//                   fontSize: "28px",
//                   textAlign: "left",
//                 }}
//               >
//                 Introduction
//               </p>
//               <p className="paragraph-text" style={{ lineHeight: "30px" }}>
//                 Avec l'essor des plateformes numériques et l'augmentation des
//                 échanges entre particuliers, la livraison de particulier à
//                 particulier (P2P) s'impose comme une solution innovante et
//                 flexible. Ce mode de livraison permet à des particuliers
//                 d'utiliser leurs déplacements pour transporter des colis pour
//                 d'autres individus, créant ainsi un réseau de distribution
//                 dynamique et décentralisé.
//               </p>
//             </div>
//           </Grid>
//         </Grid>
//         <Grid container spacing={2} style={{ padding: "0px 100px 0px 100px" }}>
//           <Grid xs={12} md={5} item style={{ marginTop: "20px" }}>
//             <div style={{ padding: "20px" }}>
//               <div className="div-frame-116" style={{ padding: "15px" }}>
//                 <div style={{ width: "100%" }}>
//                   <Accordion
//                     elevation={0}
//                     style={{
//                       border: "0px",
//                       background: "#f9c9b3",
//                       width: "100%",
//                     }}
//                   >
//                     <AccordionSummary
//                       style={{
//                         border: "none",
//                         width: "100%",
//                         fontSize: "22px",
//                       }}
//                       expandIcon={
//                         <div
//                           style={{
//                             width: "100%",
//                             display: "flex",
//                             justifyContent: "flex-end",
//                             alignItems: "flex-end",
//                           }}
//                         >
//                           {" "}
//                           <ExpandMore style={{ textAlign: "right" }} />{" "}
//                         </div>
//                       }
//                       aria-controls="panel1-content"
//                       id="panel1-header"
//                       className="span-what-is-frank"
//                     >
//                       Un Modèle Économique Collaboratif
//                     </AccordionSummary>
//                     <AccordionDetails style={{ width: "100%" }}>
//                       Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//                       Suspendisse malesuada lacus ex, sit amet blandit leo
//                       lobortis eget.
//                     </AccordionDetails>
//                   </Accordion>
//                   {/* <span className="span-what-is-frank">Qu’est-ce que Frank ?</span>
//               <div className="div-line-chevron-down">
//                 <div className="div-icon"></div>
//               </div> */}
//                 </div>
//               </div>
//               <div className="div-line-117"></div>

//               <div className="div-frame-116" style={{ padding: "15px" }}>
//                 <div style={{ width: "100%" }}>
//                   <Accordion
//                     elevation={0}
//                     style={{
//                       border: "0px",
//                       background: "#f9c9b3",
//                       width: "100%",
//                     }}
//                   >
//                     <AccordionSummary
//                       style={{
//                         border: "none",
//                         width: "100%",
//                         fontSize: "22px",
//                       }}
//                       expandIcon={
//                         <div
//                           style={{
//                             width: "100%",
//                             display: "flex",
//                             justifyContent: "flex-end",
//                             alignItems: "flex-end",
//                           }}
//                         >
//                           {" "}
//                           <ExpandMore style={{ textAlign: "right" }} />{" "}
//                         </div>
//                       }
//                       aria-controls="panel1-content"
//                       id="panel1-header"
//                       className="span-what-is-frank"
//                     >
//                       Impact Environnemental Positif
//                     </AccordionSummary>
//                     <AccordionDetails style={{ width: "100%" }}>
//                       Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//                       Suspendisse malesuada lacus ex, sit amet blandit leo
//                       lobortis eget.
//                     </AccordionDetails>
//                   </Accordion>
//                   {/* <span className="span-what-is-frank">Qu’est-ce que Frank ?</span>
//               <div className="div-line-chevron-down">
//                 <div className="div-icon"></div>
//               </div> */}
//                 </div>
//               </div>

//               <div className="div-line-117"></div>
//               <div className="div-frame-116" style={{ padding: "15px" }}>
//                 <div style={{ width: "100%" }}>
//                   <Accordion
//                     elevation={0}
//                     style={{
//                       border: "0px",
//                       background: "#f9c9b3",
//                       width: "100%",
//                     }}
//                   >
//                     <AccordionSummary
//                       style={{
//                         border: "none",
//                         width: "100%",
//                         fontSize: "22px",
//                       }}
//                       expandIcon={
//                         <div
//                           style={{
//                             width: "100%",
//                             display: "flex",
//                             justifyContent: "flex-end",
//                             alignItems: "flex-end",
//                           }}
//                         >
//                           {" "}
//                           <ExpandMore style={{ textAlign: "right" }} />{" "}
//                         </div>
//                       }
//                       aria-controls="panel1-content"
//                       id="panel1-header"
//                       className="span-what-is-frank"
//                     >
//                       Confiance et Sécurité
//                     </AccordionSummary>
//                     <AccordionDetails style={{ width: "100%" }}>
//                       Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//                       Suspendisse malesuada lacus ex, sit amet blandit leo
//                       lobortis eget.
//                     </AccordionDetails>
//                   </Accordion>
//                   {/* <span className="span-what-is-frank">Qu’est-ce que Frank ?</span>
//               <div className="div-line-chevron-down">
//                 <div className="div-icon"></div>
//               </div> */}
//                 </div>
//               </div>

//               <div className="div-line-117"></div>
//               <div className="div-frame-116" style={{ padding: "15px" }}>
//                 <div style={{ width: "100%" }}>
//                   <Accordion
//                     elevation={0}
//                     style={{
//                       border: "0px",
//                       background: "#f9c9b3",
//                       width: "100%",
//                     }}
//                   >
//                     <AccordionSummary
//                       style={{
//                         border: "none",
//                         width: "100%",
//                         fontSize: "22px",
//                       }}
//                       expandIcon={
//                         <div
//                           style={{
//                             width: "100%",
//                             display: "flex",
//                             justifyContent: "flex-end",
//                             alignItems: "flex-end",
//                           }}
//                         >
//                           {" "}
//                           <ExpandMore style={{ textAlign: "right" }} />{" "}
//                         </div>
//                       }
//                       aria-controls="panel1-content"
//                       id="panel1-header"
//                       className="span-what-is-frank"
//                     >
//                       Un Marché en Pleine Expansion
//                     </AccordionSummary>
//                     <AccordionDetails style={{ width: "100%" }}>
//                       Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//                       Suspendisse malesuada lacus ex, sit amet blandit leo
//                       lobortis eget.
//                     </AccordionDetails>
//                   </Accordion>
//                   {/* <span className="span-what-is-frank">Qu’est-ce que Frank ?</span>
//               <div className="div-line-chevron-down">
//                 <div className="div-icon"></div>
//               </div> */}
//                 </div>
//               </div>

//               <div className="div-line-117"></div>
//             </div>
//           </Grid>

//           <Grid xs={12} md={7} item>
//             <p className="heading-two">Un Modèle Économique Collaboratif</p>
//             <p className="paragraph-text">
//               La livraison de particulier à particulier repose sur l'économie
//               collaborative, où l'usage des ressources existantes est optimisé.
//               Les particuliers qui voyagent, que ce soit pour le travail, les
//               loisirs ou des raisons personnelles, peuvent rentabiliser leurs
//               trajets en transportant des colis. Les expéditeurs, de leur côté,
//               bénéficient d'une alternative souvent plus rapide et moins
//               coûteuse que les services de livraison traditionnels. Cette
//               approche réduit les coûts pour les deux parties, tout en générant
//               un revenu complémentaire pour les livreurs.
//             </p>
//             <div style={{ height: "350px" }}>
//               <img
//                 src="https://i.postimg.cc/fWvS9GcP/image-720.png"
//                 style={{ height: "100%", width: "100%", objectFit: "contain" }}
//                 alt="img"
//               />
//             </div>

//             <p className="heading-two">Impact Environnemental Positif</p>
//             <p className="paragraph-text">
//               L'impact environnemental de ce mode de livraison est notable. En
//               utilisant des trajets déjà prévus, les émissions de CO2 liées à la
//               logistique sont réduites, contrairement aux modèles de livraison
//               classiques qui nécessitent des véhicules supplémentaires. De plus,
//               la livraison P2P encourage une utilisation plus rationnelle des
//               ressources, contribuant ainsi à une diminution de l'empreinte
//               carbone collective.
//             </p>

//             <p className="heading-two">Confiance et Sécurité</p>
//             <p className="paragraph-text">
//               L'un des défis majeurs de la livraison de particulier à
//               particulier réside dans la confiance et la sécurité. Les
//               plateformes numériques jouent un rôle crucial en mettant en place
//               des systèmes de vérification d'identité, des évaluations par les
//               utilisateurs, et des garanties d'assurance pour protéger à la fois
//               les expéditeurs et les livreurs. La transparence et la traçabilité
//               offertes par ces plateformes sont essentielles pour instaurer un
//               climat de confiance.{" "}
//             </p>

//             <div style={{ height: "350px" }}>
//               <img
//                 src="https://i.postimg.cc/zBr3L7V5/image-720.png"
//                 style={{ height: "100%", width: "100%", objectFit: "contain" }}
//                 alt="img"
//               />
//             </div>

//             <p className="heading-two">Un Marché en Pleine Expansion</p>
//             <p className="paragraph-text">
//               Le marché de la livraison P2P est en pleine expansion. De
//               nombreuses start-ups se sont lancées dans ce créneau, proposant
//               des applications mobiles intuitives et des services personnalisés.
//               Ces entreprises capitalisent sur la flexibilité et la réactivité
//               offertes par ce modèle pour répondre aux attentes des
//               consommateurs modernes. Les secteurs de la vente en ligne entre
//               particuliers, tels que les marketplaces de seconde main, profitent
//               particulièrement de cette dynamique.{" "}
//             </p>

//             <p className="heading-two">Vers un Avenir Durable et Solidaire</p>
//             <p className="paragraph-text">
//               En conclusion, la livraison de particulier à particulier
//               représente une révolution dans le domaine de la logistique. Elle
//               incarne une réponse innovante aux défis économiques et
//               environnementaux contemporains, tout en favorisant une solidarité
//               entre les membres de la communauté. À mesure que cette pratique se
//               développe, elle pourrait transformer de manière significative
//               notre manière de concevoir la livraison et le transport de
//               marchandises.{" "}
//             </p>

//             <p className="paragraph-text">
//               L'avenir de la livraison réside peut-être dans la capacité des
//               individus à se mobiliser pour une cause commune, optimisant ainsi
//               les ressources disponibles et renforçant les liens sociaux. La
//               livraison de particulier à particulier est plus qu'un simple
//               service : c'est un mouvement vers un mode de vie plus durable et
//               collaboratif.
//             </p>

//             <p className="heading-two" style={{ fontSize: "24px" }}>
//               partager cet article sur
//             </p>

//             <div style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
//               <FacebookBlackandWhiteIcon />
//               <TwitterBlackandWhiteIcon />
//               <LinkedinBlackandWhiteIcon />
//             </div>
//           </Grid>
//         </Grid>
//       </div>
//     </>
//   );
// }

// export default Blogs;

import React, { useEffect, useState } from "react";
import arrowRight from "../../assets/images/FrankIcons/arrows.png";
import { Helmet } from "react-helmet";
import ScrollToTop from "../../UiComponent/ScrollToTop";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import NewHeader from "../../UiComponent/NewHeader";
import { defaultLabelColors } from "../../utils/constants";
import InputField from "../../UiComponent/TextFeild";
import { Box, useMediaQuery, useTheme } from "@mui/system";
import { ArrowForwardIos, Instagram, Search } from "@mui/icons-material";
import apiServices from "../../services/RequestHandler";
import { handleLocalize } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import Loader from "../../UiComponent/Loader";
import { color_theme } from "../SenderInbox.tsx/AccepterChat";
import CustomFooter from "../../UiComponent/CustomFooter";
import classNames from "classnames";
import { LeftIcon } from "../../assets/images/FrankIcons";
import left from "../../assets/images/FrankIcons/Left.png"
import right from "../../assets/images/FrankIcons/Right.png"

import { FrankDivider } from "../MarketPlace/Checkout";
interface InstagramPost {
  id: string;
  media_url: string;
  caption: string;
}
function Blogs(props: any) {
  const { classes } = props;
  const navigate = useNavigate();
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      activity: false,
      page: 1,
    }
  );

  const [posts, setPosts] = useState<InstagramPost[]>([]);

  // useEffect(() => {
  //   const fetchInstagramPosts = async () => {
  //     const accessToken = "YOUR_ACCESS_TOKEN"; // Replace with your token
  //     const apiUrl = `https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption&access_token=${accessToken}`;

  //     try {
  //       const response = await fetch(apiUrl);
  //       const data = await response.json();
  //       const recentPosts = data.data.slice(0, 6); // Get the latest 6 posts
  //       setPosts(recentPosts);
  //     } catch (error) {
  //       console.error("Error fetching Instagram posts:", error);
  //     }
  //   };

  //   fetchInstagramPosts();
  // }, []);

  const getInstaPost = async () => {
    try {
      setState({ activity: true });
      let resp = await apiServices.getFromApi("c2c-blogs/insta-posts", "");
      console.log("Insta Post", resp);
      setState({ instaPosts: resp?.data, activity: false });
    } catch (error) {}
  };

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));

  React.useEffect(() => {
    handleGetAllCategory();
    handleGetAllBlog();
    getInstaPost();
  }, []);

  const handleGetAllCategory = async () => {
    try {
      setState({ activity: true });

      let resp = await apiServices.getFromApi("c2c-blog-categories/web", "");
      console.log("Categories", resp);
      if (resp?.status === 200 || resp?.status === 201) {
        setState({ allCategories: resp?.data });
        setState({ activity: false });
      }
    } catch (error) {
      console.log(error);
      setState({ activity: false });
    }
  };

  useEffect(() => {
    handleGetAllBlog();
  }, []);

  const handleGetAllBlog = async () => {
    try {
      setState({ activity: true });
      let resp = await apiServices.getFromApi(
        `c2c-blogs/web?page=${state?.page}&limit=6`,
        ""
      );
      if (resp?.status === 200 || resp?.status === 201) {
        setState({ allBlogs: resp?.data, totalPage: resp?.total_pages });
        setState({ activity: false });
      }
    } catch (error) {
      setState({ activity: false });
    }
  };

  const handleGetBlogsByCategory = async (data: any) => {
    setState({ activity: true });
    let resp = await apiServices.getFromApi(`c2c-blogs/web/${data}`, "");
    console.log("resp?.", resp);
    setState({ allBlogs: resp?.data });
    setState({ activity: false });
  };

  return (
    <>
      <Loader loading={state?.activity} />

      <Helmet>
        <title>
          Blogs | Articles et Conseils sur la Livraison Collaborative
        </title>
        <link rel="canonical" href="https://www.v2internal.frankme.com/blogs" />
        <meta
          name="description"
          content="Découvrez nos articles et conseils sur la livraison collaborative, des témoignages de la communauté Frank, et des astuces pour optimiser vos envois."
        />
      </Helmet>
      <div className="web-screen">
        <ScrollToTop />

        <div style={{ background: "#f2cca7" }}>
          <ScrollToTop />
          <Grid
            container
            spacing={2}
            className="display-flex"
            style={{
              background: "#f2cca7",
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "30px",
            }}
          >
            <Grid xs={12} item style={{ zIndex: 99 }}>
              <NewHeader />
            </Grid>
          </Grid>
          <div style={{ position: "relative",display:'flex',alignItems:'center' }}>
            <div>
              <img src={left} style={{height:'200px'}} alt="" />
            </div>
            <div style={{flex:1 }}>
            <h1
              className="heading-one"
              style={{
                fontFamily: "Raleway-Italic",
                color: defaultLabelColors.main_blue,
                flex:1,
                //  padding: "40px 160px",
              }}
            >
              NOTRE BLOG
            </h1>
            </div>
            <div >
            <img src={right} style={{height:'200px'}} alt="" />
            </div>
          </div> 
        </div>
        <div style={{ background: "#f9d9b6", padding: "20px 160px" }}>
          <Grid container spacing={2}>
            <Grid
              xs={12}
              md={4}
              item
              style={{ height: "100vh", display: "block" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                {/* <div>
                  <InputField
                    placeHolder={`Chercher un produit chez   `}
                    input_value={props?.global_search}
                    input_name={"global_search"}
                    onchange={props.onInputChangeForHeader}
                    isDisable
                    input_style={{
                      background: "#E0E0E0",
                      // minWidth: "100%",
                      // width: xl ? "726px" : lg ? "600px" : md ? "400px" : "300px",
                      // maxWidth: "726px",
                      borderRadius: "100px",
                      fontFamily: "Raleway-Variable",
                      color: "#000",
                    }}
                    endIicon={
                      <Search
                        style={{ color: "#6D6D6D" }}
                      />
                    }
                  />
                </div> */}

                {/* <div
                  style={{
                    background: "#f2cca7",
                    borderRadius: "16px",
                    padding: "24px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Raleway-Extra-Bold",
                      fontSize: "20px",
                      color: defaultLabelColors.main_blue,
                      margin: 20,
                      paddingBottom: "20px",
                    }}
                  >
                    Catégorie
                  </p>
                  {state?.allCategories?.map((item: any, idx: any) => {
                    return (
                      <>
                        <div
                          style={{
                            display: "flex",
                            fontFamily: "Raleway-Italic",
                            margin: 15,
                            cursor: "pointer",
                          }}
                          onClick={() => handleGetBlogsByCategory(item?.id)}
                        >
                          <div style={{ flex: 1, fontSize: "18px" }}>
                            {handleLocalize({
                              en: item?.category_name?.en,
                              fr: item?.category_name?.fr,
                            })}
                          </div>
                          <div>
                            <img
                              src={arrowRight}
                              alt=""
                              style={{ height: "15px", width: "20px" }}
                            />
                          </div>
                        </div>
                        <div
                          style={{ height: "1px", background: "#fff" }}
                        ></div>
                      </>
                    );
                  })}
                </div> */}

<div
                  style={{
                    background: "#f2cca7",
                    borderRadius: "13px",
                    padding: "24px 35px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Raleway-Extra-Bold",
                      fontSize: "18px",
                      color: defaultLabelColors.main_blue,
                      // margin: 20, 
                      paddingBottom: "15px",
                      paddingLeft:10
                    }}
                  >
                    Catégorie
                  </p>
                  {state?.allCategories?.map((item: any, idx: any) => {
                    return (
                      <>
                      <div
                          onClick={() => handleGetBlogsByCategory(item?.id)}

                         style={{
                          display: "flex",
                          fontFamily: "Raleway-Italic",
                          margin: 15,
                        }}
                      >
                        <div style={{ flex: 1, fontSize: "18px" }}>
                          {handleLocalize({
                            en: item?.category_name?.en,
                            fr: item?.category_name?.fr,
                          })}
                        </div>
                        <div>
                         <img src={arrowRight} alt="" style={{height:'15px',width:'20px'}} />
                        </div>
                      </div>
                      <div style={{height:'1px',background:'#fff'}}></div>
                      </>
                    );
                  })}
                </div>
                 <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "22px",
                    fontFamily: "Raleway-Variable",
                    color: defaultLabelColors.main_blue,
                    gap: 6,
                  }}
                >
                  <Instagram style={{ fontSize: "32px", fontWeight: "400" }} />{" "}
                  Instagram
                </div>
                <div>
                  <Grid container spacing={1} className="gallery">
                    {state?.instaPosts?.map((i: any) => {
                      return (
                        <Grid
                          xs={6}
                          md={4}
                          item
                          className="gallery-item"
                          onClick={() =>
                            window.open(
                              "https://www.instagram.com/frankme.fr/",
                              "_blank",
                              "noopener,noreferrer"
                            )
                          }
                        >
                          <img
                            src={
                              i?.media_type === "VIDEO"
                                ? i?.thumbnail_url
                                : i?.media_url
                            }
                            alt=""
                            style={{
                              borderRadius: "12px",
                              height: "117px",
                              width: "117px",
                              objectFit: "cover",
                              maxWidth:'100%',
                            }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              </div>
            </Grid>
            <Grid xs={12} md={8} item>
              <Grid container spacing={2}>
                {state?.allBlogs?.map((item: any) => {
                  return (
                    <Grid
                      style={{ cursor: "pointer",
                       }}
                      xs={12}
                      md={6}
                      item
                      onClick={() => {
                        navigate("/blog-details", {
                          state: {
                            ...item,
                          },
                        });
                      }}
                    >
                      <div  >
                        <Card
                          elevation={0}
                          sx={{
                            backgroundColor: "#fff",
                            height: "462px",
                            borderRadius:4
                          }}
                        >
                          <CardMedia
                            component="img"
                            style={{ height: "227px", objectFit: "cover" }}
                            image={
                              item?.blog_image?.[0] ?? item?.blog_image?.[1]
                            } // Replace with the image URL you need
                            alt="Blog card image"
                          />

                          <Box
                            sx={{
                              position: "relative",
                              backgroundColor: "#fff",
                            }}
                          >
                            <Button
                              variant="contained"
                              size="small"
                              sx={{
                                position: "absolute",
                                fontSize:'14px',
                                fontFamily: "Raleway-Semi-Bold",
                                top: -20,
                                right: 16,
                                backgroundColor: "#f26e22",
                                color: "#fff",
                                fontWeight: "bold",
                                textTransform: "none",
                                boxShadow: 1,
                                borderRadius: "20px",
                                paddingX: 2,
                                "&:hover": { backgroundColor: "#e75e20" },
                              }}
                            >
                              {handleLocalize(item?.category_name)}
                            </Button>
                          </Box>

                          <CardContent style={{ padding: "37px 46px" }}>
                            <Typography
                              className="elipsis-title-blogs"
                              style={{
                                fontFamily: "Raleway-Extra-Bold",
                                fontSize: "21px",
                                color: "#000",
                              }}
                            >
                              {handleLocalize(item?.title)}
                            </Typography>
                            <Typography
                              sx={{
                                marginBottom: 2,
                                fontFamily: "Raleway-Italic",
                                fontSize:'14px'
                              }}
                            >
                              Posté le 17 octobre 2024
                            </Typography>
                            <Typography
                              className="elipsis-title-desc"
                              style={{ fontFamily: "Raleway-Variable",fontSize:'16px' }}
                            >
                              {handleLocalize(item?.sub_title)}
                            </Typography>
                          </CardContent>
                        </Card>
                      </div>
                    </Grid>
                  );
                })}
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    padding: "20px",
                  }}
                >
                  <Pagination
                    count={state?.totalPage}
                    page={state?.page}
                    onChange={(event, page) => console.log(event)}
                    color="primary"
                    size={"medium"}
                    shape="rounded"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className={classNames(classes.pagination_container)}
                    variant="outlined"
                    showFirstButton
                    showLastButton
                  />
                </div> */}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="main-container" style={{ background: "#f9d9b6" }}>
        <CustomFooter showBorder={true} />
      </div>
    </>
  );
}

export default Blogs;
