import React, { useRef, useEffect } from "react";
import { Autocomplete } from "@react-google-maps/api";

interface GoogleAutocompleteFieldProps {
  placeholder?: string;
  inputValue: string;
  apiKey: string;
  onPlaceSelected: (place: google.maps.places.PlaceResult | null) => void;
  onInputChange: (value: string) => void;
  styles?: React.CSSProperties;
}

const GoogleAutocompleteField: React.FC<GoogleAutocompleteFieldProps> = ({
  placeholder = "Search for a location",
  onPlaceSelected,
  inputValue,
  onInputChange,
  styles = {
    background: "#F4F3F6",
    width: "100%",
    borderRadius: "16px",
    paddingRight: "10px",
    border: "none",
    fontSize: "16px",
    margin: "10px",
    fontFamily: "Raleway-Italic",
    padding: "10px",
    marginLeft: 9,
    zIndex: 1111,
    color: "#000",
    height: "41px",
  },
}) => {
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place && place.geometry) {
        onPlaceSelected(place);
      } else {
        onPlaceSelected(null);
      }
    }
  };


  
   useEffect(() => {
    const styleAutocompleteDropdown = () => {
      const dropdown = document.querySelectorAll(".pac-item, .pac-item-query");
      dropdown.forEach((item) => {
        const element = item as HTMLElement;
        element.style.fontSize = "16px";
        element.style.color = "#000";
        element.style.padding = "10px 15px";
        element.style.border='none';
        element.style.marginTop='5px';
        // element.style.transition = "all 0.3s ease";
        // element.style.borderBottom = "1px solid #eee";

        element.addEventListener("mouseover", () => {
          element.style.color = "#ee6931";
          // element.style.backgroundColor = "#fdf3e6";
          // element.style.fontSize = "18px";
        });

        element.addEventListener("mouseout", () => {
          element.style.color = "#000";
          // element.style.backgroundColor = "#fff";
          element.style.fontSize = "16px";
        });
      });
    };

    const observer = new MutationObserver(() => {
      styleAutocompleteDropdown();
    });

    // Observe changes in the body for dropdown injection
    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  return (
    <Autocomplete
     
      onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
      onPlaceChanged={handlePlaceChanged}
    >
      
      <input

        type="text"
        placeholder={placeholder}
        value={inputValue}
        onChange={(e) => onInputChange(e.target.value)}
        style={styles}
      />
    </Autocomplete>
  );
};

export default GoogleAutocompleteField;
