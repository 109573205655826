import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Loader from "../../UiComponent/Loader";
import {
  Box,
  Button,
  Checkbox,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import HeaderForMarketPlace from "../../UiComponent/HeaderForMarketPlace";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../Store";
import { handleLocalize } from "../../utils/helper";
import {
  apiKey,
  defaultLabelColors,
  item_sizes,
  spacing,
  week_day_translation,
} from "../../utils/constants";
import apiServices from "../../services/RequestHandler";
import InputField from "../../UiComponent/TextFeild";
import CustomButton from "../../UiComponent/CustomButton";

import Toast from "../../UiComponent/Toast/Toast";
import { getCart } from "../../Store/MainAppReducer";
import { FrankIcon, NoPointsFound } from "../../assets/images/FrankIcons";
import { ArrowBackIosNew } from "@mui/icons-material";
import GoogleAutocompleteField from "../../UiComponent/GoogleAutocompleteField";
import _ from "lodash";
import GoogleMaps from "../../UiComponent/GoogleMaps";
import DraggableDialog from "../../UiComponent/DraggableDialog";
import { color_theme } from "../SenderInbox.tsx/AccepterChat";
import moment from "moment";

function OrderCheckout(props: any) {
  const dispatch = useDispatch<any>();
  const selector = useSelector((state: RootState) => state.mainApp);
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputValueForMondial, setInputValueForMondial] = useState("");
  const [currentLocation, setCurrentLocation] = useState<any>();
  const [selectedServices, setSelectedServices] = useState<{
    [key: number]: string;
  }>({});

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      lang: "fr",
      checkoutStep: 1,
      selectedItem: {},
    }
  );

  useEffect(() => {
    handleGetUserCurrentLocation();
  }, []);

  const handleGetMonDialPoints = async (data: any) => {
    try {
      setState({ activity: true });
      let obj = {
        city: String(data?.[0]?.city),
        countryCode: String(data?.[0]?.countryCode) ?? "FR",
        latitude: String(data?.[0]?.latitude),
        longitude: String(data?.[0]?.longitude),
        zipcode: data?.[0]?.zipcode ? String(data?.[0]?.zipcode) : "75002",
        weight: data?.[0]?.weight
          ? String(data?.[0]?.weight)
          : String(state?.orderData?.net_weight) ?? "100",
      };
      let resp = await apiServices.postFromApi(
        "c2c-orders/mondial-points",
        obj,
        ""
      );

      setState({
        mondialPoints: resp?.relay_points,
        activity: false,
      });
    } catch (error) {}
  };

  const handleGetUserCurrentLocation = async () => {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          const pickupLocation = await apiServices.getFromApi(
            `helper/get-address?lat=${latitude}&lng=${latitude}`,
            ""
          );
          setCurrentLocation(pickupLocation?.result);
        });
      } else {
        console.log("Geolocation not supported");
      }
    } catch (error) {
      console.error("Error in location retrieval:", error);
    }
  };
  
  const handleOpenMondial = (product: any) => {
    try {
      handleGetMonDialPoints([
        {
          ...state.pickupLocation?.result[0],
          weight: product.net_weight ?? "100",
        },
      ]);
      setState({
        selectedItem: product,
        mondialDialog: true,
      });
    } catch (error) {}
  };
  
  const handleSelectLocation = (data: any) => {
    try {
      handleGetMonDialPoints(data?.result);
    } catch (error) {}
  };
  
  const handleSearchRelaisPoints = () => {
    try {
      handleGetMonDialPoints(state?.pickupLocation?.result);
    } catch (error) {}
  };
  
  const handleSelectedPoint = (item: any) => {
    try {
      let idx = CartData.findIndex(
        (itemx: any) => itemx.id === state.selectedItem?.id
      );

      if (idx > -1) {
        let cart_data = CartData;
        cart_data[idx].mondial_point = item;
        console.log(cart_data, "cart_data");
        setState({
          CartData: cart_data,
        });
      }

      setInputValue("");
      setState({
        s_RelayPoint: item,
        mondialDialog: false,
      });
    } catch (error) {}
  };
  
  useEffect(() => {
    if (selector.cart.length > 0) {
      let cart = [] as any;
      selector.cart.map((item: any) =>
        cart?.push({ ...item, delivery_type: "" })
      );

      setState({ CartData: cart });
    }
  }, [selector.cart]);

  const handleUpdateCart = async (item: any) => {
    try {
      await apiServices.deleteFromApi(`/c2c-carts/${item?.id}`, "");
      dispatch(getCart());
    } catch (error) {
      console.log(error);
    }
  };
  
  const updateQuantity = async (type: string, item?: any) => {
    try {
      setLoading(true);
      if (type === "Add") {
        const response = await apiServices.postFromApi(
          "/c2c-carts",
          {
            shop_id: Number(item?.shop_details?.[0]?.id),
            product_variation_id: Number(item?.id),
            quantity: +1,
          },
          ""
        );
        setLoading(false);
        dispatch(getCart());
        return;
      }
      if (type === "Remove" && item?.quantity > 1) {
        const response = await apiServices.postFromApi(
          "/c2c-carts",
          {
            shop_id: Number(item?.shop_details?.[0]?.id),
            product_variation_id: Number(item?.id),
            quantity: -1,
          },
          ""
        );
        setLoading(false);
        dispatch(getCart());

        return;
      }
    } catch (error) {
      console.error("Error updating quantity:", error);
    } finally {
      setLoading(false);
    }
  };
  
  const handleServiceChange = (itemId: number, service: string) => {
    let idx = CartData.findIndex((item: any) => item.id === itemId);

    if (idx > -1) {
      let cart_data = CartData;
      cart_data[idx].delivery_type = service;

      setState({
        CartData: cart_data,
      });
    }

    setSelectedServices((prevState) => ({
      ...prevState,
      [itemId]: service,
    }));
  };
  
  const handleAddAddress = async () => {
    try {
      setState({ loading: true });
      let obj = {
        location: {
          type: "Point",
          coordinates: [state?.lat, state?.lng],
        },
        address_title: "Home",
        address: state?.completeAddress ?? "",
        short_address: state?.completeAddress,
        digital_code: state?.postal,
        additional_info: "-",
        city: state?.city,
        country: state?.country,
        first_name: state?.firstName,
        last_name: state?.lastName,
        is_primary: state?.isPrimary,
      };

      let resp = await apiServices.postFromApi("c2c-addresses", obj, "");
      if (resp?.status === 201) {
        setState({ loading: false, checkoutStep: state?.checkoutStep - 1 });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  
  const handlePlaceSelected = async (
    place: google.maps.places.PlaceResult | null
  ) => {
    if (place && place.geometry) {
      const lat = place.geometry.location?.lat() || 0;
      const lng = place.geometry.location?.lng() || 0;
      const address = place.formatted_address || "";
      setInputValue(address);

      const pickupLocation = await apiServices.getFromApi(
        `helper/get-address?lat=${lat}&lng=${lng}`,
        ""
      );
      setState({ lat, lng });

      setState({
        pickupLocation,
        city: pickupLocation?.result?.[0]?.city ?? "",
        completeAddress: pickupLocation?.result?.[0]?.formattedAddress ?? "",
        postal: pickupLocation?.result?.[0]?.zipcode ?? "",
        country: pickupLocation?.result?.[0]?.country ?? "",
      });
    } else {
    }
  };
  
  const onInputChange = (e: any) => {
    try {
      const { value, name } = e.target;

      if (value[0] === " ") {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {}
  };

  useEffect(() => {
    const groupedByShop = _.groupBy(state?.CartData, (item) =>
      item.shop_details?.[0]?.id ? item.shop_details?.[0]?.id : "unknown"
    );

    const groupedArray = Object.entries(groupedByShop).map(
      ([shopId, items]) => ({
        shopId: shopId === "unknown" ? null : shopId,
        items,
      })
    );

    setState({ groupedData: groupedArray });
    handlegetRelatedProd()
  }, [state?.CartData]);

const handlegetRelatedProd = async () => {
  try {
    let product_ids = [] as any;

  CartData.map((item: any) => product_ids.push(Number(item.id)))

    let resp = await apiServices.postFromApiWithoutLang("c2c-carts/related-products",[...product_ids],"")
    console.log("resp",resp);
    
  } catch (error) {
    
  }
}

  const { CartData, checkoutStep } = state;
  return (
    <div>
      <Helmet>
        <title>Product Details | Frank</title>

        <meta name="description" content="" />
      </Helmet>
      <Loader loading={state?.loading} />

      <div style={{ background: "#f9c9b3" }}>
        <Grid container>
          <Grid xs={12} item>
            <HeaderForMarketPlace />
          </Grid>
        </Grid>
        <div style={{ padding: "30px 80px 50px 80px" }}>
          {checkoutStep === 1 ? (
            <Grid container spacing={2}>
              <Grid xs={12} md={8} item style={{ rowGap: 10 }}>
                <p style={{ fontFamily: "Raleway-Bold", fontSize: "32px" }}>
                  Votre panier ( {selector?.cart?.length} produits )
                </p>
                <p
                  style={{
                    margin: 0,
                    fontFamily: "Raleway-Semi-Bold",
                    fontSize: 18,
                  }}
                >
                  Vendus et livrés par{" "}
                  <span style={{ marginLeft: 10 }}>
                    {" "}
                    <FrankIcon
                      width={50}
                      fill={defaultLabelColors.main_orange}
                    />{" "}
                  </span>
                </p>
                {selector.cart?.map((item: any) => {
                  return (
                    <div
                      style={{
                        background: "#fff",
                        padding: "10px",
                        display: "flex",
                        marginTop: "10px",
                        borderRadius: 4,
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          height: "160px",
                          maxWidth: "140px",
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item ? (
                          <img
                            src={item?.images?.[0]}
                            height={100}
                            style={{
                              objectFit: "contain",
                              height: "100%",
                              width: "100%",
                            }}
                            alt="prod_img"
                          />
                        ) : null}
                      </div>

                      <div
                        style={{
                          flex: 1,
                          fontFamily: "Raleway-Variable",
                          fontSize: "16px",
                          gap: 10,
                          width: "900px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          flexDirection: "column",
                          paddingLeft: 10,
                        }}
                        className="elipsis-title"
                      >
                        {item
                          ? handleLocalize({
                              en: item?.product_title?.en,
                              fr: item?.product_title?.fr,
                            })
                          : ""}
                        <br />
                        {/* <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Raleway-Variable",
                        }}
                      >
                        {item?.base_price ?? 0.0} €
                      </span> */}
                        <p style={{ margin: 0 }}>
                          Vendu par{" "}
                          <span
                            style={{
                              color: defaultLabelColors.main_blue,
                              fontFamily: "Raleway-Semi-Bold",
                            }}
                          >
                            {" "}
                            {item?.shop_details?.[0]?.shop_name}
                          </span>
                        </p>
                        <p style={{ margin: 0 }}>
                          Disponibilité :{" "}
                          <span
                            style={{
                              color: defaultLabelColors.main_blue,
                              fontFamily: "Raleway-Semi-Bold",
                            }}
                          >
                            {" "}
                            {item?.items_in_stock}
                          </span>
                        </p>
                      </div>

                      <div
                        style={{
                          fontFamily: "Raleway-Semi-Bold",
                          fontSize: 18,
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                          gap: 10,
                          justifyContent: "center",
                        }}
                      >
                        <p style={{ margin: 0, textAlign: "right" }}>
                          {item?.price * item?.quantity}€
                        </p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: 5,
                          }}
                        >
                          <Button
                            style={{ padding: 0 }}
                            variant="outlined"
                            onClick={() => updateQuantity("Remove", item)}
                            disabled={loading}
                          >
                            -
                          </Button>
                          <Typography variant="h6">{item?.quantity}</Typography>
                          <Button
                            variant="outlined"
                            onClick={() => updateQuantity("Add", item)}
                            disabled={loading}
                          >
                            +
                          </Button>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p
                            style={{
                              textDecoration: "underline",
                              textAlign: "right",
                              cursor: "pointer",
                            }}
                            onClick={() => handleUpdateCart(item)}
                          >
                            Supprimer
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Grid>
              <Grid xs={12} md={4} item>
                <p style={{ fontFamily: "Raleway-Bold", fontSize: "32px" }}>
                  Récapitulatif
                </p>
                <div
                  style={{
                    background: "#fff",
                    borderRadius: 4,
                    padding: 23,
                    opacity: state?.showStripeForm ? "0.8" : "1",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Raleway-Semi-Bold",
                      fontSize: 18,
                      margin: 0,
                      padding: 2,
                      borderBottom: "1px solid #E0E0E0",
                    }}
                  >
                    Code promo
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 30,
                      width: "100%",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <InputField
                        placeHolder={"Saisissez le code "}
                        input_value={state?.searched_prod}
                        input_name={"searched_prod"}
                        input_style={{
                          background: "#f4f4f4",
                          width: "100%",
                          borderRadius: "12px",
                        }}
                        readonly={true}
                        isDisable={true}
                      />
                    </div>
                    <div>
                      <CustomButton
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#fff",
                          fontFamily: "Raleway-Variable",
                          background: defaultLabelColors.main_blue,
                          borderRadius: "12px",
                          padding: "15px",
                          border: "none",
                        }}
                        disable={true}
                        value={"Valider"}
                      />{" "}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    background: "#fff",
                    borderRadius: 4,
                    padding: "20px",
                    opacity: state?.showStripeForm ? "0.8" : "1",
                    marginTop: 10,
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Raleway-Semi-Bold",
                      fontSize: 18,
                      margin: 0,
                      padding: 2,
                    }}
                  >
                    Panier ({selector?.cart?.length})
                  </p>
                  <div
                    style={{
                      display: "flex",
                      color: "#4B4A61",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        fontFamily: "Raleway-Variable",
                        fontSize: "14px",
                      }}
                    >
                      Frais de service Frank
                    </div>
                    <div
                      style={{
                        fontFamily: "Raleway-Semi-Bold",
                        fontSize: "16px",
                      }}
                    >
                      {/* {numberFormatWithoutCurrency(
                      state?.orderData?.base_price ?? 0
                    )}{" "}
                    € */}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      color: "#4B4A61",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        fontFamily: "Raleway-Variable",
                        fontSize: "14px",
                      }}
                    >
                      Mode de livraison
                    </div>
                    <div
                      style={{
                        fontFamily: "Raleway-Semi-Bold",
                        fontSize: "16px",
                      }}
                    >
                      - €
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      color: "#4B4A61",
                      padding: "10px",
                      borderBottom: "1px solid #E0E0E0",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Raleway-Semi-Bold",
                        fontSize: "16px",
                      }}
                    >
                      {/* {numberFormatWithoutCurrency(state?.platform_fee ?? 0)} € */}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      color: "#4B4A61",
                      padding: "10px",
                      borderBottom: "1px solid #E0E0E0",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        fontFamily: "Raleway-Semi-Bold",
                        fontSize: "14px",
                      }}
                    >
                      Total (TVA incluse)
                    </div>
                    <div
                      style={{
                        fontFamily: "Raleway-Bold",
                        fontSize: "18px",
                        color: defaultLabelColors.main_orange,
                      }}
                    >
                      {/* {numberFormatWithoutCurrency(state?.final_Price ?? 0)} € */}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      color: "#4B4A61",
                      padding: "10px",
                      borderBottom: "1px solid #E0E0E0",
                      flexDirection: "column",
                    }}
                  >
                    <CustomButton
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#fff",
                        fontFamily: "Raleway-Variable",
                        background: state?.showStripeForm
                          ? "lightgrey"
                          : defaultLabelColors.main_blue,
                        borderRadius: "12px",
                        padding: "15px",
                        border: "none",
                        width: "100%",
                      }}
                      disable={state?.showStripeForm ? true : false}
                      value={"Choisir ma livraison"}
                      onClick={() => {
                        setState({
                          checkoutStep: checkoutStep + 1,
                        });
                      }}
                    />{" "}
                  </div>
                </div>
              </Grid>
            </Grid>
          ) : checkoutStep === 2 ? (
            <Grid container spacing={2}>
              <Grid xs={12} md={8} item>
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 10 }}
                >
                  <p
                    style={{
                      margin: 0,
                      fontFamily: "Raleway-Variable",
                      fontSize: "18px",
                    }}
                  >
                    Votre panier vendus et livrés par{" "}
                    <span
                      style={{ fontFamily: "Raleway-Semi-Bold", margin: 0 }}
                    >
                      <FrankIcon
                        width={50}
                        fill={defaultLabelColors.main_orange}
                      />{" "}
                      ( {selector.cart.length})
                    </span>
                  </p>
                  <p
                    style={{
                      fontFamily: "Raleway-Bold",
                      fontSize: "32px",
                      margin: 0,
                    }}
                  >
                    Mode de livraison
                  </p>
                </div>
                <div
                  style={{
                    background: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    padding: "100px 100px 20px 100px",
                    gap: 10,
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Raleway-Semi-Bold",
                      fontSize: 18,
                      textAlign: "left",
                      margin: 0,
                    }}
                  >
                    Adresse de livraison :
                  </p>

                  <CustomButton
                    value={"Ajouter une adresse de livraison"}
                    style={{
                      background: defaultLabelColors.main_blue,
                      width: "100%",
                      color: "#fff",
                      borderRadius: "12px",
                      padding: 10,
                      fontSize: 18,
                    }}
                    onClick={() => {
                      setState({ checkoutStep: state?.checkoutStep + 1 });
                    }}
                  />
                </div>
                {selector.cart?.map((item: any, idx) => {
                  return (
                    <div
                      style={{
                        borderTop: "1px solid #E0E0E0",
                        padding: "20px 50px",
                        background: "#fff",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          height: "110px",
                          maxWidth: "140px",
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item ? (
                          <img
                            src={item?.images?.[0]}
                            height={100}
                            style={{
                              objectFit: "contain",
                              height: "100%",
                              width: "100%",
                            }}
                            alt="prod_img"
                          />
                        ) : null}
                      </div>

                      <div
                        style={{
                          flex: 1,
                          fontFamily: "Raleway-Semi-Bold",
                          fontSize: "14px",
                          gap: 10,
                          width: "900px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          flexDirection: "column",
                          paddingLeft: 10,
                        }}
                        className="elipsis-title"
                      >
                        {item
                          ? handleLocalize({
                              en: item?.product_title?.en,
                              fr: item?.product_title?.fr,
                            })
                          : ""}
                        <br />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: 5,
                          }}
                        >
                          <Button
                            style={{ padding: 0 }}
                            variant="outlined"
                            onClick={() => updateQuantity("Remove", item)}
                            disabled={loading}
                          >
                            -
                          </Button>
                          <Typography variant="h6">{item?.quantity}</Typography>
                          <Button
                            variant="outlined"
                            onClick={() => updateQuantity("Add", item)}
                            disabled={loading}
                          >
                            +
                          </Button>
                        </div>
                        <p style={{ margin: 0, textAlign: "right" }}>
                          {item?.price * item?.quantity}€
                        </p>
                      </div>

                      <div
                        style={{
                          fontFamily: "Raleway-Semi-Bold",
                          fontSize: 18,
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                          gap: 10,
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                          }}
                        >
                          <select
                            value={selectedServices[item.id] || ""}
                            onChange={(e) =>
                              handleServiceChange(item.id, e.target.value)
                            }
                            style={{
                              padding: "10px",
                              borderRadius: "24px",
                              border: "1px solid #ccc",
                              background: "#D9D9D9",
                              fontFamily: "Raleway-Variable",
                            }}
                          >
                            <option value="" disabled>
                              Livraison à domicile
                            </option>
                            {item.service_methods?.map((method: any) => (
                              <option
                                style={{
                                  background: "#fff",
                                  fontFamily: "Raleway-Variable",
                                }}
                                key={method?.s_key}
                                value={method?.s_key}
                              >
                                {method?.service_method_name?.en}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Grid>
              <Grid xs={12} md={4} item>
                <p style={{ fontFamily: "Raleway-Bold", fontSize: "32px" }}>
                  Récapitulatif
                </p>
                <div
                  style={{
                    background: "#fff",
                    borderRadius: 4,
                    padding: 23,
                    opacity: state?.showStripeForm ? "0.8" : "1",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Raleway-Semi-Bold",
                      fontSize: 18,
                      margin: 0,
                      padding: 2,
                      borderBottom: "1px solid #E0E0E0",
                    }}
                  >
                    Code promo
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 30,
                      width: "100%",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <InputField
                        placeHolder={"Saisissez le code "}
                        input_value={state?.searched_prod}
                        input_name={"searched_prod"}
                        input_style={{
                          background: "#f4f4f4",
                          width: "100%",
                          borderRadius: "12px",
                        }}
                        readonly={true}
                        isDisable={true}
                      />
                    </div>
                    <div>
                      <CustomButton
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#fff",
                          fontFamily: "Raleway-Variable",
                          background: defaultLabelColors.main_blue,
                          borderRadius: "12px",
                          padding: "15px",
                          border: "none",
                        }}
                        disable={true}
                        value={"Valider"}
                      />{" "}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    background: "#fff",
                    borderRadius: 4,
                    padding: "20px",
                    opacity: state?.showStripeForm ? "0.8" : "1",
                    marginTop: 10,
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Raleway-Semi-Bold",
                      fontSize: 18,
                      margin: 0,
                      padding: 2,
                    }}
                  >
                    Panier ({selector?.cart?.length})
                  </p>
                  <div
                    style={{
                      display: "flex",
                      color: "#4B4A61",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        fontFamily: "Raleway-Variable",
                        fontSize: "14px",
                      }}
                    >
                      Frais de service Frank
                    </div>
                    <div
                      style={{
                        fontFamily: "Raleway-Semi-Bold",
                        fontSize: "16px",
                      }}
                    >
                      {/* {numberFormatWithoutCurrency(
                      state?.orderData?.base_price ?? 0
                    )}{" "}
                    € */}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      color: "#4B4A61",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        fontFamily: "Raleway-Variable",
                        fontSize: "14px",
                      }}
                    >
                      Mode de livraison
                    </div>
                    <div
                      style={{
                        fontFamily: "Raleway-Semi-Bold",
                        fontSize: "16px",
                      }}
                    >
                      - €
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      color: "#4B4A61",
                      padding: "10px",
                      borderBottom: "1px solid #E0E0E0",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Raleway-Semi-Bold",
                        fontSize: "16px",
                      }}
                    >
                      {/* {numberFormatWithoutCurrency(state?.platform_fee ?? 0)} € */}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      color: "#4B4A61",
                      padding: "10px",
                      borderBottom: "1px solid #E0E0E0",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        fontFamily: "Raleway-Semi-Bold",
                        fontSize: "14px",
                      }}
                    >
                      Total (TVA incluse)
                    </div>
                    <div
                      style={{
                        fontFamily: "Raleway-Bold",
                        fontSize: "18px",
                        color: defaultLabelColors.main_orange,
                      }}
                    >
                      {/* {numberFormatWithoutCurrency(state?.final_Price ?? 0)} € */}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      color: "#4B4A61",
                      padding: "10px",
                      borderBottom: "1px solid #E0E0E0",
                      flexDirection: "column",
                    }}
                  >
                    <CustomButton
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#fff",
                        fontFamily: "Raleway-Variable",
                        background: state?.showStripeForm
                          ? "lightgrey"
                          : "lightgrey",
                        borderRadius: "12px",
                        padding: "15px",
                        border: "none",
                        width: "100%",
                      }}
                      disable={state?.showStripeForm ? true : false}
                      value={"Passer à l’étape suivante"}
                      onClick={() => {
                        setState({
                          checkoutStep: checkoutStep + 1,
                        });
                      }}
                    />
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "16",
                        margin: 0,
                      }}
                    >
                      En validant ma commande je déclare avoir pris connaissance
                      et sans réserve les conditions génerale de vente de Frank
                    </p>
                  </div>
                  <div>
                    <p
                      style={{
                        fontFamily: "Raleway-Semi-Bold",
                        fontSize: "16px",
                      }}
                    >
                      Livraison à domicile ({selector.cart.length}):
                    </p>
                    <CustomButton
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#fff",
                        fontFamily: "Raleway-Variable",
                        background: state?.showStripeForm
                          ? "lightgrey"
                          : defaultLabelColors.main_blue,
                        borderRadius: "12px",
                        padding: "15px",
                        border: "none",
                        width: "100%",
                      }}
                      disable={state?.showStripeForm ? true : false}
                      value={"Ajouter une adresse"}
                      onClick={() => {
                        setState({
                          checkoutStep: checkoutStep + 1,
                        });
                      }}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          ) : checkoutStep === 3 ? (
            <Grid container spacing={2}>
              <Grid xs={12}>
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <ArrowBackIosNew
                    onClick={() => {
                      setState({ checkoutStep: checkoutStep - 1 });
                    }}
                    style={{ cursor: "pointer" }}
                  />{" "}
                  <p
                    style={{
                      fontFamily: "Raleway-Bold",
                      fontSize: "32px",
                      textAlign: "left",
                    }}
                  >
                    {" "}
                    Mode de livraison
                  </p>
                </div>
              </Grid>
              <Grid
                xs={12}
                item
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    minWidth: "400px",
                    width: "600px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Raleway-Semi-Bold",
                      fontSize: "22px",
                      textAlign: "left",
                    }}
                  >
                    Ajouter une adresse de livraison
                  </p>
                  <Grid container spacing={2}>
                    <Grid xs={12} md={6} item>
                      <InputField
                        placeHolder={"Nom*"}
                        input_value={state?.firstName}
                        input_name={"firstName"}
                        input_style={{
                          background: "#fff",
                          width: "100%",
                          borderRadius: "12px",
                          border: "none",
                        }}
                        onchange={onInputChange}
                      />
                    </Grid>
                    <Grid xs={12} md={6} item>
                      <InputField
                        placeHolder={"Prénom*"}
                        input_value={state?.lastName}
                        input_name={"lastName"}
                        input_style={{
                          background: "#fff",
                          width: "100%",
                          borderRadius: "12px",
                          border: "none",
                        }}
                        onchange={onInputChange}
                      />
                    </Grid>
                    <Grid xs={12} item>
                      <GoogleAutocompleteField
                        apiKey={apiKey}
                        placeholder="Adresse de livraison*"
                        inputValue={inputValue}
                        onInputChange={setInputValue}
                        onPlaceSelected={handlePlaceSelected}
                        key={"selectedLocation"}
                        styles={{
                          background: "#fff",
                          borderRadius: "12px",
                          fontSize: "16px",
                          fontFamily: "Raleway-Italic",
                          padding: "18px",
                          color: "#000",
                          border: "1px solid #fff",
                          width: "94%",
                          marginLeft: 6,
                        }}
                      />
                    </Grid>
                    <Grid xs={12} md={12} item>
                      <InputField
                        placeHolder={
                          "Complément d’adresse ( Étage, Bâtiment, Digicode...)*"
                        }
                        input_value={state?.completeAddress}
                        input_name={"completeAddress"}
                        input_style={{
                          background: "#fff",
                          width: "100%",
                          borderRadius: "12px",
                          border: "none",
                        }}
                        onchange={onInputChange}
                      />
                    </Grid>
                    <Grid xs={12} md={6} item>
                      <InputField
                        placeHolder={"Ville*"}
                        input_value={state?.city}
                        input_name={"city"}
                        input_style={{
                          background: "#fff",
                          width: "100%",
                          borderRadius: "12px",
                          border: "none",
                        }}
                        onchange={onInputChange}
                      />
                    </Grid>
                    <Grid xs={12} md={6} item>
                      <InputField
                        placeHolder={"Code postal*"}
                        input_value={state?.postal}
                        input_name={"postal"}
                        input_style={{
                          background: "#fff",
                          width: "100%",
                          borderRadius: "12px",
                          border: "none",
                        }}
                        onchange={onInputChange}
                      />
                    </Grid>
                    <Grid xs={12} md={12} item>
                      <div
                        style={{
                          background: "#fff",
                          borderRadius: "12px",
                          padding: 8,
                          marginLeft: "10px",
                        }}
                      >
                        <FormControlLabel
                          value={state?.isPrimary}
                          control={
                            <Checkbox
                              checked={state?.isPrimary}
                              onChange={(event) =>
                                setState({ isPrimary: event.target.checked })
                              }
                            />
                          }
                          label="Utiliser cette adresse comme adresse par défaut"
                        />
                      </div>
                    </Grid>
                    <Grid
                      xs={12}
                      item
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <CustomButton
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#fff",
                          fontFamily: "Raleway-Variable",
                          background: defaultLabelColors.main_blue,
                          borderRadius: "12px",
                          padding: "15px",
                          border: "none",
                        }}
                        onClick={() => {
                          setState({ checkoutStep: checkoutStep + 1 });
                        }}
                        value={"Ajouter cette adresse"}
                      />{" "}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          ) : checkoutStep === 4 ? (
            <Grid container spacing={2}>
              <Grid xs={12} md={8} item>
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 10 }}
                >
                  <p
                    style={{
                      margin: 0,
                      fontFamily: "Raleway-Variable",
                      fontSize: "18px",
                    }}
                  >
                    Votre panier vendus et livrés par{" "}
                    <span
                      style={{ fontFamily: "Raleway-Semi-Bold", margin: 0 }}
                    >
                      <FrankIcon
                        width={50}
                        fill={defaultLabelColors.main_orange}
                      />{" "}
                      ( {selector.cart.length})
                    </span>
                  </p>
                  <p
                    style={{
                      fontFamily: "Raleway-Bold",
                      fontSize: "32px",
                      margin: 0,
                    }}
                  >
                    Mode de livraison
                  </p>
                </div>
                {state?.groupedData?.map((i: any, index: any) => {
                  return (
                    <div
                      style={{
                        background: "#fff",
                        borderRadius: "4px",
                        width: "100%",
                        marginTop: "10px",
                        padding: "10px 0px",
                      }}
                    >
                      <div
                        style={{
                          padding: "0px 10px",
                          borderBottom: "1px solid #E0E0E0",
                        }}
                      >
                        <p
                          style={{
                            fontFamily: "Raleway-Semi-Bold",
                            color: defaultLabelColors.main_blue,
                            fontSize: 22,
                          }}
                        >
                          {i?.items?.[0]?.shop_details?.[0]?.shop_name}
                        </p>
                      </div>

                      {i?.items?.map((j: any, idx: any) => {
                        return (
                          <div
                            style={{
                              borderTop: "1px solid #E0E0E0",
                              padding: "20px 50px",
                              background: "#fff",
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                flex: 1,
                                height: "110px",
                                maxWidth: "140px",
                                display: "flex",
                                alignItems: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              {j ? (
                                <img
                                  src={j?.images?.[0]}
                                  height={100}
                                  style={{
                                    objectFit: "contain",
                                    height: "100%",
                                    width: "100%",
                                  }}
                                  alt="prod_img"
                                />
                              ) : null}
                            </div>

                            <div
                              style={{
                                flex: 1,
                                fontFamily: "Raleway-Semi-Bold",
                                fontSize: "14px",
                                gap: 10,
                                width: "900px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "flex-start",
                                flexDirection: "column",
                                paddingLeft: 10,
                              }}
                              className="elipsis-title"
                            >
                              {j
                                ? handleLocalize({
                                    en: j?.product_title?.en,
                                    fr: j?.product_title?.fr,
                                  })
                                : ""}
                              <br />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  gap: 5,
                                }}
                              >
                                <Button
                                  style={{ padding: 0 }}
                                  variant="outlined"
                                  onClick={() => updateQuantity("Remove", j)}
                                  disabled={loading}
                                >
                                  -
                                </Button>
                                <Typography variant="h6">
                                  {j?.quantity}
                                </Typography>
                                <Button
                                  variant="outlined"
                                  onClick={() => updateQuantity("Add", j)}
                                  disabled={loading}
                                >
                                  +
                                </Button>
                              </div>
                              <p style={{ margin: 0, textAlign: "right" }}>
                                {j?.price ?? 0}€
                              </p>
                            </div>

                            <div
                              style={{
                                fontFamily: "Raleway-Semi-Bold",
                                fontSize: 18,
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                gap: 10,
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "flex-end",
                                  justifyContent: "flex-end",
                                  flexDirection: "column",
                                  gap: 10,
                                }}
                              >
                                <select
                                  value={selectedServices[j.id] || ""}
                                  onChange={(e) =>
                                    handleServiceChange(j.id, e.target.value)
                                  }
                                  style={{
                                    padding: "10px",
                                    borderRadius: "24px",
                                    border: "1px solid #ccc",
                                    background: "#D9D9D9",
                                    fontFamily: "Raleway-Variable",
                                  }}
                                >
                                  <option value="" disabled>
                                    Livraison à domicile
                                  </option>
                                  {j.service_methods?.map((method: any) => (
                                    <option
                                      style={{
                                        background: "#fff",
                                        fontFamily: "Raleway-Variable",
                                      }}
                                      key={method?.s_key}
                                      value={method?.s_key}
                                    >
                                      {method?.service_method_name?.en}
                                    </option>
                                  ))}
                                </select>
                                <div>
                                  {j?.delivery_type === "mondial" ? (
                                    <CustomButton
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        color: "#fff",
                                        fontFamily: "Raleway-Variable",
                                        background:
                                          defaultLabelColors.main_blue,
                                        borderRadius: "12px",
                                        padding: "15px",
                                        border: "none",
                                      }}
                                      value={"Ajouter un Point Relais"}
                                      onClick={() => {
                                        handleOpenMondial(j);
                                      }}
                                    />
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </Grid>
              <Grid xs={12} md={4} item>
                <p style={{ fontFamily: "Raleway-Bold", fontSize: "32px" }}>
                  Récapitulatif
                </p>
                <div
                  style={{
                    background: "#fff",
                    borderRadius: 4,
                    padding: 23,
                    opacity: state?.showStripeForm ? "0.8" : "1",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Raleway-Semi-Bold",
                      fontSize: 18,
                      margin: 0,
                      padding: 2,
                      borderBottom: "1px solid #E0E0E0",
                    }}
                  >
                    Code promo
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 30,
                      width: "100%",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <InputField
                        placeHolder={"Saisissez le code "}
                        input_value={state?.searched_prod}
                        input_name={"searched_prod"}
                        input_style={{
                          background: "#f4f4f4",
                          width: "100%",
                          borderRadius: "12px",
                        }}
                        readonly={true}
                        isDisable={true}
                      />
                    </div>
                    <div>
                      <CustomButton
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#fff",
                          fontFamily: "Raleway-Variable",
                          background: defaultLabelColors.main_blue,
                          borderRadius: "12px",
                          padding: "15px",
                          border: "none",
                        }}
                        disable={true}
                        value={"Valider"}
                      />{" "}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    background: "#fff",
                    borderRadius: 4,
                    padding: "20px",
                    opacity: state?.showStripeForm ? "0.8" : "1",
                    marginTop: 10,
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Raleway-Semi-Bold",
                      fontSize: 18,
                      margin: 0,
                      padding: 2,
                    }}
                  >
                    Panier ({selector?.cart?.length})
                  </p>
                  <div
                    style={{
                      display: "flex",
                      color: "#4B4A61",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        fontFamily: "Raleway-Variable",
                        fontSize: "14px",
                      }}
                    >
                      Frais de service Frank
                    </div>
                    <div
                      style={{
                        fontFamily: "Raleway-Semi-Bold",
                        fontSize: "16px",
                      }}
                    >
                      {/* {numberFormatWithoutCurrency(
                      state?.orderData?.base_price ?? 0
                    )}{" "}
                    € */}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      color: "#4B4A61",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        fontFamily: "Raleway-Variable",
                        fontSize: "14px",
                      }}
                    >
                      Mode de livraison
                    </div>
                    <div
                      style={{
                        fontFamily: "Raleway-Semi-Bold",
                        fontSize: "16px",
                      }}
                    >
                      - €
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      color: "#4B4A61",
                      padding: "10px",
                      borderBottom: "1px solid #E0E0E0",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Raleway-Semi-Bold",
                        fontSize: "16px",
                      }}
                    >
                      {/* {numberFormatWithoutCurrency(state?.platform_fee ?? 0)} € */}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      color: "#4B4A61",
                      padding: "10px",
                      borderBottom: "1px solid #E0E0E0",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        fontFamily: "Raleway-Semi-Bold",
                        fontSize: "14px",
                      }}
                    >
                      Total (TVA incluse)
                    </div>
                    <div
                      style={{
                        fontFamily: "Raleway-Bold",
                        fontSize: "18px",
                        color: defaultLabelColors.main_orange,
                      }}
                    >
                      {/* {numberFormatWithoutCurrency(state?.final_Price ?? 0)} € */}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      color: "#4B4A61",
                      padding: "10px",
                      borderBottom: "1px solid #E0E0E0",
                      flexDirection: "column",
                    }}
                  >
                    <CustomButton
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#fff",
                        fontFamily: "Raleway-Variable",
                        background: state?.showStripeForm
                          ? "lightgrey"
                          : "lightgrey",
                        borderRadius: "12px",
                        padding: "15px",
                        border: "none",
                        width: "100%",
                      }}
                      disable={state?.showStripeForm ? true : false}
                      value={"Passer à l’étape suivante"}
                      onClick={() => {
                        setState({
                          checkoutStep: checkoutStep + 1,
                        });
                      }}
                    />
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "16",
                        margin: 0,
                      }}
                    >
                      En validant ma commande je déclare avoir pris connaissance
                      et sans réserve les conditions génerale de vente de Frank
                    </p>
                  </div>
                  <div>
                    <p
                      style={{
                        fontFamily: "Raleway-Semi-Bold",
                        fontSize: "16px",
                      }}
                    >
                      Livraison à domicile ({selector.cart.length}):
                    </p>
                    <CustomButton
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#fff",
                        fontFamily: "Raleway-Variable",
                        background: state?.showStripeForm
                          ? "lightgrey"
                          : defaultLabelColors.main_blue,
                        borderRadius: "12px",
                        padding: "15px",
                        border: "none",
                        width: "100%",
                      }}
                      disable={state?.showStripeForm ? true : false}
                      value={"Ajouter une adresse"}
                      onClick={() => {
                        setState({
                          checkoutStep: checkoutStep + 1,
                        });
                      }}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          ) : null}
        </div>
      </div>
      <DraggableDialog
        maxWidth={"lg"}
        open={state?.mondialDialog}
        handleClose={() => {
          setState({ mondialDialog: false });
        }}
        dialogBody={
          <DialogContent
            style={{
              display: "flex",
            }}
            dividers
          >
            <Grid container spacing={spacing.spacing2}>
              <Grid xs={12} item>
                <p
                  style={{
                    margin: 0,
                    fontFamily: "Raleway-Semi-Bold",
                    fontSize: "18px",
                  }}
                >
                  Trouver un point relais
                </p>
                <div
                  style={{
                    borderBottom: "1px solid #000",
                    padding: 10,
                    display: "flex",
                    alignItems: "center",
                    gap: 40,
                  }}
                >
                  <div
                    style={{
                      width: "500px",
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <GoogleAutocompleteField
                      apiKey={apiKey}
                      placeholder="Enter a location"
                      inputValue={inputValue}
                      onInputChange={setInputValueForMondial}
                      onPlaceSelected={handlePlaceSelected}
                      key={"selectedLocation"}
                      styles={{
                        background: "#fff",
                        width: "500px",
                        borderRadius: "16px",
                        fontSize: "16px",
                        margin: "10px 0px",
                        fontFamily: "Raleway-Italic",
                        padding: "20px",
                        color: "#000",
                        border: "1px solid #E0E0E0",
                      }}
                    />
                  </div>
                  <div>
                    <CustomButton
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#fff",
                        fontFamily: "Raleway-Variable",
                        background: inputValue ? "#ee6931" : "lightgrey",
                        borderRadius: "12px",
                        border: "none",
                        width: 150,
                        height: "60px",
                        padding: 10,
                        marginLeft: 10,
                      }}
                      disable={inputValue ? false : true}
                      value={"Valider"}
                      onClick={handleSearchRelaisPoints}
                    />
                  </div>
                </div>
              </Grid>

              <Grid
                xs={12}
                md={5}
                item
                style={{ maxHeight: "500px", overflowY: "scroll" }}
              >
                {state?.mondialPoints?.length < 1 ? (
                  <p style={{ padding: "20px", opacity: "0.7" }}>
                    <NoPointsFound />
                  </p>
                ) : (
                  state?.mondialPoints?.map((item: any, idx: any) => {
                    return (
                      <>
                        <div
                          style={{
                            width: "100%",
                            marginTop: 10,
                            borderBottom: "1px solid #000",
                            paddingBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              margin: 0,
                            }}
                          >
                            <div style={{ flex: 1, display: "flex" }}>
                              <p
                                style={{
                                  fontFamily: "Raleway-Semi-Bold",
                                  fontSize: 16,
                                  margin: 0,
                                }}
                              >
                                <span>{idx + 1}.</span> {item?.point_name ?? ""}{" "}
                              </p>
                            </div>
                            <div
                              style={{
                                fontFamily: "Raleway-Bold",
                                fontSize: 16,
                                color: defaultLabelColors.main_orange,
                              }}
                            >
                              {Number(item?.distance) < 1000
                                ? `${item?.distance}m`
                                : `${Number(item?.distance) / 1000} km`}
                            </div>
                          </div>
                          <div>
                            <p
                              style={{
                                fontFamily: "Raleway-Variable",
                                color: "#4B4A61",
                                margin: 0,
                                fontSize: 14,
                                textTransform: "lowercase",
                              }}
                            >
                              {item?.address ?? ""}
                            </p>
                          </div>

                          <div
                            style={{
                              width: "100%",
                            }}
                          >
                            {item?.formattedHours?.map(
                              (hour: any, index: number) => (
                                <div
                                  key={`FORMATED_HOURS_${index + 1}`}
                                  style={{
                                    borderRadius: 7,
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    display: "flex",
                                    //   backgroundColor:"red"
                                  }}
                                >
                                  <div style={{ flex: 1 }}>
                                    <p
                                      style={{
                                        margin: 0,
                                        color: color_theme.black,
                                        fontFamily:
                                          week_day_translation[index + 1]?.en ==
                                          moment().format("dddd")
                                            ? "Raleway-Bold"
                                            : "Raleway-Variable",
                                      }}
                                    >
                                      {handleLocalize(
                                        week_day_translation[index + 1]
                                      )}{" "}
                                    </p>
                                  </div>
                                  <div style={{ flex: 1 }}>
                                    {hour?.hours?.map(
                                      (hour_: any, h_index: number) => (
                                        <div
                                          key={`FORMATED_HOURS_HOUR_${
                                            h_index + 1
                                          }`}
                                          style={{
                                            display:
                                              h_index > 0 && hour_?.is_closed
                                                ? "none"
                                                : "flex",
                                          }}
                                        >
                                          {hour?.hours[0]?.is_closed ? (
                                            <p
                                              style={{
                                                margin: 0,

                                                color: color_theme.red,
                                                fontFamily:
                                                  week_day_translation[
                                                    index + 1
                                                  ]?.en ===
                                                  moment().format("dddd")
                                                    ? "Raleway-Bold"
                                                    : "Raleway-Variable",
                                              }}
                                            >
                                              Closed
                                            </p>
                                          ) : (
                                            <div
                                              style={{
                                                flexDirection: "row",
                                                display: "flex",
                                              }}
                                            >
                                              <p
                                                style={{
                                                  color: color_theme.black,
                                                  margin: 0,

                                                  fontFamily:
                                                    week_day_translation[
                                                      index + 1
                                                    ]?.en ==
                                                    moment().format("dddd")
                                                      ? "Raleway-Bold"
                                                      : "Raleway-Variable",
                                                }}
                                              >
                                                {`${
                                                  hour_?.open.slice(0, 2) +
                                                  ":" +
                                                  hour_?.open.slice(2)
                                                }`}
                                                {" - "}
                                              </p>
                                              <p
                                                style={{
                                                  margin: 0,

                                                  color: color_theme.black,
                                                  fontFamily:
                                                    week_day_translation[
                                                      index + 1
                                                    ]?.en ==
                                                    moment().format("dddd")
                                                      ? "Raleway-Bold"
                                                      : "Raleway-Variable",
                                                }}
                                              >
                                                {`${
                                                  hour_?.close.slice(0, 2) +
                                                  ":" +
                                                  hour_?.close.slice(2)
                                                }`}{" "}
                                              </p>
                                            </div>
                                          )}
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              )
                            )}
                          </div>

                          <div style={{ marginTop: 5 }}>
                            <CustomButton
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                                fontFamily: "Raleway-Variable",
                                background: "#ee6931",
                                borderRadius: "12px",
                                border: "none",
                                width: "100%",
                                height: "50px",
                                padding: 5,
                              }}
                              value={"Choisir ce point relais"}
                              onClick={() => {
                                handleSelectedPoint(item);
                              }}
                            />
                          </div>
                        </div>
                      </>
                    );
                  })
                )}
              </Grid>

              <Grid xs={12} md={7} item>
                <div style={{ height: "500px" }}>
                  <GoogleMaps
                    {...state}
                    {...props}
                    handleGetLocation={(data) => {
                      handleSelectLocation(data);
                    }}
                    relais_points={state?.mondialPoints ?? []}
                  />
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        }
      />
    </div>
  );
}
export default OrderCheckout;
