import {
  AddBoxOutlined,
  ArrowBackIos,
  ArrowBackIosNew,
  ArrowForwardIos,
  Close,
  KeyboardArrowDown,
  Search,
} from "@mui/icons-material";
import noProdFound from "../../assets/images/FrankIcons/New folder/noProdFound.png";

import {
  Badge,
  Button,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Popover,
  Select,
  Typography,
} from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/system";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ProductCard } from ".";
import { RootState } from "../../Store";
import { getCart } from "../../Store/MainAppReducer";
import CustomFooter from "../../UiComponent/CustomFooter";
import Loader from "../../UiComponent/Loader";
import InputField from "../../UiComponent/TextFeild";
import Toast from "../../UiComponent/Toast/Toast";
import {
  BoxIconFigma,
  CartIconMarketPlace,
  FrankIcon,
  MailIconFigma,
  MailOrangeIcon,
  UserblackIcon,
  UserIconFigma,
  UserOrangeIcon,
} from "../../assets/images/FrankIcons";
import drawerBoxIcon from "../../assets/mobileImages/drawer-box-icon.png";
import apiServices from "../../services/RequestHandler";
import { getDataFromLocalStorage } from "../../utils/LocalStore.util";
import { defaultLabelColors } from "../../utils/constants";
import {
  buildHierarchy,
  getCategoryPath,
  handleLocalize,
  numberFormatWithoutCurrency,
} from "../../utils/helper";
import { color_theme } from "../SenderInbox.tsx/AccepterChat";
import { FrankDivider } from "./Checkout";

function AllProductByCateogry(props: any) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));
  let param = useParams();

  const dispatch = useDispatch<any>();
  const selector = useSelector((state: RootState) => state.mainApp);
  const navigate = useNavigate();

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      category_id: param?.id,
      lang: "fr",
      global_search: "",
      Localisation: "",
      filtered_options: [],
    }
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [anchorEM, setAnchorEM] = useState<HTMLButtonElement | null>(null);

  const handleClickforCart = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEM(event.currentTarget);
  };

  const handleCloseforCart = () => {
    setAnchorEM(null);
  };

  const handleAddToFavorite = async (item: any) => {
    try {
      let obj = {
        product_id: Number(item?.id),
      };

      let resp = await apiServices.postFromApi(
        "c2c-product-wishlists",
        obj,
        ""
      );
      if (resp?.status === 201) {
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleGetAllProductByCategory(param?.id);
  }, [param]);

  const openForCart = Boolean(anchorEM);
  const id = openForCart ? "cart-popover" : undefined;

  let userDetails = getDataFromLocalStorage("user");

  const toggleDrawer = (open: any) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  useEffect(() => {
    handleGetAllCategories();
  }, []);

  // Function to build the nested structure
  function buildCategoryTree(categories: any, parentId = null) {
    return categories
      .filter((category: any) => category.parent_id === parentId)
      .map((category: any) => ({
        label: category.category_name,
        value: category.category_name?.fr,
        cat_name: category.category_name,
        parent_cat: categories.find((a: any) => a.id == parentId),
        children: buildCategoryTree(categories, category.id),
      }));
  }

  const handleGetFilterOptions = async (categories: any) => {
    try {
      const attributes = await apiServices.getFromApi(
        "c2c-product-attributes/filters/get-filter",
        ""
      );

      let color_options = [];
      let arr = [];
      const d = attributes?.result?.attributes;
      const brands = attributes?.result?.brands;
      for (const atr of d) {
        if (atr.attribute_name?.en === "Color") {
          color_options = atr.at_values;
        } else {
          arr.push({
            ...atr,
            label: {
              en: atr.attribute_name?.en
                ?.replace("Tailles - ", "")
                .replace("Sizes - ", ""),
              fr: atr.attribute_name?.fr
                ?.replace("Tailles - ", "")
                .replace("Sizes - ", ""),
            },
            value: atr.attribute_name?.en,
            children: atr?.at_values?.map((i: any) => ({
              ...i,
              parent_cat: {
                ...atr,
                category_name: atr.attribute_name,
              },
            })),
          });
          console.log("ARHA");
        }
      }

      if (attributes.status === 200) {
        const categoryTree = buildCategoryTree(categories);
        let filtered_options = [
          {
            type: "google_place",
            name: "location",
          },
          {
            type: "categories",
            name: "Catégorie",
            options: categoryTree,
          },
          {
            type: "size",
            name: "Taille",
            options: arr,
          },
          {
            type: "brand",
            name: "Marque",
            options: brands,
          },
          {
            type: "condition",
            name: "État",
            options: [
              {
                label: { en: "Neuf", fr: "Neuf" },
                children: [],
                value: "Neuf",
              },
              {
                label: { en: "Très bon état", fr: "Très bon état" },
                children: [],
                value: "Très bon état",
              },
              {
                label: { en: "Bon état", fr: "Bon état" },
                children: [],
                value: "Bon état",
              },
              {
                label: { en: "Satisfaisant", fr: "Satisfaisant" },
                children: [],
                value: "Satisfaisant",
              },
            ],
            show_child: false,
          },
          {
            type: "color",
            name: "Couleur",
            options: color_options,
            show_child: false,
          },
          // {
          //   type: "price",
          //   name: "Prix",
          //   options: [],
          // },
          {
            type: "sort",
            name: "Trier par",
            options: [
              {
                label: { en: "Prix croissant", fr: "Prix croissant" },
                children: [],
                value: "Prix croissant",
              },
              {
                label: { en: "Prix décroissant", fr: "Prix décroissant" },
                children: [],
                value: "Prix décroissant",
              },
            ],
          },
        ];
        setState({
          filtered_options,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetAllProductByCategory = async (
    cat_id?: any,
    category?: any
  ) => {
    try {
      setState({ activity: true });

      let obj = {
        category_id: Number(cat_id),
      };

      let resp = await apiServices.postFromApi(
        "c2c-product-masters/get-by-category",
        obj,
        ""
      );
      if (resp?.status === 200) {
        setState({
          all_products: resp?.data,
        });
        setState({ activity: false });
        const groupedCategories = buildHierarchy(category);

        const category_path = getCategoryPath(cat_id, groupedCategories);

        setState({
          path: category_path
            ?.map((i: any) => `${handleLocalize(i)}`)
            .join(" > "),
        });
        setState({ activity: false });
      }

      setState({ activity: false });
    } catch (error) {}
  };

  const handleOpenProduct = (item: any) => {
    try {
      if (item?.is_particular === true) {
        navigate(`/p-product/${item?.id}`);
        return;
      }

      navigate(`/product/${item?.id}`);
    } catch (error) {}
  };

  const handleGetAllCategories = async () => {
    try {
      setState({ activity: true });

      let resp = await apiServices.getFromApi("c2c-categories", "");
      if (resp?.status === 201 || resp?.status === 200) {
        buildNestedStructure(resp?.data);
      }

      setState({
        all_categories: resp?.data,
      });

      handleGetAllProductByCategory(state?.category_id, resp?.data);
      setState({ activity: false });
      handleGetFilterOptions(resp?.data);
    } catch (error) {}
  };

  function buildNestedStructure(data: any) {
    const lookup: any = {};
    const rootNodes: any = [];

    data.forEach((item: any) => {
      lookup[item.id] = { ...item, children: [] };
    });
    data.forEach((item: any) => {
      if (item.parent_id) {
        lookup[item.parent_id]?.children.push(lookup[item.id]);
      } else {
        rootNodes.push(lookup[item.id]);
      }
    });

    setState({ parentCategories: rootNodes });
    return rootNodes;
  }

  

  const handleUpdateCart = async (item: any) => {
    try {
      await apiServices.deleteFromApi(`/c2c-carts/${item?.id}`, "");
      dispatch(getCart());
      Toast.showSuccess("Item removed from cart");
    } catch (error) {}
  };

 

  const onChangeItem = (value: any, item: any, index: number) => {
    try {
      let { filtered_options } = state;
      let selected_val = value?.target?.value;
      const data = filtered_options[index];
      let obj: any = {
        ...data,
      };
      if (item.type === "categories") {
        const filterd_child = item?.options?.find(
          (a: any) => a?.value == selected_val
        );

        if (filterd_child?.children?.length > 0) {
          obj.children_items = filterd_child?.children;
          obj.show_child = true;
          obj.is_open = true;
        } else {
          obj.selected_value = {
            fr: selected_val,
            en: selected_val,
          };
          obj.show_child = true;
          obj.is_open = false;
          handleSerachRecordsByFilter();
        }
      }

      if (item.type === "size") {
        const filterd_child = item?.options?.find(
          (a: any) => a?.value == selected_val
        );

        if (filterd_child?.children?.length > 0) {
          obj.children_items = filterd_child?.children ?? [];
          obj.show_child = true;
          obj.is_open = true;
        } else {
          obj.selected_value = {
            fr: selected_val,
            en: selected_val,
          };
          obj.show_child = true;
          obj.is_open = false;
          handleSerachRecordsByFilter();
        }
      }

      if (
        item.type === "condition" ||
        item.type === "color" ||
        item.type === "brand" ||
        item.type === "sort"
      ) {
        obj.selected_value = {
          fr: selected_val,
          en: selected_val,
        };
        obj.show_child = false;
        obj.is_open = false;

        handleSerachRecordsByFilter();
      }

      filtered_options[index] = obj;
      setState({
        filtered_options,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // adding debounce function to call my function after scroll ends 1000ms = 1 sec
  const handleSerachRecordsByFilter = debounce(async () => {
    try {
      let api_payload: any = {
        attributes: [],
      };
      for (const filter of state.filtered_options) {
        if (filter.selected_value?.en || filter.selected_value?.fr) {
          if (filter.type === "categories") {
            api_payload.categories = [
              filter.selected_value?.en || filter.selected_value?.fr,
            ];
          }

          if (filter.type === "size") {
            api_payload.attributes.push(
              filter.selected_value?.en || filter.selected_value?.fr
            );
          }

          if (filter.type === "color") {
            api_payload.attributes.push(
              filter.selected_value?.en || filter.selected_value?.fr
            );
          }

          if (filter.type === "brand") {
            api_payload.brands = [
              filter.selected_value?.en || filter.selected_value?.fr,
            ];
          }

          if (filter.type === "condition") {
            api_payload.contitions = [
              filter.selected_value?.en || filter.selected_value?.fr,
            ];
          }

          if (filter.type === "sort") {
            api_payload.order =
              filter.selected_value?.fr === "Prix croissant" ? true : false;
          }
        }
      }

      const resp = await apiServices.postFromApi(
        "c2c-product-masters/products-filter",
        {
          ...api_payload,
        },
        ""
      );
      setState({
        all_products: resp?.result ?? [],
      });
      // handleInputChange(null as any, text);
    } catch (error) {
      console.log(error);
    }
  }, 1500);

  const handleOpenFilterOptions = (
    value: any = true,
    item: any,
    index: number
  ) => {
    try {
      let filtered_options1 = state.filtered_options;
      const d = filtered_options1[index];
      let obj: any = {
        ...d,
        is_open: value,
      };

      filtered_options1[index] = obj;

      console.log(filtered_options1);
      setState({
        filtered_options: filtered_options1,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickBackCategory = (value: any, item: any, index: number) => {
    try {
      let { filtered_options } = state;
      let obj: any = {
        ...item,
        is_open: true,
        show_child: false,
      };

      filtered_options[index] = obj;
      setState({
        filtered_options,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const total_amount = selector?.cart?.reduce((a: any, b: any) => {
    return (
      a +
      Number(Number(b?.price ?? 0) - Number(b?.discount ?? 0)) *
        Number(b?.quantity ?? 1)
    );
  }, 0);

  const { parentCategories, activity } = state;

  const FilterOptions = ({
    filtered_options,
    handleOpenFilterOptions,
    onChangeItem,
    handleClickBackCategory,
  }: any) => {
    const filter_types = filtered_options;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          columnGap: 16,
          rowGap: 16,
          border:'none'
        }}
      >
        {filter_types.map((item: any, index: number) => (
          <div key={`FILER_DROPDOWN_${index + 1}`}>
            {item.type === "google_place" ? null : (
              <div style={{ maxWidth: 170 }}>
                {item?.is_open ? (
                  <Select
                    style={{
                      borderRadius: 16,
                      height: 32,
                      fontSize: 18,
                      fontFamily: "Raleway-Semi-Bold",
                      background: color_theme.white,
                      color: color_theme.main_orange,
                      padding: "8px 16px 8px 24px",
                      border:"none",
                      outline:'none'
                    }}
                    fullWidth
                    value={item?.selected_value}
                    displayEmpty
                    open={item?.is_open}
                    renderValue={(selectedValue) => {
                      // if (item?.selected_value) {
                      //   return typeof item?.selected_value === "string"
                      //     ? item?.selected_value
                      //     : handleLocalize(item?.selected_value);
                      // }
                      return item?.name;
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          borderRadius: "12px", // Add border radius here
                          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Optional: Customize shadow
                          marginTop: 1,
                          maxHeight: "400px",
                        },
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                    }}
                    onChange={(event) => {
                      onChangeItem(event, item, index);
                    }}
                    onFocus={(event) => {
                      handleOpenFilterOptions(true, item, index);
                    }}
                    onKeyDownCapture={(e) => {
                      if (e.code === "Escape") {
                        handleOpenFilterOptions(false, item, index);
                      }
                    }}
                    onClick={(e) => {
                      console.log(
                        e.clientX,
                        e.pageX,
                        e.screenX,
                        e.currentTarget.clientWidth
                      );
                      // if (e.clientX > 378) {
                      //   handleOpenFilterOptions(false, item, index);
                      // }
                    }}
                    IconComponent={KeyboardArrowDown}
                  >
                    <MenuItem
                      style={{
                        fontSize: 18,
                        color: color_theme.main_orange,
                        fontFamily: "Raleway-Semi-Bold",
                        opacity: 1,
                        background: color_theme.white,
                        textAlign: "center",
                        borderBottom: `1px solid #CBA9A9`,
                        width: 278,
                        display: "flex",
                        justifyContent: "center",
                        cursor: "auto",
                      }}
                      disableRipple
                      disableTouchRipple
                      onChange={(e) => {
                        return;
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: item?.show_child ? "flex" : "none",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleClickBackCategory(true, item, index);
                          }}
                        >
                          <ArrowBackIosNew />
                        </div>
                        <div
                          style={{
                            flex: 1,
                            textAlign: "center",
                          }}
                        >
                          {item?.show_child
                            ? item?.children_items[0]?.parent_cat?.category_name
                                ?.fr
                            : item?.name}
                        </div>
                      </div>
                    </MenuItem>
                    {item.type === "price" ? (
                      <>
                        <MenuItem
                          style={{
                            fontSize: 18,
                            color: color_theme.black,
                            fontFamily: "Raleway-Semi-Bold",
                            opacity: 1,
                            background: color_theme.white,
                            minWidth: 278,
                            display: "flex",
                            flexDirection: "column",
                            padding: "5px 14px 0px 14px",
                            cursor: "auto",
                          }}
                          disableRipple
                          disableTouchRipple
                          onChange={(e) => {
                            return;
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              gap: 10,
                            }}
                          >
                            <div>De</div>
                            <div style={{ flex: 1 }}>
                              <input
                                style={{
                                  border: "none",
                                  outline: "none",
                                  fontSize: 18,
                                  color: color_theme.black,
                                  fontFamily: "Raleway-Semi-Bold",
                                }}
                                // value={state.promo_code_text}
                                // onChange={(e) =>
                                //   setState({
                                //     // promo_code_text: e.target.value,
                                //   })
                                // }
                                placeholder={"0,00€ "}
                              />
                            </div>
                          </div>
                        </MenuItem>
                        <MenuItem
                          style={{
                            fontSize: 18,
                            color: color_theme.black,
                            fontFamily: "Raleway-Semi-Bold",
                            opacity: 1,
                            background: color_theme.white,
                            minWidth: 278,
                            display: "flex",
                            flexDirection: "column",
                            padding: "5px 14px 0px 14px",
                            cursor: "auto",
                          }}
                          disableRipple
                          disableTouchRipple
                          onChange={(e) => {
                            return;
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              gap: 10,
                            }}
                          >
                            <div>À</div>
                            <div style={{ flex: 1 }}>
                              <input
                                style={{
                                  border: "none",
                                  outline: "none",
                                  fontSize: 18,
                                  color: color_theme.black,
                                  fontFamily: "Raleway-Semi-Bold",
                                }}
                                // value={state.promo_code_text}
                                // onChange={(e) =>
                                //   setState({
                                //     // promo_code_text: e.target.value,
                                //   })
                                // }
                                placeholder={"0,00€ "}
                              />
                            </div>
                          </div>
                        </MenuItem>
                      </>
                    ) : (
                      (item?.show_child
                        ? item?.children_items
                        : item?.options
                      )?.map((opt: any, idx: number) => {
                        const is_selected =
                          item?.selected_value?.fr === opt?.label?.fr;
                        return (
                          <MenuItem
                            value={opt.value}
                            style={{
                              fontSize: 18,
                              color: color_theme.black,
                              fontFamily: "Raleway-Semi-Bold",
                              opacity: 1,
                              background: color_theme.white,
                              minWidth: 278,
                              display: "flex",
                              flexDirection: "column",
                              padding: "5px 14px 0px 14px",
                            }}
                            key={opt.value}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "100%",
                                alignItems: "center",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  display:
                                    item.type === "color" ? "flex" : "none",
                                  height: 20,
                                  width: 20,
                                  borderRadius: 5,
                                  background:
                                    `${opt.label?.en}`.toLowerCase() ===
                                      "multicolor" ||
                                    `${opt.label?.en}`.toLowerCase() ===
                                      "multi color"
                                      ? "conic-gradient(red, yellow, lime, aqua, blue, magenta, red)"
                                      : opt.label?.en,
                                  position: "absolute",
                                }}
                              />
                              <div
                                style={{
                                  paddingLeft: item.type === "color" ? 30 : 0,
                                }}
                              >
                                {handleLocalize(opt.label)}
                              </div>
                              {opt.children?.length > 1 ? (
                                <ArrowForwardIos
                                  style={{
                                    fontSize: 18,
                                    color: color_theme.black,
                                  }}
                                />
                              ) : is_selected ? (
                                <CheckedRadioIcon
                                  color={color_theme.black_20}
                                />
                              ) : (
                                <UnCheckedSelectedRadioIcon
                                  borderColor={"rgb(0 0 0 / 50%)"}
                                />
                              )}
                            </div>

                            <div
                              style={{
                                width: "100%",
                                marginLeft: 16,
                                marginRight: 16,
                                marginTop: 5,
                              }}
                            >
                              {(item?.show_child
                                ? item?.children_items
                                : item?.options
                              )?.length !==
                                idx + 1 && FrankDivider("#CBA9A9")}
                            </div>
                          </MenuItem>
                        );
                      })
                    )}
                  </Select>
                ) : (
                  <Select
                    style={{
                      borderRadius: 16,
                      height: 32,
                      fontSize: 18,
                      fontFamily: "Raleway-Semi-Bold",
                      background: color_theme.white,
                      color: color_theme.main_orange,
                      padding: "8px 16px 8px 24px",
                      border:'none',
                    }}
                    fullWidth
                    open={item?.is_open}
                    value={item?.selected_value}
                    displayEmpty
                    renderValue={(selectedValue) => {
                      // if (item?.selected_value) {
                      //   return typeof item?.selected_value === "string"
                      //     ? item?.selected_value
                      //     : handleLocalize(item?.selected_value);
                      // }
                      return item?.name;
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          borderRadius: "12px", // Add border radius here
                          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Optional: Customize shadow
                          marginTop: 1,
                          maxHeight: "400px",
                        },
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                    }}
                    onChange={(event) => {
                      onChangeItem(event, item, index);
                    }}
                    onFocus={(event) => {
                      handleOpenFilterOptions(true, item, index);
                    }}
                    IconComponent={KeyboardArrowDown}
                  >
                    <MenuItem
                      style={{
                        fontSize: 18,
                        color: color_theme.main_orange,
                        fontFamily: "Raleway-Semi-Bold",
                        opacity: 1,
                        background: color_theme.white,
                        textAlign: "center",
                        borderBottom: `1px solid #CBA9A9`,
                        width: 278,
                        display: "flex",
                        justifyContent: "center",
                      }}
                      disabled
                    >
                      {item?.name}
                    </MenuItem>
                    {(item?.show_child
                      ? item?.children_items
                      : item?.options
                    )?.map((opt: any, idx: number) => {
                      const is_selected =
                        item?.selected_value?.fr === opt?.label?.fr;
                      return (
                        <MenuItem
                          value={opt.value}
                          style={{
                            fontSize: 18,
                            color: color_theme.black,
                            fontFamily: "Raleway-Semi-Bold",
                            opacity: 1,
                            background: color_theme.white,
                            minWidth: 278,
                            display: "flex",
                            flexDirection: "column",
                            padding: "5px 14px 0px 14px",
                          }}
                          key={opt.value}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              width: "100%",
                              alignItems: "center",
                            }}
                          >
                            <div>{handleLocalize(opt.label)}</div>
                            {opt.children?.length > 1 ? (
                              <ArrowForwardIos
                                style={{
                                  fontSize: 18,
                                  color: color_theme.black,
                                }}
                              />
                            ) : is_selected ? (
                              <CheckedRadioIcon color={color_theme.black_20} />
                            ) : (
                              <UnCheckedSelectedRadioIcon
                                borderColor={"rgb(0 0 0 / 50%)"}
                              />
                            )}
                          </div>

                          <div
                            style={{
                              width: "100%",
                              marginLeft: 16,
                              marginRight: 16,
                              marginTop: 5,
                            }}
                          >
                            {item?.options?.length !== idx + 1 &&
                              FrankDivider("#CBA9A9")}
                          </div>
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              </div>
            )}

            <div
              style={{
                background: "#D9D9D9",
                padding: "3px 20px",
                fontSize: 16,
                color: color_theme.main_blue,
                fontFamily: "Raleway-Semi-Bold",
                display:
                  typeof item?.selected_value === "string"
                    ? item?.selected_value
                      ? "flex"
                      : "none"
                    : item?.selected_value?.fr || item?.selected_value?.en
                    ? "flex"
                    : "none",

                borderRadius: 100,
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 7,
                alignItems: "center",
              }}
            >
              {typeof item?.selected_value === "string"
                ? item?.selected_value
                : handleLocalize(item?.selected_value)}

              <div
                onClick={() =>
                  onChangeItem(
                    {
                      target: {
                        value: null,
                      },
                    },
                    item,
                    index
                  )
                }
                style={{
                  cursor: "pointer",
                  padding: 1,
                }}
              >
                <Close />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const handleSearchProducts = useCallback(
    debounce(async (searchValue: any) => {
      try {
        if (!searchValue) {
          setState({ activity: true });
          setState({
            showSearchedData: false,
            allSearchProduct: null,
            activity: false,
          });
        }
        if (searchValue?.trim()) {
          setState({ activity: true });
          let resp = await apiServices.postFromApi(
            "c2c-product-masters/products-search",
            { search: searchValue },
            ""
          );
          console.log("resp", resp);
          if (resp?.data?.length < 1) {
            setState({
              showImg: true,
            });
          }
          setState({
            allSearchProduct: resp?.data,
            activity: false,
            showSearchedData: true,
          });
        }
      } catch (error) {
        console.error("Error while searching products:", error);
      }
    }, 1500),
    []
  );
  const onInputChangeForHeader = (e: any) => {
    try {
      const { value, name } = e.target;
      setState({
        [name]: value,
      });
    } catch (error) {}
  };

  useEffect(() => {
    handleSearchProducts(state.global_search);
  }, [state.global_search, handleSearchProducts]);
  

  return (
    <div className="web-screen">
      <div style={{ background: defaultLabelColors.main_background_two }}>
        <Grid
          container
          spacing={2}
          className="display-flex"
          style={{
            background: defaultLabelColors.main_background_two,
            paddingLeft: "40px",
            paddingRight: "40px",
            paddingTop: "30px",
          }}
        >
          <Grid xs={12} item style={{ zIndex: 99 }}>
            <div
              style={{
                padding: 15,
                background: "#FEF5F1",
                borderRadius: 24,
                marginTop: 20,
                // width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 14,
                    width: "100%",
                  }}
                >
                  <a href="/" style={{ marginBottom: "-10px", marginLeft: 25 }}>
                    <FrankIcon fill={"#ee6931"} height={55} />
                  </a>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 14,
                      flex: 1,
                    }}
                  >
                    <div className="font_resp">
                      <div
                        className="menu-component menu-container"
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          className="particulier-marketplace font_resp"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontFamily: "Raleway-Semi-Bold",
                          }}
                        >
                          Particulier{" "}
                          <KeyboardArrowDown
                            style={{ fontSize: "24px", fontWeight: "lighter" }}
                          />
                        </span>

                        <div className="dropdown">
                          <div
                            className="dropdown-item"
                            onClick={() => {
                              navigate("/send-package");
                            }}
                          >
                            Envoyer un colis
                          </div>
                          <div className="dropdown-item">Suivre mon colis</div>
                          <div
                            className="dropdown-item"
                            onClick={() => {
                              navigate("/transporter-un-colis");
                            }}
                          >
                            Devenir transporteur
                          </div>
                          <div
                            className="dropdown-item"
                            onClick={() => {
                              navigate("/send-package");
                            }}
                          >
                            Démenagement{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="font_resp">
                      <div
                        className="menu-component menu-container"
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          className="particulier-marketplace font_resp"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontFamily: "Raleway-Semi-Bold",
                          }}
                        >
                          Transporteur{" "}
                          <KeyboardArrowDown
                            style={{ fontSize: "24px", fontWeight: "lighter" }}
                          />{" "}
                        </span>

                        <div className="dropdown">
                          <div
                            className="dropdown-item"
                            onClick={() => {
                              navigate("/transporter-un-colis");
                            }}
                          >
                            Devenir Transporteur
                          </div>
                          <div
                            className="dropdown-item"
                            onClick={() => {
                              navigate("/transporter-un-colis");
                            }}
                          >
                            Voir les annonces
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="font_resp">
                      <div
                        className="menu-component menu-container"
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          className="particulier-marketplace font_resp"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontFamily: "Raleway-Semi-Bold",
                          }}
                          onClick={() => {
                            navigate("/marketplace");
                          }}
                        >
                          Marché de Frank
                        </span>
                      </div>
                    </div>

                    <div className="font_resp" style={{ paddingLeft: 5 }}>
                      <div
                        className="menu-component menu-container"
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          className="particulier-marketplace font_resp"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontFamily: "Raleway-Semi-Bold",
                          }}
                          onClick={() => {
                            navigate("/blogs");
                          }}
                        >
                          Blog
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className="hide_login_drawer"
                    style={{ cursor: "pointer", marginTop: "10px" }}
                    onClick={toggleDrawer(true)}
                  >
                    <svg
                      width="23px"
                      height="22px"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginTop: "-10px" }}
                    >
                      <path
                        d="M4 16H28M4 8H28M4 24H20"
                        stroke="#281208"
                        stroke-width="2.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  {/* This is Drawer */}
                  <Drawer
                    anchor="right"
                    open={isDrawerOpen}
                    onClose={toggleDrawer(false)}
                    style={{
                      borderRadius: "0px",
                      background: "none",
                      height: "100vh",
                    }}
                    PaperProps={{
                      sx: {
                        width: "300px",
                      },
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div style={{ flex: 1, padding: "20px 0px 0px 20px" }}>
                        <FrankIcon fill={"#ee6931"} height={60} />
                      </div>
                      <div style={{ padding: "20px 20px 0px 0px" }}>
                        <IconButton onClick={toggleDrawer(false)}>
                          <Close style={{ fontSize: "28px", color: "#000" }} />
                        </IconButton>
                      </div>
                    </div>
                    <Typography
                      variant="body1"
                      style={{ paddingLeft: "20px", flex: 1 }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 20,
                        }}
                      >
                        {userDetails ? (
                          <div className="user" style={{ cursor: "pointer" }}>
                            <div
                              style={{
                                display: "flex",
                                gap: "20px",
                                fontFamily: "Raleway-Variable",
                                fontWeight: "600",
                              }}
                              onClick={() => {
                                navigate("/chat");
                              }}
                            >
                              <MailIconFigma />{" "}
                              <span style={{ color: "#000" }}>Mail</span>
                            </div>
                          </div>
                        ) : null}

                        {userDetails ? (
                          <div
                            className="compte"
                            style={{ cursor: "pointer", position: "relative" }}
                          >
                            <div className="user" style={{}}>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "20px",
                                  fontFamily: "Raleway-Variable",
                                  fontWeight: "600",
                                }}
                                onClick={() => {
                                  navigate("/profile");
                                }}
                              >
                                <UserIconFigma /> <span>Account</span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="menu-component menu-container"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              navigate("/login");
                            }}
                          >
                            <span
                              className="particulier-marketplace font_resp"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontFamily: "Raleway-Semi-Bold",
                                gap: 10,
                              }}
                            >
                              Se Connecter{" "}
                              <span style={{ paddingBottom: "5px" }}>
                                <UserIconFigma />
                              </span>
                            </span>
                          </div>
                        )}

                        {/* <div
                          className="compte"
                          style={{ cursor: "pointer", position: "relative" }}
                        >
                          <div className="user">
                            <div
                              className="particulier"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              onClick={handleClickForLang}
                            >
                              {selector.lang?.toUpperCase()} <ArrowDropDown />
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </Typography>
                    <Typography
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        <img
                          src={drawerBoxIcon}
                          height={"60%"}
                          alt="box-icon"
                        />
                      </div>
                    </Typography>
                  </Drawer>
                  {/*Drawer ends here*/}

                  <div className="login_area">
                    <div className="right">
                      <div className="font_resp">
                        <div
                          className="menu-component menu-container"
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            className="particulier-marketplace font_resp"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontFamily: "Raleway-Semi-Bold",
                            }}
                          >
                            Suivre mon colis
                          </span>
                        </div>
                      </div>

                      {userDetails ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 24,
                          }}
                        >
                          <div
                            className="tracker"
                            style={{ cursor: "pointer" }}
                          >
                            <div
                              className="line-mail"
                              onClick={() => {
                                navigate("/chat");
                              }}
                            >
                              <MailIconFigma />
                            </div>
                          </div>
                          <div
                            className=""
                            style={{ cursor: "pointer", margin: 2 }}
                          >
                            <div className="">
                              <BoxIconFigma />
                            </div>
                          </div>
                          <div
                            className="compte"
                            style={{ cursor: "pointer", position: "relative" }}
                          >
                            <div className="user" style={{}}>
                              <div
                                onClick={() => {
                                  navigate("/profile");
                                }}
                              >
                                <UserIconFigma />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="menu-component menu-container"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate("/login");
                          }}
                        >
                          <span
                            className="particulier-marketplace font_resp"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontFamily: "Raleway-Semi-Bold",
                              gap: 10,
                            }}
                          >
                            Se Connecter{" "}
                            <span style={{ paddingBottom: "5px" }}>
                              <UserblackIcon />
                            </span>
                          </span>
                        </div>
                      )}

                      {/* <div
                        className="compte"
                        style={{ cursor: "pointer", position: "relative" }}
                      >
                        <div className="user">
                          <div
                            className="particulier-marketplace font_resp"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={handleClickForLang}
                          >
                            {selector.lang?.toUpperCase()} <ArrowDropDown />
                          </div>

                          <Menu
                            id="account-menu"
                            MenuListProps={{
                              "aria-labelledby": "fade-button",
                            }}
                            anchorEl={anchorEN}
                            open={openForLang}
                            onClose={handleCloseForLang}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                // width: "200px",
                                borderRadius: "12px",
                                overflow: "visible",
                                filter:
                                  "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                mt: 1.5,
                                "& .MuiAvatar-root": {
                                  width: 32,
                                  height: 32,
                                  ml: -0.5,
                                  mr: 1,
                                },
                                "&::before": {
                                  content: '""',
                                  display: "block",
                                  position: "absolute",
                                  top: 0,
                                  right: 14,
                                  width: 10,
                                  height: 10,
                                  bgcolor: "background.paper",
                                  transform: "translateY(-50%) rotate(45deg)",
                                  zIndex: 0,
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                dispatch(setUpdateLanguage("fr"));
                                handleCloseForLang();
                              }}
                            >
                              <div className="display-flex" style={{ gap: 10 }}>
                                <img
                                  src={frIcon}
                                  alt="fr-icon"
                                  height={30}
                                  width={30}
                                />
                                FR
                              </div>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                dispatch(setUpdateLanguage("en"));
                                handleCloseForLang();
                              }}
                            >
                              <div className="display-flex" style={{ gap: 10 }}>
                                <img
                                  src={enIcon}
                                  alt="en-icon"
                                  height={20}
                                  width={30}
                                />
                                EN
                              </div>
                            </MenuItem>
                          </Menu>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderTop: "1px solid lightgray",
                  padding: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  height: "60px",
                }}
              >
                <div style={{ flex: 1 }}>
                  <InputField
                    placeHolder={"Rechercher des produits"}
                    input_value={state?.global_search}
                    input_name={"global_search"}
                    onchange={onInputChangeForHeader}
                    input_style={{
                      background: "#fff",
                      // minWidth: "100%",
                      width: xl
                        ? "726px"
                        : lg
                        ? "600px"
                        : md
                        ? "400px"
                        : "300px",
                      // maxWidth: "726px",
                      borderRadius: "12px",
                      fontFamily: "Raleway-Variable",
                    }}
                    startIcon={<Search style={{ opacity: "50%" }} />}
                  />
                </div>
                <div style={{ display: "flex", gap: 10 }}>
                  <Button
                    disableElevation
                    onClick={() => {
                      navigate("/add-product-individual");
                    }}
                    variant="contained"
                    startIcon={<AddBoxOutlined style={{ fontSize: "26px" }} />}
                    sx={{
                      backgroundColor: defaultLabelColors.main_orange, // Same as the blue color in the image
                      color: "#fff",
                      borderRadius: "12px",
                      padding: "10px 20px",
                      textTransform: "none",
                      fontSize: "16px",
                      fontFamily: "Raleway-Variable",
                      fontWeight: "bold",
                      width: "300px",
                      "&:hover": {
                        backgroundColor: defaultLabelColors.main_orange,
                      },
                    }}
                  >
                    Vendre un article
                  </Button>
                  <Button
                    disableElevation
                    variant="contained"
                    onClick={handleClickforCart}
                    sx={{
                      backgroundColor: defaultLabelColors.main_blue,
                      color: "#fff",
                      borderRadius: "12px",
                      textTransform: "none",
                      fontSize: "16px",
                      textAlign: "center",
                      fontFamily: "Raleway-Variable",
                      fontWeight: "bold",
                      "&:hover": {
                        backgroundColor: defaultLabelColors.main_blue,
                      },
                    }}
                  >
                    <IconButton aria-describedby={id}>
                      <Badge
                        badgeContent={selector?.cart?.reduce(
                          (a: any, b: any) => {
                            return a + Number(b?.quantity ?? 0);
                          },
                          0
                        )}
                        color="primary"
                      >
                        <CartIconMarketPlace fill={defaultLabelColors.white} />
                      </Badge>
                    </IconButton>
                  </Button>

                  <Popover
                    id={id}
                    open={openForCart}
                    anchorEl={anchorEM}
                    onClose={handleCloseforCart}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    sx={{
                      "& .MuiPaper-root": {
                        borderRadius: "16px", // Apply border radius here
                        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Add shadow for effect
                        padding: "10px",
                      },
                    }}
                  >
                    <List>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                            fontFamily: "Raleway-Variable",
                            fontWeight: 700,
                            color: defaultLabelColors.main_blue,
                            fontSize: "22px",
                            width: "100%",
                          }}
                        >
                          Votre panier
                        </div>

                        <CartIconMarketPlace
                          fill={defaultLabelColors.main_blue}
                        />
                      </div>
                      {FrankDivider()}
                      {selector?.cart?.length > 0 ? (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              rowGap: 12,
                              height: 400,
                              overflow: "hidden scroll",
                              paddingRight: 5,
                            }}
                          >
                            {selector?.cart?.map((item: any, index: number) => (
                              <div
                                key={`CART_ITEM_${index + 1}`}
                                style={{
                                  width: 400,
                                  display: "flex",
                                  flexDirection: "row",
                                  borderRadius: 12,
                                  columnGap: 10,
                                  backgroundColor: "#F4F3F6",
                                  marginTop: index === 0 ? 10 : 0,
                                }}
                              >
                                <img
                                  alt="prod-img"
                                  src={item?.images?.[0]}
                                  style={{
                                    height: 93,
                                    width: 102,
                                    objectFit: "cover",
                                    borderTopLeftRadius: 12,
                                    borderBottomLeftRadius: 12,
                                    backgroundColor: "#F4F3F6",
                                    objectPosition: "top",
                                  }}
                                />
                                <div>
                                  <div
                                    style={{
                                      flexDirection: "row",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: 16,
                                        whiteSpace: "nowrap",
                                        width: 250,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        fontFamily: "Raleway-Bold",
                                        margin: "10px 0px 0px 0px",
                                      }}
                                    >
                                      {handleLocalize(item?.product_title) ??
                                        ""}
                                    </p>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Close
                                        style={{
                                          marginTop: "0.3em",
                                          cursor: "pointer",
                                        }}
                                        titleAccess="Supprimer l'article ?"
                                        onClick={() => handleUpdateCart(item)}
                                      />
                                    </div>
                                  </div>
                                  <p
                                    style={{
                                      fontSize: 16,
                                      whiteSpace: "nowrap",
                                      width: 250,
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      fontFamily: "Raleway",
                                      margin: 0,
                                    }}
                                  >
                                    {handleLocalize(item?.brand_name) ?? ""}
                                  </p>

                                  <p
                                    style={{
                                      fontSize: 18,
                                      fontFamily: "Raleway-Bold",
                                      margin: 0,
                                      textAlign: "right",
                                      color: color_theme.main_blue,
                                    }}
                                  >
                                    {numberFormatWithoutCurrency(
                                      Number(
                                        Number(item?.price ?? 0) -
                                          Number(item?.discount ?? 0)
                                      ) * Number(item?.quantity ?? 0),
                                      "en-FR"
                                    )}{" "}
                                    €
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>

                          <div
                            style={{
                              borderTop: "1px solid #d4d4d4",
                              marginTop: 10,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div style={{ flex: 1 }}>
                                <p
                                  style={{
                                    fontSize: 27,
                                    color: "#000",
                                    fontFamily: "Raleway-Bold",
                                  }}
                                >
                                  Total
                                  <span
                                    style={{
                                      fontSize: 16,
                                      color: "#281208",
                                      fontFamily: "Raleway-Variable",
                                    }}
                                  >
                                    {" "}
                                    (Hors livraison){" "}
                                  </span>
                                </p>
                              </div>
                              <p
                                style={{
                                  fontSize: 28,
                                  color: "#000",
                                  fontFamily: "Raleway-Bold",
                                }}
                              >
                                {total_amount?.toFixed(2)} €
                              </p>
                            </div>
                            <div
                              style={{
                                padding: 10,
                                backgroundColor: color_theme.main_blue,
                                background: color_theme.main_blue,
                                borderRadius: 6,
                                placeContent: "center",
                                display: "flex",
                                placeItems: "center",
                              }}
                              onClick={() => {
                                navigate("/cart-details");
                              }}
                            >
                              <span
                                style={{
                                  fontSize: 14,
                                  fontFamily: "Raleway-Semi-Bold",
                                  lineHeight: "18px",
                                  color: color_theme.white,
                                }}
                              >
                                Voir mon panier
                              </span>
                            </div>
                          </div>
                        </>
                      ) : (
                        <ListItem style={{ width: "400px" }}>
                          <Typography>No items in the cart.</Typography>
                        </ListItem>
                      )}
                    </List>
                  </Popover>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: 14,
                  width: "100%",
                  padding: "5px 14px",
                }}
              >
                {parentCategories?.map((category: any) => {
                  return (
                    <div
                      style={{
                        backgroundColor: "#F4F3F6",
                        color: "#000",
                        fontSize: "1em",
                        textTransform: "none",
                        fontFamily: "Raleway-Semi-Bold",
                        borderRadius: "16px",
                        boxShadow: "none",
                        textAlign: "center",
                        padding: "10px 16px",
                        cursor: "pointer",
                      }}
                      key={`CATEORY_KEY_DIV_${category?.id}`}
                      onClick={() => {
                        handleGetAllProductByCategory(
                          category?.id,
                          parentCategories
                        );
                      }}
                    >
                      {handleLocalize({
                        en: category?.category_name?.en,
                        fr: category?.category_name?.fr,
                      }) ?? ""}
                    </div>
                  );
                })}
              </div>
            </div>
          </Grid>
        </Grid>
        <Loader loading={activity} />
        {/* navbar ends here */}

        { 
        state?.showSearchedData ?
        <div style={{ minHeight:'100vh',padding: "0px 50px 0px 50px",background:defaultLabelColors.main_background_two }}>
        <Grid container spacing={2}>
          <Grid
            xs={12}
            item
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            onClick={() => {
              setState({showSearchedData:false,global_search:""})
            }}
          >
            <p
              style={{
                fontFamily: "Raleway-Variable",
                fontSize: "26px",
                gap: 10,
                cursor: "pointer",
              }}
              className="display-flex"
            >
              <ArrowBackIos style={{ fontSize: "22px" }} /> Vendre un article
            </p>
          </Grid>
        </Grid>

        {state?.allSearchProduct?.length < 1 ||
        !state?.allSearchProduct?.length ? (
          <div
            style={{
              height: "60vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              opacity: "0.5",
            }}
          >
            {state?.showImg && (
              <img
                src={noProdFound}
                alt=""
                style={{ height: "400px", width: "400px" }}
              />
            )}
          </div>
        ) : (
          <>
            <p style={{ fontFamily: "Raleway-Semi-Bold", fontSize: "16px" }}>
              {state?.allSearchProduct?.length} annonces
            </p>
            <Grid container spacing={2}>
            {state?.allSearchProduct
                ?.slice(0, xl ? 6 : lg ? 4 : md ? 3 : 4)
                ?.map((i: any, idx: any) => {
                  return (
                    <Grid
                      key={idx}
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      xl={2}
                      style={{ cursor: "pointer", maxHeight: "370px" }}
                      onClick={() => {
                        handleOpenProduct(i);
                      }}
                    >
                      <ProductCard
                        i={i}
                        handleAddToFavorite={handleAddToFavorite}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </>
        )}
      </div> 
      : <div style={{ padding: "30px 80px 50px 80px" }}>
          <Grid container spacing={2} style={{ paddingBottom: "40px" }}>
            <Grid
              xs={12}
              item
              style={{
                display: "flex",
                fontFamily: "Raleway-Variable",
                fontWeight: "500",
                fontSize: "26px",
                color: "#4B4A61",
                gap: 10,
                alignItems: "center",
              }}
            >
              <ArrowBackIosNew
                onClick={() => {
                  navigate(-1);
                }}
                style={{ cursor: "pointer" }}
              />
              <h1 style={{ fontSize: "26px" }}> {` ${state.path ?? ""} `}</h1>
            </Grid>
          </Grid>
          <FilterOptions
            filtered_options={state.filtered_options}
            handleOpenFilterOptions={handleOpenFilterOptions}
            onChangeItem={onChangeItem}
            handleClickBackCategory={handleClickBackCategory}
          />

          <Grid container spacing={2}>
            <Grid xs={12} item>
              <p style={{ fontFamily: "Raleway-Variable", fontWeight: "700" }}>
                {state?.all_products?.length < 1
                  ? null
                  : `${state?.all_products?.length} annonces`}
              </p>
            </Grid>
            {state?.all_products?.map((i: any, idx: any) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  lg={3}
                  xl={2}
                  style={{ cursor: "pointer", maxHeight: "380px" }}
                  onClick={() => {
                    handleOpenProduct(i);
                  }}
                >
                  <ProductCard
                    i={i}
                    handleAddToFavorite={handleAddToFavorite}
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>}
      </div>

      <div className="main-container" style={{  background: defaultLabelColors.main_background_two }}>
        <CustomFooter />
      </div>
    </div>
  );
}

export default AllProductByCateogry;

export const CheckedRadioIcon = ({ color = color_theme.main_orange }) => (
  <div
    style={{
      width: 15,
      height: 15,
      borderRadius: "100%",
      border: `2px solid ${color_theme.main_orange}`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <div
      style={{
        width: 10,
        height: 10,
        borderRadius: "100%",
        border: `2px solid ${color_theme.main_orange}`,
        background: color_theme.main_orange,
      }}
    />
  </div>
);
export const UnCheckedSelectedRadioIcon = ({
  borderColor = color_theme.main_orange,
}) => (
  <div
    style={{
      width: 15,
      height: 15,
      borderRadius: "100%",
      border: `0.5px solid ${borderColor}`,
      background: color_theme.white,
    }}
  />
);
