import { Box, Divider, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../../Store";
import { strings } from "../../Store/Localization";
import Loader from "../../UiComponent/Loader";
import NewHeader from "../../UiComponent/NewHeader";
import noChatimage from "../../assets/images/FrankIcons/noChatimage.png";
import noImage from "../../assets/images/FrankIcons/noImageAvailable.png";
import apiServices from "../../services/RequestHandler";
import { getDataFromLocalStorage } from "../../utils/LocalStore.util";
import { defaultLabelColors } from "../../utils/constants";
import {
  handleLocalize,
  numberFormatWithoutCurrency,
} from "../../utils/helper";
import { checkForPermission } from "../../utils/notificationListner";
import AccepterChat, { color_theme } from "./AccepterChat";
import MarketPlaceChat from "./MarketPlaceChat";
import SenderChat from "./SenderChat";

const useStyles = makeStyles(() => ({
  scrollableArea: {
    height: "600px",
    width: "100%",
    overflowY: "auto",

    "&::-webkit-scrollbar": {
      width: "12px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ee6931",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f5f5f5",
    },

    scrollbarWidth: "thin",
    scrollbarColor: "#ee6931 #f5f5f5",
  },
}));

function SenderInbox(props: any) {
  const classes = useStyles();
  const [chatHeads, setChatHeads] = useState<Array<any>>([]);
  const selector = useSelector((state: RootState) => state.mainApp);
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      user: getDataFromLocalStorage("user"),
      loading_screen: true,
      showCustomerChat: false,
      showAccepterChat: false,
    }
  );

  useEffect(() => {
    handleGetAllChats();
  }, []);

  useEffect(() => {}, [selector]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const screen = queryParams.get("screen"); // "messaging"
  const resId = queryParams.get("res_id"); // "391302"

  const handleGetAllChats = async () => {
    try {
      let resp = await apiServices.getFromApi("chat/announcement-chats", "");
      setChatHeads(resp?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleGetShipmentRates = async (item: any, val?: boolean) => {
    try {
      setState({ loading: true });
      if (item?.type === "market_place") {
        let obj: any = {
          id: Number(item?.product_id),
          customer_id: Number(selector?.user?.id),
        };
        const resp = await apiServices.postFromApi(
          "/c2c-product-masters/particular/details",
          obj,
          ""
        );
        let receiver_id;

        if (!item?.is_owner) {
          receiver_id = item?.product_owner;
          setState({ loading: false });
        } else {
          receiver_id = item?.customer_id;
          setState({ loading: false });
        }
        console.log("resp?.data", resp?.data);

        setState({
          chat_type: "market_place",
          chat_details: resp?.data,
          selected_item: item,
          market_place_chat_item: {
            ...resp?.data,
            product_id: resp?.data?.id,
            receiver_id,
            customer_id: item?.customer_id,
            re_render: new Date().getTime(),
            loading: false,
          },
        });

        return;
      }

      const resp = await apiServices.getFromApi(
        `c2c-shipments/${item?.ship_id}`,
        ""
      );

      setState({
        chat_details: resp?.data,
        chat_type:
          `${item?.customer_id}` === `${state?.user?.id}`
            ? "sender"
            : "transporter",
        selected_item: item,
        market_place_chat_item: null,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      setState({ loading: false });
    }
  };
  const handleGetShipmentRatesWhenNOtification = async (
    item: any,
    val?: boolean
  ) => {
    try {
      const resp = await apiServices.getFromApi(
        `c2c-shipments/${item?.ship_id}`,
        ""
      );
      if (val) {
        setState({
          chat_details: resp?.data,
        });
        return;
      }

      setState({
        chat_details: resp?.data,
        chat_type:
          `${item?.customer_id}` === `${state?.user?.id}`
            ? "sender"
            : "transporter",
        selected_item: item,
      });
      console.log("resp?.data", resp?.data);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (selector.messaging) {
      const message = JSON.parse(selector.messaging ?? "{}");
      if (message.navigation_id) {
        if (state.selected_item) {
          handleGetShipmentRates(state.selected_item);
        }
      }
    }
    console.log("selected_item",state?.selected_item);
    
  }, [selector.messaging]);

  const renderAnnounceCard = () => (
    <>
      {state?.chat_details?.shipment_items?.length > 1 ? (
        <div>
          <p style={{fontFamily:"Raleway-Semi-Bold",color:'#000',fontSize:'20px'}}>
            {state?.chat_details?.owner_detail?.id === state?.user?.id
              ? null
              : state?.chat_details?.owner_detail?.added_by}
          </p>
          <div className="card-container" style={{ marginTop: 5 }}>
            <img
              src={state?.chat_details?.shipment_items?.[0]?.prod_images[0]}
              className="card-image"
              alt="prod-img"
            />
            <div className="card-details">
              <div className="card-title" style={{ fontSize: 18 }}>
                {`Lot de (${state?.chat_details?.shipment_items?.length}) colis`}
              </div>
              <p
                style={{
                  color: "#080626",
                  font: "14px",
                  fontWeight: "500",
                  fontFamily: "Raleway-Semi-Bold",
                }}
              >
                {" "}
                Postée le{" "}
                {moment(state?.chat_details?.created_at).format(
                  "DD/MM/YYYY"
                )}{" "}
              </p>
            </div>
            <div
              className="card-quantity"
              style={{
                paddingRight: "20px",
                color: defaultLabelColors.main_orange,
                fontSize: "26px",
              }}
            >
              {numberFormatWithoutCurrency(
                state?.chat_details?.ship_price ??
                  state?.chat_details?.price ??
                  0.0
              )}
              &nbsp; €
            </div>
          </div>
        </div>
      ) : (
        <div className="card-container">
          <img
            src={
              state?.chat_details?.shipment_items?.[0]?.prod_images?.[0] ??
              state?.selected_item?.images?.[0] ??
              noImage
            }
            className="card-image"
            alt="prod-img"
          />
          <div className="card-details" style={{ flex: 1 }}>
            <div className="card-title" style={{ fontSize: 20 }}>
              {handleLocalize({
                en: state?.selected_item?.product_title?.en,
                fr: state?.selected_item?.product_title?.fr,
              }) ??
                state?.chat_details?.shipment_items?.[0]?.prod_name ??
                ""}
            </div>

            <div
              className="card-quantity"
              style={{
                color: defaultLabelColors.main_orange,
                display: "flex",
                alignItems: "center",
                flex: 1,
              }}
            >
              <p
                style={{
                  color: "#080626",
                  font: "14px",
                  fontWeight: "500",
                }}
              >
                Postée le
                {moment(state?.selected_item?.created_at_tz).format(
                  "DD/MM/YYYY"
                )}
              </p>
            </div>
          </div>
          <div
            style={{
              fontFamily: "Raleway-Variable",
              fontWeight: "700",
              fontSize: "22px",
              color: defaultLabelColors.main_orange,
            }}
          >
            {numberFormatWithoutCurrency(
              state?.chat_details?.base_price ?? state?.chat_details?.ship_price
            )}{" "}
            €
          </div>
        </div>
      )}
    </>
  );

  return (
    <>
      <Loader loading={state?.loading} />
      <Helmet>
        <title>Le Chat | Discutez avec la Communauté Frank</title>
        <link rel="canonical" href="https://www.v2internal.frankme.com/chat" />
        <meta
          name="description"
          content="Discutez en temps réel avec d'autres membres de la communauté Frank. Partagez vos expériences et restez connecté avec des transporteurs et clients."
        />
      </Helmet>
      <div className="web-screen">
        <div style={{ background: "#f9c9b3" }}>
          <Grid
            container
            spacing={2}
            className="display-flex"
            style={{
              background: "#F4C9B3",
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "30px",
            }}
          >
            <Grid xs={12} item style={{ zIndex: 99 }}>
              <NewHeader />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            style={{
              padding: "60px 160px 70px 150px",
              gap: 10,
              paddingBottom: "30px",
            }}
          >
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                background: "#fff",
                borderRadius: "16px",
                padding: "16px",
                display: "flex",
                flexDirection: "column",
                height: "90vh",
              }}
              className={classes?.scrollableArea}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ flex: 1 }}>
                  <h2
                    style={{
                      fontFamily: "Raleway-Extra-Bold",
                      fontSize: "22px",
                      fontWeight: 500,
                    }}
                  >
                    {strings?.inbox}
                  </h2>
                </div>
                <div
                  style={{
                    cursor: "pointer",
                    fontFamily: "Raleway-Bold",
                    color: defaultLabelColors.main_blue,
                  }}
                  onClick={checkForPermission}
                >
                  {" "}
                  Enable notification
                </div>
              </div>

              <Box
                sx={{
                  flex: 1,
                  backgroundColor: "white",
                  overflowY: "scroll",
                  maxHeight: "90vh",
                }}
              >
                {chatHeads?.map((item: any, index: any) => {
                  let item_name = "";
                  if (item?.shipment_items?.length > 1) {
                    item_name = item?.shipment_items?.[0]?.item_name_fr;
                  } else {
                    item_name = item?.shipment_items?.[0]?.item_name_en;
                  }

                  return (
                    <>
                      <div
                        style={{
                          display: "flex",
                          padding: "10px",
                          cursor: "pointer",
                        }}
                        key={`CHAT_HEAD_${index + 1}`}
                        onClick={() => handleGetShipmentRates(item)}
                      >
                        <div
                          style={{
                            backgroundColor: color_theme.card_background,
                            borderWidth: 1,
                            borderColor: color_theme.card_border,
                            borderRadius: 6,
                            display: "flex",
                            alignItems: "center",
                            marginRight: 15,
                            height: 60,
                            width: 50,
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={
                              item?.shipment_items?.[0]?.prod_images?.[0] ??
                              item?.images?.[0]
                                ? item?.type == "announcement"
                                  ? item?.shipment_items?.[0]?.prod_images?.[0]
                                  : item?.images?.[0]
                                : noImage
                            }
                            style={
                              item?.shipment_items?.[0]?.prod_images?.[0] ??
                              item?.images?.[0]
                                ? {
                                    height: 60,
                                    width: 60,
                                    objectFit: "cover",
                                    borderRadius: 6,
                                  }
                                : {
                                    height: 40,
                                    width: 40,
                                    objectFit: "contain",
                                    borderRadius: 6,
                                  }
                            }
                          />
                        </div>
                        <div style={styles.crightContainer}>
                          <div style={{ flex: 1 }}>
                            <Typography
                              style={{
                                ...styles.cusername,
                                color: color_theme.black,
                                fontFamily: "Raleway-Semi-Bold",
                              }}
                            >
                              {item.type == "announcement"
                                ? item?.shipment_items?.[0]?.item_name_fr ??
                                  item?.shipment_items?.[0]?.item_name_en
                                : item.added_by}
                            </Typography>
                            <Typography
                              style={{
                                ...styles.cmessage,
                                color: `${color_theme.black}90`,
                                fontFamily: "Raleway-Italic",
                              }}
                            >
                              {item?.type == "announcement"
                                ? item.customer_id == state?.user?.id
                                  ? item.is_agree === false
                                    ? selector.lang == "fr"
                                      ? `Vous avez refusé l'offre de ${item.fullname}`
                                      : `You declined ${item.fullname}'s offer`
                                    : item.is_agree === true
                                    ? selector.lang === "fr"
                                      ? `vous avez accepté l’offre de ${item.fullname}`
                                      : `You Accepted ${item.fullname}'s offer`
                                    : selector.lang == "fr"
                                    ? `${
                                        item.added_by ?? "Someone"
                                      } fait une offre de ${
                                        item.ship_price
                                      }€ à votre annonce`
                                    : `${
                                        item.added_by ?? "Someone"
                                      } makes an offer of ${item.ship_price}€`
                                  : selector.lang == "fr"
                                  ? `Vous faites une offre pour cette annonce`
                                  : "You makes an offer to this announcement"
                                : `${item.created_by}` === `${state?.user?.id}`
                                ? item?.is_accepted === true
                                  ? handleLocalize({
                                      fr: `vous avez accepté l’offre de ${
                                        item.cust_f_name ?? ""
                                      }`,
                                      en: `You Accepted ${
                                        item.cust_f_name
                                          ? `${item.cust_f_name}'s`
                                          : "the"
                                      } offer`,
                                    })
                                  : item?.is_accepted === false
                                  ? handleLocalize({
                                      fr: `Vous avez refusé l'offre de ${
                                        item.cust_f_name ?? ""
                                      }`,
                                      en: `You declined ${item.cust_f_name}'s offer`,
                                    })
                                  : handleLocalize({
                                      en: "You makes an offer to this announcement",
                                      fr: `Vous faites une offre pour cette annonce`,
                                    })
                                : item?.is_accepted === true
                                ? handleLocalize({
                                    fr: `${item.owner_f_name} a accepté votre offre.`,
                                    en: `${item.owner_f_name} accepted your offer`,
                                  })
                                : item?.is_accepted === false
                                ? handleLocalize({
                                    fr: `${item.owner_f_name} a décliné votre offre.`,
                                    en: `${item.owner_f_name} declined your offer`,
                                  })
                                : handleLocalize({
                                    fr: `${item.owner_f_name} fait une offre de ${item.price}€ à votre annonce`,
                                    en: `${item.owner_f_name} makes an offer of ${item.price}€`,
                                  })}
                            </Typography>
                            {`${
                              item?.type == "announcement"
                                ? item.customer_id
                                : item?.product_owner
                            }` == `${state?.user?.id}` && (
                              <Typography
                                style={{
                                  ...styles.cmessage,
                                  fontFamily: "Raleway-Semi-Bold",
                                  color:
                                    item?.type == "announcement"
                                      ? color_theme.main_blue
                                      : color_theme.main_orange,
                                  marginTop: 10,
                                }}
                                // numberOfLines={1}
                              >
                                {item?.type == "announcement"
                                  ? strings.your_announcements
                                  : strings.your_product}
                              </Typography>
                            )}
                          </div>
                          <div>
                            <Typography
                              style={{ color: `${color_theme.black}95` }}
                            >
                              {moment(
                                `${item.created_at_tz}`.endsWith("Z")
                                  ? item.created_at_tz
                                  : item.created_at_tz + "Z"
                              ).format("HH:mm")}
                            </Typography>
                          </div>
                        </div>

                        <Divider />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          background: "#D4D4D4",
                          height: "1px",
                        }}
                      ></div>
                    </>
                  );
                })}
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              md={7.5}
              sx={{
                background: "#fff",
                borderRadius: "16px",
                padding: "16px",
                height: "90vh",
              }}
            >
              <div
                style={{
                  height: "90vh",
                }}
              >
                {state?.chat_type === "sender" ? (
                  <>
                    {renderAnnounceCard()}
                    <SenderChat
                      handleGetShipmentRates={handleGetShipmentRates}
                      {...props}
                      {...state}
                      handleSwitChat={() => {
                        setState({
                          chat_type: "transporter",
                        });
                      }}
                    />
                  </>
                ) : state?.chat_type === "transporter" ? (
                  <>
                    {renderAnnounceCard()}
                    <AccepterChat
                      handleGetShipmentRates={handleGetShipmentRates}
                      {...props}
                      {...state}
                    />
                  </>
                ) : state?.chat_type === "market_place" ? (
                  <>
                    {renderAnnounceCard()}
                    <MarketPlaceChat
                      handleGetShipmentRates={handleGetShipmentRates}
                      {...props}
                      {...state}
                    />
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={noChatimage}
                        alt="no-chat-img"
                        style={{ opacity: "0.7" }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default SenderInbox;

const styles = {
  loginscreen: {
    flex: 1,
    backgroundColor: "#EEF1FF",
    alignItems: "center",
    justifyContent: "center",
    padding: 12,
    width: "100%",
  },
  loginheading: {
    fontSize: 26,
    marginBottom: 10,
  },
  logininputContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  logininput: {
    borderWidth: 1,
    width: "90%",
    padding: 8,
    borderRadius: 2,
  },
  loginbutton: {
    backgroundColor: "green",
    padding: 12,
    marginVertical: 10,
    width: "60%",
    // borderRadius: '50%',
    elevation: 1,
  },
  loginbuttonText: {
    textAlign: "center",
    color: "#fff",
    fontWeight: "600",
  },
  chatscreen: {
    backgroundColor: "#F7F7F7",
    flex: 1,
    padding: 10,
    position: "relative",
  },
  chatheading: {
    fontSize: 24,
    fontWeight: "bold",
    color: "green",
  },
  chattopContainer: {
    backgroundColor: "#F7F7F7",
    height: 70,
    width: "100%",
    padding: 20,
    justifyContent: "center",
    marginBottom: 15,
    elevation: 2,
  },
  chatheader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  chatlistContainer: {
    paddingHorizontal: 10,
    backgroundColor: color_theme.white,
    flex: 1,
  },
  chatemptyContainer: {
    width: "100%",
    height: "80%",
    alignItems: "center",
    justifyContent: "center",
  },
  chatemptyText: { fontWeight: "bold", fontSize: 24, paddingBottom: 30 },
  messagingscreen: {
    flex: 1,
  },
  messaginginputContainer: {
    width: "100%",
    minHeight: 100,
    backgroundColor: "white",
    paddingVertical: 30,
    paddingHorizontal: 15,
    justifyContent: "center",
    flexDirection: "row",
  },
  messaginginput: {
    borderWidth: 1,
    padding: 15,
    flex: 1,
    marginRight: 10,
    borderRadius: 20,
  },
  messagingbuttonContainer: {
    width: "30%",
    backgroundColor: "green",
    borderRadius: 3,
    alignItems: "center",
    justifyContent: "center",
    // borderRadius: 50,
  },
  modalbutton: {
    width: "40%",
    height: 45,
    backgroundColor: "green",
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
  },
  modalbuttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
  },
  modaltext: {
    color: "#fff",
  },
  modalContainer: {
    width: "100%",
    borderTopColor: "#ddd",
    borderTopWidth: 1,
    elevation: 1,
    height: 400,
    backgroundColor: "#fff",
    position: "absolute",
    bottom: 0,
    zIndex: 10,
    paddingVertical: 50,
    paddingHorizontal: 20,
  },
  modalinput: {
    borderWidth: 2,
    padding: 15,
  },
  modalsubheading: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 15,
    textAlign: "center",
  },
  mmessageWrapper: {
    width: "100%",
    alignItems: "flex-start",
    marginBottom: 15,
  },
  mmessage: {
    maxWidth: "50%",
    backgroundColor: "#F5CCC2",
    padding: 15,
    borderRadius: 10,
    marginBottom: 2,
  },
  mvatar: {
    marginRight: 5,
  },
  cchat: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 16,
    paddingHorizontal: 15,
    minHeight: 80,
    marginTop: 10,
    borderBottomWidth: 1,
    borderColor: color_theme.card_border,
    backgroundColor: color_theme.white,
  },
  cavatar: {
    marginRight: 15,
  },
  cusername: {
    fontSize: 18,
    marginBottom: 5,
    fontWeight: "bold",
  },
  cmessage: {
    fontSize: 14,
    opacity: 0.7,
  },
  crightContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1,
  },
} as any;
