import { Box, DialogContent, Grid, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import NewHeader from "../../UiComponent/NewHeader";
import { strings } from "../../Store/Localization";
import moment from "moment";
import { ArrowDownward, ArrowUpward, Search } from "@mui/icons-material";
import { spacing } from "@mui/system";
import classNames from "classnames";
import CustomButton from "../../UiComponent/CustomButton";
import InputField from "../../UiComponent/TextFeild";
import apiServices from "../../services/RequestHandler";
import { useNavigate } from "react-router-dom";
import {
  HomeIcon,
  MapHomeIcon,
  MapPointerIcon,
  PointerIcon,
} from "../../assets/images/FrankIcons";
import {
  defaultLabelColors,
  getStatusColors,
  status_translations,
} from "../../utils/constants";
import DraggableDialog from "../../UiComponent/DraggableDialog";
import { Rating } from "react-simple-star-rating";
import JobCard from "../../UiComponent/JobCard";
import {
  handleLocalize,
  numberFormatWithoutCurrency,
} from "../../utils/helper";
import OTPInput from "react-otp-input";
import Toast from "../../UiComponent/Toast/Toast";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeCheckout from "./StripeCheckout";
import { decrypt } from "../../utils/Encrypt";
import { useSelector } from "react-redux";
import { RootState } from "../../Store";
import CustomFooter from "../../UiComponent/CustomFooter";
import { Helmet } from "react-helmet";

function MyAnnounces(props: any) {
  const { classes } = props;
  const [otp, setOtp] = React.useState("");
  const navigate = useNavigate();
  const selector = useSelector((state: RootState) => state.mainApp);
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      announes: "All",
      negotiate: false,
      messagingScreen: false,
      openDeliveryDialog: false,
      showOtpScreen: false,
      showStripeForm: false,
    }
  );
  useEffect(() => {}, [selector.lang]);
  useEffect(() => {
    handleGetAllDeliveries();
  }, []);

  const handleSendOtpAfterPayment = async () => {
    try {
      const res = await apiServices.getFromApi(
        `c2c-shipments/generate-pickup-otp/${state?.jobDetails?.id}`,
        ""
      );
      if (res?.status === 200 || res?.status === 201) {
        Toast.showSuccess("OTP has send to you phone no");
        setState({
          showStripeForm: false,
          showOtpInput: true,
          openJobDialog: false,
        });
        handleGetAllDeliveries();
      }
    } catch (error) {}
  };
  const handleGetAllDeliveries = async () => {
    try {
      let resp = await apiServices.getFromApi(
        "c2c-shipments/get-by-customer",
        ""
      );

      if (resp?.status === 200 || resp?.status === 201) {
        setState({
          deliveries: resp?.data,
        });
      }
    } catch (error) {}
  };
  const onInputChange = (e: any) => {
    try {
      const { value, name } = e.target;
      if (value[0] === " ") {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {}
  };
  const handleViewDetails = async (data?: any) => {
    try {
      let resp = await apiServices.getFromApi(`c2c-shipments/${data?.id}`, "");
      if (resp?.status === 200 || resp?.status === 201) {
        setState({ jobDetails: resp?.data });
      }
      navigate(`/anounce/${data?.id}`)
    } catch (error) {
      console.log(error);
    }
  };
  const handlePickOrder = async () => {
    try {
      if (!state?.showOtpScreen) {
        setState({ showOtpScreen: true });
        return;
      }
      if (!otp || otp?.length < 4) {
        Toast.Error("Otp cannot be empty");
        return;
      }
      let obj = {
        ship_id: Number(state?.deliveryDetails?.id),
        ship_status: "Picked",
        ship_pickup_otp: Number(otp),
      };

      let resp = await apiServices.postFromApi(
        "c2c-shipments/update-status",
        obj,
        ""
      );
      if (resp?.status === 201 || resp?.status === 200) {
        Toast.showSuccess("Delivery Picked Successfully");
        setState({ showOtpScreen: false, openDeliveryDialog: false });
        handleGetAllDeliveries();
        setOtp("");
        return;
      } else {
        Toast.Error(
          resp?.data?.error ??
            resp?.error?.error ??
            resp?.data?.isEmail ??
            resp?.data?.isNotEmpty ??
            resp?.message ??
            "Something went wrong. Please wait a short while and try again. If problem persists then contact to support team."
        );
      }
    } catch (error) {}
  };
  const handleDeliverOrder = async () => {
    try {
      if (!state?.showOtpScreen) {
        setState({ showOtpScreen: true });
        return;
      }
      if (!otp || otp?.length < 4) {
        Toast.Error("Otp cannot be empty");
        return;
      }
      let obj = {
        ship_id: Number(state?.deliveryDetails?.id),
        ship_status: "Deliver",
        ship_deliver_otp: Number(otp),
      };

      let resp = await apiServices.postFromApi(
        "c2c-shipments/update-status",
        obj,
        ""
      );
      if (resp?.status === 201 || resp?.status === 200) {
        Toast.showSuccess("Delivery Picked Successfully");
        setState({ showOtpScreen: false, openDeliveryDialog: false });
        handleGetAllDeliveries();
        setOtp("");
        return;
      } else {
        Toast.Error(
          resp?.data?.error ??
            resp?.error?.error ??
            resp?.data?.isEmail ??
            resp?.data?.isNotEmpty ??
            resp?.message ??
            "Something went wrong. Please wait a short while and try again. If problem persists then contact to support team."
        );
      }
    } catch (error) {}
  };
  const stripePromise = loadStripe(
    "pk_test_51PHLwERrtK56w4VJovEUBRsfDb0K90Mo3tt7QrypEedKpj9HuTszcu8bnI8SnXsmA7EWSwZ87LorkcfAHi7zXz4l00kogJUBL5"
    // "pk_live_51PHLwERrtK56w4VJcMQLW2zbn7LTZPNZnBLUmFdMIakX5v5HKsPRllmYUMmWJXvmPVJaptcEN8uxmQdzDNVJYFUC0050uiD5gE"
  );

  useEffect(() => {
    if (
      state.jobDetails?.ship_status_level === 2 &&
      state.jobDetails?.payment_status !== "Paid"
    ) {
      initPayment();
    }
  }, [JSON.stringify(state.jobDetails)]);

  const initPayment = async () => {
    try {
      let total_amt = 0;
      if (state.jobDetails?.handling) {
        total_amt =
          state.jobDetails?.order_amount_detail?.sub_total > 0
            ? Number(state.jobDetails?.order_amount_detail?.sub_total ?? 0)
            : Number(
                state.jobDetails?.agree_ship_price ??
                  state.jobDetails?.ship_price ??
                  0
              ) +
              Number(
                (Number(
                  state.jobDetails?.agree_ship_price ??
                    state.jobDetails?.ship_price ??
                    0
                ) /
                  100) *
                  Number(state.jobDetails?.handling?.handling_charges_percent)
              ) +
              Number(state.jobDetails?.service_charges ?? 0);
      } else {
        total_amt =
          state.jobDetails?.order_amount_detail?.sub_total > 0
            ? Number(state.jobDetails?.order_amount_detail?.sub_total ?? 0) +
              Number(state.jobDetails?.service_charges ?? 0)
            : Number(
                state.jobDetails?.agree_ship_price ??
                  state.jobDetails?.ship_price ??
                  0
              ) + Number(state.jobDetails?.service_charges ?? 0);
      }

      const response = await apiServices.postFromApi(
        "stripe/payment-sheet-with-card",
        {
          ship_id: Number(state?.jobDetails?.id),
          amount: Number(total_amt.toFixed(2)),
        },
        ""
      );
      let decryptData = JSON.parse(decrypt(response.pi_key));
      if (typeof decryptData === "string") {
        decryptData = JSON.parse(decryptData);
      }
      const { setupIntent, paymentIntent, ephemeralKey, customer } =
        decryptData ?? {};

      const options = {
        theme: "stripe",

        clientSecret: paymentIntent,
        appearance: {
          theme: "stripe",
          variables: {
            fontFamily: '"Raleway-Variable", sans-serif',
          },
          rules: {
            ".Label": {
              fontFamily: '"Raleway-Variable", sans-serif',
              fontSize: "14px", // Adjust the size as needed
              color: "#000000", // Change the color if needed
            },
          },
        },
      };

      setState({ options, show_stripe: true });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Mes Annonces | Gérez vos Envois avec Frank</title>
        <link
          rel="canonical"
          href="https://www.v2internal.frankme.com/my-anounces"
        />
        <meta
          name="description"
          content="Consultez et gérez toutes vos annonces de livraison sur Frank. Suivez vos envois, modifiez vos offres, et rejoignez notre communauté de transporteurs."
        />
      </Helmet>
      <div className="web-screen">
        <div style={{ background: "#f9c9b3", height: "100%" }}>
          <Grid
            container
            spacing={2}
            className="display-flex"
            style={{
              background: "#F4C9B3",
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "30px",
            }}
          >
            <Grid xs={12} item style={{ zIndex: 99 }}>
              <NewHeader />
            </Grid>
          </Grid>
          <div style={{ padding: "10px 100px 0px 100px" }}>
            <Grid container spacing={2} style={{ padding: "30px" }}>
              <Grid
                xs={12}
                item
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <h1
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "35px",
                    fontWeight: "700",
                  }}
                >
                  {strings?.my_parcels}
                </h1>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                alignItems: "flex-start",
                padding: "20px 40px",
              }}
            >
              {state?.deliveries?.length < 1 ? (
                <img src={""} alt="none" style={{ opacity: "40%" }} />
              ) : (
                state?.deliveries?.map((i: any) => {
                  return (
                    <Grid xs={12} sm={12} md={4} lg={3} item>
                      <div
                        style={{
                          background: "#fff",
                          borderRadius: "16px",
                          padding: "20px",
                          // height: "250px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleViewDetails(i);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <p
                              style={{
                                fontSize: "16px",
                                fontFamily: "Raleway-Italic",
                                color: "#000",
                                fontWeight: "700",
                              }}
                              className="title-text-announces"
                            >
                              {handleLocalize({
                                en: i?.shipment_items[0]?.item_name_en,
                                fr: i?.shipment_items[0]?.item_name_fr,
                              }) ??
                                i?.shipment_items[0]?.prod_name ??
                                ""}
                            </p>
                          </div>
                          <div style={{}}>
                            <div
                              style={{
                                borderRadius: "8px",
                                padding: "5px",
                                fontFamily: "Raleway-Variable",
                                fontWeight: "600",
                                fontSize: "16px",
                                background: getStatusColors[i?.ship_status].bg,
                                color: getStatusColors[i?.ship_status].txt,
                              }}
                            >
                              {handleLocalize(
                                status_translations[i?.ship_status]
                              )}
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            height: "100px",
                            width: "100%",
                            borderRadius: "15px 15px 0px 0px",
                          }}
                        >
                          <img
                            src={
                              i?.shipment_items[0]?.prod_images[0] ??
                              "https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png"
                            }
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "contain",
                              borderRadius: "15px 15px 0px 0px",
                            }}
                            alt="prod-img"
                          />
                        </div>

                        <div
                          style={{
                            background: "#eef2f6",
                            borderRadius: "16px",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            marginTop: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              borderBottom: "1px solid #3026f1",
                            }}
                          >
                            <p
                              style={{
                                background: "#f9c9b3",
                                borderRadius: "32px",
                                padding: "10px 15px",
                              }}
                            >
                              <MapHomeIcon
                                fill={defaultLabelColors.main_blue}
                                height={20}
                                width={20}
                              />
                            </p>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "Raleway-Italic",
                                  fontSize: "16px",
                                }}
                              >
                                Depart
                              </span>
                              <span
                                style={{
                                  fontFamily: "Raleway-Italic",
                                  fontWeight: "700",
                                  wordBreak: "break-all",
                                  fontSize: "14px",
                                }}
                              >
                                {" "}
                                {i?.pickup?.city ?? "City"},
                                {i?.pickup?.country ?? "City"}
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <p
                              style={{
                                background: "#f9c9b3",
                                borderRadius: "32px",
                                padding: "10px 15px",
                              }}
                            >
                              <MapPointerIcon
                                fill={defaultLabelColors.main_blue}
                              />
                            </p>
                            <p
                              style={{
                                fontFamily: "Raleway-Italic",
                                fontSize: "16px",
                              }}
                            >
                              Destination
                              <br />
                              <span
                                style={{
                                  fontFamily: "Raleway-Italic",
                                  fontWeight: "700",
                                  wordBreak: "break-all",
                                  fontSize: "14px",
                                }}
                              >
                                {" "}
                                {i?.dropoff?.city ?? "City"},
                                {i?.dropoff?.country ?? "City"}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  );
                })
              )}
            </Grid>
          </div>
        </div>

        <div
          className="main-container"
          style={{ background: "#fff", marginTop: "100px" }}
        >
          <CustomFooter />
        </div>

        <DraggableDialog
          maxWidth={"sm"}
          open={state?.openDeliveryDialog}
          handleClose={() => {
            setState({ openDeliveryDialog: false, showOtpScreen: false });
            setOtp("");
          }}
          dialog_title={"Transporter un colis"}
          classes={classes}
          dialogBody={
            <DialogContent style={{}} dividers>
              <>
                <div
                  style={{
                    borderRadius: "16px",
                    border: "1px solid #E0E0E0",
                    padding: 15,
                    gap: 5,
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        flex: 1,
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                      }}
                    >
                      {strings?.sender_details}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                      }}
                    >
                      Membre depuis &nbsp;
                      {moment(
                        state?.deliveryDetails?.user_details?.created_at
                      ).format("YYYY") ?? "recently"}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        width: "15%",
                        paddingTop: "20px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          width: 140,
                          height: 100,
                          borderRadius: 100,
                          overflow: "hidden",
                          display: "flex",

                          boxShadow: 3,
                        }}
                      >
                        <img
                          src={
                            state?.deliveryDetails?.user_details?.profile_pic ??
                            "https://d2v5dzhdg4zhx3.cloudfront.net/web-assets/images/storypages/short/linkedin-profile-picture-maker/dummy_image/thumb/001.webp"
                          } // replace with your image path
                          alt="Profile"
                          style={{
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    </div>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-end",
                        paddingLeft: "15px",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <p
                          style={{
                            fontFamily: "Raleway-Variable",
                            fontSize: "18px",
                            fontWeight: 600,
                          }}
                        >
                          {state?.deliveryDetails?.user_details?.cus_name ??
                            "Customer Name"}
                        </p>
                        <Rating
                          disableFillHover
                          initialValue={
                            state?.deliveryDetails?.user_details?.cus_ratings ??
                            2
                          }
                          allowHover={false}
                          size={30}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    borderRadius: "16px",
                    border: "1px solid #E0E0E0",
                    padding: "0px 20px 20px 20px",
                    gap: 10,
                    marginTop: "10px",
                  }}
                >
                  <p
                    style={{ fontFamily: "Raleway-Variable", fontSize: "16px" }}
                  >
                    {strings?.type_of_delivery}
                  </p>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        flex: 1,
                        fontFamily: "Raleway-Variable",
                        fontWeight: "600",
                        fontSize: "24px",
                      }}
                    >
                      {state?.deliveryDetails?.ship_delivery_type ?? "Express"}
                      {handleLocalize({
                        en: state?.deliveryDetails?.ship_delivery_type
                          ?.item_name_en,
                        fr: state?.deliveryDetails?.ship_delivery_type
                          ?.item_name_fr,
                      }) ?? ""}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                      }}
                    >
                      Annonce créée le{" "}
                      {moment(state?.deliveryDetails?.created_at).format(
                        "DD/MM/YYYY"
                      )}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    borderRadius: "16px",
                    border: "1px solid #E0E0E0",
                    padding: "15px",
                    gap: 5,
                    marginTop: "10px",
                  }}
                >
                  <div
                    style={{
                      background: "#F4F3F6",
                      borderRadius: "24px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <div style={{ display: "flex", padding: "10px" }}>
                      <div style={{ flex: 1 }} className="display-flex">
                        <HomeIcon />
                      </div>
                      <div style={{}}>
                        <p
                          className="paragraph-text"
                          style={{ fontSize: "14px", lineHeight: "6px" }}
                        >
                          {strings?.pickup_address}
                        </p>
                        <p
                          className="paragraph-text"
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            margin: 0,
                          }}
                        >
                          {state?.deliveryDetails?.pickup?.address ??
                            "Paris, France"}
                          &nbsp;
                          {state?.deliveryDetails?.pickup?.city ?? ""},
                          {state?.deliveryDetails?.pickup?.country ?? "Paris"}
                        </p>
                      </div>
                    </div>

                    <div style={{ display: "flex", padding: "10px" }}>
                      <div style={{ flex: 1 }} className="display-flex">
                        <PointerIcon />
                      </div>
                      <div style={{ lineHeight: "5px" }}>
                        <p
                          className="paragraph-text"
                          style={{ fontSize: "14px" }}
                        >
                          {strings?.dropoff_address}
                        </p>
                        <p
                          className="paragraph-text"
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          {state?.deliveryDetails?.dropoff?.address ??
                            "Beausoleil, france"}
                          &nbsp;
                          {state?.deliveryDetails?.dropoff?.city ??
                            "Beausoleil, france"}
                          ,
                          {state?.deliveryDetails?.dropoff?.country ??
                            "Beausoleil, france"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    borderRadius: "16px",
                    border: "1px solid #E0E0E0",
                    padding: "0px 20px 20px 20px",
                    gap: 10,
                    marginTop: "10px",
                  }}
                >
                  <p
                    style={{ fontFamily: "Raleway-Variable", fontSize: "16px" }}
                  >
                    Produits & dimension
                  </p>

                  <div>
                    {state?.deliveryDetails?.shipment_items?.map((i: any) => {
                      return (
                        <div
                          className="card-container"
                          style={{ background: "#F4F4F6" }}
                        >
                          <img
                            src={i?.prod_images[0]}
                            className="card-image"
                            alt="prod-img"
                          />
                          <div className="card-details">
                            <div
                              className="card-title"
                              style={{ fontSize: 17 }}
                            >
                              {handleLocalize({
                                en: i?.item_name_en,
                                fr: i?.item_name_fr,
                              }) ??
                                i?.prod_name ??
                                ""}
                            </div>
                            <div className="card-dimensions">
                              {i?.prod_weight ?? ""}
                            </div>
                            <div className="card-quantity">
                              x{i?.prod_quantity}
                            </div>
                            {i?.prod_is_fragile ? (
                              <div
                                className="card-quantity"
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "Raleway-Variable",
                                  fontWeight: "500",
                                  marginTop: 5,
                                }}
                              >
                                <span style={{ fontWeight: 600 }}>Fragile</span>{" "}
                                : manipuler avec soin
                              </div>
                            ) : null}
                          </div>
                          <div
                            className="card-quantity"
                            style={{ paddingRight: "20px" }}
                          >
                            {i?.cat_name}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div
                  style={{
                    borderRadius: "16px",
                    border: "1px solid #E0E0E0",
                    padding: "20px 20px 20px 20px",
                    gap: 10,
                    marginTop: "10px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1, fontFamily: "Raleway-Variable" }}>
                      {strings?.delivery_cost}
                    </div>
                    <div style={{ fontFamily: "Raleway-Variable" }}>
                      {state?.deliveryDetails?.agree_ship_price
                        ? numberFormatWithoutCurrency(
                            state?.deliveryDetails?.agree_ship_price
                          )
                        : numberFormatWithoutCurrency(
                            state?.deliveryDetails?.ship_price
                          )}
                      €
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1, fontFamily: "Raleway-Variable" }}>
                      Commission
                    </div>
                    <div
                      style={{ fontFamily: "Raleway-Variable", color: "red" }}
                    >
                      -
                      {numberFormatWithoutCurrency(
                        state?.deliveryDetails?.order_amount_detail
                          ?.commission_amount
                      )}{" "}
                      €
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1, fontFamily: "Raleway-Variable" }}>
                      {strings?.tax_on_commission}
                    </div>
                    <div
                      style={{ fontFamily: "Raleway-Variable", color: "red" }}
                    >
                      -
                      {numberFormatWithoutCurrency(
                        state?.deliveryDetails?.order_amount_detail
                          ?.commission_tax_amount
                      )}{" "}
                      €
                    </div>
                  </div>

                  <div style={{ display: "flex", marginTop: "10px" }}>
                    <div
                      style={{
                        flex: 1,
                        fontFamily: "Raleway-Variable",
                        fontWeight: "600",
                      }}
                    >
                      {strings?.total}
                    </div>
                    <div
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontWeight: "600",
                      }}
                    >
                      {numberFormatWithoutCurrency(
                        state?.deliveryDetails?.ship_price -
                          state?.deliveryDetails?.order_amount_detail
                            ?.commission_amount -
                          state?.deliveryDetails?.order_amount_detail
                            ?.commission_tax_amount
                      )}{" "}
                      €
                    </div>
                  </div>
                </div>
                {state?.showOtpScreen ? (
                  <>
                    <Grid container spacing={2} className="display-flex">
                      <Grid
                        xs={12}
                        item
                        className="display-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <p
                          className="heading-one"
                          style={{ fontSize: "22px", textAlign: "center" }}
                        >
                          Insérez le code reçu par SMS
                        </p>
                        <OTPInput
                          inputStyle={{
                            fontFamily: "Raleway-Variable",
                            fontSize: "33px",
                            fontWeight: "900",
                            color: "#000",
                            border: "none",
                            background: "#F4F3F6",
                            borderRadius: "12px",
                            padding: "20px",
                          }}
                          inputType="number"
                          value={otp}
                          onChange={setOtp}
                          numInputs={4}
                          renderSeparator={
                            <span style={{ padding: "15px" }}></span>
                          }
                          renderInput={(props) => <input {...props} />}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : null}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    gap: "10px",
                    paddingTop: 10,
                  }}
                >
                  <CustomButton
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#000",
                      background: "#FFF",
                      border: "1px solid grey",
                      borderRadius: "12px",
                      padding: "10px 40px 10px 40px",
                      fontFamily: "Raleway-Variable",
                    }}
                    onClick={() => {
                      setState({
                        openDeliveryDialog: false,
                        showOtpScreen: false,
                      });
                      setOtp("");
                    }}
                    value={strings?.back}
                  />
                  {state?.deliveryDetails?.ship_status === "Picked" ? (
                    <CustomButton
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#fff",
                        fontFamily: "Raleway-Variable",
                        background: "#ee6931",
                        border: "none",
                        borderRadius: "12px",
                        padding: "10px 40px 10px 40px",
                      }}
                      onClick={handleDeliverOrder}
                      value={strings?.deliver_order}
                    />
                  ) : (
                    <CustomButton
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#fff",
                        fontFamily: "Raleway-Variable",
                        background: "#ee6931",
                        border: "none",
                        borderRadius: "12px",
                        padding: "10px 40px 10px 40px",
                      }}
                      onClick={handlePickOrder}
                      value={strings?.pick_order}
                    />
                  )}
                </div>
              </>
            </DialogContent>
          }
        />
        <DraggableDialog
          maxWidth={state?.showStripeForm ? "sm" : "md"}
          open={state?.openJobDialog}
          handleClose={() => {
            setState({ openJobDialog: false, showStripeForm: false });
          }}
          dialog_title={`${
            state?.showStripeForm ? strings.payments : "Transporter un colis"
          }`}
          classes={classes}
          dialogBody={
            <DialogContent
              style={
                {
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "center",
                  // flexDirection: "column",
                }
              }
              dividers
            >
              {state?.showStripeForm ? (
                <div style={{ gap: 10 }}>
                  <div>
                    <div>
                      {state?.jobDetails?.shipment_items?.map((i: any) => {
                        return (
                          <div
                            className="card-container"
                            style={{ marginTop: "10px" }}
                          >
                            <img
                              src={i?.prod_images[0]}
                              className="card-image"
                              alt="prod-img"
                            />
                            <div className="card-details">
                              <div
                                className="card-title"
                                style={{ fontSize: 17 }}
                              >
                                {i?.prod_name ?? ""}
                              </div>
                              <div className="card-dimensions">
                                {i?.prod_weight ?? ""}
                              </div>
                              <div className="card-quantity">
                                x{i?.prod_quantity}
                              </div>
                              {i?.prod_is_fragile ? (
                                <div
                                  className="card-quantity"
                                  style={{
                                    fontSize: "14px",
                                    fontFamily: "Raleway-Variable",
                                    fontWeight: "500",
                                    marginTop: 5,
                                  }}
                                >
                                  <span style={{ fontWeight: 600 }}>
                                    Fragile
                                  </span>{" "}
                                  : manipuler avec soin
                                </div>
                              ) : null}
                            </div>
                            <div
                              className="card-quantity"
                              style={{ paddingRight: "20px" }}
                            >
                              {state?.jobDetails?.agree_ship_price
                                ? numberFormatWithoutCurrency(
                                    state?.jobDetails?.agree_ship_price
                                  )
                                : numberFormatWithoutCurrency(
                                    state?.jobDetails?.ship_price
                                  )}{" "}
                              €
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <Elements stripe={stripePromise} options={state.options}>
                      <StripeCheckout
                        handleOtpSend={handleSendOtpAfterPayment}
                      />
                    </Elements>
                  </div>
                </div>
              ) : (
                <>
                  {state?.jobDetails?.acc_name ? (
                    <div
                      style={{
                        borderRadius: "16px",
                        border: "1px solid #E0E0E0",
                        padding: 20,
                        gap: 10,
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            flex: 1,
                            fontFamily: "Raleway-Variable",
                            fontSize: "16px",
                          }}
                        >
                          Transporteur Détails
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            fontFamily: "Raleway-Variable",
                            fontSize: "16px",
                          }}
                        >
                          Membre depuis
                          <span
                            style={{ fontWeight: "600", paddingLeft: "5px" }}
                          >
                            {" "}
                            {moment(
                              state?.jobDetails?.user_details?.created_at
                            ).format("YYYY") ?? "recently"}
                          </span>
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            width: "15%",
                            paddingTop: "20px",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              position: "relative",
                              width: 100,
                              height: 100,
                              borderRadius: 100,
                              overflow: "hidden",
                              display: "flex",

                              boxShadow: 3,
                            }}
                          >
                            <img
                              src={
                                state?.jobDetails?.acc_picture ??
                                "https://d2v5dzhdg4zhx3.cloudfront.net/web-assets/images/storypages/short/linkedin-profile-picture-maker/dummy_image/thumb/001.webp"
                              } // replace with your image path
                              alt="Profile"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </Box>
                        </div>
                        <div
                          style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "flex-end",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <p
                              style={{
                                fontFamily: "Raleway-Variable",
                                fontSize: "22px",
                                fontWeight: 600,
                              }}
                            >
                              {state?.jobDetails?.acc_name ?? "Accepter Name"}
                            </p>
                            <Rating
                              disableFillHover
                              readonly={true}
                              initialValue={
                                state?.jobDetails?.user_details?.cus_ratings ??
                                2
                              }
                              allowHover={false}
                              size={40}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div
                    style={{
                      borderRadius: "16px",
                      border: "1px solid #E0E0E0",
                      padding: "0px 20px 20px 20px",
                      gap: 10,
                      marginTop: "10px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                      }}
                    >
                      Type de livraison
                    </p>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          flex: 1,
                          fontFamily: "Raleway-Variable",
                          fontWeight: "600",
                          fontSize: "24px",
                        }}
                      >
                        {state?.jobDetails?.ship_delivery_type ?? "Express"}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontFamily: "Raleway-Variable",
                          fontSize: "16px",
                        }}
                      >
                        Annonce créée le{" "}
                        {moment(state?.jobDetails?.created_at).format(
                          "DD/MM/YYYY"
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      borderRadius: "16px",
                      border: "1px solid #E0E0E0",
                      padding: "15px",
                      gap: 10,
                      marginTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        background: "#F4F3F6",
                        borderRadius: "24px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{ display: "flex", padding: "10px", gap: 10 }}
                      >
                        <div style={{ flex: 1 }} className="display-flex">
                          <HomeIcon height={36} />
                        </div>
                        <div>
                          <p
                            className="paragraph-text"
                            style={{ fontSize: "14px" }}
                          >
                            {strings?.pickup_address}
                          </p>
                          <p
                            className="paragraph-text"
                            style={{ fontSize: "14px", fontWeight: "600" }}
                          >
                            {state?.jobDetails?.pickup?.address ??
                              "Paris, France"}
                            &nbsp;
                            {state?.jobDetails?.pickup?.city ?? ""},
                            {state?.jobDetails?.pickup?.country ?? "Paris"}
                          </p>
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", padding: "10px", gap: 10 }}
                      >
                        <div style={{ flex: 1 }} className="display-flex">
                          <PointerIcon height={36} />
                        </div>
                        <div>
                          <p
                            className="paragraph-text"
                            style={{ fontSize: "14px" }}
                          >
                            {strings?.dropoff_address}
                          </p>
                          <p
                            className="paragraph-text"
                            style={{ fontSize: "14px", fontWeight: "600" }}
                          >
                            {state?.jobDetails?.dropoff?.address ??
                              "Beausoleil, france"}
                            &nbsp;
                            {state?.jobDetails?.dropoff?.city ??
                              "Beausoleil, france"}
                            ,
                            {state?.jobDetails?.dropoff?.country ??
                              "Beausoleil, france"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      borderRadius: "16px",
                      border: "1px solid #E0E0E0",
                      padding: "0px 20px 20px 20px",
                      gap: 10,
                      marginTop: "10px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                      }}
                    >
                      {strings?.item_sizes}
                    </p>

                    <div>
                      {state?.jobDetails?.shipment_items?.map((i: any) => {
                        return (
                          <div
                            className="card-container"
                            style={{ marginTop: "5px" }}
                          >
                            <img
                              src={i?.prod_images[0]}
                              className="card-image"
                              alt="prod-img"
                            />
                            <div className="card-details">
                              <div
                                className="card-title"
                                style={{ fontSize: 17 }}
                              >
                                {handleLocalize({
                                  en: i?.item_name_en,
                                  fr: i?.item_name_fr,
                                }) ??
                                  i?.prod_name ??
                                  ""}
                              </div>

                              <div className="card-quantity">
                                x{i?.prod_quantity}
                              </div>
                              {i?.prod_is_fragile ? (
                                <div
                                  className="card-quantity"
                                  style={{
                                    fontSize: "14px",
                                    fontFamily: "Raleway-Variable",
                                    fontWeight: "500",
                                    marginTop: 5,
                                  }}
                                >
                                  <span style={{ fontWeight: 600 }}>
                                    Fragile
                                  </span>{" "}
                                  : manipuler avec soin
                                </div>
                              ) : null}
                            </div>
                            <div
                              className="card-quantity"
                              style={{ paddingRight: "20px" }}
                            >
                              {i?.cat_name}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div
                    style={{
                      borderRadius: "16px",
                      border: "1px solid #E0E0E0",
                      padding: "20px 20px 20px 20px",
                      gap: 10,
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          flex: 1,
                          fontFamily: "Raleway-Variable",
                          fontWeight: "700",
                          fontSize: "18px",
                        }}
                      >
                        {strings?.delivery_cost}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: "600",
                          fontFamily: "Raleway-Variable",
                          fontSize: "18px",
                          color: defaultLabelColors.main_blue,
                        }}
                      >
                        {state?.jobDetails?.agree_ship_price
                          ? numberFormatWithoutCurrency(
                              state?.jobDetails?.agree_ship_price
                            )
                          : numberFormatWithoutCurrency(
                              state?.jobDetails?.ship_price
                            )}{" "}
                        €
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          flex: 1,
                          fontFamily: "Raleway-Variable",
                          fontWeight: "700",
                          fontSize: "18px",
                        }}
                      >
                        {strings?.service_charges}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: "600",
                          fontFamily: "Raleway-Variable",
                          fontSize: "18px",
                          color: defaultLabelColors.main_blue,
                        }}
                      >
                        {state?.jobDetails?.service_charges ?? "1.99"} €
                      </div>
                    </div>
                    <div style={{ display: "flex", marginTop: "10px" }}>
                      <div
                        style={{
                          flex: 1,
                          fontFamily: "Raleway-Variable",
                          fontWeight: "700",
                          fontSize: "24px",
                        }}
                      >
                        {strings?.total}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: "600",
                          fontFamily: "Raleway-Variable",
                          fontSize: "22px",
                          color: defaultLabelColors.main_blue,
                        }}
                      >
                        {state?.jobDetails?.agree_ship_price
                          ? state?.jobDetails?.agree_ship_price +
                            state?.jobDetails?.service_charges
                          : state?.jobDetails?.ship_price +
                            state?.jobDetails?.service_charges}{" "}
                        €
                      </div>
                    </div>
                  </div>

                  {state?.jobDetails?.payment_status === "Paid" &&
                  state?.jobDetails?.ship_pickup_otp ? (
                    state?.jobDetails?.ship_status === "Accepted" ? (
                      <div
                        style={{
                          borderRadius: "16px",
                          border: "1px solid #E0E0E0",
                          padding: "20px 20px 20px 20px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                            fontFamily: "Raleway-Variable",
                            fontWeight: "700",
                            fontSize: "24px",
                            textAlign: "center",
                          }}
                        >
                          {"Your Pickup Otp"}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <OTPInput
                            inputStyle={{
                              fontFamily: "Raleway-Variable",
                              fontSize: "33px",
                              fontWeight: "900",
                              color: "#000",
                              border: "none",
                              background: "#F4F3F6",
                              borderRadius: "12px",
                              padding: "20px",
                            }}
                            inputType="number"
                            value={state?.jobDetails?.ship_pickup_otp}
                            onChange={setOtp}
                            numInputs={4}
                            renderSeparator={
                              <span style={{ padding: "15px" }}></span>
                            }
                            renderInput={(props) => <input {...props} />}
                          />
                        </div>
                      </div>
                    ) : state?.jobDetails?.ship_status === "Picked" ? (
                      <div
                        style={{
                          borderRadius: "16px",
                          border: "1px solid #E0E0E0",
                          padding: "20px 20px 20px 20px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                            fontFamily: "Raleway-Variable",
                            fontWeight: "700",
                            fontSize: "24px",
                            textAlign: "center",
                          }}
                        >
                          {"Your Delivery Otp"}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <OTPInput
                            inputStyle={{
                              fontFamily: "Raleway-Variable",
                              fontSize: "33px",
                              fontWeight: "900",
                              color: "#000",
                              border: "none",
                              background: "#F4F3F6",
                              borderRadius: "12px",
                              padding: "20px",
                            }}
                            inputType="number"
                            value={state?.jobDetails?.ship_deliver_otp}
                            onChange={setOtp}
                            numInputs={4}
                            renderSeparator={
                              <span style={{ padding: "15px" }}></span>
                            }
                            renderInput={(props) => <input {...props} />}
                          />
                        </div>
                      </div>
                    ) : null
                  ) : null}

                  {state?.jobDetails?.ship_status_level === 2 &&
                  state?.jobDetails?.payment_status !== "Paid" ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: "10px 0px 10px 0px",
                      }}
                    >
                      <CustomButton
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#fff",
                          fontFamily: "Raleway-Variable",
                          background: "#ee6931",
                          border: "none",
                          borderRadius: "12px",
                          padding: "10px 40px 10px 40px",
                        }}
                        onClick={() => {
                          setState({ showStripeForm: true });
                        }}
                        value={strings?.continue_payment}
                      />
                    </div>
                  ) : null}
                </>
              )}
            </DialogContent>
          }
        />
      </div>
    </>
  );
}

export default MyAnnounces;
