import React, { useCallback, useEffect } from "react";
import Loader from "../../../UiComponent/Loader";
import HeaderForMarketPlace from "../../../UiComponent/HeaderForMarketPlace";
import { defaultLabelColors } from "../../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import apiServices from "../../../services/RequestHandler";
import { Rating } from "react-simple-star-rating";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import InputField from "../../../UiComponent/TextFeild";
import { Search } from "@mui/icons-material";
import { styled } from "@mui/system";
import AllProductByParticular from "./AllProductByParticular";
import { getDataFromLocalStorage } from "../../../utils/LocalStore.util";
import { numberFormatWithoutCurrencyFixedOne } from "../../../utils/helper";
import { debounce } from "lodash";
import CustomFooter from "../../../UiComponent/CustomFooter";
export const CustomTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#3026f1", // Indicator color
  },
});

export const CustomTab = styled(Tab)({
  fontFamily: "Raleway-Variable",
  fontWeight: 700,
  fontSize: "16px",
  textTransform: "capitalize",
  color: "#626175", // Inactive tab text color
  "&.Mui-selected": {
    color: "#3026f1", // Active tab text color
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
function ParticularShopDetails(props: any) 
{
  const params = useParams();
  const navigate = useNavigate()
  const [value, setValue] = React.useState(0);
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      isFavorited: false,
      activity: false,
      shop_id: params?.id,
      user: getDataFromLocalStorage("user"),
      global_search: "",
    }
  );

  useEffect(() => {
    handleGetParticularShopDetails();
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleGetParticularShopDetails = async () => {
    try {
      let resp = await apiServices.postFromApi(
        `c2c/particular-details`,
        { owner_id: Number(params?.id), user: state?.user?.id },
        ""
      );

      if (resp?.status === 200 || resp?.status === 201) {
        handleGetProductbyParticular()
        let avg_rating_ = resp.data?.ratings2?.reduce((a: any, b: any) => {
          return a + b.rating;
        }, 0);
        let avg_rating = isNaN(avg_rating_ / resp?.data?.ratings2?.length)
          ? 0
          : avg_rating_ / resp?.data?.ratings2?.length;
        setState({
          details: {
            ...resp.data,
            avg_rating,
          },
        });
        setState({
          shopInfo: resp?.data,
        });
      }
    } catch (error) {}
  };
  const handleGetProductbyParticular = async () => {
    try {
      let resp = await apiServices.postFromApi(
        `c2c-product-masters/products-by-particular`,
        { owner_id: params?.id },
        ""
      );
      if (resp?.status === 200 || resp?.status === 201) {
        setState({
          shopProducts: resp?.data,
        });
      }

      console.log("resp", resp);
    } catch (error) {}
  };
  const handleSearchProducts = useCallback(
    debounce(async (searchValue: any) => {
      try {
        if (!searchValue) {
          handleGetProductbyParticular();
        }
        if (searchValue?.trim()) {
          let resp = await apiServices.postFromApi(
            "c2c-product-masters/particular/search-by-owner",
            { owner_id: params?.id, search: searchValue },
            ""
          );

          setState({
            shopProducts: resp?.data,
          });
        }
      } catch (error) {
        console.error("Error while searching products:", error);
      }
    }, 1500),
    [params?.id]
  );
  useEffect(() => {
    handleSearchProducts(state.searched_prod);
  }, [state.searched_prod, handleSearchProducts]);

  const onInputChangeForHeader = (e: any) => {
    try {
      const { value, name } = e.target;

      const validValue = value.replace(/[^1-9a-zA-Z]/g, "");

      setState({
        [name]: validValue,
      });
       
    } catch (error) {}
  };

  const { shopInfo } = state;
  return (
    <div>
      <div style={{ background: defaultLabelColors.main_background_two, paddingBottom: 20, height: "100%" }}>
        <Loader loading={state?.activity} />
        <Grid container>
          <Grid xs={12} item>
            <HeaderForMarketPlace {...props} {...state} onInputChangeForHeader={onInputChangeForHeader} />
          </Grid>
        </Grid>
        <div style={{ padding: "20px 60px" }}>
          <Grid container>
            <Grid
              xs={12}
              item
              style={{
                background: defaultLabelColors.main_orange,
                padding: "20px 60px",
                borderRadius: "12px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <p
                  style={{
                    fontFamily: "Raleway-Bold",
                    fontSize: 36,
                    color: "#fff",
                  }}
                >
                  Les produits de{" "}
                  {shopInfo?.user_name ??
                    shopInfo?.surname ??
                    shopInfo?.first_name ??
                    ""}{" "}
                  -{" "}
                  {numberFormatWithoutCurrencyFixedOne(
                    shopInfo?.positive_reviews
                  )}{" "}
                  <Rating emptyColor="#fff" fillIcon={1} iconsCount={1} />
                </p>
              </div>
              <div>
                <InputField
                  placeHolder={`Chercher un produit chez ${shopInfo?.user_name} `}
                  input_value={state?.searched_prod}
                  input_name={"searched_prod"}
                  input_style={{
                    background: "#F4F3F6",
                    minWidth: "200px",
                    width: "100%",
                    maxWidth: "772px",
                    borderRadius: "12px",
                  }}
                  onchange={(e) => {
                    const validValue = e.target.value.replace(/[^1-9a-zA-Z]/g, "");

                    setState({ searched_prod: validValue });
                  }}
                   startIcon={<Search style={{ opacity: "50%" }} />}
                />
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            className="display-flex"
            style={{ padding: "30px 30px 30px 30px" }}
          >
            <Grid xs={12} item>
              <Box sx={{ width: "100%" }}>
                <CustomTabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <CustomTab label="Profil" />
                  {/* <CustomTab label="Evaluations" /> */}
                </CustomTabs>

                <CustomTabPanel value={value} index={0}>
                  <AllProductByParticular handleGetProductbyParticular={handleGetProductbyParticular} {...state} {...props} />
                </CustomTabPanel>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>

      <div
          className="main-container"
          style={{ background: defaultLabelColors.main_background_two }}
        >
          <CustomFooter />
        </div>
    </div>
  );
}

export default ParticularShopDetails;
