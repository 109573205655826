import { ArrowBackIosNew } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Box } from "@mui/system";

import moment from "moment";
import OTPInput from "react-otp-input";

import { useTheme } from "@mui/styles";
import ReactQuill from "react-quill";
import { BoXIcon, DotIcon } from "../../../assets/images/FrankIcons";
import apiServices from "../../../services/RequestHandler";
import { RootState } from "../../../Store";
import { strings } from "../../../Store/Localization";
import CustomFooter from "../../../UiComponent/CustomFooter";
import Loader from "../../../UiComponent/Loader";
import NewHeader from "../../../UiComponent/NewHeader";
import Toast from "../../../UiComponent/Toast/Toast";
import { defaultLabelColors } from "../../../utils/constants";
import { decrypt } from "../../../utils/Encrypt";
import {
  handleLocalize,
  numberFormatWithoutCurrency,
} from "../../../utils/helper";
import { getDataFromLocalStorage } from "../../../utils/LocalStore.util";

function ParticularMyListedProduct(props: any) {
  const { classes } = props;
  const [otp, setOtp] = React.useState("");
  const selector = useSelector((state: RootState) => state.mainApp);
  const [selectedImage, setSelectedImage] = useState("");
  const theme = useTheme();
  const param = useParams();
  const navigate = useNavigate();
  const [isShaking, setIsShaking] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const user = getDataFromLocalStorage("user");
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      showPickupOtpScreen: false,
      openStripeForm: false,
      openConfirmDialog: false,
      ratingsCount: 1,
      itemDetailsDialog: false,
    }
  );
  const toggleDescription = () => {
    if (showFullDescription === true) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    setShowFullDescription(!showFullDescription);
  };
  const handleGetProdInfo = async (job_id: any) => {
    try {
      setState({ activity: true });
      const resp = await apiServices.postFromApi(
        `c2c-product-masters/particular/details`,
        { user: user?.id, id: param?.id },
        ""
      );
      const data = resp.data;
      setSelectedImage(data?.images?.[0]);
      setState({
        myProd_details: data,
        activity: false,
      });
    } catch (error) {
      setState({
        activity: false,
      });
      console.log(error);
    }
  };
  const [expanded, setExpanded] = useState<Record<number, boolean>>({});

  const toggleViewMore = (index: any) => {
    setExpanded((prev) => ({
      ...prev,
      [index]: !prev[index] as any,
    }));
  };
  const onInputChange = (e: any) => {
    try {
      const { value, name } = e.target;
      if (value[0] === " ") {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {}
  };

  const handleDeleteShipment = async () => {
    try {
      let resp = await apiServices.postFromApi(
        `c2c-shipments/delete/${state?.myProd_details?.id}`,
        {},
        ""
      );
      if (resp?.status === 200 || resp?.status === 201) {
        setState({ openConfirmDialog: false });
        Toast.showSuccess("Annouce Deleted Succesfully");
        navigate(-1);
      }
    } catch (error) {}
  };
  const getItemDetails = (item: any) => {
    try {
      console.log("getItemDetails", item);
      setSelectedImage(item?.prod_images?.[0]);
      setState({
        itemDetails: item,
        itemDetailsDialog: true,
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (
      state.myProd_details?.ship_status_level === 2 &&
      state.myProd_details?.payment_status !== "Paid"
    ) {
      initPayment();
    }
  }, [JSON.stringify(state.myProd_details)]);

  const handleSendOtpAfterPayment = async () => {
    try {
      const res = await apiServices.getFromApi(
        `c2c-shipments/generate-pickup-otp/${state?.myProd_details?.id}`,
        ""
      );
      if (res?.status === 200 || res?.status === 201) {
        Toast.showSuccess("OTP has send to you phone no");
        setState({
          openStripeForm: false,
        });
        handleGetProdInfo(param?.id);
      }
    } catch (error) {}
  };

  const initPayment = async () => {
    try {
      let total_amt = 0;
      if (state.myProd_details?.handling) {
        total_amt =
          state.myProd_details?.order_amount_detail?.sub_total > 0
            ? Number(state.myProd_details?.order_amount_detail?.sub_total ?? 0)
            : Number(
                state.myProd_details?.agree_ship_price ??
                  state.myProd_details?.ship_price ??
                  0
              ) +
              Number(
                (Number(
                  state.myProd_details?.agree_ship_price ??
                    state.myProd_details?.ship_price ??
                    0
                ) /
                  100) *
                  Number(
                    state.myProd_details?.handling?.handling_charges_percent
                  )
              ) +
              Number(state.myProd_details?.service_charges ?? 0);
      } else {
        total_amt =
          state.myProd_details?.order_amount_detail?.sub_total > 0
            ? Number(
                state.myProd_details?.order_amount_detail?.sub_total ?? 0
              ) + Number(state.myProd_details?.service_charges ?? 0)
            : Number(
                state.myProd_details?.agree_ship_price ??
                  state.myProd_details?.ship_price ??
                  0
              ) + Number(state.myProd_details?.service_charges ?? 0);
      }

      const response = await apiServices.postFromApi(
        "stripe/payment-sheet-with-card",
        {
          ship_id: Number(state?.myProd_details?.id),
          amount: Number(total_amt.toFixed(2)),
        },
        ""
      );
      let decryptData = JSON.parse(decrypt(response.pi_key));
      if (typeof decryptData === "string") {
        decryptData = JSON.parse(decryptData);
      }
      const { setupIntent, paymentIntent, ephemeralKey, customer } =
        decryptData ?? {};

      const options = {
        theme: "stripe",

        clientSecret: paymentIntent,
        appearance: {
          theme: "stripe",
          variables: {
            fontFamily: '"Raleway-Variable", sans-serif',
          },
          rules: {
            ".Label": {
              fontFamily: '"Raleway-Variable", sans-serif',
              fontSize: "14px", // Adjust the size as needed
              color: "#000000", // Change the color if needed
            },
          },
        },
      };

      setState({ options, show_stripe: true });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetProdInfo(param?.id);
  }, [param?.id]);

  const triggerShake = () => {
    setIsShaking(true);
    setTimeout(() => {
      setIsShaking(false);
    }, 500);
  };
  const handleAddFeedback = async () => {
    try {
      let obj = {
        order_id: Number(state.myProd_details?.id),
        rating_to_id: `${state.myProd_details?.acc_id}`,
        rating_from_id: `${state.myProd_details?.user_details?.id}`,
        rating: state?.ratingsCount ?? 1,
        feedback: state?.feedback,
      };

      let resp = await apiServices.postFromApi(
        "c2c-feedbacks",
        { data: [obj] },
        ""
      );

      if (resp?.status === 200 || resp?.status === 201) {
        handleGetProdInfo(state.myProd_details?.id);
        setState({
          feedbackDialog: false,
        });
      }
    } catch (error) {}
  };
  const { myProd_details } = state;
  return (
    <div className="web-screen">
      <Loader loading={state?.activity} />
      <div style={{ background: "#FCE6DD", minHeight: "100vh" }}>
        <Grid
          container
          spacing={2}
          className="display-flex"
          style={{
            background: "#FCE6DD",
            paddingLeft: "40px",
            paddingRight: "40px",
            paddingTop: "30px",
          }}
        >
          <Grid xs={12} item style={{ zIndex: 99 }}>
            <NewHeader />
          </Grid>
        </Grid>
        <div style={{ padding: "30px 80px 50px 80px" }}>
          <Grid container spacing={2} style={{ paddingBottom: "40px" }}>
            <Grid
              xs={12}
              item
              style={{
                display: "flex",
                fontFamily: "Raleway-Variable",
                fontSize: "28px",
                color: "#4B4A61",
                gap: 10,
                alignItems: "center",
              }}
            >
              <ArrowBackIosNew
                onClick={() => {
                  navigate(-1);
                }}
                style={{ cursor: "pointer" }}
              />{" "}
              Récapitulatif de la commande
              <DotIcon />
              <span
                style={{
                  fontFamily: "Raleway-Bold",
                  color: defaultLabelColors.main_orange,
                  fontSize: "28px",
                }}
              >
                {"Mes ventes"}
              </span>
              <DotIcon />
              <h1
                style={{
                  fontFamily: "Raleway-Bold",
                  fontSize: "28px",
                  color: "#281208",
                }}
              >
                {handleLocalize({
                  en: myProd_details?.product_title?.en,
                  fr: myProd_details?.product_title?.fr,
                }) ?? ""}{" "}
              </h1>
              <DotIcon />
              <span
                style={{
                  fontSize: "28px",
                  color: "#281208",
                  fontFamily: "Raleway-Bold",
                }}
              >
                n°{myProd_details?.id}
              </span>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            style={{
              background: "#fff",
              borderRadius: "16px",
              padding: "20px",
              gap: 20,
              minHeight: "110vh",
            }}
          >
            <Grid
              xs={12}
              md={5.8}
              item
              style={{
                borderRadius: "16px",
                height: "100%",
                padding: 0,
              }}
            >
              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                }}
              >
                <Box
                  position="relative"
                  sx={{ borderRadius: "32px 32px 0px 0px" }}
                >
                  <div style={{ height: "480px" }} className="display-flex">
                    <CardMedia
                      component="img"
                      image={selectedImage ?? ""}
                      alt="Product Image"
                      sx={{
                        objectFit: "contain",
                        width: "98%",
                        height: "460px",
                        borderRadius: "32px 32px 0px 0px",
                      }}
                    />
                  </div>
                  {myProd_details?.is_particular && (
                    <div
                      style={{
                        position: "absolute",
                        bottom: "20px",
                        right: "20px",
                        height: "20px",
                        padding: 10,
                        display: "flex",
                        fontFamily: "Raleway-Bold",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#F5D9BC",
                        borderRadius: 5,
                        color: "#281208",
                        fontSize: "18px",
                      }}
                    >
                      {" "}
                      Seconde main
                    </div>
                  )}
                </Box>

                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "30px",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <Typography
                        color="#281208"
                        sx={{
                          fontFamily: "Raleway-Bold",
                          fontSize: "24px",
                        }}
                      >
                        {handleLocalize({
                          en: myProd_details?.product_title?.en,
                          fr: myProd_details?.product_title?.fr,
                        }) ?? ""}{" "}
                      </Typography>
                    </div>

                    <div
                      style={{
                        color: defaultLabelColors.main_orange,
                        fontFamily: "Raleway-Bold",
                        fontSize: "24px",
                      }}
                    >
                      {" "}
                      {numberFormatWithoutCurrency(
                        myProd_details?.base_price
                      )}{" "}
                      €
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 5,
                      maxWidth: "100%",
                    }}
                  >
                    {state?.item_images?.map((item: any) => {
                      return (
                        <div
                          className="display-flex"
                          style={{
                            height: "116px",
                            width: "116px",
                            cursor: "pointer",
                            borderRadius: "12px",
                            border: `1px solid ${
                              selectedImage === item?.url
                                ? defaultLabelColors.main_orange
                                : "grey"
                            } `,
                          }}
                          onClick={() => {
                            const filtered_variation =
                              state.myProd_details?.product_variations?.filter(
                                (i: any) =>
                                  Number(i.id) === Number(item?.variation_id)
                              );
                            console.log(
                              "filtered_variation",
                              filtered_variation
                            );

                            setState({
                              display_sel_variant: filtered_variation?.[0],
                            });
                            setSelectedImage(item?.url);
                          }}
                        >
                          <img
                            src={item?.url}
                            alt="prod-img"
                            style={{ objectFit: "contain", height: "110px" }}
                          />
                        </div>
                      );
                    })}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    {myProd_details?.images?.map((item: any) => {
                      return (
                        <div
                          className="display-flex"
                          style={{
                            height: "116px",
                            width: "116px",
                            cursor: "pointer",
                            borderRadius: "12px",
                            border: `2px solid ${
                              selectedImage === item
                                ? defaultLabelColors.main_orange
                                : "grey"
                            } `,
                          }}
                          onClick={() => {
                            setSelectedImage(item);
                          }}
                        >
                          <img
                            src={item}
                            alt="prod-img"
                            style={{
                              objectFit: "contain",
                              height: "110px",
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              xs={12}
              md={5.8}
              item
              style={{
                borderRadius: "16px",
                height: "100%",
                padding: 0,
              }}
            >
              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                  padding: "10px 16px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <div style={{ flex: 1, width: "90%" }}>
                    <p
                      style={{
                        fontSize: "18px",
                        fontFamily: "Raleway-Bold",
                      }}
                    >
                      {handleLocalize({
                        en: myProd_details?.product_title?.en,
                        fr: myProd_details?.product_title?.fr,
                      }) ?? ""}{" "}
                    </p>
                  </div>
                  <div
                    style={{
                      color: defaultLabelColors.main_orange,
                      fontFamily: "Raleway-Bold",
                      fontSize: "24px",
                    }}
                  >
                    {numberFormatWithoutCurrency(myProd_details?.base_price)} €
                  </div>
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    fontFamily: "Raleway-Variable",
                    color: "#4B4A61",
                  }}
                >
                  Mise en ligne le{" "}
                  {moment(myProd_details?.created_at).format("DD/MM/YY")}
                  <br />
                </div>
              </Card>

              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                  padding: "15px 15px",
                  marginTop: "15px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      flex: 1,
                      fontFamily: "Raleway-Variable",
                      fontWeight: "700",
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <BoXIcon /> Mode de retrait disponible
                  </div>
                </div>

                <p style={{ fontFamily: "Raleway-Variable", fontSize: 16 }}>
                  Livraison possible
                  <br />
                  Remise en main propre
                </p>
              </Card>

              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                  padding: "15px 15px",
                  marginTop: "15px",
                }}
              >
                <p
                  style={{
                    fontFamily: "Raleway-Semi-Bold",
                    display: "flex",
                    margin: 0,
                  }}
                >
                  Catégorie : &nbsp;
                  {handleLocalize({
                    en: myProd_details?.category_name?.en,
                    fr: myProd_details?.category_name?.fr,
                  })}
                </p>
                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    fontWeight: 600,
                    gap: 10,
                    fontSize: 14,
                    margin: 0,
                    marginTop: 5,
                  }}
                >
                  <BoXIcon /> Marque :{" "}
                  {handleLocalize({
                    en: myProd_details?.brand_name?.en,
                    fr: myProd_details?.brand_name?.fr,
                  })}
                </p>
                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    fontWeight: 600,
                    gap: 10,
                    fontSize: 14,
                    margin: 0,
                    marginTop: 5,
                  }}
                >
                  <BoXIcon /> État :{" "}
                  {handleLocalize(myProd_details?.used_condition) ?? "N/A"}
                </p>
              </Card>

              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                  marginTop: "15px",
                  padding: "15px",
                }}
              >
                <p
                  style={{
                    fontSize: "18px",
                    fontFamily: "Raleway-Variable",
                    fontWeight: "600",
                  }}
                >
                  Description
                </p>
                <div style={{ margin: 10 }}>
                  <table
                    style={{
                      border: myProd_details?.product_specifications
                        ? "1px solid #ddd"
                        : "none",
                      padding: "5px",
                      gap: 5,
                      width: "100%",
                    }}
                  >
                    {myProd_details?.product_specifications?.map(
                      (i: any, idx: any) => {
                        return (
                          <tr key={idx}>
                            <td
                              style={{
                                background: "lightgrey",
                                fontFamily: "Raleway-Bold",
                                padding: 5,
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              {/* {i?.specification_name?.en} */}
                            </td>
                            {i?.product_specification_values?.map((j: any) => {
                              return (
                                <td
                                  style={{
                                    fontFamily: "Raleway-Variable",
                                    textAlign: "left",
                                    width: "70%",
                                  }}
                                >
                                  {/* {j?.specification_value?.en}{" "} */}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      }
                    )}
                  </table>
                </div>
                {myProd_details?.product_desc ? (
                  <Box
                    sx={{
                      maxHeight: showFullDescription ? "none" : "80vh",
                      overflow: showFullDescription ? "visible" : "hidden",
                      position: "relative",
                    }}
                  >
                    <ReactQuill
                      value={myProd_details?.product_desc}
                      readOnly
                      theme="snow"
                      modules={{ toolbar: false }}
                      style={{ pointerEvents: "none", border: "none" }}
                    />
                  </Box>
                ) : null}

                {!showFullDescription && (
                  <Box
                    sx={{
                      textAlign: "center",
                      background:
                        "linear-gradient(to bottom, rgba(255,255,255,0), white)",
                      padding: "10px 0",
                    }}
                  >
                    {myProd_details?.product_desc?.length > 1000 && (
                      <Button
                        variant="contained"
                        onClick={toggleDescription}
                        sx={{
                          borderRadius: "12px",
                          textTransform: "none",
                          fontFamily: "Raleway-Variable",
                          fontSize: "14px",
                          fontWeight: "600",
                          background: defaultLabelColors.main_orange,
                          color: "#fff",
                          outline: "#ee6931",
                        }}
                      >
                        Show More
                      </Button>
                    )}
                  </Box>
                )}

                {showFullDescription && (
                  <Box
                    sx={{
                      textAlign: "center",
                      background:
                        "linear-gradient(to bottom, rgba(255,255,255,0), white)",
                      padding: "10px 0",
                    }}
                  >
                    {myProd_details?.product_desc && (
                      <Button
                        variant="contained"
                        onClick={toggleDescription}
                        sx={{
                          borderRadius: "12px",
                          textTransform: "none",
                          fontFamily: "Raleway-Variable",
                          fontSize: "14px",
                          fontWeight: "600",
                          background: defaultLabelColors.main_orange,
                          color: "#fff",
                          outline: "#ee6931",
                        }}
                      >
                        Show Less
                      </Button>
                    )}
                  </Box>
                )}
              </Card>
            </Grid>

            <Grid xs={12} item>
              <Grid
                container
                style={{
                  background: defaultLabelColors.main_orange,
                  borderRadius: "24px",
                  padding: 10,
                }}
              >
                <Grid xs={12} md={2.25} item className="display-flex">
                  <p
                    style={{
                      fontFamily: "Raleway-Extra-Bold",
                      color: "#fff",
                      fontSize: "22px",
                    }}
                  >
                    01
                    <br />
                    <span style={{ fontSize: "18px" }}>
                      {" "}
                      Mise en ligne <br />
                      {myProd_details?.order_statuses?.[0]?.created_at
                        ? moment(
                            `${myProd_details?.order_statuses?.[0]?.created_at}`.endsWith(
                              "Z"
                            )
                              ? myProd_details?.order_statuses?.[0]?.created_at
                              : myProd_details?.order_statuses?.[0]
                                  ?.created_at + "Z"
                          ).format("DD/MM/YYYY HH:mm")
                        : "- - -"}
                    </span>
                  </p>
                </Grid>
                <Grid xs={12} md={2.25} item className="display-flex">
                  <p
                    style={{
                      fontFamily: "Raleway-Extra-Bold",
                      color: "#fff",

                      fontSize: "22px",
                    }}
                  >
                    02
                    <br />
                    <span style={{ fontSize: "18px" }}>
                      {" "}
                      Accepté <br />
                      {myProd_details?.order_statuses?.[1]?.created_at
                        ? moment(
                            `${myProd_details?.order_statuses?.[1]?.created_at}`.endsWith(
                              "Z"
                            )
                              ? myProd_details?.order_statuses?.[1]?.created_at
                              : myProd_details?.order_statuses?.[1]
                                  ?.created_at + "Z"
                          ).format("DD/MM/YYYY HH:mm")
                        : "- - -"}
                    </span>
                  </p>
                </Grid>
                <Grid xs={12} md={2.25} item className="display-flex">
                  <p
                    style={{
                      fontFamily: "Raleway-Extra-Bold",
                      color: "#fff",

                      fontSize: "22px",
                    }}
                  >
                    03
                    <br />
                    <span style={{ fontSize: "18px" }}>
                      {" "}
                      Expédié <br />
                      {myProd_details?.order_statuses?.[2]?.created_at
                        ? moment(
                            `${myProd_details?.order_statuses?.[2]?.created_at}`.endsWith(
                              "Z"
                            )
                              ? myProd_details?.order_statuses?.[2]?.created_at
                              : myProd_details?.order_statuses?.[2]
                                  ?.created_at + "Z"
                          ).format("DD/MM/YYYY HH:mm")
                        : "- - -"}
                    </span>
                  </p>
                </Grid>
                <Grid xs={12} md={2.25} item className="display-flex">
                  <p
                    style={{
                      fontFamily: "Raleway-Extra-Bold",
                      color: "#fff",

                      fontSize: "22px",
                    }}
                  >
                    04
                    <br />
                    <span style={{ fontSize: "18px" }}>
                      {" "}
                      Livré
                      <br />
                      {myProd_details?.order_statuses?.[3]?.created_at
                        ? moment(
                            `${myProd_details?.order_statuses?.[3]?.created_at}`.endsWith(
                              "Z"
                            )
                              ? myProd_details?.order_statuses?.[3]?.created_at
                              : myProd_details?.order_statuses?.[3]
                                  ?.created_at + "Z"
                          ).format("DD/MM/YYYY HH:mm")
                        : "- - -"}
                    </span>
                  </p>
                </Grid>
                <Grid xs={12} md={3} item className="display-flex">
                  {myProd_details?.ship_status_level === 2 &&
                  myProd_details?.payment_status !== "Paid" ? (
                    <div
                      style={{
                        background: "#fff",
                        borderRadius: "16px",
                        color: "#000",
                        fontSize: "18px",
                        fontFamily: "Raleway-Extra-Bold",
                        padding: 20,
                        textAlign: "center",
                        cursor: "pointer",
                        width: "231px",
                      }}
                      onClick={() => {
                        setState({ openStripeForm: true });
                      }}
                    >
                      {strings?.continue_payment}
                    </div>
                  ) : null}

                  {myProd_details?.ship_pickup_otp &&
                  myProd_details?.ship_status === "Accepted" ? (
                    <>
                      <div
                        style={{
                          padding: "10px 0px 10px 0px",
                        }}
                      >
                        <OTPInput
                          inputStyle={{
                            fontFamily: "Raleway-Variable",
                            fontSize: "22px",
                            fontWeight: "900",
                            color: "#000",
                            border: "none",
                            background: "#F4F3F6",
                            borderRadius: "12px",
                            padding: "16px",
                          }}
                          inputType="number"
                          value={myProd_details?.ship_pickup_otp}
                          onChange={(otpValue) => {
                            setOtp(otpValue);
                          }}
                          numInputs={4}
                          renderSeparator={
                            <span style={{ padding: "5px" }}></span>
                          }
                          renderInput={(props) => <input {...props} readOnly />}
                        />
                        <p
                          style={{
                            fontFamily: "Raleway-Extra-Bold",
                            fontSize: "20px",
                            color: "#fff",
                            margin: 0,
                            paddingTop: "5px",
                          }}
                        >
                          Code à transmettre
                          <br /> au transporteur
                        </p>
                      </div>
                    </>
                  ) : null}

                  {myProd_details?.ship_deliver_otp &&
                  myProd_details?.ship_status === "Picked" ? (
                    <>
                      <div
                        style={{
                          padding: "10px 0px 10px 0px",
                        }}
                      >
                        <OTPInput
                          inputStyle={{
                            fontFamily: "Raleway-Extra-Bold",
                            fontSize: "22px",
                            color: "#000",
                            border: "none",
                            background: "#F4F3F6",
                            borderRadius: "12px",
                            padding: "16px",
                          }}
                          inputType="number"
                          value={myProd_details?.ship_deliver_otp}
                          onChange={(otpValue) => {
                            setOtp(otpValue);
                          }}
                          numInputs={4}
                          renderSeparator={
                            <span style={{ padding: "5px" }}></span>
                          }
                          renderInput={(props) => <input {...props} readOnly />}
                        />
                        <p
                          style={{
                            fontFamily: "Raleway-Extra-Bold",
                            fontSize: "20px",
                            color: "#fff",
                            margin: 0,
                            paddingTop: "5px",
                          }}
                        >
                          Code à transmettre
                          <br /> au transporteur
                        </p>
                      </div>
                    </>
                  ) : null}

                  {myProd_details?.ship_status === "Deliver" ? (
                    myProd_details?.feedback ? null : (
                      <>
                        <div
                          style={{
                            background: "#fff",
                            borderRadius: "16px",
                            color: "#000",
                            fontSize: "18px",

                            fontFamily: "Raleway-Extra-Bold",
                            padding: 20,
                            textAlign: "center",
                            cursor: "pointer",
                            width: "231px",
                          }}
                          onClick={() => {
                            setState({ feedbackDialog: true });
                          }}
                        >
                          Laisser un commentaire
                        </div>
                      </>
                    )
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div
          className="main-container"
          style={{ background: "#fff", paddingTop: "90px" }}
        >
          <CustomFooter />
        </div>
      </div>
    </div>
  );
}

export default ParticularMyListedProduct;
