import { ArrowBackIos, ArrowBackIosNew } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  DialogContent,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Box, styled, useMediaQuery, useTheme } from "@mui/system";
import noProdFound from "../../../assets/images/FrankIcons/New folder/noProdFound.png";
import { debounce } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { BoXIcon, LockIcon } from "../../../assets/images/FrankIcons";
import apiServices from "../../../services/RequestHandler";
import { RootState } from "../../../Store";
import { strings } from "../../../Store/Localization";
import CustomButton from "../../../UiComponent/CustomButton";
import CustomFooter from "../../../UiComponent/CustomFooter";
import DraggableDialog from "../../../UiComponent/DraggableDialog";
import HeaderForMarketPlace from "../../../UiComponent/HeaderForMarketPlace";
import Loader from "../../../UiComponent/Loader";
import Toast from "../../../UiComponent/Toast/Toast";
import {
  defaultLabelColors,
  no_image_url,
  spacing,
} from "../../../utils/constants";
import {
  handleLocalize,
  numberFormatWithoutCurrency,
} from "../../../utils/helper";
import {
  getDataFromLocalStorage,
  saveDataToLocalStorage,
} from "../../../utils/LocalStore.util";
import AuthAction from "../../Auth/AuthAction";
import { color_theme } from "../../SenderInbox.tsx/AccepterChat";
import { ProductCard } from "..";

export const card_style = {
  border: `1px solid #E0E0E0`,
  borderRadius: 24,
  padding: 16,
  gap: 8,
};

function ParticularProductDetails(props: any) {
  const navigate = useNavigate();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));
  let param = useParams();
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedAttributes, setSelectedAttributes] = useState<any>({});

  const EuroAdornment = styled("span")({
    fontSize: "52px",
    fontFamily: "Raleway-Variable",
    fontWeight: "700",
    color: "#626262", // Adjust color as needed
  });

  const StyledTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "&:hover fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      },
    },
    "& .MuiInputBase-input": {
      fontSize: "52px",
      padding: "12px 14px",
      textAlign: "center",
      fontFamily: "Raleway-Variable",
      fontWeight: "700",
    },
    "& .MuiInputAdornment-root": {
      fontSize: "52px !important",
      fontFamily: "Raleway-Variable",
      fontWeight: "700",
    },
  });

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      isFavorited: false,
      cart_items: [],
      userDetails: getDataFromLocalStorage("user"),
      openOffer: false,
      global_search: "",
      nego_price: null,
      shouldNavigate: false,
    }
  );

  useEffect(() => {
    const user = getDataFromLocalStorage("user");
    if (user) {
      ViewProduct();
    }
  }, []);

  const handleAddToFavorite = async (item: any) => {
    try {
      let obj = {
        product_id: Number(item?.id),
      };

      let resp = await apiServices.postFromApi(
        "c2c-product-wishlists",
        obj,
        ""
      );
      if (resp?.status === 201) {
       }
    } catch (error) {}
  };


  const ViewProduct = async () => {
    try {
      await apiServices.postFromApi(
        "c2c-product-user-views",
        { product_id: Number(param?.id) },
        ""
      );
    } catch (error) {}
  };

  const [category, setCategory] = React.useState("");
  const dispatch = useDispatch<any>();
  const selector = useSelector((state: RootState) => state.mainApp);
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  let userDetails = getDataFromLocalStorage("user");
  const handleCloseLoginPage = () => {
    try {
      setState({ openLoginDialog: false });
    } catch (error) {}
  };

  useEffect(() => {
    handleGetItemByID();
  }, []);

  const [localAttributes, setLocalAttributes] =
    useState<Record<string, string>>(selectedAttributes);

  // const handleChange = (name: string, value: string) => {
  //   setLocalAttributes((prev) => ({ ...prev, [name]: value }));
  //   handleSelectAttribute(name, value);
  // };
  const onInputChange = (name: string, value: string | number) => {
    try {
      setState({
        ...state,
        [name]: value,
      });
    } catch (error) {
      console.error("Error in onInputChange:", error);
    }
  };

  const debouncedSearch = debounce((name: string, value: string | number) => {
    onInputChange(name, value);
  }, 500);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    debouncedSearch(name, value);
  };
  const handleChangeForNego = (event: any) => {
    const { value } = event.target;
    setState({ nego_price: value });
  };

  const handleOpenProduct = (item: any) => {
    try {
      if (item?.is_particular === true) {
        navigate(`/p-product/${item?.id}`);
        return;
      }

      navigate(`/product/${item?.id}`);
    } catch (error) {}
  };

  useEffect(() => {
    if (selectedAttributes) {
      handleSelectVariation();
    }
  }, [JSON.stringify(selectedAttributes)]);
  const openOfferDialog = () => {
    try {
      setState({ openOffer: true });
    } catch (error) {}
  };
  const handleNewMessageWithNegotiate = async () => {
    let user = getDataFromLocalStorage("user");
    if (!user) {
      setState({ openLoginDialog: true });
      return;
    }

    setTimeout(() => {
      setState({
        disable_btn: false,
      });
    }, 3000);
    let text = state?.nego_price
      .replaceAll("،", ".")
      .replaceAll(".", ".")
      .replaceAll(",", ".")
      .replaceAll("，", ".");

    if (isNaN(Number(text))) {
      Toast.Error(
        selector.lang == "fr"
          ? `Veuillez entrer un montant valide.`
          : "Please enter a valid amount"
      );
      setState({
        disable_btn: false,
      });
      return;
    }

    let new_msg: any = {
      message: `has sent you a message. neg_amt=${text}`,
      message_status: "sent",
      product_id: state?.product_details_?.id,
      is_negotiate: true,
      order_details: null,
      sender_id: `${state?.userDetails?.id}`,
      receiver_id: `${state?.product_details_?.created_by}`,
    };

    let obj: any = {
      product_id: Number(state?.product_details_?.id),
      price: Number(text ?? 0),
    };
    if (state?.product_details_?.negotiations?.length > 0) {
      obj.parent_id = Number(
        state?.product_details_?.negotiations?.[
          state?.product_details_?.negotiations?.length - 1
        ]?.id
      );
    }
    const resp = await apiServices.postFromApi(
      "/c2c-order-negotiations",
      obj,
      ""
    );

    if (resp.status === 201) {
      Toast.showSuccess(strings.offer_request_sent);
      await apiServices.postFromApi("chat/send-new-message", new_msg, "");
      setState({
        openOffer: false,
        disableOfferButton: true,
        nego_price: "",
      });
      handleGetItemByID();
      setState({
        disable_btn: false,
      });
    } else {
      Toast.Error(resp.error?.message ?? resp?.message ?? resp?.error?.error);
      setState({
        disable_btn: false,
      });
    }
  };
  const handleGetItemByID = async () => {
    try {
      setState({ refreshing: true, loading: true });
      const item_id = param?.id;
      const resp = await apiServices.postFromApi(
        `/c2c-product-masters/particular/details`,
        {
          id: item_id,
          customer_id: selector.user?.id,
        },
        ""
      );

      const accepter_details = resp?.data?.negotiations?.filter(
        (i: any) => i?.created_by === state?.userDetails?.id
      );

      accepter_details?.sort((a: any, b: any) =>
        new Date(a?.created_at).getTime() > new Date(b?.created_at).getTime()
          ? -1
          : 1
      );
      console.log("accepter_details", accepter_details);

      if (accepter_details?.[0]?.is_accepted === null) {
        setState({
          disableOfferButton: true,
          accepter_details,
        });
      }

      const data = resp?.data;

      const item_images = [];
      if (!data?.without_variant) {
        for (const i of data?.images) {
          item_images.push({
            url: i,
            price:
              data?.product_variations?.find((a: any) => a?.is_default)
                ?.price ?? data?.product_variations?.[0]?.price,
            discount:
              data?.product_variations?.find((a: any) => a?.is_default)
                ?.discount ?? data?.product_variations?.[0]?.discount,
            variation_id:
              data?.product_variations?.find((a: any) => a?.is_default)?.id ??
              data?.product_variations?.[0]?.id,
          });
        }
      }

      let attributeNames = [
        ...new Set(
          data?.product_variations?.flatMap((variation: any) =>
            variation?.attributes?.map(
              (attr: any) => attr.attribute_name.en ?? attr.attribute_name.fr
            )
          )
        ),
      ]?.sort((a: any, b: any) => (a < b ? -1 : 1));
      console.log("attributeNames", attributeNames);

      for (const var_ of data?.product_variations ?? []) {
        for (const img of var_?.images) {
          item_images.push({
            url: img,
            price: var_?.price,
            discount: var_?.discount,
            variation_id: var_.id,
          });
        }
      }
      setSelectedImage(item_images?.[0]?.url);

      const defaultSelection: any = {};

      attributeNames?.forEach((name: any) => {
        let default_item = data?.product_variations?.find(
          (a: any) => a.is_default
        );
        const firstValue = default_item?.id
          ? default_item?.attributes?.find(
              (attr: any) => attr.attribute_name.en === name
            )?.attribute_value?.en
          : data?.product_variations?.[0]?.attributes?.find(
              (attr: any) => attr?.attribute_name?.en === name
            )?.attribute_value?.en;

        if (firstValue) {
          defaultSelection[name] = firstValue;
        }
      });

      let default_item = data?.product_variations?.find(
        (a: any) => a?.is_default
      );

      // Calculate average rating
      let totalRating = 0;
      if (data?.product_reviews?.length > 0) {
        totalRating = data?.product_reviews?.reduce(
          (sum: any, feedback: any) => sum + Number(feedback?.rating),
          0
        );
      }
      const averageRating =
        Number(totalRating ?? 0) / Number(data?.product_reviews?.length ?? 1);
      console.log(averageRating);

      setState({
        item_id,
        item_images,
        product_details_: data,
        display_sel_variant: default_item?.id
          ? default_item
          : data?.product_variations?.[0],
        default_item: default_item?.id
          ? default_item
          : data?.product_variations?.[0],
        attributeNames,
        screen_loader: false,
        averageRating,
        loading: false,
        related_prod: resp?.data?.related_product,
      });

      console.log("product_details_", data);

      setTimeout(() => {
        setSelectedAttributes(defaultSelection);
      }, 1000);

      setState({ loading: false });
    } catch (error) {
      console.log(error);
      setState({
        refreshing: false,
      });
    }
  };
  const handleSelectAttribute = (
    name: any,
    value: any,
    first_item?: boolean
  ) => {
    // if (first_item) {
    //   let val = {};
    //   for (const key in state.attributeNames) {
    //     if (Object.prototype.hasOwnProperty.call(state.attributeNames, key)) {
    //       const element = state.attributeNames[key];
    //       if (element == name) {
    //         console.log('AYA');
    //         setSelectedAttributes({[element]: value});
    //       } else {
    //         setSelectedAttributes({[element]: null});
    //       }
    //     }
    //   }
    //   // setSelectedAttributes({[name]: value});
    // } else {
    // }
    setSelectedAttributes((prev: any) => ({ ...prev, [name]: value }));
    // setSelectedImage(state?.display_sel_variant?.images?.[0]);
  };
  const handleSelectVariation = () => {
    try {
      const variations = state?.product_details_?.product_variations;
      const selectedVariation = variations?.find((variation: any) =>
        Object.entries(selectedAttributes)?.every(([name, value]) =>
          variation?.attributes?.some(
            (attr: any) =>
              attr?.attribute_name?.en === name &&
              attr?.attribute_value?.en === value
          )
        )
      );
      setState({
        display_sel_variant: selectedVariation,
      });
      console.log("CALLING");
    } catch (error) {}
  };
  const handleAddToCart = async () => {
    try {
      let cartItem = {
        shop_id: state?.prod_details?.shop_id,
        quantity: 2,
        product_variation_id: state?.prod_details?.id,
      };

      state?.cart_items.push(cartItem);

      let obj = {
        cart_items: state?.cart_items,
      };

      return;
      let resp = await apiServices.postFromApi("", obj, "");
    } catch (error) {}
  };
  const handleBuyProduct = () => {
    try {
      let user = getDataFromLocalStorage("user");
      if (!user) {
        setState({ openLoginDialog: true });
        return;
      } else {
        let tempData = saveDataToLocalStorage(
          "tempData",
          state?.product_details_
        );
        navigate("/p-checkout");
      }
    } catch (error) {}
  };

  const onInputChangeForHeader = (e: any) => {
    try {
      const { value, name } = e.target;
      setState({
        [name]: value,
      });
    } catch (error) {}
  };
   
  const handleSearchProducts = useCallback(
    debounce(async (searchValue: any) => {
      try {
        if (!searchValue) {
          setState({ activity: true });
          setState({
            showSearchedData: false,
            allSearchProduct: null,
            activity: false,
          });
        }
        if (searchValue?.trim()) {
          setState({ activity: true });
          let resp = await apiServices.postFromApi(
            "c2c-product-masters/products-search",
            { search: searchValue },
            ""
          );
          console.log("resp", resp);
          if (resp?.data?.length < 1) {
            setState({
              showImg: true,
            });
          }
          setState({
            allSearchProduct: resp?.data,
            activity: false,
            showSearchedData: true,
          });
        }
      } catch (error) {
        console.error("Error while searching products:", error);
      }
    }, 1500),
    []
  );
  useEffect(() => {
    handleSearchProducts(state.global_search);
  }, [state.global_search, handleSearchProducts]);

  let { related_prod, product_details_, display_sel_variant } = state;
  return (
    <>
      <Helmet>
        <title>Product Details | Frank</title>
        <meta name="description" content="" />
      </Helmet>
      <Loader loading={state?.loading} />

      <div style={{ background: "#FCE6DD" }}>
        <Grid container>
          <Grid xs={12} item>
            <HeaderForMarketPlace
              {...props}
              {...state}
              onInputChangeForHeader={onInputChangeForHeader}
            />
          </Grid>
        </Grid>
        {state?.showSearchedData ? (
          <div
            style={{
              minHeight: "100vh",
              padding: "0px 50px 0px 50px",
              background: defaultLabelColors.main_background_two,
            }}
          >
            <Grid container spacing={2}>
              <Grid
                xs={12}
                item
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
                onClick={() => {
                  setState({ showSearchedData: false, global_search: "" });
                }}
              >
                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "26px",
                    gap: 10,
                    cursor: "pointer",
                  }}
                  className="display-flex"
                >
                  <ArrowBackIos style={{ fontSize: "22px" }} /> Vendre un
                  article
                </p>
              </Grid>
            </Grid>

            {state?.allSearchProduct?.length < 1 ||
            !state?.allSearchProduct?.length ? (
              <div
                style={{
                  height: "60vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  opacity: "0.5",
                }}
              >
                {state?.showImg && (
                  <img
                    src={noProdFound}
                    alt=""
                    style={{ height: "400px", width: "400px" }}
                  />
                )}
              </div>
            ) : (
              <>
                <p
                  style={{ fontFamily: "Raleway-Semi-Bold", fontSize: "16px" }}
                >
                  {state?.allSearchProduct?.length} annonces
                </p>
                <Grid container spacing={2}>
                  {state?.allSearchProduct
                    ?.slice(0, xl ? 6 : lg ? 4 : md ? 3 : 4)
                    ?.map((i: any, idx: any) => {
                      return (
                        <Grid
                          key={idx}
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          lg={3}
                          xl={2}
                          style={{ cursor: "pointer", maxHeight: "370px" }}
                          onClick={() => {
                            handleOpenProduct(i);
                          }}
                        >
                          <ProductCard
                            i={i}
                            handleAddToFavorite={handleAddToFavorite}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </>
            )}
          </div>
        ) : (
        <div style={{ padding: "30px 80px 50px 80px" }}>
          <Grid container spacing={2} style={{ paddingBottom: "40px" }}>
            <Grid
              xs={12}
              item
              style={{
                display: "flex",
                fontFamily: "Raleway-Variable",
                fontWeight: "500",
                fontSize: "26px",
                color: "#4B4A61",
                gap: 10,
                alignItems: "center",
              }}
            >
              <ArrowBackIosNew
                onClick={() => {
                  navigate(-1);
                }}
                style={{ cursor: "pointer" }}
              />{" "}
              Maison{" "}
              <svg
                width="6"
                height="6"
                viewBox="0 0 6 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="3" cy="3" r="3" fill="#626175" />
              </svg>{" "}
              {product_details_
                ? handleLocalize({
                    en: product_details_?.category_name?.en,
                    fr: product_details_?.category_name?.fr,
                  })
                : ""}
              <svg
                width="6"
                height="6"
                viewBox="0 0 6 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="3" cy="3" r="3" fill="#626175" />
              </svg>{" "}
              <h1 style={{ fontWeight: "700", fontSize: "26px" }}>
                {" "}
                {product_details_
                  ? handleLocalize({
                      en: product_details_?.product_title?.en,
                      fr: product_details_?.product_title?.fr,
                    })
                  : ""}
              </h1>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            style={{
              background: "#fff",
              borderRadius: "16px",
              padding: "20px",
              gap: 20,
            }}
          >
            <Grid xs={12} md={5.8} item>
              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                }}
              >
                <Box
                  position="relative"
                  sx={{ borderRadius: "32px 32px 0px 0px" }}
                >
                  <div style={{ height: "480px" }} className="display-flex">
                    <CardMedia
                      component="img"
                      image={selectedImage ?? ""}
                      alt="Product Image"
                      sx={{
                        objectFit: "contain",
                        width: "98%",
                        height: "460px",
                        borderRadius: "32px 32px 0px 0px",
                      }}
                    />
                  </div>
                  {product_details_?.is_particular && (
                    <div
                      style={{
                        position: "absolute",
                        bottom: "20px",
                        right: "20px",
                        height: "20px",
                        padding: 10,
                        display: "flex",
                        fontFamily: "Raleway-Bold",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#F5D9BC",
                        borderRadius: 5,
                        color: "#281208",
                        fontSize: "18px",
                      }}
                    >
                      {" "}
                      Seconde main
                    </div>
                  )}
                </Box>

                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "30px",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        color="#3B3A3A"
                        sx={{
                          fontFamily: "Raleway-Variable",
                        }}
                      >
                        {handleLocalize({
                          en: product_details_?.product_title?.en,
                          fr: product_details_?.product_title?.fr,
                        }) ?? ""}{" "}
                      </Typography>
                    </div>

                    <div
                      style={{
                        color: defaultLabelColors.main_orange,
                        fontWeight: "700",
                        fontFamily: "Raleway-Variable",
                        fontSize: "22px",
                      }}
                    >
                      {" "}
                      {numberFormatWithoutCurrency(
                        product_details_?.base_price
                      )}{" "}
                      €
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 5,
                      maxWidth: "100%",
                    }}
                  >
                    {state?.item_images?.map((item: any, idx: number) => {
                      return (
                        <div
                          className="display-flex"
                          style={{
                            height: 114,
                            width: 114,
                            cursor: "pointer",
                            borderRadius: 16,
                            border: `1px solid ${
                              selectedImage === item?.url
                                ? defaultLabelColors.main_orange
                                : "#D9D9D9"
                            } `,
                            display: idx > 4 ? "none" : "block",
                          }}
                          onClick={() => {
                            const filtered_variation =
                              state.product_details_?.product_variations?.filter(
                                (i: any) =>
                                  Number(i.id) === Number(item?.variation_id)
                              );

                            setState({
                              display_sel_variant: filtered_variation?.[0],
                            });
                            setSelectedImage(item?.url);
                          }}
                        >
                          <img
                            src={item?.url}
                            alt="prod-img"
                            style={{
                              objectFit: "contain",
                              height: 114,
                              width: 114,
                              borderRadius: 16,
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </CardContent>
              </Card>
              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                  marginTop: "15px",
                  padding: "15px",
                }}
              >
                <p
                  style={{
                    fontSize: "18px",
                    fontFamily: "Raleway-Variable",
                    fontWeight: "600",
                  }}
                >
                  Description
                </p>
                {/* <div style={{ margin: 10 }}>
                  <table
                    style={{
                      border: state?.product_details_?.product_specifications
                        ? "1px solid #ddd"
                        : "none",
                      padding: "5px",
                      gap: 5,
                      width: "100%",
                    }}
                  >
                    {state?.product_details_?.product_specifications?.map(
                      (i: any, idx: any) => {
                        return (
                          <tr key={idx}>
                            <td
                              style={{
                                background: "lightgrey",
                                fontFamily: "Raleway-Bold",
                                padding: 5,
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              {i?.specification_name?.en}
                            </td>
                            {i?.product_specification_values?.map((j: any) => {
                              return (
                                <td
                                  style={{
                                    fontFamily: "Raleway-Variable",
                                    textAlign: "left",
                                    width: "70%",
                                  }}
                                >
                                  {j?.specification_value?.en}{" "}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      }
                    )}
                  </table>
                </div> */}
                {product_details_?.product_desc ? (
                  <Box
                    sx={{
                      maxHeight: showFullDescription ? "none" : "80vh",
                      overflow: showFullDescription ? "visible" : "hidden",
                      position: "relative",
                    }}
                  >
                    <ReactQuill
                      value={product_details_?.product_desc}
                      readOnly
                      theme="snow"
                      modules={{ toolbar: false }}
                      style={{ pointerEvents: "none", border: "none" }}
                    />
                  </Box>
                ) : null}

                {!showFullDescription && (
                  <Box
                    sx={{
                      textAlign: "center",
                      background:
                        "linear-gradient(to bottom, rgba(255,255,255,0), white)",
                      padding: "10px 0",
                    }}
                  >
                    {product_details_?.product_desc?.length > 1000 && (
                      <Button
                        variant="contained"
                        onClick={toggleDescription}
                        sx={{
                          borderRadius: "12px",
                          textTransform: "none",
                          fontFamily: "Raleway-Variable",
                          fontSize: "14px",
                          fontWeight: "600",
                          background: defaultLabelColors.main_orange,
                          color: "#fff",
                          outline: "#ee6931",
                        }}
                      >
                        Show More
                      </Button>
                    )}
                  </Box>
                )}

                {showFullDescription && (
                  <Box
                    sx={{
                      textAlign: "center",
                      background:
                        "linear-gradient(to bottom, rgba(255,255,255,0), white)",
                      padding: "10px 0",
                    }}
                  >
                    {product_details_?.product_desc && (
                      <Button
                        variant="contained"
                        onClick={toggleDescription}
                        sx={{
                          borderRadius: "12px",
                          textTransform: "none",
                          fontFamily: "Raleway-Variable",
                          fontSize: "14px",
                          fontWeight: "600",
                          background: defaultLabelColors.main_orange,
                          color: "#fff",
                          outline: "#ee6931",
                        }}
                      >
                        Show Less
                      </Button>
                    )}
                  </Box>
                )}
              </Card>
            </Grid>

            <Grid xs={12} md={5.8} item>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: 12,
                }}
              >
                <div
                  style={card_style}
                  onClick={() => {
                    navigate(`/p-shop-details/${product_details_?.created_by}`);
                  }}
                >
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: 12,
                        margin: 0,
                      }}
                    >
                      {strings?.seller_details}
                    </p>
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: 12,
                        margin: 0,
                      }}
                    >
                      Membre depuis{" "}
                      {moment(product_details_?.created_at).format("YYYY")}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: 12,
                      marginTop: 15,
                    }}
                  >
                    <img
                      src={
                        product_details_?.owner_detail?.profile_pic ??
                        no_image_url
                      }
                      style={{
                        height: 60,
                        width: 60,
                        borderRadius: 100,
                        objectFit: "contain",
                        background: color_theme.disabled,
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          columnGap: 16,
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            fontFamily: "Raleway-Bold",
                            display: "flex",
                            fontSize: 18,
                            margin: 0,
                          }}
                        >
                          {product_details_?.added_by ?? ""}{" "}
                        </p>
                        <div
                          style={{
                            padding: 5,
                            fontFamily: "Raleway-Bold",
                            background: "#F5D9BC",
                            borderRadius: 5,
                            color: "#281208",
                            fontSize: 14,
                            textAlign: "center",
                          }}
                        >
                          Seconde main
                        </div>
                      </div>

                      <Rating
                        iconsCount={5}
                        initialValue={product_details_?.reviews}
                        fillColor="#FFC403"
                        SVGstrokeColor="#FFC403"
                        size={24}
                        readonly
                      />
                    </div>
                  </div>
                </div>

                <div style={card_style}>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        flex: 1,
                        fontFamily: "Raleway-Variable",
                        fontWeight: "700",
                      }}
                    >
                      {product_details_
                        ? handleLocalize({
                            en: product_details_?.product_title?.en,
                            fr: product_details_?.product_title?.fr,
                          })
                        : ""}{" "}
                    </div>
                    <div
                      style={{
                        color: defaultLabelColors.main_orange,
                        fontWeight: "700",
                        fontFamily: "Raleway-Variable",
                        fontSize: "22px",
                      }}
                    >
                      <span
                        style={{
                          color: defaultLabelColors.main_orange,
                          paddingRight: 20,
                        }}
                      >
                        {numberFormatWithoutCurrency(
                          product_details_?.base_price
                        )}
                        €
                      </span>
                    </div>
                  </div>

                  <p style={{ fontFamily: "Raleway-Variable", fontSize: 16 }}>
                    Mise en ligne le{" "}
                    {moment(product_details_?.created_at).format("DD/MM/YY")}
                  </p>
                </div>

                <div style={card_style}>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        flex: 1,
                        fontFamily: "Raleway-Variable",
                        fontWeight: "700",
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <BoXIcon /> Mode de retrait disponible
                    </div>
                  </div>
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      margin: 0,
                      fontSize: 16,
                    }}
                  >
                    Livraison possible
                  </p>
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      margin: 0,
                      fontSize: 16,
                    }}
                  >
                    Remise en main propre
                  </p>
                </div>

                <div style={card_style}>
                  <p
                    style={{
                      fontFamily: "Raleway-Semi-Bold",
                      display: "flex",
                      margin: 0,
                    }}
                  >
                    Catégorie : &nbsp;
                    {product_details_
                      ? handleLocalize({
                          en: product_details_?.category_name?.en,
                          fr: product_details_?.category_name?.fr,
                        })
                      : ""}
                  </p>
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontWeight: 600,
                      gap: 10,
                      fontSize: 14,
                      margin: 0,
                      marginTop: 5,
                    }}
                  >
                    <BoXIcon /> Marque :{" "}
                    {product_details_
                      ? handleLocalize({
                          en: product_details_?.brand_name?.en,
                          fr: product_details_?.brand_name?.fr,
                        })
                      : ""}
                  </p>
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontWeight: 600,
                      gap: 10,
                      fontSize: 14,
                      margin: 0,
                      marginTop: 5,
                    }}
                  >
                    <BoXIcon /> État :{" "}
                    {product_details_?.used_condition ? handleLocalize({
                      en: product_details_?.used_condition?.en,
                      fr: product_details_?.used_condition?.en,
                    }) : "N/A" }
                  </p>
                </div>

                <div style={card_style}>
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontWeight: 600,
                      gap: 10,
                      fontSize: 14,
                      margin: 0,
                    }}
                  >
                    <LockIcon /> Paiement sécurisé
                  </p>
                </div>
              </div>

              {state?.product_details_?.created_by ===
              state?.userDetails?.id ? null : (
                <div
                  className="display-flex"
                  style={{ gap: 10, paddingTop: 10 }}
                >
                  <Button
                    fullWidth
                    disabled={state?.disableOfferButton}
                    variant="contained"
                    sx={{
                      backgroundColor: state?.disableOfferButton
                        ? "lightgrey"
                        : defaultLabelColors.white, // Same as the blue color in the image
                      color: defaultLabelColors.main_orange,
                      borderRadius: "16px",
                      padding: "10px 20px",
                      textTransform: "none",
                      fontSize: "16px",
                      fontFamily: "Raleway-Variable",
                      fontWeight: "bold",
                      "&:hover": {
                        backgroundColor: "#fff",
                      },
                    }}
                    onClick={openOfferDialog}
                  >
                    Faire une offre
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleBuyProduct}
                    fullWidth
                    disabled={
                      display_sel_variant?.items_in_stock < 1 ? true : false
                    }
                    sx={{
                      backgroundColor:
                        display_sel_variant?.items_in_stock < 1
                          ? "lightGrey"
                          : defaultLabelColors.main_orange,
                      color: "#fff",
                      borderRadius: "16px",
                      padding: "10px 20px",
                      textTransform: "none",
                      fontSize: "16px",
                      fontFamily: "Raleway-Variable",
                      fontWeight: "bold",

                      "&:hover": {
                        backgroundColor:
                          display_sel_variant?.items_in_stock < 1
                            ? "lightGrey"
                            : defaultLabelColors.main_orange,
                      },
                    }}
                  >
                    Acheter
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid
              xs={12}
              item
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "-35px",
              }}
            >
              <p style={{ flex: 1 }} className="page-title-two">
                Produits similaires à cet article
              </p>
            </Grid>

            {related_prod?.slice(0, 4)?.map((i: any, idx: any) => {
              return (
                <Grid
                item
                xs={12}
                sm={4}
                md={3}
                lg={3}
                xl={2}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleOpenProduct(i);
                }}
              >
                <ProductCard
                  i={i}
                  handleAddToFavorite={handleAddToFavorite}
                />
              </Grid>
              );
            })}
          </Grid>
        </div>)}

        <div
          className="main-container"
          style={{ background: defaultLabelColors.main_background_two }}
        >
          <CustomFooter />
        </div>
      </div>

      <DraggableDialog
        maxWidth={"md"}
        open={state?.openLoginDialog}
        handleClose={() => {
          setState({ openLoginDialog: false });
        }}
        dialogBody={
          <DialogContent
            style={{
              display: "flex",
              background: "#FCE6DD",
            }}
            dividers
          >
            <Grid container spacing={spacing.spacing2}>
              <Grid xs={12} item>
                <AuthAction
                  {...state}
                  {...props}
                  handleCloseLoginPage={handleCloseLoginPage}
                />
              </Grid>
            </Grid>
          </DialogContent>
        }
      />

      <DraggableDialog
        maxWidth={"sm"}
        dialog_title={"Faire une offre"}
        open={state?.openOffer}
        handleClose={() => {
          setState({ openOffer: false });
        }}
        dialogBody={
          <DialogContent
            style={{
              display: "flex",
              background: "#fff",
            }}
            dividers
          >
            <Grid container spacing={spacing.spacing2}>
              <Grid xs={12} item>
                <div>
                  <div>
                    <div className="card-container">
                      <img
                        src={state?.product_details_?.images?.[0]}
                        className="card-image"
                        alt="prod-img"
                        style={{ height: "100%" }}
                      />
                      <div className="card-details" style={{ display: "flex" }}>
                        <div
                          style={{
                            fontSize: 20,
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            fontFamily: "Raleway-Bold",
                          }}
                        >
                          <p style={{ margin: 0 }}>
                            {handleLocalize({
                              en: state?.product_details_?.product_title?.en,
                              fr: state?.product_details_?.product_title?.fr,
                            }) ??
                              state?.product_details_?.shipment_items[0]
                                ?.prod_name ??
                              ""}
                          </p>
                          <p
                            style={{
                              margin: 0,
                              color: defaultLabelColors.main_orange,
                            }}
                          >
                            Seconde main
                          </p>
                          <p
                            style={{
                              fontFamily: "Raleway-Variable",
                              fontSize: 18,
                              margin: 0,
                              marginTop: "10px",
                            }}
                          >
                            Prix de l’article :{" "}
                            <span
                              style={{
                                fontFamily: "Raleway-Bold",
                                color: defaultLabelColors.main_orange,
                              }}
                            >
                              {state?.product_details_?.base_price} €
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className=" "
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      lineHeight: "1px",
                      marginTop: "20px",
                      gap: "10px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Raleway-Bold",
                        color: "#281208",
                        fontSize: "28px",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      Insérez le montant de votre offre
                    </p>
                   
                    <div
                      className="display-flex"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      {/* <StyledTextField
                        focused
                        name="nego_price"
                        onChange={handleChange}
                        type="text"
                        placeholder="ex : 8"
                        value={state?.nego_price || ""}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <EuroAdornment>€</EuroAdornment>
                            </InputAdornment>
                          ),
                        }}
                      />
                       */}

                      <input
                        type="number"
                        onChange={handleChangeForNego}
                        style={{
                          fontSize: "54px",
                          fontFamily: "Raleway-Bold",
                          width: "100%",
                          border: "none",
                          textAlign: "center",
                          color: defaultLabelColors.main_orange,
                        }}
                        value={state?.nego_price}
                        placeholder="ex : 7 €"
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      gap: "10px",
                      paddingTop: "20px",
                    }}
                  >
                    <CustomButton
                      value={"Annuler"}
                      // disable={state?.items?.length > 0 ? true : false}
                      style={{
                        width: "100%",
                        background: "#fff",
                        color: "#000",
                        border: "1px solid #BCBBC4",
                        padding: "12px 24px",
                        borderRadius: "12px",
                        fontFamily: "Raleway-Semi-Bold",
                        fontSize: "16px",
                      }}
                      onClick={() => {
                        setState({
                          openOffer: false,
                          nego_price: "",
                        });
                      }}
                    />
                    <CustomButton
                      value={"Faire une offre"}
                      style={{
                        width: "100%",
                        background: defaultLabelColors.main_orange,
                        color: "#fff",
                        border: "1px solid #ee6931",
                        padding: "12px 24px",
                        borderRadius: "12px",
                        fontFamily: "Raleway-Semi-Bold",
                        fontSize: "16px",
                      }}
                      onClick={handleNewMessageWithNegotiate}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        }
      />
    </>
  );
}

export default ParticularProductDetails;
