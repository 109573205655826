import {
  AddCircleOutline,
  ArrowBackIos,
  ArrowBackIosNew,
  ShoppingBagOutlined,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  DialogContent,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import noProdFound from "../../assets/images/FrankIcons/New folder/noProdFound.png";
import { useNavigate, useParams } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { BoXIcon, LockIcon } from "../../assets/images/FrankIcons";
import noImageFile from "../../assets/images/FrankIcons/noImageAvailable.png";
import particularLabel from "../../assets/images/FrankIcons/particular_badge.png";
import apiServices from "../../services/RequestHandler";
import { RootState } from "../../Store";
import { strings } from "../../Store/Localization";
import CustomFooter from "../../UiComponent/CustomFooter";
import HeaderForMarketPlace from "../../UiComponent/HeaderForMarketPlace";
import {
  defaultLabelColors,
  no_image_url,
  spacing,
} from "../../utils/constants";

import { getCart } from "../../Store/MainAppReducer";
import Loader from "../../UiComponent/Loader";
import Toast from "../../UiComponent/Toast/Toast";
import {
  handleLocalize,
  numberFormatWithoutCurrency,
} from "../../utils/helper";
import { getDataFromLocalStorage } from "../../utils/LocalStore.util";
import { color_theme } from "../SenderInbox.tsx/AccepterChat";
import { card_style } from "./Individual/ParticularProductDetails";
import moment from "moment";
import DraggableDialog from "../../UiComponent/DraggableDialog";
import AuthAction from "../Auth/AuthAction";
import { debounce } from "lodash";
import { ProductCard } from ".";
import { useMediaQuery, useTheme } from "@mui/system";

interface CartItem {
  id: number;
  name: string;
  quantity: number;
}

export const select_feild_style = {
  minWidth: 119,
  height: 24,
  margin: 0,
  border: "1px solid #3026f1",
  borderRadius: "20px",
  fontFamily: "Raleway",
  fontSize: 14,
  color: "#3026f1",
  "& .MuiSelect-icon": {
    color: "#3026f1",
  },
  "&.MuiOutlinedInput-root": {
    padding: "0px",
    fontFamily: "Raleway",
    margin: "0px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&:focus-visible": {
    outline: "none",
  },
  "&.Mui-focused": {
    boxShadow: "none",
  },
};
function ProductDetails(props: any) {
  const navigate = useNavigate();
  let param = useParams();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedAttributes, setSelectedAttributes] = useState<any>({});

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      isFavorited: false,
      cart_items: [],
      quantity: 1,
      global_search: "",
    }
  );

  const [category, setCategory] = React.useState("");
  const dispatch = useDispatch<any>();
  const selector = useSelector((state: RootState) => state.mainApp);
  const [showFullDescription, setShowFullDescription] = useState(false);

  useEffect(() => {
    const user = getDataFromLocalStorage("user");
    if (user) {
      ViewProduct();
    }
  }, []);

  const ViewProduct = async () => {
    try {
      let resp = await apiServices.postFromApi(
        "c2c-product-user-views",
        { product_id: Number(param?.id) },
        ""
      );
    } catch (error) {}
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const handleOpenProduct = (item: any) => {
    try {
      if (item?.is_particular === true) {
        navigate(`/p-product/${item?.id}`);
        return;
      }

      navigate(`/product/${item?.id}`);
    } catch (error) {}
  };

  let userDetails = getDataFromLocalStorage("user");

  useEffect(() => {
    handleGetProdInfo(param?.id);
    handleGetItemByID();
  }, [param?.id]);

  const handleGetProdInfo = async (prod_id: any) => {
    try {
      let obj = { product_id: prod_id };
      let resp = await apiServices.postFromApi(
        "c2c-product-masters/get-by-id-and-related",
        obj,
        ""
      );

      let prod_details = resp?.data?.filter((i: any) => i?.id === prod_id)[0];
      let related_prod = resp?.data?.filter((i: any) => i?.id !== prod_id);

      setState({
        prod_details,
        related_prod,
      });

      // setSelectedImage(prod_details?.images[0]);
      console.log("prod_details?.shop_id", prod_details);

      handleGetShopInfo(prod_details?.shop_id);
    } catch (error) {
      console.log(error);
    }
  };
  const [localAttributes, setLocalAttributes] =
    useState<Record<string, string>>(selectedAttributes);

  const handleChange = (name: string, value: string) => {
    setLocalAttributes((prev) => ({ ...prev, [name]: value }));
    handleSelectAttribute(name, value);
  };

  useEffect(() => {
    if (selectedAttributes) {
      handleSelectVariation();
    }
  }, [JSON.stringify(selectedAttributes)]);

  const handleGetItemByID = async () => {
    try {
      setState({ refreshing: true, loading: true });
      const item_id = param?.id;
      const resp = await apiServices.postFromApi(
        `/c2c-product-masters/get-by-id`,
        {
          product_id: item_id,
          user: selector.user?.id,
        },
        ""
      );
      const data = resp?.data;
      console.log(JSON.stringify(data));

      const item_images = [];
      if (!data?.without_variant) {
        for (const i of data?.images) {
          item_images.push({
            url: i,
            price:
              data?.product_variations?.find((a: any) => a?.is_default)
                ?.price ?? data?.product_variations?.[0]?.price,
            discount:
              data?.product_variations?.find((a: any) => a?.is_default)
                ?.discount ?? data?.product_variations?.[0]?.discount,
            variation_id:
              data?.product_variations?.find((a: any) => a?.is_default)?.id ??
              data?.product_variations?.[0]?.id,
          });
        }
      }

      let attributeNames = [
        ...new Set(
          data?.product_variations?.flatMap((variation: any) =>
            variation?.attributes?.map(
              (attr: any) => attr.attribute_name.en ?? attr.attribute_name.fr
            )
          )
        ),
      ]?.sort((a: any, b: any) => (a < b ? -1 : 1));

      for (const var_ of data?.product_variations ?? []) {
        for (const img of var_?.images) {
          item_images.push({
            url: img,
            price: var_?.price,
            discount: var_?.discount,
            variation_id: var_.id,
          });
        }
      }
      setSelectedImage(item_images?.[0]?.url ?? noImageFile);

      const defaultSelection: any = {};

      attributeNames?.forEach((name: any) => {
        let default_item = data?.product_variations?.find(
          (a: any) => a.is_default
        );
        const firstValue = default_item?.id
          ? default_item?.attributes?.find(
              (attr: any) => attr.attribute_name.en === name
            )?.attribute_value?.en
          : data?.product_variations?.[0]?.attributes?.find(
              (attr: any) => attr?.attribute_name?.en === name
            )?.attribute_value?.en;

        if (firstValue) {
          defaultSelection[name] = firstValue;
        }
      });

      let default_item = data?.product_variations?.find(
        (a: any) => a?.is_default
      );

      // Calculate average rating
      let totalRating = 0;
      if (data?.product_reviews?.length > 0) {
        totalRating = data?.product_reviews?.reduce(
          (sum: any, feedback: any) => sum + Number(feedback?.rating),
          0
        );
      }
      const averageRating =
        Number(totalRating ?? 0) / Number(data?.product_reviews?.length ?? 1);
      console.log(averageRating);

      setState({
        item_id,
        item_images,
        product_details_: data,
        display_sel_variant: default_item?.id
          ? default_item
          : data?.product_variations?.[0],
        default_item: default_item?.id
          ? default_item
          : data?.product_variations?.[0],
        attributeNames,
        screen_loader: false,
        averageRating,
      });

      setTimeout(() => {
        setSelectedAttributes(defaultSelection);
      }, 1000);

      setState({ loading: false });
    } catch (error) {
      console.log(error);
      setState({
        refreshing: false,
      });
    }
  };
  const handleAddToFavorite = async (item: any) => {
    try {
      let obj = {
        product_id: Number(item?.id),
      };

      let resp = await apiServices.postFromApi(
        "c2c-product-wishlists",
        obj,
        ""
      );
      if (resp?.status === 201) {
        handleGetItemByID();
      }
    } catch (error) {}
  };

  const handleSelectAttribute = (
    name: any,
    value: any,
    first_item?: boolean
  ) => {
    // if (first_item) {
    //   let val = {};
    //   for (const key in state.attributeNames) {
    //     if (Object.prototype.hasOwnProperty.call(state.attributeNames, key)) {
    //       const element = state.attributeNames[key];
    //       if (element == name) {
    //         console.log('AYA');
    //         setSelectedAttributes({[element]: value});
    //       } else {
    //         setSelectedAttributes({[element]: null});
    //       }
    //     }
    //   }
    //   // setSelectedAttributes({[name]: value});
    // } else {
    // }
    setSelectedAttributes((prev: any) => ({ ...prev, [name]: value }));
    // setSelectedImage(state?.display_sel_variant?.images?.[0]);
  };

  const handleSelectVariation = () => {
    try {
      const variations = state?.product_details_?.product_variations;
      const selectedVariation = variations?.find((variation: any) =>
        Object.entries(selectedAttributes)?.every(([name, value]) =>
          variation?.attributes?.some(
            (attr: any) =>
              attr?.attribute_name?.en === name &&
              attr?.attribute_value?.en === value
          )
        )
      );
      setState({
        display_sel_variant: selectedVariation,
      });
      console.log("selectedVariation", selectedVariation);
      setSelectedImage(selectedVariation?.images?.[0]);
      console.log("CALLING");
    } catch (error) {}
  };

  const handleGetShopInfo = async (id: any) => {
    try {
      let resp = await apiServices.getFromApi(`c2c-shop-configure/${id}`, "");
      if (resp?.status === 200 || resp?.status === 201) {
        setState({
          shopInfo: resp?.data,
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    console.log("RENDER", selector.cart);
  }, [JSON.stringify(selector.cart)]);

  const handleAddToCart = async () => {
    try {
      const user = getDataFromLocalStorage("user");
      if (!user) {
        setState({ openLoginDialog: true });
        return;
      }

      setState({
        screen_loader: false,
      });

      let cart = getDataFromLocalStorage("cart") ?? ([] as any);
      let c_resp = null;
      const selected_item = selector?.cart?.find(
        (c_item: any) =>
          Number(state.display_sel_variant?.id) == Number(c_item?.id)
      );

      if (selected_item?.quantity) {
        if (
          selected_item?.quantity == state.display_sel_variant?.items_in_stock
        ) {
          Toast.Error(
            `You’ve already added the maximum available quantity (${state.display_sel_variant?.items_in_stock}) of this item to your cart. You cannot add more`
          );
          setState({
            screen_loader: false,
          });
          return;
        }
        if (
          Number(selected_item?.quantity + state.quantity) >
          state.display_sel_variant?.items_in_stock
        ) {
          Toast.showSuccess(
            `You already have ${
              selected_item?.quantity
            } items in your cart. You can only add ${
              state.display_sel_variant?.items_in_stock -
              selected_item?.quantity
            } more item.`
          );
          setState({
            screen_loader: false,
          });
          return;
        }
      }

      if (cart?.length < 1) {
        c_resp = await apiServices.postFromApi(
          "/c2c-carts",
          {
            shop_id: Number(state.display_sel_variant?.shop_id),
            product_variation_id: Number(state.display_sel_variant?.id),
            quantity: Number(state.quantity),
          },
          ""
        );
      } else {
        let filtered_item = cart.filter(
          (i: any) => Number(i?.id) === Number(state.display_sel_variant?.id)
        );
        if (filtered_item?.length < 1) {
          c_resp = await apiServices.postFromApi(
            "/c2c-carts",
            {
              shop_id: Number(state.display_sel_variant?.shop_id),
              product_variation_id: Number(state.display_sel_variant?.id),
              quantity: Number(state.quantity),
            },
            ""
          );
        } else {
          const indexofItem = cart.findIndex(
            (a: any) => Number(a.id) === Number(filtered_item[0]?.id)
          );
          let c = cart[indexofItem];
          if (c?.items_in_stock <= c.quantity) {
            Toast.Error(`You can select a maximum of ${c?.items_in_stock}`);
            setState({
              screen_loader: false,
            });
            return;
          }

          c_resp = await apiServices.postFromApi(
            "/c2c-carts",
            {
              shop_id: Number(c?.shop_id),
              product_variation_id: Number(c?.id),
              quantity: Number(state.quantity),
            },
            ""
          );
        }
      }

      let obj = {
        ...state?.display_sel_variant,
        quantity: c_resp?.data?.quantity,
        shop_details: state.product_details?.shop,
      };

      dispatch(getCart());
      Toast.showSuccess(`${strings.item_added} `);

      setState({
        screen_loader: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // const handleGetShipmentRates = () => {
  //   try {
  //     let api_payload = [];
  //       for (const c_det of state?.product_details_?.service_methods) {
  //         let objc = {
  //           service_method_id:
  //             c_det?.service_method_id ??
  //             c_det.service_methods[0]?.service_method_id,
  //           service_method_key:
  //             c_det?.s_key ??
  //             c_det.service_methods[0]?.s_key,
  //           weight: Number(c_det?.net_weight),
  //           height: Number(c_det?.height ?? 55),
  //           width: Number(c_det?.width ?? 40),
  //           length: Number(c_det?.length ?? 40),
  //           customer: {
  //             address: address_selector.selected_address?.address,
  //             contact: {
  //               firstname: selector?.user.first_name,
  //               lastname: selector?.user.surname,
  //               phone: `${selector?.user.phone_code ?? ''}${
  //                 selector?.user.phone
  //               }`,
  //               email: selector?.user.email,
  //             },
  //           },
  //           shop_details: {
  //             address: c_det?.shop_details[0]?.address ?? ``,
  //             contact: {
  //               firstname: c_det?.shop_details[0]?.first_name,
  //               lastname: c_det?.shop_details[0]?.last_name,
  //               phone: `${c_det?.shop_details[0]?.phone_code ?? ''}${
  //                 c_det?.shop_details[0]?.phone
  //               }`,
  //               email: c_det?.shop_details[0]?.email,
  //               company: c_det?.shop_details[0]?.shop_name,
  //             },
  //           },
  //           id: Number(c_det?.id),
  //         };
  //         api_payload.push(objc);
  //       }
  //       const resp = await apiServices.postFromApi(
  //         'c2c-orders/shipment-price/',
  //         {
  //           data: api_payload,
  //         },
  //         '',
  //       );
  //   } catch (error) {

  //   }
  // }
  const handleCloseLoginPage = () => {
    try {
      setState({ openLoginDialog: false });
    } catch (error) {}
  };

  const handleSearchProducts = useCallback(
    debounce(async (searchValue: any) => {
      try {
        if (!searchValue) {
          setState({ activity: true });
          setState({
            showSearchedData: false,
            allSearchProduct: null,
            activity: false,
          });
        }
        if (searchValue?.trim()) {
          setState({ activity: true });
          let resp = await apiServices.postFromApi(
            "c2c-product-masters/products-search",
            { search: searchValue },
            ""
          );
          console.log("resp", resp);
          if (resp?.data?.length < 1) {
            setState({
              showImg: true,
            });
          }
          setState({
            allSearchProduct: resp?.data,
            activity: false,
            showSearchedData: true,
          });
        }
      } catch (error) {
        console.error("Error while searching products:", error);
      }
    }, 1500),
    []
  );
  const onInputChangeForHeader = (e: any) => {
    try {
      const { value, name } = e.target;
      setState({
        [name]: value,
      });
    } catch (error) {}
  };

  useEffect(() => {
    handleSearchProducts(state.global_search);
  }, [state.global_search, handleSearchProducts]);

  let {
    prod_details,
    related_prod,
    shopInfo,
    item_images,
    display_sel_variant,
  } = state;
  return (
    <>
      <Helmet>
        <title>Product Details | Frank</title>

        <meta name="description" content="" />
      </Helmet>
      <Loader loading={state?.loading} />

      <div style={{ background: defaultLabelColors.main_background_two }}>
        <Grid container>
          <Grid xs={12} item>
            <HeaderForMarketPlace
              onInputChangeForHeader={onInputChangeForHeader}
              {...props}
              {...state}
            />
          </Grid>
        </Grid>

        <DraggableDialog
          maxWidth={"md"}
          open={state?.openLoginDialog}
          handleClose={() => {
            setState({ openLoginDialog: false });
          }}
          dialogBody={
            <DialogContent
              style={{
                display: "flex",
                background: "#FCE6DD",
              }}
              dividers
            >
              <Grid container spacing={spacing.spacing2}>
                <Grid xs={12} item>
                  <AuthAction
                    {...state}
                    {...props}
                    shouldNavigate={false}
                    handleCloseLoginPage={handleCloseLoginPage}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          }
        />

        {state?.showSearchedData ? (
          <div
            style={{
              minHeight: "100vh",
              padding: "0px 50px 0px 50px",
              background: defaultLabelColors.main_background_two,
            }}
          >
            <Grid container spacing={2}>
              <Grid
                xs={12}
                item
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
                onClick={() => {
                  setState({ showSearchedData: false, global_search: "" });
                }}
              >
                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "26px",
                    gap: 10,
                    cursor: "pointer",
                  }}
                  className="display-flex"
                >
                  <ArrowBackIos style={{ fontSize: "22px" }} /> Vendre un
                  article
                </p>
              </Grid>
            </Grid>

            {state?.allSearchProduct?.length < 1 ||
            !state?.allSearchProduct?.length ? (
              <div
                style={{
                  height: "60vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  opacity: "0.5",
                }}
              >
                {state?.showImg && (
                  <img
                    src={noProdFound}
                    alt=""
                    style={{ height: "400px", width: "400px" }}
                  />
                )}
              </div>
            ) : (
              <>
                <p
                  style={{ fontFamily: "Raleway-Semi-Bold", fontSize: "16px" }}
                >
                  {state?.allSearchProduct?.length} annonces
                </p>
                <Grid container spacing={2}>
                  {state?.allSearchProduct
                    ?.slice(0, xl ? 6 : lg ? 4 : md ? 3 : 4)
                    ?.map((i: any, idx: any) => {
                      return (
                        <Grid
                          key={idx}
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          lg={3}
                          xl={2}
                          style={{ cursor: "pointer", maxHeight: "370px" }}
                          onClick={() => {
                            handleOpenProduct(i);
                          }}
                        >
                          <ProductCard
                            i={i}
                            handleAddToFavorite={handleAddToFavorite}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </>
            )}
          </div>
        ) : (
          <div style={{ padding: "30px 80px 50px 80px" }}>
            <Grid container spacing={2} style={{ paddingBottom: "40px" }}>
              <Grid
                xs={12}
                item
                style={{
                  display: "flex",
                  fontFamily: "Raleway-Variable",
                  fontWeight: "500",
                  fontSize: "26px",
                  color: "#4B4A61",
                  gap: 10,
                  alignItems: "center",
                }}
              >
                <ArrowBackIosNew
                  onClick={() => {
                    navigate(-1);
                  }}
                  style={{ cursor: "pointer" }}
                />{" "}
                Maison{" "}
                <svg
                  width="6"
                  height="6"
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3" cy="3" r="3" fill="#626175" />
                </svg>
                {prod_details
                  ? handleLocalize({
                      en: prod_details?.category_name?.en,
                      fr: prod_details?.category_name?.fr,
                    })
                  : ""}
                <svg
                  width="6"
                  height="6"
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3" cy="3" r="3" fill="#626175" />
                </svg>
                <h1
                  style={{ fontWeight: "700", fontSize: "26px" }}
                  className="elipsis-title"
                >
                  {prod_details
                    ? handleLocalize({
                        en: prod_details?.product_title?.en,
                        fr: prod_details?.product_title?.fr,
                      })
                    : ""}
                </h1>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{
                background: "#fff",
                borderRadius: "16px",
                padding: "20px",
                gap: 20,
              }}
            >
              <Grid
                xs={12}
                md={5.8}
                item
                style={{
                  borderRadius: "16px",
                  height: "100%",
                  padding: 0,
                }}
              >
                <Card
                  elevation={0}
                  sx={{
                    borderRadius: "32px",
                    overflow: "hidden",
                    border: "1px solid #E0E0E0",
                  }}
                >
                  <Box
                    position="relative"
                    sx={{ borderRadius: "32px 32px 0px 0px" }}
                  >
                    <div style={{ height: "480px" }} className="display-flex">
                      <CardMedia
                        component="img"
                        image={selectedImage ?? ""}
                        alt="Product Image"
                        sx={{
                          objectFit: "contain",
                          width: "98%",
                          height: "460px",
                          borderRadius: "32px 32px 0px 0px",
                        }}
                      />
                    </div>
                    {prod_details?.is_particular && (
                      <Box
                        component="img"
                        src={particularLabel}
                        alt="Discount Label"
                        sx={{
                          position: "absolute",
                          top: "0px",
                          left: "0px",
                          width: "150px",
                          height: "auto",
                        }}
                      />
                    )}
                  </Box>

                  <CardContent>
                    <div
                      style={{
                        display: "flex",
                        // justifyContent: "center",
                        alignItems: "baseline",
                        marginBottom: "30px",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <p
                          style={{
                            fontFamily: "Raleway-Bold",
                            fontSize: 24,
                          }}
                        >
                          {handleLocalize({
                            en: prod_details?.product_title?.en,
                            fr: prod_details?.product_title?.fr,
                          }) ?? ""}

                          {" - "}

                          <span
                            style={{
                              fontFamily: "Raleway-Bold",
                              fontSize: 24,
                              color: "#8F8E9C",
                            }}
                          >
                            {handleLocalize(prod_details?.brand_name)}
                            {" - "}
                            {display_sel_variant?.attributes?.map(
                              (i: any, idx: number) =>
                                `${handleLocalize(i.attribute_value)}${
                                  display_sel_variant?.attributes?.length - 1 ==
                                  idx
                                    ? ""
                                    : " - "
                                }`
                            )}
                          </span>
                        </p>
                      </div>

                      <div
                        style={{
                          color: defaultLabelColors.main_blue,
                          fontFamily: "Raleway-Bold",
                          fontSize: 24,
                        }}
                      >
                        {/* {display_sel_variant?.discount ? (
                        <span style={{ color: "lightgray", paddingRight: 20 }}>
                          <s>
                            {" "}
                            {numberFormatWithoutCurrency(
                              display_sel_variant?.price
                            )}{" "}
                            €{" "}
                          </s>
                        </span>
                      ) : null} */}
                        {display_sel_variant?.discount
                          ? numberFormatWithoutCurrency(
                              display_sel_variant?.price -
                                display_sel_variant?.discount
                            )
                          : display_sel_variant?.price
                          ? display_sel_variant?.price
                          : numberFormatWithoutCurrency(
                              display_sel_variant?.price
                            ) ?? ""}
                        €
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        maxWidth: "100%",
                        columnGap: 10,
                      }}
                    >
                      {state?.item_images?.map((item: any, idx: number) => {
                        return (
                          <div
                            className="display-flex"
                            style={{
                              height: 114,
                              width: 114,
                              cursor: "pointer",
                              borderRadius: 16,
                              border: `1px solid ${
                                selectedImage === item?.url
                                  ? defaultLabelColors.main_orange
                                  : "#D9D9D9"
                              } `,
                              display: idx > 4 ? "none" : "block",
                            }}
                            onClick={() => {
                              const filtered_variation =
                                state.product_details_?.product_variations?.filter(
                                  (i: any) =>
                                    Number(i.id) === Number(item?.variation_id)
                                );
                              console.log(
                                "filtered_variation",
                                filtered_variation
                              );

                              setState({
                                display_sel_variant: filtered_variation[0],
                              });
                              setSelectedImage(item?.url);
                            }}
                          >
                            <img
                              src={item?.url}
                              alt="prod-img"
                              style={{
                                objectFit: "contain",
                                height: 114,
                                width: 114,
                                borderRadius: 16,
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </CardContent>
                </Card>
                <Card
                  elevation={0}
                  sx={{
                    borderRadius: "32px",
                    overflow: "hidden",
                    border: "1px solid #E0E0E0",
                    marginTop: "15px",
                    padding: "15px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      fontFamily: "Raleway-Variable",
                      fontWeight: "600",
                    }}
                  >
                    Description
                  </p>
                  {/* <div style={{ margin: 10 }}>
                  <table
                    style={{
                      border: `${
                        state?.product_details_?.product_specifications
                          ? "1px"
                          : "0px"
                      } solid #ddd`,
                      padding: state?.product_details_?.product_specifications
                        ? "5px"
                        : "0px",
                      gap: 5,
                      width: "100%",
                    }}
                  >
                    {state?.product_details_?.product_specifications
                      ? state?.product_details_?.product_specifications?.map(
                          (i: any, idx: any) => {
                            return (
                              <tr key={idx}>
                                <td
                                  style={{
                                    background: "lightgrey",
                                    fontFamily: "Raleway-Bold",
                                    padding: 5,
                                    textAlign: "left",
                                  }}
                                >
                                  {i?.specification_name?.en}
                                </td>
                                {i?.product_specification_values?.map(
                                  (j: any) => {
                                    return (
                                      <td
                                        style={{
                                          fontFamily: "Raleway-Variable",
                                          textAlign: "left",
                                        }}
                                      >
                                        {j?.specification_value?.en}{" "}
                                      </td>
                                    );
                                  }
                                )}
                              </tr>
                            );
                          }
                        )
                      : null}
                  </table>
                </div> */}
                  {/* {prod_details?.product_desc && (
                  <Box
                    sx={{
                      maxHeight: showFullDescription ? "none" : "80vh",
                      overflow: showFullDescription ? "visible" : "hidden",
                      position: "relative",
                    }}
                  >
                    <ReactQuill
                      value={prod_details?.product_desc}
                      readOnly
                      theme="snow"
                      modules={{ toolbar: false }}
                      style={{
                        pointerEvents: "none",
                        border: "none",
                        margin: 0,
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                      }}
                    />
                  </Box>
                )}

                {!showFullDescription && (
                  <Box
                    sx={{
                      textAlign: "center",
                      background:
                        "linear-gradient(to bottom, rgba(255,255,255,0), white)",
                      padding: "10px 0",
                    }}
                  >
                    {prod_details?.product_desc?.length > 1000 ? (
                      <Button
                        variant="contained"
                        onClick={toggleDescription}
                        sx={{
                          borderRadius: "12px",
                          textTransform: "none",
                          fontFamily: "Raleway-Variable",
                          fontSize: "14px",
                          fontWeight: "600",
                          background: defaultLabelColors.main_orange,
                          color: "#fff",
                          outline: "#ee6931",
                        }}
                      >
                        Show More
                      </Button>
                    ) : null}
                  </Box>
                )}

                {showFullDescription && (
                  <Box
                    sx={{
                      textAlign: "center",
                      background:
                        "linear-gradient(to bottom, rgba(255,255,255,0), white)",
                      padding: "10px 0",
                    }}
                  >
                    {prod_details?.product_desc && (
                      <Button
                        variant="contained"
                        onClick={toggleDescription}
                        sx={{
                          borderRadius: "12px",
                          textTransform: "none",
                          fontFamily: "Raleway-Variable",
                          fontSize: "14px",
                          fontWeight: "600",
                          background: defaultLabelColors.main_orange,
                          color: "#fff",
                          outline: "#ee6931",
                        }}
                      >
                        Show Less
                      </Button>
                    )}
                  </Box>
                )} */}

                  {prod_details?.product_desc ? (
                    <Box
                      sx={{
                        maxHeight: showFullDescription ? "none" : "80vh",
                        overflow: showFullDescription ? "visible" : "hidden",
                        position: "relative",
                      }}
                    >
                      <ReactQuill
                        value={prod_details?.product_desc}
                        readOnly
                        theme="snow"
                        modules={{ toolbar: false }}
                        style={{ pointerEvents: "none", border: "none" }}
                      />
                    </Box>
                  ) : null}

                  {!showFullDescription && (
                    <Box
                      sx={{
                        textAlign: "center",
                        background:
                          "linear-gradient(to bottom, rgba(255,255,255,0), white)",
                        padding: "10px 0",
                      }}
                    >
                      {prod_details?.product_desc?.length > 1000 && (
                        <Button
                          variant="contained"
                          onClick={toggleDescription}
                          sx={{
                            borderRadius: "12px",
                            textTransform: "none",
                            fontFamily: "Raleway-Variable",
                            fontSize: "14px",
                            fontWeight: "600",
                            background: defaultLabelColors.main_orange,
                            color: "#fff",
                            outline: "#ee6931",
                          }}
                        >
                          Show More
                        </Button>
                      )}
                    </Box>
                  )}

                  {showFullDescription && (
                    <Box
                      sx={{
                        textAlign: "center",
                        background:
                          "linear-gradient(to bottom, rgba(255,255,255,0), white)",
                        padding: "10px 0",
                      }}
                    >
                      {prod_details?.product_desc && (
                        <Button
                          variant="contained"
                          onClick={toggleDescription}
                          sx={{
                            borderRadius: "12px",
                            textTransform: "none",
                            fontFamily: "Raleway-Variable",
                            fontSize: "14px",
                            fontWeight: "600",
                            background: defaultLabelColors.main_orange,
                            color: "#fff",
                            outline: "#ee6931",
                          }}
                        >
                          Show Less
                        </Button>
                      )}
                    </Box>
                  )}
                </Card>
              </Grid>

              <Grid
                xs={12}
                md={5.8}
                item
                style={{
                  borderRadius: "16px",
                  height: "100%",
                  padding: 0,
                }}
              >
                <div
                  style={card_style}
                  onClick={() => {
                    navigate(`/shop-details/${shopInfo?.id}`);
                  }}
                >
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: 12,
                        margin: 0,
                      }}
                    >
                      {strings?.seller_details}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: 12,
                      marginTop: 15,
                    }}
                  >
                    <img
                      src={shopInfo?.image ?? no_image_url}
                      style={{
                        height: 60,
                        width: 60,
                        borderRadius: 100,
                        objectFit: "contain",
                        background: color_theme.disabled,
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          columnGap: 16,
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            fontFamily: "Raleway-Bold",
                            display: "flex",
                            fontSize: 18,
                            margin: 0,
                          }}
                        >
                          {shopInfo?.address?.address ?? ""}
                        </p>
                      </div>

                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          columnGap: 10,
                        }}
                      >
                        <Rating
                          iconsCount={5}
                          initialValue={shopInfo?.type_level}
                          fillColor="#FFC403"
                          SVGstrokeColor="#FFC403"
                          size={24}
                          readonly
                        />
                        <p
                          style={{
                            fontFamily: "Raleway-Variable",
                            color: "gray",
                            margin: 0,
                          }}
                        >
                          {`(${shopInfo?.type_level})`}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <Card
                onClick={() => {
                  navigate(`/shop-details/${shopInfo?.id}`);
                }}
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                  padding: "0px 15px",
                  cursor: "pointer",
                }}
              >
                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  {strings?.seller_details}
                </p>
                <p
                  style={{ fontFamily: "Raleway-Variable", fontWeight: "700" }}
                >
                  {shopInfo?.address?.short_address
                    ? shopInfo?.address?.short_address
                    : shopInfo?.address?.address ?? ""}
                </p>
                <p style={{ fontFamily: "Raleway-Variable", color: "gray" }}>
                  <Rating
                    initialValue={shopInfo?.type_level}
                    readonly
                    size={30}
                  />{" "}
                  {`(${shopInfo?.type_level})`}
                </p>
              </Card> */}

                <Card
                  elevation={0}
                  sx={{
                    borderRadius: "32px",
                    overflow: "hidden",
                    border: "1px solid #E0E0E0",
                    padding: "15px 15px",
                    marginTop: "15px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        flex: 1,
                        fontFamily: "Raleway-Variable",
                        fontWeight: "700",
                      }}
                    >
                      {prod_details
                        ? handleLocalize({
                            en: prod_details?.product_title?.en,
                            fr: prod_details?.product_title?.fr,
                          })
                        : ""}{" "}
                    </div>
                    <div
                      style={{
                        color: defaultLabelColors.main_blue,
                        fontWeight: "700",
                        fontFamily: "Raleway-Variable",
                        fontSize: "18px",
                      }}
                    >
                      {display_sel_variant?.discount ? (
                        <span style={{ color: "lightgray", paddingRight: 20 }}>
                          <s>
                            {" "}
                            {numberFormatWithoutCurrency(
                              display_sel_variant?.price
                            )}{" "}
                            €{" "}
                          </s>
                        </span>
                      ) : null}
                      {display_sel_variant?.discount
                        ? numberFormatWithoutCurrency(
                            display_sel_variant?.price -
                              display_sel_variant?.discount
                          )
                        : numberFormatWithoutCurrency(
                            display_sel_variant?.price
                          ) ?? ""}{" "}
                      €
                    </div>
                  </div>

                  {/* <p style={{ fontFamily: "Raleway-Variable", fontSize: 14 }}>
                  Livraison disponible à partir de{" "}
                  <span
                    style={{
                      fontWeight: "700",
                      color: defaultLabelColors.main_blue,
                    }}
                  >
                    {" "}
                    3,90€{" "}
                  </span>
                </p> */}
                  {state?.product_details_?.service_methods?.map(
                    (s_methods: any) => {
                      return (
                        <p
                          style={{
                            fontFamily: "Raleway-Variable",
                            fontSize: 14,
                          }}
                        >
                          {handleLocalize({
                            en: s_methods?.service_method_name?.en,
                            fr: s_methods?.service_method_name?.fr,
                          })}

                          {/* <span
                          style={{
                            fontWeight: "700",
                            color: defaultLabelColors.main_blue,
                            paddingLeft:10
                          }}
                        >
                          Gratuit
                        </span> */}
                        </p>
                      );
                    }
                  )}
                </Card>

                <Card
                  elevation={0}
                  sx={{
                    borderRadius: "32px",
                    overflow: "hidden",
                    border: "1px solid #E0E0E0",
                    padding: "15px 15px",
                    marginTop: "15px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontWeight: 600,
                      gap: 10,
                      fontSize: 14,
                    }}
                  >
                    <LockIcon /> Paiement sécurisé
                  </p>
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontWeight: 600,
                      gap: 10,
                      fontSize: 14,
                    }}
                  >
                    <BoXIcon /> Retours acceptés dans un délai de 14 jours
                  </p>
                </Card>

                <div
                  className="display-flex"
                  style={{ gap: 10, paddingTop: 10 }}
                >
                  <Button
                    fullWidth
                    disabled={
                      display_sel_variant?.items_in_stock < 1 ? true : false
                    }
                    variant="contained"
                    sx={{
                      backgroundColor: defaultLabelColors.white, // Same as the blue color in the image
                      color: "#000",
                      borderRadius: "16px",
                      padding: "10px 20px",
                      textTransform: "none",
                      fontSize: "16px",
                      fontFamily: "Raleway-Variable",
                      fontWeight: "bold",
                      "&:hover": {
                        backgroundColor: "#fff",
                      },
                    }}
                    onClick={() => {
                      const user = getDataFromLocalStorage("user");
                      if (!user) {
                        setState({ openLoginDialog: true });
                        return;
                      }
                    }}
                  >
                    Retrait en magasin
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleAddToCart}
                    fullWidth
                    disabled={
                      display_sel_variant?.items_in_stock < 1 ? true : false
                    }
                    sx={{
                      backgroundColor:
                        display_sel_variant?.items_in_stock < 1
                          ? "lightGrey"
                          : defaultLabelColors.main_blue, // Same as the blue color in the image
                      color: "#fff",
                      borderRadius: "16px",
                      padding: "10px 20px",
                      textTransform: "none",
                      fontSize: "16px",
                      fontFamily: "Raleway-Variable",
                      fontWeight: "bold",

                      "&:hover": {
                        backgroundColor:
                          display_sel_variant?.items_in_stock < 1
                            ? "lightGrey"
                            : "#3f00ff",
                      },
                    }}
                  >
                    <Badge
                      badgeContent={
                        <AddCircleOutline
                          sx={{
                            fontSize: "14px",
                            backgroundColor: "#FF6E40",
                            borderRadius: "50%",
                            padding: "2px",
                          }}
                        />
                      }
                      sx={{ marginRight: "10px" }}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <ShoppingBagOutlined sx={{ fontSize: "24px" }} />
                    </Badge>
                    Ajouter au panier
                  </Button>
                </div>
                <Card
                  elevation={0}
                  sx={{
                    overflow: "hidden",
                    padding: "15px 15px",
                    marginTop: "15px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontWeight: "700",
                    }}
                  >
                    À propos de cet article :{" "}
                    <>
                      {display_sel_variant?.items_in_stock === 0 ? (
                        <span
                          style={{
                            fontSize: "18px",
                            color: color_theme.red,
                            fontFamily: "Raleway-Bold",
                          }}
                        >
                          Out of stock 😔
                        </span>
                      ) : display_sel_variant?.items_in_stock < 5 ? (
                        <span
                          style={{
                            fontSize: "18px",
                            color: color_theme.red,
                            fontFamily: "Raleway-Bold",
                          }}
                        >
                          Low in stock
                        </span>
                      ) : display_sel_variant?.items_in_stock >= 5 &&
                        display_sel_variant?.items_in_stock < 15 ? (
                        <span
                          style={{
                            fontSize: "18px",
                            color: color_theme.red,
                            fontFamily: "Raleway-Bold",
                          }}
                        >
                          only {display_sel_variant?.items_in_stock} left
                        </span>
                      ) : (
                        <span
                          style={{
                            fontSize: "18px",
                            color: defaultLabelColors.main_blue,
                            fontFamily: "Raleway-Bold",
                          }}
                        >
                          in stock
                        </span>
                      )}
                    </>
                  </p>

                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontWeight: "500",
                      fontSize: 16,
                    }}
                  >
                    Marque :
                    <span
                      style={{
                        fontFamily: "Raleway-Bold",
                        paddingLeft: "10px",
                      }}
                    >
                      {prod_details
                        ? handleLocalize({
                            en: prod_details?.brand_name?.en,
                            fr: prod_details?.brand_name?.fr,
                          })
                        : ""}
                    </span>
                  </p>
                  {state?.attributeNames?.length < 1 ||
                  state?.attributeNames === undefined ||
                  state?.attributeNames?.[0] === undefined ? null : (
                    <Box>
                      {state?.attributeNames?.map((name: any, idx: any) => {
                        console.log("attributeNames", state?.attributeNames);

                        const options = [
                          ...new Set(
                            state?.product_details_?.product_variations?.flatMap(
                              (variation: any) =>
                                variation.attributes
                                  ?.filter(
                                    (attr: any) =>
                                      attr?.attribute_name?.en === name
                                  )
                                  ?.map(
                                    (attr: any) => attr?.attribute_value?.en
                                  )
                            )
                          ),
                        ];

                        return (
                          <Box
                            key={`VARIATION_NAME_${name}`}
                            mb={3}
                            mt={idx === 0 ? 3 : 0}
                            sx={{
                              display: name ? "flex" : "none",
                              fontFamily: "Raleway-Variable",
                              fontWeight: "500",
                              fontSize: 16,
                              gap: "10px",
                            }}
                          >
                            <p
                              style={{
                                fontFamily: "Raleway-Variable",
                                fontSize: 18,
                                margin: 0,
                              }}
                            >
                              {name ?? ""} :
                            </p>
                            <Select
                              value={localAttributes[name] || ""}
                              onChange={(e) =>
                                handleChange(name, e.target.value)
                              }
                              displayEmpty
                              variant="outlined"
                              sx={select_feild_style}
                              renderValue={(selected) => {
                                if (!selected) {
                                  return (
                                    <span style={{ color: "#3026f1" }}>
                                      Select {name}
                                    </span>
                                  );
                                }
                                return selected;
                              }}
                            >
                              {options?.map((option: any, index: any) => {
                                const isOptionAvailable =
                                  state?.product_details_?.product_variations?.some(
                                    (variation: any) =>
                                      Object.entries({
                                        ...localAttributes,
                                        [name]: option,
                                      }).every(([attrName, attrValue]) =>
                                        variation?.attributes?.some(
                                          (attr: any) =>
                                            attr.attribute_name.en ===
                                              attrName &&
                                            attr.attribute_value.en ===
                                              attrValue
                                        )
                                      )
                                  );

                                return (
                                  <MenuItem
                                    key={`VARIATION_ITEM_${index}`}
                                    value={option}
                                    disabled={!isOptionAvailable}
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      color: isOptionAvailable
                                        ? "#3026f1"
                                        : "#ccc",
                                      "&.Mui-disabled": {
                                        color: "#ccc",
                                      },
                                    }}
                                  >
                                    {option}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                </Card>
              </Grid>
            </Grid>

            {state?.product_details_?.related_product?.length < 1 ? null : (
              <Grid container spacing={2}>
                <Grid
                  xs={12}
                  item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "-35px",
                  }}
                >
                  <p style={{ flex: 1 }} className="page-title-two">
                    Produits similaires à cet article
                  </p>
                </Grid>

                {state?.product_details_?.related_product
                  ?.slice(0, 4)
                  ?.map((i: any, idx: any) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        lg={2}
                        xl={2}
                        style={{ cursor: "pointer", maxHeight: "380px" }}
                        onClick={() => {
                          handleOpenProduct(i);
                        }}
                      >
                        <Card
                          sx={{
                            borderRadius: "32px",
                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                            overflow: "hidden",
                            minHeight: "280px",
                          }}
                        >
                          <Box position="relative">
                            <div style={{ height: "130px" }}>
                              <CardMedia
                                component="img"
                                image={
                                  i?.images[0] ??
                                  "https://via.placeholder.com/300x200"
                                }
                                alt="Product Image"
                                sx={{ objectFit: "cover", height: "165px" }}
                              />
                            </div>
                            {i?.is_particular && (
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "-25px",
                                  right: "5px",
                                  height: "20px",
                                  padding: 5,
                                  display: "flex",
                                  fontFamily: "Raleway-Bold",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  background: "#F5D9BC",
                                  borderRadius: 5,
                                  color: "#281208",
                                  fontSize: "14px",
                                }}
                              >
                                {" "}
                                Seconde main
                              </div>
                            )}
                            {/* <IconButton
                            onClick={(e) => {
                              e.stopPropagation();  
                              handleAddToFavorite(i);
                            }}
                            sx={{
                              position: "absolute",
                              top: "10px",
                              right: "10px",
                              backgroundColor: "#fff",
                              padding: "5px",
                              borderRadius: "50%",
                              boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                              "&:hover": { backgroundColor: "#fff" },
                            }}
                          >
                            {i?.wishlist ? (
                              <Favorite sx={{ color: "#ee6931" }} />
                            ) : (
                              <FavoriteBorder sx={{ color: "#626175" }} />
                            )}
                          </IconButton> */}
                          </Box>

                          <CardContent
                            style={{
                              paddingTop: "30px",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "16px",
                                fontFamily: "Raleway-Variable",
                                maxWidth: "300px",
                                textOverflow: "ellipsis",
                                whiteSpace: " nowrap",
                                overflow: "hidden",
                                fontWeight: "bold",
                                color: "#3B3A3A",
                              }}
                            >
                              {handleLocalize({
                                en: i?.product_title?.en,
                                fr: i?.product_title?.fr,
                              }) ?? "N/A"}
                            </p>

                            <Typography
                              variant="body2"
                              color="#8F8F8F"
                              sx={{
                                fontSize: "12px",
                                marginBottom: "4px",
                                fontFamily: "Raleway-Variable",
                              }}
                            >
                              {handleLocalize({
                                en: i?.brand_name?.en,
                                fr: i?.brand_name?.fr,
                              }) ?? "N/A"}{" "}
                            </Typography>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ flex: 1 }}>
                                {i?.is_particular ? null : (
                                  <Typography
                                    variant="body2"
                                    color="#8F8F8F"
                                    sx={{
                                      fontSize: "12px",
                                      fontFamily: "Raleway-Semi-Bold",
                                    }}
                                  >
                                    {i?.address?.city ?? " "},
                                    {i?.address?.country ?? " "} -{" "}
                                    {i?.address?.digital_code ?? " "}
                                  </Typography>
                                )}
                              </div>
                              <div
                                style={{
                                  color: i?.is_particular
                                    ? defaultLabelColors.main_orange
                                    : defaultLabelColors.main_blue,
                                  fontWeight: "700",
                                  fontFamily: "Raleway-Variable",
                                  fontSize: "22px",
                                }}
                              >
                                {i?.variation?.discount ? (
                                  <span
                                    style={{
                                      color: "lightgray",
                                      paddingRight: 20,
                                    }}
                                  >
                                    <s> {i?.variation?.price} € </s>
                                  </span>
                                ) : null}
                                {i?.variation?.discount
                                  ? numberFormatWithoutCurrency(
                                      Number(i?.variation?.price) -
                                        Number(i?.variation?.discount)
                                    )
                                  : i?.variation?.price
                                  ? numberFormatWithoutCurrency(
                                      i?.variation?.price
                                    )
                                  : i?.base_price}
                                €
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
            )}
          </div>
        )}

        <div
          className="main-container"
          style={{
            background: defaultLabelColors.main_background_two,
            marginTop: "90px",
          }}
        >
          <CustomFooter />
        </div>
      </div>
    </>
  );
}

export default ProductDetails;
